
/**
endpoints are the set of constant values that determine our
backend service endpoints. These endpoints are used in unit tests,
backendless development listeners, and $http calls.
    - controls versioning
    - follows pattern that is consumable by the
        ngMockE2E endpoint selection system

NOTE: do not use endpoints directly unless it's being used in
backend-listeners (mocking) or in $resources
*/
angular.module('PatientApp').factory('endpoints', [ 'Config', function(Config){

    var API_VERSION = 'v1',
        API_URI = Config.apiUrl + '/services/' + API_VERSION + '/',
        EXPERIMENT_API_URI = Config.apiUrl + '/v1/experiment/',

        /* istanbul ignore next */
        ep = function endpointFactory( endpointUrl, endpointMatcher ){
            return {
                url: API_URI + endpointUrl,
                match: endpointMatcher || endpointUrl
            };
        },

        experimentEp = function ( endpointUrl, endpointMatcher ) {
            return {
                url: EXPERIMENT_API_URI + endpointUrl,
                match: endpointMatcher || endpointUrl
            };
        },

        endpoints = {};

    endpoints.bills = {};
    endpoints.bills.primary = ep('bills/:id', /\/bills(\/\d+)?$/);
    endpoints.bills.shc = ep('bills/shc/:secureCode', /\/bills\/shc\//);
    endpoints.bills.find = ep('bills/find', /\/bills\/find$/);
    endpoints.bills.link = ep('bills/link', /\/bills\/link/);
    endpoints.bills.cci = ep('bills/cci', /\/bills\/cci/);
    endpoints.bills.findAuthOptions = ep('bills/findAuthOptions', /\/bills\/findAuthOptions/);
    endpoints.bills.firstBillGetCode = ep('bills/firstBillGetCode', /\/bills\/firstBillGetCode/);
    endpoints.bills.findNew = ep('bills/findNew', /\/bills\/findNew/);
    endpoints.bills.payAsGuest = ep('bills/payAsGuest', /\/bills\/payAsGuest/);

    // Currently, we need to dynamically load the pdf in a new tab
    // Since we are not using $http or $resource to build the url
    // we are putting it here for completeness but will do the concatenation
    // in the BillsService method
    endpoints.bills.pdf = ep('bills/pdf/');

    endpoints.patientUser = {};
    endpoints.patientUser.primary = ep('patientUser', /\/patientUser$/);
    endpoints.patientUser.login = ep('patientUser/login', /\/patientUser\/login/);
    endpoints.patientUser.bdLogin = ep('patientUser/token', /\/patientUser\/token/);
    endpoints.patientUser.logout = ep('patientUser/logout', /\/patientUser\/logout/);
    endpoints.patientUser.register = ep('patientUser/register', /\/patientUser\/register/);
    endpoints.patientUser.emailAvailable = ep('patientUser/emailAvailable', /\/patientUser\/emailAvailable/);
    endpoints.patientUser.forgotPassword = ep('patientUser/forgot', /\/patientUser\/forgot$/);
    endpoints.patientUser.resetPassword = ep('patientUser/resetPassword', /\/patientUser\/resetPassword/);
    endpoints.patientUser.acceptTerms = ep('patientUser/acceptTerms', /\/patientUser\/acceptTerms/);
    endpoints.patientUser.feedback = ep('patientUser/feedback', /\/patientUser\/feedback/);
    endpoints.patientUser.confirmPhoneGetCode = ep('patientUser/confirmPhoneGetCode', /\/patientUser\/confirmPhoneGetCode/);
    endpoints.patientUser.confirmPhoneVerifyCode = ep('patientUser/confirmPhoneVerifyCode', /\/patientUser\/confirmPhoneVerifyCode/);
    endpoints.patientUser.forgotPasswordGetCode = ep('patientUser/forgotPasswordGetCode', /\/patientUser\/forgotPasswordGetCode/);
    endpoints.patientUser.forgotPasswordSendEmail = ep('patientUser/forgotPasswordSendEmail', /\/patientUser\/forgotPasswordSendEmail/);
    endpoints.patientUser.forgotPasswordVerifyCode = ep('patientUser/forgotPasswordVerifyCode', /\/patientUser\/forgotPasswordVerifyCode/);
    endpoints.patientUser.verifyEmail = ep('patientUser/verifyEmail', /\/patientUser\/verifyEmail/);
    endpoints.patientUser.verifyPhone = ep('patientUser/verifyPhone', /\/patientUser\/verifyPhone/);
    endpoints.patientUser.confirmEmailSendVerification = ep('patientUser/confirmEmailSendVerification', /\/patientUser\/confirmEmailSendVerification/);
    endpoints.patientUser.getSmsEligibility = ep('patientUser/getSmsEligibility', /\/patientUser\/getSmsEligibility/);
    endpoints.patientUser.deactivateAccount = ep('patientUser/deactivateAccount', /\/patientUser\/deactivateAccount/);

    endpoints.paymentForms = {};
    endpoints.paymentForms.primary = ep('paymentForms/:id', /\/paymentForms(\/\d+)?$/);
    endpoints.paymentForms.routing = ep('paymentForms/routingNumber/:routingNumber', /\/paymentForms\/routingNumber\/\d{9}$/);
    endpoints.paymentForms.prepGateway = ep('paymentForms/prepGateway', /\/paymentForms\/prepGateway/);
    endpoints.paymentForms.confirm = ep('paymentForms/confirm', /\/paymentForms\/confirm/);

    endpoints.payments = {};
    endpoints.payments.primary = ep('payments/:paymentId', /\/payments(\/\d+)?$/);
    endpoints.payments.prepGateway = ep('payments/prepGateway', /\/payments\/prepGateway/);
    endpoints.payments.confirm = ep('payments/confirm', /\/payments\/confirm/);

    endpoints.paymentPlans = {};
    endpoints.paymentPlans.primary = ep('paymentPlans/:planId', /\/paymentPlans(\/\d+)?$/);
    endpoints.paymentPlans.updateMethod = ep('paymentPlans/updateMethod', /\/paymentPlans\/updateMethod$/);

    endpoints.providers = {};
    endpoints.providers.primary = ep('providers/:id', /\/providers(\/[0-9a-zA-Z]+)?$/);

    // Currently, we build the provider logo url based on the provider id.
    // But, since we are not using, $http, we can't intercept it and thus
    // don't need a matcher
    endpoints.providers.logo = ep('providers/logo');


    endpoints.accounts = {};
    endpoints.accounts.primary = ep('accounts/:id/activity', /\/accounts\/\d+\/activity$/);

    endpoints.masterLinks = {};
    endpoints.masterLinks.primary = ep('masterLinks/unverified', /\/masterLinks\/unverified/);
    endpoints.masterLinks.verify = ep('masterLinks/:id/verify', /\/masterLinks\/\d+\/verify$/);

    endpoints.messages = {};
    endpoints.messages.primary = ep('messages', /\/messages$/);
    endpoints.messages.details = ep('messages/:id/details', /\/messages\/\d+\/details$/);
    endpoints.messages.attachment = ep('messages/downloadAttachment', /\/messages\/downloadAttachment$/);

    endpoints.notifications = {};
    endpoints.notifications.primary = ep('notifications', /\/notifications$/);
    endpoints.notifications.updateNotificationReadLog = ep('notifications/updateNotificationReadLog', /\/notifications\/updateNotificationReadLog$/);


    endpoints.contact = {};
    endpoints.contact.primary = ep('contact', /\/contact$/);

    endpoints.usageData = {};
    endpoints.usageData.record = ep('usageData/record', /\/usageData\/record$/);
    endpoints.usageData.log = ep('usageData/log', /\/usageData\/log$/);

    endpoints.financing = {};
    endpoints.financing.getOptions = ep('financing/getOptions', /\/financing\/getOptions$/);
    endpoints.financing.chooseOption = ep('financing/chooseOption', /\/financing\/chooseOption$/);
    endpoints.financing.getVendorLogo = ep('financing/getVendorLogo', /\/financing\/getVendorLogo(\/[0-9a-zA-Z]+)?$/);
    endpoints.financing.downloadFinancingDoc = ep('financing/downloadFinancingDoc', /\/financing\/downloadFinancingDoc$/);
    endpoints.financing.getFinancingVendorDoc = ep('financing/getFinancingVendorDoc', /\/financing\/getFinancingVendorDoc\/(\/[0-9a-zA-Z]+)\/(\/[0-9a-zA-Z]+)$/);
    endpoints.financing.chargeFinancingPaymentFormFirstTime = ep('financing/chargeFinancingPaymentFormFirstTime', /\/financing\/chargeFinancingPaymentFormFirstTime$/);
    endpoints.financing.chargeExistingFinancingAccount = ep('financing/chargeExistingFinancingAccount', /\/financing\/chargeExistingFinancingAccount$/);

    endpoints.experiment = {};
    endpoints.experiment.getVariation = experimentEp(':name/variation', /\/[0-9a-zA-Z]+\/variation(\?identifier=[0-9a-zA-Z]+)?$/);
    endpoints.experiment.addEvent = experimentEp(':name/event', /\/[0-9a-zA-Z]+\/event$/);

    return endpoints;

}]);
