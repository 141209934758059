
angular.module('PatientApp').controller('DefaultCtrl', function($scope, $rootScope, $state, $filter, $log, $window, AuthService){

    // User already has an auth token, send
    // them to their dashboard
    if(AuthService.hasAccess()){
        $state.go('app.dashboard');
        return;
    }


    if($state.is('app.default.forgotPassword')){

        // open the login modal in a way that will
        // allow us to set the password then
        // have the user login from there
        AuthService.requireAuthedUser({
            openSection: 'resetPassword'
        }).then(function(){

            // if the user goes all the way to the
            // point of logging in, take them
            // where they should go
            // Note: we should not get here
            // if the user simply reset their password
            $state.go('app.dashboard');
        });
    }




    $scope.login = function(){
        AuthService.requireAuthedUser({
            openSection: 'login'
        }).then(function(){
            $state.go('app.dashboard');
        }).catch(function(err){
            $log.info(err);
        });
    };

    // to pass to the scode-lookup directive

    $scope.needHelp = function(){
        var tw = $rootScope.transworld ? '-tw' : '';
        $scope.$emit('simpleModal:showPrompt',{
            header: $filter('translate')('landing.needHelpHeader'),
            subcontent: $filter('applySecureCodeNaming')($filter('translate')('landing.needHelpContent')) +
                '<br /><br /><img class="needhelp' + tw + '"version-src="assets/images/needhelp' + tw + '.png"/>',
            actions: [{
                label: $filter('translate')('actions.close'),
                includeClasses: 'button-primary'
            }]
        });

    };

    $scope.contactUs = function(){
        // todo: add tracking

        // currently we only want to open the url in a new tab for the user
        $window.open('https://www.patientco.com/contact-us/', 'ContactPatientco');
    };

    $scope.learnMore = function(){
        // todo: add tracking
        $window.open('https://www.patientco.com/happy-patients/', '_blank');
    };

});
