// ErrorsService is our attempt to wrangle the messy business of
// sychconizing error values from the rest service and the app state

angular.module('PatientApp').factory('ErrorsService', function(_){

    var _errors = {
            UNKNOWN_ERROR: 'UNKNOWN_ERROR',
            NETWORK_ERROR: 'NETWORK_ERROR',
            UNAUTHORIZED_ACCESS: 'UNAUTHORIZED_ACCESS',
            INVALID_PARAMETERS: 'INVALID_PARAMETERS',
            STUBBED_METHOD: 'STUBBED_METHOD',
            RATE_LIMIT_EXCEEDED: 'RATE_LIMIT_EXCEEDED',

            // updating user specific
            INVALID_PASSWORD: 'INVALID_PASSWORD',
            INVALID_TOKEN: 'INVALID_TOKEN',

            // Login specific
            LOGIN_BLOCKED: 'LOGIN_BLOCKED',

            // Bill Find specific errors
            SECURECODE_BLOCKED: 'SECURECODE_BLOCKED',
            SECURECODE_NOT_FOUND: 'SECURECODE_NOT_FOUND',
            PROVIDER_SUSPENDED: 'PROVIDER_SUSPENDED',

            // Payments specific errors
            ACCOUNT_PAID_IN_FULL: 'ACCOUNT_PAID_IN_FULL',
            PAYMENT_PROCESSING_ERROR: 'PAYMENT_PROCESSING_ERROR',
            PAYMENT_INVALID_CARD_NUMBER: 'PAYMENT_INVALID_CARD_NUMBER',
            PAYMENT_CARD_EXPIRED: 'PAYMENT_CARD_EXPIRED',
            PAYMENT_DECLINED: 'PAYMENT_DECLINED',
            PREMATURE_EXPIRATION: 'PREMATURE_EXPIRATION',

            PAYMENT_DUPLICATE: 'PAYMENT_DUPLICATE',
            METHOD_UNAVAILABLE: 'METHOD_UNAVAILABLE',
            GATEWAY_RETRY_REQUESTED: 'GATEWAY_RETRY_REQUESTED',

            // prepGateway needs PAYMENT_PROCESSING_ERROR, INVALID_PARAMETERS

            // encounterLinking specific
            LINK_BLOCKED: 'LINK_BLOCKED',
            LINK_FAILURE: 'LINK_FAILURE'

        },

        // simple check to see if the call was for a REST service
        _isRESTCall = function(response){
            return _.isObject(response) && _.isObject(response.config) && _.includes(response.config.url, '/services/');
        },

        // calculate if the response status is an OK
        _validResponseStatus = function(status){
            // 2xx and 3xx are OK
            return status >= 200 && status < 400;
        };

    return {

        // parse is meant to be an easy way to take a
        // an error string or a rest response and extract the
        // error code out of it.
        // Note: the error will still need to be in the
        // correct naming convention -- we do not convert naming conventions
        resolve: function(errorContainer){
            var error = null;

            if(_.isObject(errorContainer) && _.isString(errorContainer.errorCode)){
                // REST object
                error = _errors[errorContainer.errorCode.toUpperCase()];
            }else if(_.isString(errorContainer)){
                // passed the error key to search on
                error = _errors[errorContainer.toUpperCase()];
            }else if(_isRESTCall(errorContainer) && !_validResponseStatus(errorContainer.status)){
                error = _errors.NETWORK_ERROR;
            }

            return error;
        },

        errors: _errors
    };
});
