angular.module('PatientApp').controller('VerifyCtrl', function( $q, $scope, $rootScope, $state, $filter, $log, $interval, _, PatientUsersService, AuthService, BillsService, ServerStatusService, Compass){
    
    var bill = BillsService.getCurrentBill(),

        // all of the entry points extending from verify
        // need the bill to be linked asap. So this method
        // is used to make sure that happens from these touch points
        getUserAndLink = function(){
            var deferred = $q.defer();

            AuthService.requireAuthedUser().then(function(user){
                
                if(!bill.linked){
                    // link this bill with the current user - async
                    BillsService.link(bill.secureCode, bill.billAmount)
                        .catch(function() {
                            $interval(function() {
                                //if first call fails, try again.  yes, this is not very robust.  there's almost certainly a better solution out 
                                //there, but for the time being, we're hoping that calling this endpoint twice (if necessary) will be enough.  we're
                                //talking about network failures here, not business logic errors.  if the first call fails, it's most likely because 
                                //there was a blip.  in order for payment processing to work, we need the bill to be linked to the current account.  
                                //so, try again - but wait a second to let the network get settled
                                BillsService.link(bill.secureCode, bill.billAmount);
                            }, 1000, 1);
                        });
                }

                deferred.resolve(user);

            }).catch(function(message){
                $log.error(message);
                deferred.reject(message);
            });

            return deferred.promise;
        };

    if(!bill){
        $state.go('app.default');
        return;
    }

    $scope.showDbuModal = function(){

        // explain to the user what they should expect on there account
        // show the tell me more content dialog
        $scope.$emit('simpleModal:showPrompt',{
            includeClasses: 'dbu-modal dbu-modal-more-info',
            header: $filter('translate')($scope.dbuUp ? 'dialogs.dbuReasonHeaderUp' : 'dialogs.dbuReasonHeaderDown'),
            subcontent: '<div class="dbu-reason">' + $filter('translate')('dialogs.dbuReason') + '</div>' +
                    ($scope.dbuUp ? '<b>' + $filter('translate')('dialogs.dbuStillPayable') + '</b>' : ''),
            intent: 'information',
            actions: [{
                includeClasses: 'button-primary',
                label: $filter('translate')('actions.close')
            }, {
                label: $filter('translate')('actions.sendMessage'),
                clickHandler: function(){
                    $scope.messageProvider();
                }
            }]
        });
    };

    // todo: investigate if it is worth adding logic to only
    // show this message to the user once
    if(bill.reflectsDbu()){
        // $scope.showDbuModal();
        $scope.hasDbu = true;
        $scope.dbuUp = bill.balanceDiff() > 0;
    }else {
        $scope.hasDbu = false;
    }

    $scope.bill = bill;

    $scope.translationData = {
        date : $filter('dateFormat')(bill.dueDate, 'longDate'),
        secureCode: bill.secureCode,
        provider: {
            name: bill.providerDetails.name,
            number: bill.providerDetails.billingPhoneNumber
        }
    };

    $scope.notifications = {};

    // notify the user that the payment will go towards the latest bill
    // not the one they searched with
    if(bill.newerBillReturned()){
        $scope.notifications.showNewBillNote = true;
    }

    if (!ServerStatusService.getPaymentStatus('card') && (!ServerStatusService.getPaymentStatus('echeck') || !_.includes(bill.providerDetails.limits.methods, 'echeck'))) {
        $scope.notifications.showGatewayDownNote = true;
    }

    $scope.makePayment = function(){
        getUserAndLink().then(function(){
            $state.go('app.payment.start');
        }).catch(function(message){
            $log.error(message);
        });
    };

    $scope.messageProvider = function(){
        // make sure we link our bill
        getUserAndLink().then(function(){
            $rootScope.$emit('message:compose', /*useCurrentBill*/true);
        });
    };

    $scope.viewBill = function(){

        var userAlreadyLoggedIn = !!PatientUsersService.getCurrentUser();

        getUserAndLink().then(function(){
            
            // since we have to link the user to the bill after asychronously 
            // having them log in, we can not immediately 
            if(userAlreadyLoggedIn){
                BillsService.openPdf(bill.secureCode);  
            }else{
                angular.element(document.getElementById('view-bill-link')).addClass('u-emphasize');
            }
        });
    };

    $scope.goToDashboard = function(){
        getUserAndLink().then(function(){
            $state.go('app.dashboard');
        });
    };

    if ($rootScope.isFirstBill) {
        Compass.capture.event('first-bill', 'verification', 'started');
    }

});