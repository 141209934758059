angular.module('PatientApp').factory('UsageDataService', function( $q, $resource, endpoints){

    var UsageData = $resource(null, null, {
        record: {
            method: 'POST',
            url: endpoints.usageData.record.url
        },
        log: {
            method: 'POST',
            url: endpoints.usageData.log.url
        }
    });

    return {
        record: function(providerId, statType, statGroup, statValue) {
            return UsageData
                .record({'providerId': providerId, 'statType': statType, 'statGroup': statGroup, 'statValue': statValue})
                .$promise.then(function(resp) {
                    if(resp && resp.hasData()){
                        return resp.data;
                    }
                    return $q.reject(resp);
                });
        },
        log: function(type, event, secureCode) {
            return UsageData
                .log({'type': type, 'event': event, 'secureCode': secureCode})
                .$promise.then(function(resp) {
                    if(resp && resp.hasData()){
                        return resp.data;
                    }
                    return $q.reject(resp);
                });
        }
    };
});
