angular.module('PatientApp').controller('SettingsCtrl', function ($scope, $rootScope, $state, $log, $filter, $timeout, _, Config, PatientUsersService, AuthService){

    // bind to the current user so any hydration of
    // the user will be reflected and available on the scope
    var _user = $scope.user = PatientUsersService.getCurrentUser(),

        _setUserKey = function () {
            if (_user.firstName.length <= 12) {
                $scope.usersKey = _user.firstName ? _user.firstName.slice(-1) !== 's' ? 'setting.argsSettings' : 'setting.argsSettingsEndsWithS' : 'setting.plural';
            } else {
                $scope.usersKey = 'setting.plural';
            }
            $scope.translationData = {
                name: _user.firstName
            };
        },
        _refreshUserDetails = function (user) {
            $scope.details = {
                firstName: user.firstName ? user.firstName : '',
                lastName: user.lastName ? user.lastName : '',
                email: user.email ? user.email : '',
                phoneNumber: user.phoneNumber ? user.phoneNumber : '',
                receivesEbills: !!user.receivesEbills,
                receivesSms: !!user.receivesSms
            };

            _refreshConfirmPhoneNotification();
            _refreshConfirmEmailNotification();
        },
        _refreshConfirmPhoneNotification = function () {
            $scope.showConfirmPhone = !_user.phoneNumberConfirmed && !_user.newUser;
        },
        _refreshConfirmEmailNotification = function () {
            var user = PatientUsersService.getCurrentUser();
            $scope.details.email = user.email;
            $scope.showConfirmEmail = !user.emailVerified && !user.newUser && !user.newEmail;
            $scope.confirmEmailMessage = $filter('translate')('actions.confirmEmail', {email: user.email});
        },
        _destroyPhoneConfirmationListener = $rootScope.$on('confirmPhone:confirmed', function () {
            PatientUsersService.fetchUser().then(function (user) {
                _user = user;
                _refreshUserDetails(_user);
            });
        }),
        _destroyUserSettingsUpdatedListener = $rootScope.$on('userSettings:updated', function () {
            _refreshConfirmEmailNotification();
        }),
        _destroyAddPaymentForm = $rootScope.$on('paymentForm:added', function (event, args) {
            PatientUsersService.fetchSavedMethods().then(function () {
                var method = _.find($scope.user.savedMethods, {id: args.paymentFormId});

                $scope.$emit('simpleModal:showPrompt', {
                    header: $filter('translate')('setting.methodAdded', {method: $filter('methodDesc')(method, 'long', '&nbsp;')}),
                    intent: 'success',
                    autoExpire: true
                });
            });
        }),
        _submitSettings = function(currentPassword) {

            $scope.submittingDialog = $filter('translate')('labels.savingChanges');
            $scope.submittingChange = true;

            var payload = _.assign({}, $scope.details, {'currentPassword': currentPassword});

            PatientUsersService.update(payload).then(function(){
                // set what we locally know of the user's updates
                _user = PatientUsersService.getCurrentUser();

                _setUserKey();

                _refreshConfirmPhoneNotification();
                _refreshConfirmEmailNotification();

                $scope.changePosted = true;

                $scope.forms.detailsForm.$setPristine();

                $timeout(function(){
                    $scope.changePosted = false;
                }, 3000);

            })
            .catch(function() {
                $scope.changeFailed = true;

                $timeout(function(){
                    $scope.changeFailed = false;
                }, 3000);
            })
            .finally(function(){
                $scope.submittingDialog = $filter('translate')('actions.saveChanges');
                $scope.submittingChange = false;
            });
        };

    $scope.$on('$destroy', function () {
        _destroyPhoneConfirmationListener();
        _destroyUserSettingsUpdatedListener();
        _destroyAddPaymentForm();
    });

    // set the inital key state
    _setUserKey();

    _refreshUserDetails(_user);


    // this should hydrate the saved methods on the current user
    // and through data binding, should reflect on the scope
    // object without the need to manually do this
    PatientUsersService.fetchSavedMethods().finally(function () {
        $scope.methodsFetched = true;
    });

    $scope.filterMethods = function (methods, types) {
        if(_.isUndefined(methods) || !_.isArray(methods)) {
            return [];
        }

        return _.filter(methods, function (method) {
            return types.indexOf(method.formType) > -1;
        });
    };

    $scope.forms = {detailsForm: null};

    $scope.communicationPreferences = {
        emailNotifications: false,
        smsNotifications: false,
        payByText: false
    };

    // Only show communication settings on demo environment
    $scope.isDemo = (Config.env.indexOf('demo') !== -1);
    $scope.hasNoSavedMethods = function () {
        return $scope.methodsFetched && ($scope.user.savedMethods || []).length === 0;
    };

    $scope.deleteMethod = function (formId) {
        var method = _.find($scope.user.savedMethods, {id: formId});

        if (!method) {
            $log.error('A delete was requested for a form that we do not know about, id:', formId);
            return;
        }

        if (method.hasScheduledPayment) {
            $log.error('A delete was requested for a form that has a scheduled payment, id:', formId);
            return;
        }

        // present confirmation
        $scope.$emit('simpleModal:showPrompt', {
            header: $filter('translate')('setting.confirmDeleteDialog'),
            subcontent: $filter('methodDesc')(method),
            includeClasses: 'settings-delete-method',
            actions: [{
                label: $filter('translate')('setting.deleteMethodArg', {detail: method.formType}),
                includeClasses: 'button-primary',
                clickHandler: function () {
                    // submit the delete request to server
                    PatientUsersService.deleteSavedMethod(method.id).then(function () {
                        // show thank you
                        $scope.$emit('simpleModal:showPrompt', {
                            header: $filter('translate')('setting.argMethodDeleted', {method: $filter('methodDesc')(method, 'long', '&nbsp;')}),
                            intent: 'success',
                            autoExpire: true
                        });
                    });
                }
            }]
        });
    };

    $scope.addNewMethod = function () {
        $rootScope.$emit('paymentForm:prompt');
    };

    $scope.changePassword = function () {
        $scope.$broadcast('changePassword:prompt');
    };

    $scope.moreInfo = function (formId) {
        var method = _.find($scope.user.savedMethods, {id: formId});

        $scope.$emit('simpleModal:showPrompt', {
            header: $filter('translate')('payment.financing.paymentForm.infoHeader', method),
            subcontent: $filter('translate')('payment.financing.paymentForm.infoContent', method),
            includeClasses: 'settings-more-info',
            actions: [{
                label: $filter('translate')('actions.close'),
                includeClasses: 'button-primary'
            }]
        });
    };

    $scope.deactivate = function(){
        $rootScope.$emit('confirmPassword:prompt', {
            'header': $filter('translate')('dialogs.deactivateAccountHeader'),            
            'subheader': $filter('translate')('dialogs.deactivateAccountWarning'),
            'additionalInfo': $filter('translate')('dialogs.deactivateAccountSubheader'),
            'callback': function(currentPassword) {
                PatientUsersService.deactivateAccount(currentPassword)
                    .then(function(){
                        $scope.$emit('simpleModal:showPrompt', {
                            header: $filter('translate')('dialogs.deactivateAccountSuccessHeader'),
                            subcontent: $filter('translate')('dialogs.deactivateAccountSuccessSubheader'),
                            intent: 'success',
                            closeHandler: function() {
                                //wait until the user has seen feedback before logging them out forever
                                AuthService.unauthenticateUser(/*showModal*/ false);
                            }
                        });
                    })
                    .catch(function() {
                        $scope.invalidPassword = true;

                        $timeout(function(){
                            $scope.invalidPassword = false;
                        }, 3000);
                    });
            }
        });
    };


    $scope.changePosted = false;

    $scope.submittingChange = false;
    $scope.submittingDialog = $filter('translate')('actions.saveChanges');

    $scope.postChange = function (continuingSubmission) {

        //If our input is invalid and we are displaying that we are submitting changes
        //We will reset the UI
        if ($scope.forms.detailsForm.$invalid && $scope.submittingChange) {
            $scope.submittingDialog = $filter('translate')('actions.saveChanges');
            $scope.submittingChange = false;
        }

        // supress duplicate submission attempts
        if ($scope.forms.detailsForm.$invalid || $scope.forms.detailsForm.$pristine || ($scope.submittingChange && !continuingSubmission)) {
            return;
        }

        if ($scope.forms.detailsForm.email.$pending || $scope.forms.detailsForm.phone.$pending) {
            $scope.resumbitPostAsyncValidation = true;
            return;
        }

        if ($scope.details.email != $scope.user.email || $scope.details.phoneNumber != $scope.user.phoneNumber) {
            //get the password from the user before submitting it
            $rootScope.$emit('confirmPassword:prompt', {
                'callback': _submitSettings
            });
        }
        else {
            _submitSettings();
        }
    };

    // Callback for email and phone validations on settings form
    // this is needed to handle resubmitting
    // when we try to submit settings change form before we
    // have recieved the async validation
    $scope.asyncValidationComplete = function () {
        if ($scope.resumbitPostAsyncValidation) {
            $scope.resumbitPostAsyncValidation = false;
            $scope.postChange(/*continuingSubmission*/ true);
        }
    };

    $scope.confirmPhone = function () {
        $rootScope.$emit('confirmPhone:prompt');
    };

    $scope.confirmEmail = function () {
        $rootScope.$emit('confirmEmail:prompt');
    };

    $scope.triggerMethodModal = function (method) {
        $rootScope.$emit('simpleModal:showPrompt', {
            header: $filter('translate')('setting.modals.' + method + '.header'),
            subcontent: $filter('translate')('setting.modals.' + method + '.content'),
            actions: [{
                label: $filter('translate')('actions.close'),
                includeClasses: 'button-primary'
            }]
        });
    };

    $scope._ = _;

});
