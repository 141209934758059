angular.module('PatientApp').controller('ContactCtrl', function($rootScope, $scope, $q, $log, $state, $filter, $interval, AuthService, ContactService, BillsService, ErrorsService, PatientUsersService, MoneyUtilsService, ReasonsService) {

    var _reset = function() {
            if ($scope.forms && $scope.forms.contactForm) {
                $scope.forms.contactForm.$setPristine();
            }

            if ($scope.forms && $scope.forms.createForm) {
                $scope.forms.createForm.$setPristine();
            }

            if ($scope.forms && $scope.forms.scEmailForm) {
                $scope.forms.scEmailForm.$setPristine();
            }

            if ($scope.forms && $scope.forms.loginForm) {
                $scope.forms.loginForm.$setPristine();
            }

            var user = PatientUsersService.getCurrentUser();
            $scope.isLoggedIn = !!user;

            $scope.reasonId = '';
            $scope.reasonsObj = ReasonsService.getContactReasons();
            $scope.itemizedBillReasonId = ReasonsService.getItemizedBillReasonId();
            $scope.canMessage = false;

            $scope.contact = {
                email: null,
                secureCode: null,
                billAmount: null,
                password: null,
                confirmPassword: null,
                acceptTerms: false,
                firstName: null,
                lastName: null,
                message: null
            };

            if ($scope.isLoggedIn) {
                $scope.contact.email = user.email;
                $scope.contact.firstName = user.firstName;
                $scope.contact.lastName = user.lastName;
            }


            // Contact Us States
            $scope.currentState = 'main';
            $scope.state = {
                main: {
                    next: 'reason'
                },
                reason: {
                    next: 'bill'
                },
                bill: {},
                login: {
                    next: 'register'
                },
                register: {
                    next: 'forgot'
                },
                zendesk: {}
            };

            // Dialogs
            $scope.verifyDialog = $filter('translate')('actions.verify');
            $scope.verifying = false;

            $scope.submitDialog = $filter('translate')('actions.submit');
            $scope.submitting = false;

            $scope.retrieveDialog = $filter('translate')('actions.retrievePassword');
            $scope.retrieving = false;

            $scope.sendButton = $filter('translate')('actions.sendMessage');
            $scope.sending = false;

            $scope.back = $filter('translate')('actions.back');

            _setSubjects();

            $scope.notifications = {};
        },

        _createMessage = function() {
            $state.go('app.messages', {
                'autofill': true,
                'data': {
                    'reason': $scope.reasonId
                }
            });
        },

        // all of the entry points extending from verify
        // need the bill to be linked asap. So this method
        // is used to make sure that happens from these touch points
        _getUserAndLink = function() {
            var deferred = $q.defer();
            AuthService.requireAuthedUser().then(function(user) {

                // link this bill with the current user - async
                BillsService.link($scope.contact.secureCode, $scope.contact.billAmount)
                    .catch(function() {

                        $interval(function() {
                            //if first call fails, try again.  yes, this is not very robust.  there's almost certainly a better solution out
                            //there, but for the time being, we're hoping that calling this endpoint twice (if necessary) will be enough.  we're
                            //talking about network failures here, not business logic errors.  if the first call fails, it's most likely because
                            //there was a blip.  in order for payment processing to work, we need the bill to be linked to the current account.
                            //so, try again - but wait a second to let the network get settled
                            BillsService.link($scope.contact.secureCode, $scope.contact.billAmount);
                        }, 1000, 1);
                    });
                deferred.resolve(user);

            }).catch(function(message) {
                $log.error(message);
                deferred.reject(message);
            });

            return deferred.promise;
        },

        _setPreviousState = function(nextState) {
            $scope.state[nextState].previous = $scope.currentState;
        },

        _getPreviousState = function() {
            if (!$scope.currentState) {
                return null;
            }
            return $scope.state[$scope.currentState].previous;
        },
        _billCached = function() {
            var _bill = BillsService.getCurrentBill();
            return _bill && MoneyUtilsService.equals($scope.contact.billAmount, _bill.billAmount) && $scope.contact.secureCode === _bill.secureCode;
        },
        _findBillHelper = function() {
            var _bill = BillsService.getCurrentBill(),
                name = _bill.guarantorDetails.name.split(' ');
            if (!$scope.isLoggedIn) {
                $scope.contact.firstName = name[0];
                $scope.contact.lastName = name[1];
            }
            if (!_bill.canMessageAboutBill()) {
                $scope.goNext('zendesk');
                $scope.verifying = false;
                $scope.verifyDialog = $filter('translate')('actions.verify');
            } else {
                if (!$scope.isLoggedIn) {
                    PatientUsersService.emailAvailable($scope.contact.email)
                    .then(function() {
                        // Register
                        $scope.goNext('register');
                    })
                    .catch(function(resp) {
                        if (resp.errorCode) {
                            $scope.forms.scEmailForm.email.$error.unknown = true;
                        } else {
                            $scope.goNext('login');
                        }
                    })
                    .finally(function() {
                        $scope.verifying = false;
                        $scope.verifyDialog = $filter('translate')('actions.verify');
                    });
                } else {
                    $scope.showContact = false;
                    $scope.verifying = false;
                    $scope.verifyDialog = $filter('translate')('actions.verify');
                    _getUserAndLink().then(_createMessage());
                }
            }
        },
        _setSubjects = function() {
            // We don't want to translate what is being sent to Zendesk
            // Separating the wording here
            $scope.subjects = [{
                zen: 'Recovering a lost bill',
                translated: $filter('translate')('contact.help.lostBill')
            }, {
                zen: 'Logging in',
                translated: $filter('translate')('contact.help.loggingIn'),
                hide: $scope.isLoggedIn
            }];
        };

    $scope.cancel = function() {
        _reset();
        $scope.showContact = false;
    };

    //Initialization
    $scope.forms = {
        scEmailForm: null,
        loginForm: null,
        createForm: null,
        contactForm: null // zendesk
    };

    _reset();

    $rootScope.$on('contact:show', function() {
        $scope.isLoggedIn = !!PatientUsersService.getCurrentUser();
        _setSubjects();
        //defaults
        $scope.showContact = true;
        $scope.currentState = 'main';
    });

    $scope.goNext = function(option) {
        var nextState;
        if (option) {
            nextState = $scope.state[$scope.currentState].next = option;
        } else {
            nextState = $scope.state[$scope.currentState].next;
        }

        _setPreviousState(nextState);
        $scope.currentState = nextState;
    };

    $scope.goPrevious = function() {
        $scope.currentState = $scope.state[$scope.currentState].previous;
    };

    $scope.setReason = function(id) {
        $scope.reasonId = id;
    };

    $scope.setSubject = function(subject) {
        $scope.contact.subject = subject;
    };

    $scope.submitVerify = function() {
        $scope.searchErrors = {};
        $scope.emailFailed = false;

        if ($scope.forms.scEmailForm.$invalid || $scope.verifying) {
            return;
        }

        // let the user know we are looking for the bill
        $scope.verifying = true;
        $scope.verifyDialog = $filter('translate')('actions.verifying');

        if (_billCached()) {
            _findBillHelper();
        } else {
            BillsService.find($scope.contact.secureCode, $scope.contact.billAmount)
                .then(function() {
                    _findBillHelper();
                })
                .catch(function(resp) {
                    // could not find bill. Handle error
                    $scope.verifying = false;
                    $scope.verifyDialog = $filter('translate')('actions.verify');

                    switch (ErrorsService.resolve(resp)) {
                        case ErrorsService.errors.SECURECODE_NOT_FOUND:
                            $scope.searchErrors.notFound = true;
                            break;
                        case ErrorsService.errors.SECURECODE_BLOCKED:
                            $scope.searchErrors.throttled = true;
                            break;
                    }
                });
        }

    };


    $scope.submitLogin = function() {
        $scope.submissionNotifications = {};

        if ($scope.forms.loginForm.$invalid || $scope.submitting) {
            return;
        }

        $scope.submitting = true;
        $scope.submitDialog = $filter('translate')('actions.submitting');
        var login = { email: $scope.contact.email, password: $scope.contact.password };
        PatientUsersService.login(login)
            .then(function() {

                $scope.showContact = false;
                $scope.state.login = false;
                _getUserAndLink().then(_createMessage());
            })
            .catch(function(resp) {
                switch (ErrorsService.resolve(resp)) {
                    case ErrorsService.errors.INVALID_PARAMETERS:
                        $scope.submissionNotifications.loginFailed = true;
                        break;
                    case ErrorsService.errors.LOGIN_BLOCKED:
                        $scope.submissionNotifications.userBlocked = true;
                        break;
                }

                $scope.contact.password = '';
                $scope.forms.loginForm.$submitted = false;
            })
            .finally(function() {
                $scope.submitting = false;
                $scope.submitDialog = $filter('translate')('actions.submit');
            });
    };

    $scope.submitRegister = function(continuingSubmission) {

        // reset submitting status if we are invalid but resuming
        if ($scope.forms.createForm.$invalid && $scope.submitting) {
            $scope.submitting = false;
            $scope.submitDialog = $filter('translate')('actions.submit');
        }

        // supress duplicate submission attempts
        if ($scope.forms.createForm.$invalid || ($scope.submitting && !continuingSubmission)) {
            return;
        }

        $scope.submitting = true;
        $scope.submitDialog = $filter('translate')('actions.submitting');

        var registerInfo = {
            email: $scope.contact.email,
            password: $scope.contact.password,
            acceptTerms: $scope.contact.acceptTerms,
            confirmPassword: $scope.contact.confirmPassword,
            firstName: $scope.contact.firstName,
            lastName: $scope.contact.lastName
        };

        PatientUsersService.register(registerInfo)
            .then(function(user) {
                if (user) {

                    $scope.showContact = false;
                    $scope.state.createPassword = false;
                    _getUserAndLink().then(_createMessage());
                }
            })
            .catch(function(message) {
                $log.error(message);
            })
            .finally(function() {
                $scope.submitting = false;
                $scope.submitDialog = $filter('translate')('actions.submit');
            });
    };

    $scope.forgot = function() {
        $scope.showContact = false;
        var deferred = $q.defer();
        $rootScope.$emit('login:showPrompt', deferred, { openSection: 'forgotpassword' });
    };

    // Zendesk
    $scope.sendZenMessage = function() {

        // Make sure form is valid and not submitting
        if ($scope.forms.contactForm.$invalid || $scope.sending) {
            return;
        }

        $scope.sending = true;
        $scope.sendButton = $filter('translate')('actions.sending');

        // get subject if necessary
        if (_getPreviousState() === 'bill') {
            $scope.contact.subject = ReasonsService.getContactReasonById($scope.reasonId);
        }

        ContactService.send($scope.contact)
            .then(function(status) {
                //We're done sending
                $scope.sending = false;
                $scope.sendButton = $filter('translate')('actions.sendMessage');
                // Show a success modal
                if (status === 'OK') {
                    $scope.showContact = false;

                    $rootScope.$emit('simpleModal:showPrompt', {
                        header: $filter('translate')('dialogs.contactSent'),
                        subcontent: $filter('translate')('dialogs.contactThanks'),
                        intent: 'success',
                        autoExpire: true
                    });
                } else {
                    // Display a five hundred error message
                    $scope.notifications.fiveHundred = true;
                }
            });
    };
});
