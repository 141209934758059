angular.module('PatientApp').controller('ChangePasswordCtrl', function($scope, $rootScope, $filter, PatientUsersService, ErrorsService){
    
    $scope.promptVisible = false;

    $scope.$on('changePassword:prompt', function(){
        $scope.promptVisible = true;
    });

    $scope.forms = {
        changePasswordForm: null
    };

    var _clearInputs = function(){
            $scope.fields = {};
            $scope.fields.existingPassword = '';
            $scope.fields.newPassword = '';
            $scope.fields.confirmNewPassword = '';

            if($scope.forms.changePasswordForm){
                $scope.forms.changePasswordForm.$submitted = false;
            }
        },

        _close = function(){
            _clearInputs();
            $scope.promptVisible = false;
        };

    _clearInputs();

    $scope.cancel = function(){
        _close();
    };

    $scope.save = function(){

        $scope.forms.changePasswordForm.$submitted = true;
        $scope.submissionNotifications = {};

        if($scope.forms.changePasswordForm.$invalid){
            return;
        }

        PatientUsersService.update({
            oldPassword: $scope.fields.existingPassword,
            newPassword: $scope.fields.newPassword
        }).then(function(){
            
            _close();
            
            $scope.$emit('simpleModal:showPrompt', {
                header: $filter('translate')('setting.passwordChangedDialog'),
                intent: 'success',
                autoExpire: true
            });
        }).catch(function(resp){

            switch(ErrorsService.resolve(resp.errorCode)){
                case ErrorsService.errors.INVALID_PASSWORD:
                    $scope.submissionNotifications.invalidPassword = true;
                    break;
                default:
                    $scope.submissionNotifications.unableToResetPassword = true;
                    break;
            }
        });

        
    };

});