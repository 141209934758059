angular.module('PatientApp').controller('ProviderDetailsCtrl', function($scope, $rootScope, $state, $filter, $log, _, ProvidersService, AccountsService, BillsService, ContextHelperService, PatientUsersService, LoadingIndicatorService, LinkedAccountsService, Compass){

    var _providerId = $state.params.id,
        _sortAccounts = function(accounts){
            var partitions,
                financed,
                greaterThanZero,
                zero,
                lessThanZero;


            partitions = _.partition(accounts, function( acc ){
                return acc.financedBy && acc.amountDue > 0;
            });

            financed = partitions[0];

            partitions = _.partition(partitions[1], function( acc ){
                return acc.amountDue > 0;
            });

            greaterThanZero = _.sortBy(partitions[0], function(acc){
                return new Date(acc.dueDate);
            });

            partitions = _.partition(partitions[1], function( acc ){
                return acc.amountDue === 0;
            });

            zero = partitions[0];
            lessThanZero = partitions[1];

            return greaterThanZero.concat(financed, lessThanZero, zero);
        },

        _getAccountById = function(id){
            return _.find($scope.provider.accounts, { accountId: id });
        },

        _fetchHistoryForAccount = function(accountId){
            AccountsService.fetchAccountHistory(accountId).then(function(history){
                var account = _getAccountById(accountId);

                account.activity = history;
                account.activityLimit = account.activity.length >= 15 ? 10 : account.activity.length;
                account.resetLimit = function() {
                    //should be able to set this to undefined, but there's a bug with the limitTo filter: https://github.com/angular/angular.js/issues/10484
                    if (account.activity) {
                        account.activityLimit = account.activity.length;
                    }
                };
                account.showMoreVisible = function() {
                    return account.activity && (account.activityLimit < account.activity.length);
                };

                // flag for if we have performed this check
                // TODO: decide how to handle this if we fail
                account.activityFetched = true;
            });
        },

        _refreshLinkableNotification = function() {
            LinkedAccountsService.fetchUnverified().then(function() {
                //if the provider doesn't have an encountergroup, then it cannot have any associated linked accts
                if ($scope.provider.encounterGroupId && LinkedAccountsService.hasUnverified($scope.provider.encounterGroupId)) {

                    Compass.capture.event('account-linking', 'prompt', 'presented-provider-details');

                    $scope.showLinkableNotification = true;
                    $scope.linkableNotificationText = LinkedAccountsService.getNotificationText($scope.provider.encounterGroupId);
                } else {
                    $scope.showLinkableNotification = false;
                }
            });
        },

        _refreshConfirmPhoneNotification = function() {
            var user = PatientUsersService.getCurrentUser();
            $scope.showConfirmPhone = !user.phoneNumberConfirmed && !user.newUser;
        },

        _refreshConfirmEmailNotification = function() {
            var user = PatientUsersService.getCurrentUser();
            $scope.showConfirmEmail = !user.emailVerified && !user.newUser && !user.newEmail;
            $scope.confirmEmailMessage = $filter('translate')('actions.confirmEmail', {email: user.email});
        },

        _loadingKeyProviderDetails = LoadingIndicatorService.loading(),

        _threadCreationListener,

        _destroyVerificationListener = $rootScope.$on('accountVerification:updated', function(){
            _refreshLinkableNotification();
        }),

        _destroyPhoneConfirmationListener = $rootScope.$on('confirmPhone:confirmed', function() {
            _refreshConfirmPhoneNotification();
        }),

        _destroyUserSettingsUpdatedListener = $rootScope.$on('userSettings:updated', function() {
            _refreshConfirmEmailNotification();
        });


    if(!_providerId){
        $state.go('app.dashboard');
    }

    $scope.$on('$destroy', function(){
        _destroyVerificationListener();
        _destroyPhoneConfirmationListener();
        _destroyUserSettingsUpdatedListener();
    });

    _refreshConfirmPhoneNotification();
    _refreshConfirmEmailNotification();

    ProvidersService.getProviders().then(function(providers){

        // if we only have one provider, we omit the
        // providers/dashboard ui state. So do not offer the
        // breadcrumbs for this
        // also dont have the helper if we did not
        // navigate to providers first
        if(providers.length === 1 || $state.previous.name !== 'app.dashboard'){
            return;
        }

        ContextHelperService.showBreadCrumbs({
            label: $filter('translate')('actions.backToDashboard'),
            onClick: function(){
                $state.go('app.dashboard');
            }
        });
    });

    $scope.completeAcct = function(){
        $rootScope.$emit('accountVerification:prompt');
    };

    $scope.confirmPhone = function() {
        $rootScope.$emit('confirmPhone:prompt');
    };

    $scope.confirmEmail = function() {
        $rootScope.$emit('confirmEmail:prompt');
    };

    $scope.translationData = {};
    $scope.provider = {};

    // populate the ui pieces that we already have from the dashboard
    ProvidersService.getProviderById(_providerId).then(function(provider){
        provider.accounts = _sortAccounts(provider.accounts);

        var acctsWithBalance =  _.filter(provider.accounts, function( acc ){
                return acc.amountDue > 0;
            }),
            acctsWithZeroBalance =  _.filter(provider.accounts, function( acc ){
                return acc.amountDue === 0;
            });

        //we need to have at least one non-zero acct or else we would have an empty page with a button to 'show more'
        $scope.shouldHideZeroBalanceAccts = acctsWithBalance.length > 0 && acctsWithZeroBalance.length > 3;
        $scope.zeroBalanceHiddenVars = {count: acctsWithZeroBalance.length};

        $scope.provider = provider;

        _refreshLinkableNotification();

        $scope.translationData.provider = {
            name: provider.name,
            number: provider.billingPhoneNumber
        };

        // for the fist item in accounts we have the history expanded
        // load the history for that account immediately
        if((provider.accounts || []).length > 0){
            _fetchHistoryForAccount(provider.accounts[0].accountId);
        }
    }).catch(function(){
        $log.error('Unable to load provider by Id');
        $state.go('app.dashboard');
    }).finally(function(){
        LoadingIndicatorService.complete(_loadingKeyProviderDetails);
    });


    // we are listening for this so we can reflect in the ui
    // the new threads that were added
    _threadCreationListener = $rootScope.$on('message:newThreadCreated', function(event, creationDetails){

        $log.log('New Thread Created, refetch provider activity for account', creationDetails.accountId);

        var id = creationDetails.accountId,
            shouldUpdateAccountHistory = id && (_getAccountById(id) || {}).activityFetched;

        // only update the account history if the history has already been pulled
        // otherwise we will let the toggling of the activity history section handle
        // fetching
        if(shouldUpdateAccountHistory){
            _fetchHistoryForAccount(creationDetails.accountId);
        }
    });
    $scope.$on('$destroy', function() {
        _threadCreationListener();
    });

    $scope.getHistory = function(id){
        if(!_getAccountById(id).activity){
            _fetchHistoryForAccount(id);
        }
    };

    $scope.makePayment = function(accountId){
        var account = _getAccountById(accountId);
        account.loadingPayment = true;

        $state.go('app.payment.start', {secureCode: account.secureCode, callback: function(){
            account.loadingPayment = false;
        }});
    };


    // return whether or not this item type is something that
    // should be wrapped in a link that will ultimately make
    // a call to openEvent to route it
    $scope.shouldLink = function(itemType, itemId){

        // cant link if we have no item id or if it's
        // not a linking type
        return itemId !== null && _.includes([
            'bill',
            'collectionUpdate',
            'message',
            'payment',
            'declinedPayment',
            'canceledPayment',
            'reversePayment',
            'paymentPlanHeld',
            'canceledPaymentPlan',
            'paymentPlanCreated',
            'paymentPlanDeclined',
            'transitionedEbill', 
            'ebill'
        ], itemType);
    };


    // route the the event to it's proper location
    $scope.openEvent = function(accountId, historyEventItemId, historyEventItemType){

        var account = _getAccountById(accountId),
            item = _.find(account.activity, {itemId: historyEventItemId, itemType: historyEventItemType});

        /*

        CURRENT LOGIC GOALS
            -update if this changes

        bill : 'view bill',
        transitionedEbill : 'view bill',
        ebill : 'view bill',
        collection : 'no link',
        collectionStop : 'no link',
        collectionUpdate : 'view letter aka, view bill',
        collectionExtaction: 'no link',
        correspondence : 'no link',
        correspondenceOnly : 'no link',
        message : 'view message',
        changeOfAddress : 'no link',
        payment : 'view receipt',
        returnMail : 'no link',
        reversePayment : 'no link',
        balanceUpdate : 'no link',
        voidedPayment : 'no link',
        declinedPayment : 'view cancel receipt',
        canceledPayment : 'view receipt',
        paymentPlanHeld : 'plan receipt',
        canceledPaymentPlan : 'plan receipt',
        paymentPlanCreated : 'plan receipt',
        paymentPlanDeclined : 'plan receipt',

        */

        if(_.includes([ 'payment', 'paymentPlan', 'declinedPayment', 'canceledPayment', 'reversePayment' ], item.itemType)){
            $state.go('app.dashboard.provider.details.receipt', {id: _providerId, receiptId: _.parseInt(item.itemId)});
        }
        else if(_.includes([ 'paymentPlanHeld', 'canceledPaymentPlan', 'paymentPlanCreated', 'paymentPlanDeclined'], item.itemType)){
            $state.go('app.dashboard.provider.details.plan-receipt', {id: _providerId, planId: _.parseInt(item.itemId)});
        }
        else if(_.includes(['bill', 'collectionUpdate', 'transitionedEbill', 'ebill'], item.itemType)){
            BillsService.openPdf(item.itemId);
        }
        else if(item.itemType === 'message'){
            $state.go('app.messages.details', {threadId: item.itemId});
        }
    };

    $scope.viewBill = function(sCode){
        BillsService.openPdf(sCode);
    };

    $scope.getSubheaderText = function(account, provider) {
        if (!provider.active) {
            return $filter('translate')('labels.providerDisabledExplanationShort', $scope.translationData);
        }
        else if (account.financedBy) {
            return $filter('translate')('labels.servicedByFinancingVendor', account);
        }
        else if (account.isBadDebt) {
            return $filter('translate')('labels.badDebt');
        }
        else if (account.hasPendingBalance) {
            return '&nbsp';
        }
        else if (account.amountDue === 0) {
            return $filter('translate')('labels.noBalance');
        }
        else if (account.amountDue < 0) {
            return $filter('translate')('labels.negativeBalance');
        }
        else if (account.currentPlan) {
            return $filter('translate')('labels.remainingBalance');
        }
        else if (account.amountDue > 0) {
            if (provider.patientDueDateOverrideText) {
                return provider.patientDueDateOverrideText; //even though a bill can have a dueDate, some providers may not want to show that within the patientApp 
            }
            else {
                return (account.dueDate  ? account.friendlyDueDate : '&nbsp');
            }
        }
    };

    $scope.goToPaymentPlan = function(providerId, paymentPlanId) {
        Compass.capture.event('payment', 'paymentPlan', 'dashboardViewPlanDetailsClicked');
        $state.go('app.dashboard.provider.details.plan-receipt', {id: providerId, planId: _.parseInt(paymentPlanId)});
    };
});
