angular.module('PatientApp').service('AccountsService', function($resource, $filter, _, endpoints){

    var Accounts = $resource(endpoints.accounts.primary.url, { id: '@id' }),

        _eventTypes = {
            bill : 'bill',
            collection : 'collection',
            collectionStop : 'collection-stop',
            collectionUpdate : 'collection-update',
            collectionExtraction: 'collection-extraction',
            correspondence : 'correspondence',
            correspondenceOnly : 'correspondenceOnly',
            message : 'message',
            changeOfAddress : 'changeOfAddress',
            payment : 'payment',
            paymentPlan : 'paymentPlan',
            returnMail : 'returnMail',
            reversePayment : 'reversePayment',
            balanceUpdate : 'balanceUpdate',
            voidedPayment : 'voidedPayment',
            declinedPayment : 'declinedPayment',
            canceledPayment : 'canceledPayment',
            voidedPaymentPlan : 'voidedPaymentPlan',
            canceledPaymentPlan : 'canceledPaymentPlan',
            paymentPlanCreated : 'paymentPlanCreated'
        },

        _getWordLimit = function(string, limit){
            var words = string.split(' '),
                len = words.length;
            return _.slice(words, 0, limit || len).join(' ') + (len > limit ? '...' : '');
        },

        _getFriendlyDialog = function(event){

            var output = '',
                type = event.itemType;

            // future handling for different states
            // handle event special cases where it's not a simple
            // localization mapping
            // if(type === _eventTypes.message){
            //  output = $filter('translate')('accountEvents.' + type, { message: event.description });
            // }else {
            //  output = $filter('translate')('accountEvents.' + type);
            // }


            if(type === _eventTypes.message){
                // truncate the message
                event.description = _getWordLimit(event.description, 20);
            }

            return output;
        };

    this.fetchAccountHistory = function(id){
        return Accounts.get({id: id}).$promise.then(function(resp){
            var history = (resp && resp.getData()) || [];

            _.forEach(history, function(event){
                event.friendlyDialog = _getFriendlyDialog(event);
            });
            
            return history; 
        });
    };
});