angular.module('PatientApp').controller('FullPageNotificationCtrl', function($rootScope, $scope, $q, $log, $state, $filter, $window) {

    var showTerms = function() {
            $window.open('https://www.patientco.com/terms-of-use/', '_blank');
        },

        showContactUs = function() {
            $rootScope.$emit('contact:show');
        },

        showWhyPatientco = function() {
            $window.open('https://www.patientco.com/happy-patients/', '_blank');
        },

        showDashboard = function() {
            $state.go('app.dashboard');
        };

    if ($state.is('app.emailVerified') || $state.is('app.phoneVerified')) {
        $scope.header = $filter('translate')('fullPageNotifications.header.thankYou');
        if ($state.is('app.emailVerified')) {
            $scope.subheader = $filter('translate')('fullPageNotifications.subheader.emailVerified');
        } else if ($state.is('app.phoneVerified')) {
            $scope.subheader = $filter('translate')('fullPageNotifications.subheader.phoneVerified');
        }
        $scope.mainButtonText = $filter('translate')('actions.continue');
        $scope.mainButtonAction = showDashboard;
        $scope.message = $filter('translate')('fullPageNotifications.message.welcome');
        $scope.subButtons = [
            {
                text: $filter('translate')('links.whyPatientco'),
                action: showWhyPatientco
            },
            {
                text: $filter('translate')('links.termsOfService'),
                action: showTerms
            },
            {
                text: $filter('translate')('actions.contactUs'),
                action: showContactUs
            }
        ];
    } else {
        showDashboard();
    }

});
