angular.module('PatientApp').controller('HeaderCtrl', function($rootScope, $scope, $state, $filter, _, AuthService, NotificationsService, ProvidersService, $log){

    $scope.payNewBill = function(){
        // show the new bill modal
        // The modal is in our scope so broadcast down
        $scope.$emit('newBill:showPrompt');
    };

    $scope.logout = function(){

        AuthService.unauthenticateUser(/*showModal*/ true);
    };

    $scope.login = function(){
        AuthService.requireAuthedUser({
            openSection: 'login'
        }).then(function(){
            $state.go('app.dashboard');
        }).catch(function(err){
            $log.info(err);
        });
    };

    $scope.viewNotifications = function() {
        $scope.$emit('notifications:show');
    };

    $scope.isLoggedIn = AuthService.hasAccess;

    // Attach to the provider fetching process to see if we
    // have providers with messaging enabled
    ProvidersService.getProviders().then(function(providers){
        $scope.showMessages = _.some(providers, function(provider){
            return provider.canMessage;
        });
    });

    // Notifications Ctrl will notify when modal has been opened
    $rootScope.$on('notifications:seen', function() {
        $scope.numberOfNewNotifications = NotificationsService.getNumberOfNewNotifications();
    });

    NotificationsService.getNotifications().then(function() {
        $scope.numberOfNewNotifications = NotificationsService.getNumberOfNewNotifications();
    });
});