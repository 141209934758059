
angular.module('PatientApp').factory('KeyboardService', function($window, _){
    
    var _parser = {
        
        // modifiers are things like the shift key or ctrl
        // that can fire the event themselves or could be used
        // in addition to a normal key.
        // We make the assumption throughout the other input checks
        // that if the user is using a modifier, that there intent is not
        // for something like a primitive value
        hasModifier: function(){
            var _this = this, 
                modifierKeyMap = {
                    16:'shiftKey',
                    18:'altKey',
                    17:'ctrlKey',
                    91:'metaKey'
                },
                modKeyNames = _.valuesIn(modifierKeyMap);

            return _.has(modifierKeyMap, this.keyCode) || _.some(modKeyNames, function(modName){
                return _this.keyboardEvent[modName] === true;
            });
        },

        hasShift: function() {
            return this.keyboardEvent.shiftKey === true;
        },
        
        isLetter: function(){
            // exclude modifiers exception for SHIFT
            // on an external numberpad keyCode of 110 is decimal but is 
            // incorrectly registers as a string from the regex
            return (!this.hasModifier() || this.hasShift()) && /[a-zA-Z]/.test(this.keyString) && this.keyCode !== 110;
        },

        isNumber: function(){

            // NOTE: 
            //  48-57 is for the top keyboard entry
            //  96 - 105 is for the number pad
            return !this.hasModifier() && ((this.keyCode >= 48 && this.keyCode <= 57) || (this.keyCode >= 96 && this.keyCode <= 105));
        },

        // Special characters are ones that we 
        // are not alpha numeric
        isSpecial: function(){
            return this.hasModifier() || (this.keyCode >= 106 && this.keyCode <= 222);
        },

        // Edit keys are things like delete, backspace
        // space, arrows, etc.
        isEditorKey: function(){
            
            var editorKeyMap = {
                8: 'backspace', 
                9: 'tab',
                12: 'clear',
                13: 'enter', 
                27: 'esc', 
                37: 'left', 
                38: 'up',
                39: 'right', 
                40: 'down', 
                46: 'delete',
                36: 'home', 
                35: 'end'
            };
            return _.has(editorKeyMap, this.keyCode);
        },


        // This is a convenience method to check to 
        // see if a single or any of the characters are 
        // actually represented by the last key entry
        is: function( /*...*/ ){
            var _this = this;

            return _.some(arguments, function(keyString){
                // This is used as a shortcut method so this will grow as needs arise
                switch (_.trim(keyString).toLowerCase()){
                    case 'shift':
                        return _this.keyboardEvent.shiftKey === true;
                    case 'backspace':
                        return _this.keyCode === 8;
                    case 'delete':
                        return _this.keyCode === 46;
                    case 'enter':
                        return _this.keyCode === 13;
                    default:
                        return _this.keyString === keyString;
                }
            });
        }
    };



    return {

        parseEvent: function(event){

            var key = event.keyCode;

            // normalization of the command key
            // right command on webkit, command on Gecko
            if(key === 93 || key === 224) {
                key = 91; 
            }
            return _.create(_parser, { 
                keyboardEvent: event, 
                keyCode: key, 
                keyString: String.fromCharCode(key)
            });
        }
        
    };
});


// var keys = keyboard.parseEvent(event);


// keys.isLetter 
