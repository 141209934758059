angular.module('PatientApp').controller('NewBillCtrl', function($scope, $rootScope, $state, $filter, _, BillsService, ErrorsService, ProvidersService){
    
    var _reset = function() {
            // attach this on an obj so 
            // ng-include doesnt attach data to
            // a child scope
            $scope.findData = {};
        },
        _hidePrompt = function() {
            $scope.promptVisible = false;
        },
        _destroyShowPrompt = $rootScope.$on('newBill:showPrompt', function(event, promptProperties){
            _reset();

            var config = _.assign({
                showAcctsWithBalances: false
            }, promptProperties);

            if (config.showAcctsWithBalances) {
                ProvidersService.sync().then(function(providers){
                    $scope.accounts = _.chain(providers)
                        .map(function(provider) {
                            //apply some additional info to the models to populate everything the view needs
                            var accounts = provider.accounts;
                            _.forEach(accounts, function(acct) {
                                acct.providerName = provider.name;
                                acct.isPastDueVal = acct.isPastDue ? 0 : 1; //hack to get boolean sorting to work.  lodash sort by order doesn't seem to work - so, 
                                                                            //setting past-due accts to zero will put them at the top
                                acct.buttonText = $filter('translate')('newbill.accountDue' , { providerName: provider.name,
                                    accountNumber: acct.accountNumber,
                                    amountDue: $filter('currency')(acct.amountDue)
                                });
                            });
                            return accounts;
                        })
                        .flatten()
                        .filter(function(acct) {
                            return acct.amountDue > 0 && !acct.financedBy;
                        })
                        .sortByAll(['isPastDueVal', 'providerName', 'dueDate'])
                        .value();

                    $scope.showNewBillForm = _.isEmpty($scope.accounts); //skip to the new-bill form if there are no accts w/ balances
                    $scope.promptVisible = true;
                });
            } else {
                $scope.showNewBillForm = true;
                $scope.promptVisible = true;
            }
        });

    $rootScope.$on('newBill:hidePrompt', _hidePrompt);
    $scope.$on('$destroy', function() {
        _destroyShowPrompt();
    });

    $scope.promptVisible = false;

    $scope.forms = {};

    _reset();

    // initial pay bill state
    $scope.payBillDialog = $filter('translate')('actions.payBill');
    $scope.submitting = false;

    $scope.payNewBill = function() {
        $scope.showNewBillForm = true;
    };

    $scope.selectAccount = function(account) {
        $state.go('app.payment.start', {secureCode: account.secureCode, callback: function(){
            _hidePrompt();
        }});
    };
});
