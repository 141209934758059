angular.module('PatientApp').controller('FeedbackCtrl', function($rootScope, $scope, $state, $filter, $log, _, PatientUsersService, Base64Service) {

    var _defaultScore = 1, //default to happy if there's nothing passed-in
        _reset = function() {
            $scope.feedback = {
                comment: ''
            };
            if ($scope.forms && $scope.forms.feedbackForm) {
                $scope.forms.feedbackForm.$setPristine();
            }
            $scope.submitting = false; 
            $scope.showFeedback = false;
            $scope.buttonText = $filter('translate')('actions.sendFeedback');
        },
        _destroyStateChangeStart = $rootScope.$on('$stateChangeSuccess', function(event, toState, toParams){
            if (toState.name === 'app.default.feedback') {
                var tokenData = JSON.parse(Base64Service.decode(toParams.token.split('.')[1])); //payload is in second part of JWT
                $scope.feedbackSource = tokenData.data[3]; 
                $scope.providerName = tokenData.data[4];

                $scope.translationData = {
                    providerName: $scope.providerName
                };

                $scope.feedback.feedbackToken = toParams.token;

                PatientUsersService.addFeedback($scope.feedback.feedbackToken, toParams.score || _defaultScore).then(function (feedbackId) {
                    $scope.feedback.feedbackId = feedbackId;
                    $scope.setScore(toParams.score ? parseInt(toParams.score) : _defaultScore);

                    //don't show until after first ajax finishes.  otherwise, we give the impression that they can submit a comment 
                    //(and they can't - because they're missinging the id)
                    $scope.showFeedback = true; 
                });
            }
        });

    _reset();

    $scope.forms = {
        feedbackForm: {}
    };

    $scope.setScore = function(newScore) {
        $scope.feedback.happySelected = newScore === 1;

        if ($scope.feedbackSource === 'receiptLink') {
            $scope.feedbackTitle = $filter('translate')('dialogs.feedbackTitleReceiptLink');
            $scope.commentPrompt = $scope.feedback.happySelected ? $filter('translate')('dialogs.feedbackSubtitlePaymentHappy') : $filter('translate')('dialogs.feedbackSubtitlePaymentSad');
        } else if ($scope.feedbackSource === 'closedMessageLink') {
            $scope.feedbackTitle = $filter('translate')('dialogs.feedbackTitleClosedMessageLink', $scope.translationData);
            $scope.commentPrompt = $scope.feedback.happySelected ? $filter('translate')('dialogs.feedbackSubtitleClosedMessageHappy') : $filter('translate')('dialogs.feedbackSubtitleClosedMessageSad');
        }
    };

    $scope.cancel = function() {
        _reset();
        $state.go('app.default');
    };

    $scope.sendFeedback = function() {
        if ($scope.forms.feedbackForm.$invalid || $scope.submitting) {
            return;
        }

        $scope.submitting = true;
        $scope.buttonText = $filter('translate')('actions.sendingFeedback');

        //no error handling below.  we have no legit situations where this call would fail.  if it does, we'll pretend that it went through and clean-up after ourselves
        PatientUsersService.editFeedback($scope.feedback.feedbackId, $scope.feedback.feedbackToken, ($scope.feedback.happySelected ? 1 : 0), $scope.feedback.comment)
            .finally(function(){ 
                _reset();
                
                $state.go('app.default');

                // overlay the thank you modal
                $scope.$emit('simpleModal:showPrompt',{
                    header: $filter('translate')('dialogs.thanksForFeedback'),
                    intent: 'success'
                });      
            });
    };

    $scope.$on('$destroy', function(){
        _destroyStateChangeStart();
    });
});
