angular.module('PatientApp').controller('ReceiptCtrl', function($rootScope, $q, $scope, $state, $filter, $window, $log, _, PaymentsService, CardService, ContextHelperService, MoneyUtilsService, BillsService, ProvidersService, LinkedAccountsService, Compass){


    var _receiptId = $state.params.receiptId,
        _payment,
        _forceProvidersReload,
        _setupUIVariables;

    if(!_receiptId){
        $state.go('app.dashboard');
    }


    $scope.translateData = {};

    _forceProvidersReload = function(){
        ProvidersService.flushProviders();
    };

    _setupUIVariables = function(){
        var totalAmount = MoneyUtilsService.add.apply(null, _.map(_payment.reversals, function(rev){
                return rev.amount;
            }).concat(_payment.amount)),
            formattedOrigAmount = $filter('currency')(_payment.amount),
            formattedTotalAmount = $filter('currency')(totalAmount),
            scheduled = _payment.status.toUpperCase() === PaymentsService.paymentStates.SCHEDULED,
            canceled = _payment.status.toUpperCase() === PaymentsService.paymentStates.CANCELLED;

        $scope.translateData.date = $filter('dateFormat')(_payment.dateCreated, 'MM/dd/yy');

        if(_payment.paymentForm !== null && _payment.paymentForm.formType.toLowerCase() === 'card' && !_payment.paymentForm.cardType){
            $scope.payment.paymentForm.cardType = CardService.getIssuer(_payment.paymentForm.first6Digits);
        }

        if(_payment.paymentForm !== null && _payment.paymentForm.formType.toLowerCase() === 'financing') {
            $scope.financingVendorInfo = $filter('translate')('payment.financing.success.financingSuccessHeader', _payment.paymentForm) + ' ' + $filter('translate')('payment.financing.success.financeSuccessSubContent', _payment.paymentForm);
        }

        // set up lineItems for table summary
        $scope.lineItems = [];

        // balance payment
        $scope.lineItems.push({
            date: scheduled || canceled ? _payment.dateScheduled : _payment.dateProcessed,
            friendlyDialog: $filter('translate')('payment.friendlyType.' + _payment.paymentType) +
                    '<br /><span>' +
                    (_payment.transactionId ? $filter('translate')('receipt.transactionIdArg', {transactionId: _payment.transactionId}) : '') + '</span>',
            amountDialog: formattedOrigAmount
        });

        switch(_payment.status.toUpperCase()){

            case PaymentsService.paymentStates.SCHEDULED:
                $scope.receiptState = $filter('translate')('labels.futurePayment');
                $scope.amountStatus = $filter('translate')( 'labels.argScheduled', { amount: formattedOrigAmount});
                $scope.callOutAmountStatus = $filter('translate')('receipt.argScheduledCallOut', { amount: formattedOrigAmount});
                break;


            case PaymentsService.paymentStates.DECLINED:
                $scope.receiptState = $filter('translate')('labels.declinedPayment');
                $scope.amountStatus = $filter('translate')( 'labels.argDeclined', { amount: formattedOrigAmount});
                $scope.callOutAmountStatus = $filter('translate')('receipt.argDeclinedCallout', { amount: formattedOrigAmount});
                $scope.summationTotalFormatted = $filter('currency')(0);

                // add declined info
                $scope.lineItems.push({
                    date: _payment.dateProcessed,
                    friendlyDialog: $filter('translate')('labels.paymentDeclined'),
                    amountDialog: '<span>' + formattedOrigAmount + '</span>'
                });

                break;


            case PaymentsService.paymentStates.CANCELLED:
            case PaymentsService.paymentStates.VOIDED:
                $scope.receiptState = $filter('translate')('labels.canceledPayment');
                $scope.amountStatus = $filter('translate')( 'labels.argCanceled', { amount: formattedOrigAmount});
                $scope.callOutAmountStatus = $filter('translate')('receipt.argCanceledCallout', { amount: formattedOrigAmount});
                $scope.summationTotalFormatted = $filter('currency')(0);

                // add declined info
                $scope.lineItems.push({
                    date: _payment.dateCanceled,
                    friendlyDialog: $filter('translate')('labels.paymentCanceled'),
                    amountDialog: '<span>' + formattedOrigAmount + '</span>'
                });

                break;


            default:
                // left column values
                $scope.receiptState = $filter('translate')('receipt');
                $scope.amountStatus = $filter('translate')('labels.argPaid' , { amount: formattedTotalAmount});
                // content values
                $scope.callOutAmountStatus = $filter('translate')('receipt.argPaidCallout' , { amount: formattedTotalAmount});
                break;
        }

        if (!_.isEmpty(_payment.reversals)) {
            _.forEach(_payment.reversals, function(reversal) {
                var reversalDesc = '';
                switch(reversal.reason.toUpperCase()){
                    case PaymentsService.reversalReasons.REFUND:
                        reversalDesc = MoneyUtilsService.lessThan(-1 * reversal.amount, _payment.amount) ? $filter('translate')('receipt.partialRefund') : $filter('translate')('receipt.fullRefund');
                        break;
                    case PaymentsService.reversalReasons.CHARGEBACK:
                        reversalDesc = $filter('translate')('receipt.chargeback');
                        break;
                    case PaymentsService.reversalReasons.FAILED_ECHECK:
                        reversalDesc = $filter('translate')('receipt.failedECheck');
                        break;
                    case PaymentsService.reversalReasons.BOUNCED_CHECK:
                        reversalDesc = $filter('translate')('receipt.bouncedCheck');
                        break;
                }

                $scope.lineItems.push({
                    date: reversal.date,
                    friendlyDialog: reversalDesc,
                    amountDialog: $filter('currency')(reversal.amount)
                });
            });

            $scope.summationTotalFormatted = formattedTotalAmount;
        }

        $q.all([
            ProvidersService.getProviderById(_payment.provider.id),
            LinkedAccountsService.fetchUnverified()
        ])
        .then(function(data) {
            var provider = data[0]; //results for first promise.  we don't care about the results from the second promise.

            //only show notification if the provider has a provider group that matches an unverified ML.  also make sure that the ML has at least one
            //new acct that will be added to the PatientWallet

            if((provider.encounterGroupId && LinkedAccountsService.hasUnverified(provider.encounterGroupId, true))){
                $scope.showLinkableNotification = true;
                Compass.capture.event('account-linking', 'prompt', 'presented-receipt');
            }
        });
    };


    $scope.payment = {};

    $scope.completeAcct = function(){
        $rootScope.$emit('accountVerification:prompt');
    };

    PaymentsService.fetchPaymentDetails(_receiptId).then(function(payment){

        if(!payment){
            $state.go('app.dashboard');
            return;
        }

        _payment = $scope.payment = payment;

        _setupUIVariables();

        ContextHelperService.showBreadCrumbs({
            label: $filter('translate')('actions.backToArg', {properNoun: _payment.provider.name}),
            onClick: function(){
                $state.go('app.dashboard.provider.details', {id: _payment.provider.id});
            }
        });
    }, function() {
        $log.warn('Could not load payment with id ' + _receiptId);  //user is probably not allowed to see payment
        $state.go('app.default');                                   //retreat!
    });

    $scope.cancelPayment = function(){

        $scope.$emit('simpleModal:showPrompt', {
            header: $filter('translate')('receipt.confirmCancelDialog'),
            subcontent: $filter('translate')('receipt.cancelPaymentDesc', {
                date: $filter('dateFormat')(_payment.dateScheduled, 'MM/dd/yy'),
                amount: $filter('currency')(_payment.amount)
            }),
            // includeClasses: '',
            actions:[{
                label: $filter('translate')('actions.cancelPayment'),
                includeClasses: 'button-primary',
                clickHandler: function(){

                    _forceProvidersReload();

                    PaymentsService.cancelPayment(_receiptId).then(function(updatedPayment){
                        _payment = $scope.payment = updatedPayment;
                        _setupUIVariables();
                    });
                }
            }]
        });
    };

    $scope.viewBill = function(){
        BillsService.openPdf(_payment.secureCode);
    };

    $scope.print = function(){
        $window.print();
    };
});
