angular.module('PatientApp').controller('PlanReceiptCtrl', function($rootScope, $q, $scope, $state, $filter, $window, $log, _, PaymentPlansService, CardService, 
    ContextHelperService, MoneyUtilsService, BillsService, ProvidersService, LinkedAccountsService, Compass, PatientUsersService, ServerStatusService, ErrorsService){


    var _planId = $state.params.planId,
        _plan,
        _setupUIVariables,
        _destroyAddPaymentForm = $rootScope.$on('paymentForm:added', function(event, args) {
            $scope.updatePaymentForm(args.paymentFormId); //send back to payment-form selection dlg (but skip to confirm step)
        });

    $scope.$on('$destroy', function(){
        _destroyAddPaymentForm();
    });

    if(!_planId){
        $state.go('app.dashboard');
    }

    $scope.translateData = {};


    _setupUIVariables = function(){
        $scope.translateData.date = $filter('dateFormat')(_plan.dateCreated, 'MM/dd/yy');
        $scope.amountStatus = $filter('translate')( 'labels.argTotal', { amount: $filter('currency')(_plan.totalAmount) });
        
        if (!_plan.terminalState) {
            $scope.callOutAmountStatus = $filter('translate')('receipt.argRemainingCallOut', { amount: $filter('currency')(_plan.remainingAmount) });
        }
        
        if(_plan.paymentForm !== null && _plan.paymentForm.formType.toLowerCase() === 'card' && !_plan.paymentForm.cardType){
            $scope.plan.paymentForm.cardType = CardService.getIssuer(_plan.paymentForm.first6Digits);
        }

        // set up lineItems for table summary
        $scope.lineItems = [];

        _.forEach(_plan.payments, function(payment) {
            $scope.lineItems.push({
                date: payment.date,
                status: _.startCase(payment.status.toLowerCase()),
                friendlyDialog: $filter('translate')('payment.friendlyType.' + payment.paymentType) 
                    + '<br /><span>' + (payment.transactionId ? $filter('translate')('receipt.transactionIdArg', {transactionId: payment.transactionId}) : '') + '</span>',
                amountDialog: $filter('currency')(payment.amount)
            });
        });

        $q.all([
            ProvidersService.getProviderById(_plan.provider.id),
            LinkedAccountsService.fetchUnverified()
        ])
        .then(function(data) {
            var provider = data[0]; //results for first promise.  we don't care about the results from the second promise.

            $scope.provider = provider;

            //only show notification if the provider has a provider group that matches an unverified ML.  also make sure that the ML has at least one
            //new acct that will be added to the PatientWallet

            if((provider.encounterGroupId && LinkedAccountsService.hasUnverified(provider.encounterGroupId, true))){
                $scope.showLinkableNotification = true;
                Compass.capture.event('account-linking', 'prompt', 'presented-receipt');
            }
        });

        $scope.paymentPossible = ServerStatusService.getPaymentStatus('card') || ServerStatusService.getPaymentStatus('eCheck');
    };

    $scope.completeAcct = function(){
        $rootScope.$emit('accountVerification:prompt');
    };

    PaymentPlansService.fetchPaymentPlanDetails(_planId).then(function(plan){

        if(!plan){
            $state.go('app.dashboard');
            return;
        }

        _plan = $scope.plan = plan;

        _setupUIVariables();

        ContextHelperService.showBreadCrumbs({
            label: $filter('translate')('actions.backToArg', {properNoun: _plan.provider.name}),
            onClick: function(){
                $state.go('app.dashboard.provider.details', {id: _plan.provider.id});
            }
        });
    }, function() {
        $log.warn('Could not load plan with id ' + _planId);  //user is probably not allowed to see payment
        $state.go('app.default');                                   //retreat!
    });

    $scope.viewBill = function(){
        BillsService.openPdf(_plan.secureCode);
    };

    $scope.print = function(){
        $window.print();
    };

    $scope.updatePaymentForm = function(initialPaymentFormId) {
        Compass.capture.event('payment-plan', 'update-payment-form', 'opened');

        PatientUsersService.fetchSavedMethods(/*filterExpired*/ true)
            .then(function(methods) {
                var paymentFormOptions = _(methods)
                    .filter(function(method) { 
                        //exclude current method from list.  also, only include method types which are currently operational
                        return method.id != _plan.paymentForm.id && ServerStatusService.getPaymentStatus(method.formType); 
                    })
                    .map(function(method) {
                        var confirmContent = '';
                        if (_plan.status == 'On Hold') {
                            confirmContent = $filter('translate')('receipt.updateMethodHeldPlanConfirmDialog', {
                                method: $filter('methodDesc')(method, 'long', '&nbsp;')
                            });
                        }
                        else {
                            confirmContent = $filter('translate')('receipt.updateMethodScheduledPlanConfirmDialog', {
                                method: $filter('methodDesc')(method, 'long', '&nbsp;')
                            });
                        }

                        return {
                            label: $filter('methodDesc')(method, 'long'),
                            class: 'saved-method',
                            requireConfirmation: true,
                            confirmContent: confirmContent,
                            selected: (!!initialPaymentFormId && initialPaymentFormId==method.id),
                            clickHandler: function() {
                                Compass.capture.event('payment-plan', 'update-payment-form', 'started');

                                var deferred = $q.defer();
                                PaymentPlansService.updatePaymentForm(_planId, method.id).then(function(resp){
                                    deferred.resolve(resp);
                                }).catch(function(resp) {
                                    deferred.reject(resp);
                                });
                                return deferred.promise;
                            }
                        };
                    })
                    .value();

                var addNewIsAvailable = ServerStatusService.getPaymentStatus('card') || ServerStatusService.getPaymentStatus('eCheck');

                if (paymentFormOptions.length == 0) {
                    if (addNewIsAvailable) {
                        //user has no other payment forms on file.  skip to the add-new dialog
                        $rootScope.$emit('paymentForm:prompt');
                    }
                }
                else {
                    if (addNewIsAvailable) {
                        var addNewMethodLabel = '';
                        if (ServerStatusService.getPaymentStatus('card') && ServerStatusService.getPaymentStatus('eCheck')) {
                            addNewMethodLabel = $filter('translate')('dialogs.addAnotherCardOrECheck');
                        }
                        else if (ServerStatusService.getPaymentStatus('card')) {
                            addNewMethodLabel = $filter('translate')('dialogs.addAnotherCard');
                        }
                        else {
                            addNewMethodLabel = $filter('translate')('dialogs.addAnotherECheck');
                        }

                        paymentFormOptions.push({
                            label: addNewMethodLabel,
                            class: 'add-new',
                            requireConfirmation: false,
                            clickHandler: function() {
                                $rootScope.$emit('paymentForm:prompt');
                            }
                        });
                    }

                    $scope.$emit('optionModal:showPrompt', {
                        optionHeader: $filter('translate')('dialogs.updatePaymentFormHeader'),
                        confirmHeader: $filter('translate')('dialogs.updatePaymentFormConfirmHeader'),
                        options: paymentFormOptions,
                        includeClasses: 'change-payment-form',
                        confirmButtonLanguage: (_plan.status == 'On Hold') ? $filter('translate')('dialogs.updateAndApplyPaymentMethodButtonText') : $filter('translate')('dialogs.updatePaymentMethodButtonText'),
                        confirmingButtonLanguage: (_plan.status == 'On Hold') ? $filter('translate')('dialogs.updateAndApplyingPaymentMethodButtonText') : $filter('translate')('dialogs.updatingPaymentMethodButtonText'),
                        errorHandler: function(resp) {
                            Compass.capture.failure('payment-plan', 'update-payment-form');

                            switch (ErrorsService.resolve(resp)){
                                case ErrorsService.errors.PAYMENT_INVALID_CARD_NUMBER:
                                case ErrorsService.errors.PAYMENT_CARD_EXPIRED:
                                    return {
                                        message: $filter('translate')('errors.paymentFormInvalidCardInformation')
                                    };
                                case ErrorsService.errors.UNKNOWN_ERROR:
                                case ErrorsService.errors.INVALID_PARAMETERS:
                                case ErrorsService.errors.PAYMENT_PROCESSING_ERROR:
                                case ErrorsService.errors.NETWORK_ERROR:
                                    return {
                                        message: $filter('translate')('errors.paymentProcessingError')
                                    };
                                default:
                                    return {
                                        message: $filter('translate')('errors.paymentProcessingError')
                                    };
                            }
                        },
                        successHandler: function(updatedPlan) {
                            Compass.capture.success('payment-plan', 'update-payment-form');

                            _plan = $scope.plan = updatedPlan;
                            _setupUIVariables();

                            if (_plan.status == 'On Hold') {
                                //method was updated but failed to process on old payments
                                $scope.$emit('simpleModal:showPrompt', {
                                    header: $filter('translate')('receipt.paymentMethodHeld'),
                                    intent: 'information',
                                    meta: 'warning'
                                });
                            }
                            else {
                                $scope.$emit('simpleModal:showPrompt', {
                                    header: $filter('translate')('receipt.paymentMethodUpdated'),
                                    intent: 'success',
                                    autoExpire: true
                                });
                            }
                        }
                    });
                }
            });
    };
});
