angular.module('PatientApp').controller('FindBillCtrl', function($scope, $filter, $state, BillsService, ErrorsService, NotificationsService, AuthService) {
    function _reset() {
        $scope.searchErrors = {};
        $scope.payBillDialog = $scope.buttonText;
    }
    _reset();

    $scope.linkBill = function() {
        _reset();

        if($scope.forms.scodeAmountForm.$invalid || $scope.submitting){
            return;
        }

        // let the user know we are looking for the bill
        $scope.submitting = true;
        $scope.payBillDialog = 'actions.findingBill';

        var linkFunction = ($scope.submitHandler == 'find') ? BillsService.find : BillsService.link;
        linkFunction($scope.findData.sCode, $scope.findData.billAmount)
            .then(function(){
                $scope.onFind();
                $scope.findData.sCode = '';
                $scope.findData.billAmount = undefined;
                $scope.forms.scodeAmountForm.$setPristine();
                $state.go('app.verify');

                if (AuthService.hasAccess()) {
                    // new provider is being added to user's account.  force refresh on the notifications in case the new provider has
                    // notifications.  IMPORTANT: only do this if we're currently logged-in.  otherwise, the UNAUTH from this call will
                    // send the user to the login page (and away from the bill-link flow).
                    NotificationsService.fetchNotifications();
                }
            })
            .catch(function(resp){  

                // could not find bill. Handle error
                switch(ErrorsService.resolve(resp)){
                    case ErrorsService.errors.SECURECODE_NOT_FOUND:
                        $scope.searchErrors.notFound = true;
                        break;
                    case ErrorsService.errors.SECURECODE_BLOCKED:
                        $scope.searchErrors.throttled = true;
                        break;
                }

            })
            .finally(function(){
                $scope.submitting = false;
                $scope.payBillDialog = $scope.buttonText;
            });

        return false;
    };
});
