angular.module('PatientApp').service('PaymentFormsGatewayService', function( $resource, $q, $log, _, endpoints, GatewayFactoryService, ErrorsService, Compass, Config ){

    var PaymentForms = $resource(endpoints.paymentForms.primary.url, null, {
            prepGateway: {
                method: 'POST',
                url: endpoints.paymentForms.prepGateway.url
            },
            acknowledge: {
                method: 'POST',
                url: endpoints.paymentForms.confirm.url
            }
        }),

        _acknowledgeResumeData = null,

        _step1 = function(){

            var deferred = $q.defer(),
                tryPrep = function(){
                    return PaymentForms.prepGateway({
                        redirectUrl: Config.baseUrl + 'gatewayProxies/request.html?responded=true'
                    }).$promise;
                };

            Compass.capture.event('paymentForm', 'gateway', 'step1Start');

            tryPrep().then(function(resp){
                deferred.resolve(resp);
            }).catch(function(resp){

                // if we did not have a network error,
                // pass through the error
                if(ErrorsService.resolve(resp) !== ErrorsService.errors.NETWORK_ERROR){
                    Compass.capture.failure('paymentForm', 'gateway', 'step1Ended');
                    deferred.reject(resp);
                    return;
                }

                // if we have a network error
                // we will retry it once
                $log.error('Network error, could not prepGatway, trying again...');

                Compass.capture.event('paymentForm', 'gateway', 'step1Retry');

                tryPrep().then(deferred.resolve, function(resp){
                    Compass.capture.failure('paymentForm', 'gateway', 'step1Ended');
                    deferred.reject(resp);
                });
            });

            return deferred.promise;
        },

        _step2 = function(prepResp, paymentForm){

            var deferred = $q.defer(),
                tryPostToGateway = function(){
                    return GatewayFactoryService
                        .create(prepResp.gateway)
                        .postToGateway(prepResp, paymentForm);
                };

            Compass.capture.event('paymentForm', 'gateway', 'step2Start');

            tryPostToGateway().then(function(resp){
                deferred.resolve(resp);
            }).catch(function(resp){

                // if we did not have a network error,
                // pass through the error
                if(ErrorsService.resolve(resp) !== ErrorsService.errors.NETWORK_ERROR){
                    Compass.capture.failure('paymentForm', 'gateway', 'step2Ended');
                    deferred.reject(resp);
                    return;
                }

                $log.error('Network error, could not post to gatway, trying again...');

                Compass.capture.event('paymentForm', 'gateway', 'step2Retry');

                tryPostToGateway().then(deferred.resolve,  function(resp){
                    Compass.capture.failure('paymentForm', 'gateway', 'step2Ended');
                    deferred.reject(resp);
                });
            });

            return deferred.promise;
        },

        _step3 = function(ackDetails){

            Compass.capture.event('paymentForm', 'gateway', 'step3Start');

            var deferred = $q.defer(),
                tryAck = function(ackDetails){
                    return PaymentForms.acknowledge(ackDetails).$promise;
                };

            tryAck(ackDetails).then(function(resp){
                if (resp && (typeof resp.hasData !== 'undefined') && resp.hasData()) {
                    deferred.resolve(resp.getData());
                }
                else {
                    deferred.resolve();
                }
            }).catch(function(resp){

                // if we did not have a network error,
                // or this is attempt 2+
                // pass through the error
                if(ErrorsService.resolve(resp) !== ErrorsService.errors.NETWORK_ERROR || _acknowledgeResumeData !== null){

                    if(_acknowledgeResumeData){
                        Compass.capture.failure('paymentForm', 'gateway', 'step3Resume');
                    }

                    Compass.capture.failure('paymentForm', 'gateway', 'step3Ended');

                    deferred.reject(resp);
                    return;
                }

                $log.error('Network error, could not send paymentForm acknowledgement. Request for retry');

                Compass.capture.event('paymentForm', 'gateway', 'step3RetryRequested');

                _acknowledgeResumeData = _.cloneDeep(ackDetails);
                deferred.reject(ErrorsService.errors.GATEWAY_RETRY_REQUESTED);

            });

            return deferred.promise;
        },

        _3StepRedirect = function(paymentForm, promise, resuming){

            // if we are resuming we are saying that we want
            // to skip the _step1 and _step2 parts and
            // only complete _step3
            if(resuming && _acknowledgeResumeData !== null){
                Compass.capture.event('paymentForm', 'gateway', 'step3ResumeStart');
                return _step3(_acknowledgeResumeData).then(function(){
                    Compass.capture.success('paymentForm', 'gateway', 'step3Resume');
                });
            }

            return _step1().then(function(resp){

                if(!resp || !resp.hasData()){
                    $log.error('PrepGateway responded with no data');
                    promise.reject();
                    return;
                }

                Compass.capture.success('paymentForm', 'gateway', 'step1Ended');

                $log.info('PrepGateway Responded, invoking gateway.postToGateway');


                // We are returning the correct failure,
                // though we need to put this into its own function
                // and retry once

                return _step2(resp.getData(), paymentForm);

            }).then(function( gatewayRequestProperties ){

                Compass.capture.success('paymentForm', 'gateway', 'step2Ended');

                $log.info('PostToGateway responded, invoke acknowledge.');

                // the ack details can contain
                // gateway specific requirements
                // those should be returned from the gateway's
                // postToGateway promise resolver
                var ackDetails = _.assign({
                    paymentForm: {}

                }, (gatewayRequestProperties || {}));


                if(paymentForm.method.card){
                    ackDetails.paymentForm.method = 'card';
                    ackDetails.paymentForm.first6Digits = paymentForm.method.card.number.toString().slice(0, 6);
                    ackDetails.paymentForm.last4Digits = paymentForm.method.card.number.toString().slice(-4);
                    ackDetails.paymentForm.exp = paymentForm.method.card.expiration;
                }else {
                    ackDetails.paymentForm.method = 'echeck';
                    // backend needs routing number so they don't have to parse
                    // the gateway response
                    ackDetails.paymentForm.routing = paymentForm.method.eCheck.routing;
                }

                return _step3(ackDetails);
            }).then(function(resp) {
                promise.resolve(resp);
                Compass.capture.success('paymentForm', 'gateway', 'step3Ended');
            });
        },

        _submitPaymentForm = function( paymentForm, promise, resuming ){

            if(!resuming && (!paymentForm.method.card && !paymentForm.method.eCheck)){
                $log.error('Unable to submit without a paymentForm.method');
                return;
            }

            // clear the previous attempt if it existed
            if (!resuming){
                _acknowledgeResumeData = null;
            }

            $log.info('Invoking PrepGate for new form');

            if (resuming){
                Compass.capture.event('paymentForm', 'gateway', 'resuming3Step');
            }
            else {
                Compass.capture.event('paymentForm', 'gateway', 'starting3Step');
            }

            // regarding acknowledge
            return _3StepRedirect(paymentForm, promise, resuming).then(function(resp){
                $log.info('Acknowledge successful, payment form saved');
                promise.resolve(resp);
            })
            .catch(function(resp){
                $log.log('Unable to save payment form');
                promise.reject(resp);
            });
        },

        _resumePreviousPaymentFormAttempt = function(){
            var deferred = $q.defer();

            if(_acknowledgeResumeData === null){
                $log.log('Attempting to resume acknowledgement without details for it, bailing out... ');
                deferred.reject(ErrorsService.errors.UNKNOWN_ERROR);
            }
            else {
                _submitPaymentForm(null, deferred, /*resuming*/ true);
            }

            return deferred.promise;
        };


    return {

        // Called when we want to submit a new paymentForm.
        submit: function( paymentForm ){
			// prepGateway -- send a prepGateway insert
			//             -- we get back the gateway info
			// for the gateway we were given
			//     POST to their action with the app
			//         processing the response is gateway specific
			// if successful POST response
			//     sendUpdate for this payment
            var deferred = $q.defer();

            if( !paymentForm ){
                $log.error('GatewayService was passed an invalid paymentForm object');
                deferred.reject(null);
            } 
            else {
                _submitPaymentForm(paymentForm, deferred);
            }

            return deferred.promise;
        },

        // When we call this process if it gets to the confirmation
        // step it will request that the user manually resubmit the form
        // this is to buy us some time between NMI being ready and
        // our server call
        resume: function() {
            return _resumePreviousPaymentFormAttempt();
        }
    };
});