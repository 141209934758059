angular.module('PatientApp').directive('versionSrc', function($window, Config) {
    return {
        restrict : 'A',
        link: function ($scope, el, attrs) {
            var interpolateVersionedUrl = function (val) {
                if(angular.isString(val)) {
                    el.attr('src', Config.releaseFolder + val);
                }
            };

            interpolateVersionedUrl(attrs.versionSrc);

            $scope.$watch(attrs.versionSrc, interpolateVersionedUrl);
        }};
});
