angular.module('PatientApp').directive('phoneNumber', function($timeout, _, KeyboardService, $q, PatientUsersService, $interval){

    var _MAX_FORMATTED_LENGTH = 12,

        _getUnformattedValue = function(val){
            return (val || '').toString().replace(/(\s|\D)/g, '');
        },

        _getFormattedString = function(inputStr){
            var noFormatting = _getUnformattedValue(inputStr),
                i = 0,
                curChar = '',
                output = '';

            while(i < noFormatting.length){

                curChar = noFormatting[i];

                if(/[0-9]/.test(curChar)){
                    output += curChar;
                }

                i++;

                if(_.includes([3, 6], i)){
                    output += '-';
                }
            }

            return output.slice(0, _MAX_FORMATTED_LENGTH);
        };

    return {
        require: 'ngModel',
        scope: {
            onPhoneCheckFinished: '='
        },
        link: function(scope, el, attrs, ngModel){
            
            var runValidators = function(value){
                    var noFormatValue = _getUnformattedValue(value);
                    ngModel.$setValidity('minlength', noFormatValue.length > 0 ? noFormatValue.length >= 10 : true);

                },
                validKey = function(keyCode){
                    return /[a-zA-Z0-9-]/.test(String.fromCharCode(keyCode));
                };

            // Check if the sms-eligible directive is set
            if (angular.isDefined(attrs.smsEligible)) {
                el.on('keydown', function() {
                    scope.needsValidation = true;
                });

                ngModel.$asyncValidators.smsEligible = function (modelValue, viewValue) {

                    var deferred = $q.defer(),
                        currentValue = modelValue || viewValue;

                    // make sure we have a number
                    if(!currentValue){
                        scope.needsValidation = false;
                        deferred.resolve();
                    } else {
                        var noFormatValue = _getUnformattedValue(currentValue);
                        if(noFormatValue.length != 10){
                            scope.needsValidation = false;
                            deferred.resolve();
                        } else {
                            PatientUsersService.getSmsEligibility(noFormatValue)
                            .then(function () {
                                deferred.resolve();
                            })
                            .catch(function(){
                                deferred.reject();
                            }).finally(function() {
                                scope.needsValidation = false;
                            });
                        }
                    }
                    deferred.promise.finally(function(){

                        // let anyone who cares, know we finished validating
                        // we have to do this in a timeout style function because
                        // we need to call the callback once the check is done
                        // AND the $pending state is changed and that state
                        // changes after the promise control flow, so we must take
                        // it out of that flow, then execute
                        $interval(function(){
                            if(scope.onPhoneCheckFinished){
                                scope.onPhoneCheckFinished();
                            }
                        }, 0, 1);

                    });
                    return deferred.promise;
                };
            }



            // don't allow more than the formatted length
            el.attr('maxlength', _MAX_FORMATTED_LENGTH);

            el.on('paste', function(){
                $timeout(function(){
                    el.val(_getFormattedString(el.val()));
                }, 50);
            });
    
            // only accept numbers
            el.on('keydown', function(event){

                var key = KeyboardService.parseEvent(event);

                // we have no reason to use shift currently, so instead of 
                // trying to filter those out on keyup, prevent them now
                if((!key.isNumber() && !key.isEditorKey() && !key.hasModifier()) || key.is('shift')){
                    event.preventDefault();
                }
            });


            el.on('keyup', function(event){
                var key = event.which || event.keyCode || event.charCode,
                    start = this.selectionStart,
                    end = this.selectionEnd,
                    val = el.val(),
                    atEnd = this.selectionStart === val.length;


                // key sent when browser is processing the event
                // we will pull the character out and get the code directly
                if(key === 229 && val.length > 0 ){
                    key = val.charCodeAt(val.length - 1);
                }

                // if we are deleting, we shouldn't worry about anything
                if(!validKey(key)){
                    return;
                }

                el.val(_getFormattedString(el.val()));

                if(!atEnd){
                    this.setSelectionRange(start, end);
                }
            });

            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function (newValue) {
                runValidators(newValue);
            });


            ngModel.$formatters.push(function(newInput){
                return _getFormattedString(newInput || '');
            });

            ngModel.$parsers.push(function(viewValue){
                return _getFormattedString(viewValue || '');
            });
        }
    };

});