angular.module('PatientApp').filter('methodDesc', function($log, _, CardService){

    var _card = function(m, s, separator){

            var output = [];

            switch (s) {
                case 'short':
                    output = [CardService.getIssuerFromCardType(m.cardType), '****' + m.last4Digits];
                    break;
                default:
                    output = [CardService.getIssuerFromCardType(m.cardType), '****' + m.last4Digits];
                    break;
            }

            return output.join( separator === undefined ? ' ' : separator);
        },

        _eCheck = function(m, s, separator){

            var output = [];

            // we aren't always consistent with 
            // how we display these values, so we will check
            // for the routing if we don't see a 4 digits 
            if(!m.last4Digits && m.accountNumber){
                m.last4Digits = m.accountNumber.slice(-4);
            }

            switch (s) {
                case 'short':
                    output = ['eCheck', '**' + m.last4Digits];
                    break;
                default:
                    output = ['eCheck', m.bankName, '**' + m.last4Digits];
                    break;
            }

            return output.join( separator === undefined ? ' ' : separator);
        },

        _financing = function(m, s, separator){

            var output = [];

            switch (s) {
                case 'short':
                    output = [m.vendor, m.accountNumber];
                    break;
                default:
                    output = [m.vendor, 'Card', m.accountNumber, 'for', m.provider];
                    break;
            }

            return output.join( separator === undefined ? ' ' : separator);
        };

    return function(method, style, separator){

        var acceptedStyles = [ 'short' ],
            officialStyle;

        if(!method){
            return '';
        }

        if(style && acceptedStyles.indexOf(style) > -1){
            officialStyle = style.toLowerCase();
        }

        switch (method.formType.toLowerCase()) {
            case 'card':
                return _card(method, officialStyle, separator);
            case 'financing':
                return _financing(method, officialStyle, separator);
            default:
                return _eCheck(method, officialStyle, separator);
        }
    };

});