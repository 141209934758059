angular.module('PatientApp').controller('MessageDetailsCtrl', function($scope, $q, $filter, $state, $log, _, MessagesService, ContextHelperService, ProvidersService, BillsService, LoadingIndicatorService, endpoints){

    var _threadId = $state.params.threadId,
        _loadingKey;

    if(!_threadId){
        $state.go('app.dashboard');
    }

    $scope.messages = null;

    _loadingKey = LoadingIndicatorService.loading();

    // Load up our messages
    MessagesService.getMessages(/*forceLoad*/true).then(function(messages){

        // load the thread into memory so we can populate the peripheral areas of the ui
        $scope.thread = _.first(_.filter(messages, function(thread){
            return _.parseInt(thread.id) === _.parseInt(_threadId);
        }));

        // make sure we always have a valid message thread
        if(!$scope.thread){
            return $q.reject();
        }

        return MessagesService.getDetails(_threadId);
    
    }).then(function(details) {
        $scope.messages = details;
        return BillsService.getAccountBill($scope.thread.secureCode);
    }, function() {
        $log.error('Unable to load thread details');
        $state.go('app.dashboard');
    }).then(function(bill) {
        $scope.bill = bill;
    }).finally(function(){
        LoadingIndicatorService.complete(_loadingKey);
    });


    $scope.messageDetails = {
        message : ''
    };

    $scope.today = new Date();

    // default state of the send ui
    $scope.submitting = false;
    $scope.sendDialog = $filter('translate')('actions.sendMessage');

    $scope.sendMessage = function(){
        if(_.isEmpty($scope.messageDetails.message) || $scope.submitting){
            return;
        }

        $scope.sendDialog = $filter('translate')('actions.sending');
        $scope.submitting = true;

        MessagesService.send($scope.messageDetails, _threadId).then(function(){
            $scope.messageDetails.message = '';

            // TODO: it may make more sense to simply inline add the 
            // content to the dom than to reload the data.
            MessagesService.getDetails(_threadId).then(function(details){
                $scope.messages = details;
            });
        })
        .finally(function(){
            $scope.sendDialog = $filter('translate')('actions.sendMessage');
            $scope.submitting = false;
        });
    };

    $scope.viewBill = function(){
        BillsService.openPdf($scope.bill.secureCode);
    };

    $scope.buildDownloadUrl = function(attachmentId){
        return endpoints.messages.attachment.url + '/' + attachmentId;
    };

    ProvidersService.getProviders().then(function(providers){
        var hasAccessToMessage = _.some(providers, function(provider){
            return provider.canMessage;
        });

        // show the back to messages breadcrumb if we have access to
        // that section of the application
        if(hasAccessToMessage && $state.previous.name === 'app.messages'){
            ContextHelperService.showBreadCrumbs({
                label: $filter('translate')('actions.backToMessages'),
                onClick: function(){
                    $state.go('app.messages');
                }
            });
        }
    });
});