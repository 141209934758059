angular.module('PatientApp').factory('MessagesService', function($q, $log, $filter, $resource, $rootScope, _, endpoints){



    var Messages = $resource(endpoints.messages.primary.url, null, {
            fetchMessages: {
                method: 'GET'
            },
            fetchDetails: {
                method: 'GET',
                url: endpoints.messages.details.url
            },

            reply: {
                method: 'POST',
                url: endpoints.messages.details.url,
                params: {
                    id: '@id'
                }
            },

            compose: {
                method: 'POST'
            }
        }),

        _fetched = false,
        _messages = [];


    // Register this service to clear it's data when asked to
    $rootScope.$on('data:clear', function(){
        _fetched = false;
        _messages = [];
    });

    return {

        fetchMessages: function(){
            _fetched = false;

            return Messages.fetchMessages().$promise
                .then(function(resp){
                    if(resp && resp.hasData()){
                        _messages = resp.getData();
                    }
                    return _messages;
                })
                .finally(function(){
                    // flag to know that we hit the server for messages
                    _fetched = true;
                });
        },

        getMessages: function(forceFetch){
            var deferred = $q.defer();

            if(!_fetched || forceFetch){
                this.fetchMessages().then(function(messages){

                    // get some complex values in a friendlier form
                    _.forEach(messages, function(message){
                        message.friendlyUpdateTime = $filter('dateFormat')(message.timeUpdated, 'MMM d, yyyy');
                        message.friendlyAccountNumber = $filter('accountNumber')( message.accountNumber );
                    });

                    deferred.resolve(messages);
                })
                .catch(function(er){
                    deferred.reject(er);
                });
            }else {
                deferred.resolve(_messages);
            }

            return deferred.promise;
        },

        getDetails: function(id){

            id = _.parseInt(id);

            return Messages.fetchDetails({id: id}).$promise
                .then(function(resp){
                    var _threadMessages = null;

                    if(resp && resp.hasData()){

                        // try to cache this data
                        _.forEach(_messages, function(thread){

                            //todo: look into using a merge with a empty thread obj so that we can
                            // cache this response even if we haven't performed getMessages yet
                            if(thread.id === id){
                                _threadMessages = thread.threadMessages = resp.getData();
                            }
                        });

                        if(!_threadMessages){
                            _threadMessages = resp.getData();
                        }

                        _.forEach(_threadMessages, function(message){
                            message.friendlyMessageTime = $filter('dateFormat')(message.messageTime, 'MMM d, yyyy');
                        });

                    }else {
                        _threadMessages = [];
                    }

                    return _threadMessages;
                });
        },

        send: function(messageDetails, threadId){

            var deferred = $q.defer();

            // decide if we are replying or creating a new message thread/message
            if(threadId){
                Messages.reply({id: threadId, message: messageDetails.message })
                    .$promise.then(function(){
                        deferred.resolve();
                    })
                    .catch(function(){
                        deferred.reject();
                    });
            }else {
                Messages.compose({
                    secureCode: messageDetails.secureCode,
                    message: messageDetails.message,
                    reason: messageDetails.reason
                }).$promise.then(function(resp){
                    if(resp && resp.hasData()){
                        deferred.resolve(resp.getData().messageId);
                    }else {
                        deferred.reject(resp);
                    }
                }).catch(function(){
                    deferred.reject();
                });
            }

            return deferred.promise;
        }
    };

});