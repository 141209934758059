/*
    a standardized way of turning a string of words into a string of words
    with all of the words having the first letter capitalized and the restore
    of the word lowercase
*/

angular.module('PatientApp').filter('capitalize', function(_){
    return function (str) {
        return _.map((str || '').toLowerCase().split(' '), function(word){
            return _.capitalize(word);
        }).join(' ');
    };
});
