// ServerStatusService is where we keep track of server state.  At the moment, this includes which payment forms are currently process-able

angular.module('PatientApp').factory('ServerStatusService', function($log, _){
    
    var _eCheckProcessingAvailable = true,
        _cardProcessingAvailable = true,
        _currXsrfToken = '';

    return {
        updatePaymentStatus: function( availableMethods ) {
            _eCheckProcessingAvailable = _.includes(availableMethods, 'echeck');
            _cardProcessingAvailable = _.includes(availableMethods, 'card');
        },
        getPaymentStatus: function( paymentType ) {
            switch (_.trim(paymentType).toLowerCase()) {
                case 'card':
                    return _cardProcessingAvailable;
                case 'echeck':
                    return _eCheckProcessingAvailable;
                default:
                    $log.error('Invalid payment type: ' + paymentType);
                    return false;
            }
        },
        updateXsrfToken: function( xsrfToken ) {
            _currXsrfToken = xsrfToken;
        },
        getXsrfToken: function() {
            return _currXsrfToken;
        }
    };
});