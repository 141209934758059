angular.module('PatientApp').directive('creditCard', function($timeout, _, CardService, KeyboardService){

    var _MAX_FORMATTED_LENGTH = 19, //16 digits + 3 spaces
        _MAX_AMEX_FORMATTED_LENGTH = 17, //15 digits + two spaces
        _MAX_NONFORMATTED_LENGTH = 16, 
        _MAX_AMEX_NONFORMATTED_LENGTH = 15,

        _getUnformattedValue = function(val){
            var noFormatting = (val || '').toString().replace(/(\s|\D)/g, ''),
                issuer = (CardService.getIssuer(noFormatting) || '').toLowerCase();
            return noFormatting.slice(0, _getMaxLengthForIssuer(issuer));
        },

        _getFormattedString = function(inputStr){
            var noFormatting = _getUnformattedValue(inputStr),
                i = 0,
                curChar = '',
                output = '',
                issuer = (CardService.getIssuer(noFormatting) || '').toLowerCase();

            while(i < noFormatting.length){

                curChar = noFormatting[i];

                if(/[0-9]/.test(curChar)){
                    output += curChar;
                }

                i++;

                // FORMATTING
                if(_.includes(['visa', 'discover', 'mastercard'], issuer)){
                    if(_.includes([4, 9, 14], output.length)){
                        output += ' ';
                    }
                }else if(issuer === 'american express'){
                    if(_.includes([4, 11], output.length)){
                        output += ' ';
                    }
                }
            }

            return output.slice(0, _getMaxFormattedLengthForIssuer(issuer)).trim();
        },

        _getMaxFormattedLengthForIssuer = function(issuer) {
            return (issuer === 'american express') ? _MAX_AMEX_FORMATTED_LENGTH : _MAX_FORMATTED_LENGTH;
        },
        _getMaxLengthForIssuer = function(issuer) {
            return (issuer === 'american express') ? _MAX_AMEX_NONFORMATTED_LENGTH : _MAX_NONFORMATTED_LENGTH;
        },
        _getMaxFormattedLength = function(value) {
            var noFormatValue = _getUnformattedValue(value),
                issuer = (CardService.getIssuer(noFormatValue) || '').toLowerCase();
            return _getMaxFormattedLengthForIssuer(issuer);
        };

    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ngModel){
            
            // luhn check sampled from https://gist.github.com/ShirtlessKirk/2134376
            var luhnCheck = (function (arr) {
                    return function (ccNum) {
                        var len = ccNum.length,
                            bit = 1,
                            sum = 0,
                            val;
                 
                        while (len) {
                            val = parseInt(ccNum.charAt(--len), 10);
                            sum += (bit ^= 1) ? arr[val] : val; /* jshint ignore:line */
                        }
                 
                        return sum && sum % 10 === 0;
                    };
                }([0, 2, 4, 6, 8, 1, 3, 5, 7, 9])),

                runValidators = function(value){
                    var noFormatValue = _getUnformattedValue(value),
                        issuer = (CardService.getIssuer(noFormatValue) || '').toLowerCase(),
                        len = noFormatValue.length,

                        // set to true so we only check on the 
                        // card lengths we know about
                        validLength = true,

                        // the number of characters we allow
                        // before we can definitively infer that
                        // we have enough characters to treat it
                        // as a number we can apply checks against
                        issuerMaxUnknownCharCount = 6;

                    if(issuer === 'visa'){
                        validLength = len >= 13 && len <= 16;
                    }else if(_.includes(['mastercard', 'discover'], issuer)){
                        validLength = len === 16;
                    }else if(issuer === 'american express') {
                        validLength = len === 15;
                    }

                    ngModel.$setValidity('knownIssuer', issuer !== '' || len < issuerMaxUnknownCharCount);
                    ngModel.$setValidity('lengthMatch', validLength);
                    ngModel.$setValidity('luhn', luhnCheck(noFormatValue) || len < issuerMaxUnknownCharCount);
                },
                setValue = function(value) {
                    el.val(value);
                    if (value.length > 10) {
                        //maxlength is only updated as we approach the max.  This is for performance reasons.  10 is an arbitrary number
                        el.attr('maxlength', _getMaxFormattedLength(value));  //max length is different for different cards
                    }
                };


            el.on('paste', function(){
                scope.$applyAsync(function() { 
                    setValue(_getFormattedString(el.val()));
                });
            });
    
            // only accept numbers
            el.on('keydown', function(event){
                var key = KeyboardService.parseEvent(event);
                // we have no reason to use shift currently, so instead of 
                // trying to filter those out on keyup, prevent them now
                if((!key.isNumber() && !key.isEditorKey() && !key.hasModifier()) || key.is('shift')){
                    event.preventDefault();
                }
            });

            el.on('keyup', function(){
                var start = this.selectionStart,
                    end = this.selectionEnd,
                    val = el.val(),
                    atEnd = this.selectionStart === val.length;

                setValue(_getFormattedString(el.val()));

                if(!atEnd){
                    this.setSelectionRange(start, end);
                }
            });

            // needed to help format apple scanned cards
            el.on('format', function(){
                setValue(_getFormattedString(el.val()));
            });


            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function (newValue) {
                runValidators(newValue);
            });


            ngModel.$formatters.push(function(newInput){
                return _getFormattedString(newInput || '');
            });

            ngModel.$parsers.push(function(viewValue){
                return _getUnformattedValue(viewValue);
            });
        }
    };
});