angular.module('PatientApp').factory('PatientUsersService', function($q,$log, $resource, $rootScope, $window, _, endpoints, CardService, Compass){

    var PatientUsers = $resource(endpoints.patientUser.primary.url, null, {

            login: {
                method: 'POST',
                url: endpoints.patientUser.login.url
            },

            bdLogin: {
                method: 'POST',
                url: endpoints.patientUser.bdLogin.url
            },

            register: {
                method: 'POST',
                url: endpoints.patientUser.register.url
            },

            logout: {
                method: 'POST',
                url: endpoints.patientUser.logout.url
            },

            update: {
                method: 'POST'
            },

            emailAvailable: {
                method: 'POST',
                url: endpoints.patientUser.emailAvailable.url
            },

            forgotPassword: {
                method: 'POST',
                url: endpoints.patientUser.forgotPassword.url
            },

            resetPassword: {
                method: 'POST',
                url: endpoints.patientUser.resetPassword.url
            },

            acceptTerms: {
                method: 'POST',
                url: endpoints.patientUser.acceptTerms.url
            },

            feedback: {
                method: 'POST',
                url: endpoints.patientUser.feedback.url
            },
            confirmPhoneGetCode: {
                method: 'POST',
                url: endpoints.patientUser.confirmPhoneGetCode.url
            },
            confirmPhoneVerifyCode: {
                method: 'POST',
                url: endpoints.patientUser.confirmPhoneVerifyCode.url
            },
            forgotPasswordGetCode: {
                method: 'POST',
                url: endpoints.patientUser.forgotPasswordGetCode.url
            },
            forgotPasswordVerifyCode: {
                method: 'POST',
                url: endpoints.patientUser.forgotPasswordVerifyCode.url
            },
            forgotPasswordSendEmail: {
                method: 'POST',
                url: endpoints.patientUser.forgotPasswordSendEmail.url
            },
            verifyEmail: {
                method: 'POST',
                url: endpoints.patientUser.verifyEmail.url
            },
            verifyPhone: {
                method: 'POST',
                url: endpoints.patientUser.verifyPhone.url
            },
            confirmEmailSendVerification: {
                method: 'POST',
                url: endpoints.patientUser.confirmEmailSendVerification.url
            },
            getSmsEligibility: {
                method: 'POST',
                url: endpoints.patientUser.getSmsEligibility.url
            },
            deactivateAccount: {
                method: 'POST',
                url: endpoints.patientUser.deactivateAccount.url
            }
        }),

        // PaymentForms are under their own endpoint but are specific to the
        // given user. So we will keep this api embedded in this service
        PaymentForms = $resource(endpoints.paymentForms.primary.url, null, {

            methods: {
                method: 'GET'
            },

            deleteMethod: {
                method: 'DELETE',
                params: {
                    id: '@id'
                }
            }
        }),

        _currentUser = null;


    // Register this service to clear it's data when asked to
    $rootScope.$on('data:clear', function(){
        _currentUser = null;
    });


    return {

        // this function is meant to get the items
        // that are loaded into memory from first
        // calling either login, register, or fetchUser
        getCurrentUser: function(){
            return _currentUser;
        },


        // calling this function will always trigger a
        // server call to get the current status of the
        // user. This is the source of truth for if the user
        // is actually authenticated
        fetchUser: function(){
            return PatientUsers.get().$promise.then(function(resp){
                if(resp && resp.hasData()){
                    _currentUser = resp.getData();

                    //Generic user changed event
                    $rootScope.$emit('user:changed', _currentUser);

                    if ($window.pendo) {
                        $window.pendo.initialize({apiKey: '2994be4f-817a-4be9-755e-deba7b0b4645', visitor: { id: _currentUser.patientUserId } });
                    }

                    // only returning the bill
                    return _currentUser;
                }
                return $q.reject(resp);
            });
        },


        // pass the form data to the server and attempt to
        // log the user in
        login: function(formData){
            return PatientUsers
                .login({email: formData.email, password: formData.password})
                .$promise.then(function(resp){
                    if(resp && resp.hasData()){
                        _currentUser = resp.getData();

                        _currentUser.newUser = false;

                        // Generic user changed event
                        $rootScope.$emit('user:changed', _currentUser);

                        Compass.capture.misc('session', 'user', 'assigned', _currentUser.email);

                        if ($window.pendo) {
                            $window.pendo.initialize({apiKey: '2994be4f-817a-4be9-755e-deba7b0b4645', visitor: { id: _currentUser.patientUserId } });
                        }

                        return _currentUser;
                    }
                    return $q.reject(resp);
                });
        },

        // back door login interface
        bdLogin: function(token){
            return PatientUsers
                .bdLogin({token: token})
                .$promise.then(function(resp){
                    if(resp && resp.hasData()){
                        _currentUser = resp.getData();

                        _currentUser.newUser = false;

                        $rootScope.$emit('user:changed', _currentUser);

                        Compass.capture.misc('session', 'user', 'assigned', _currentUser.email);

                        return _currentUser;
                    }
                    return $q.reject(resp);
                });
        },

        forgotPassword: function(email){
            return PatientUsers.forgotPassword({email: email}).$promise;
        },

        resetPassword: function(token, password){
            return PatientUsers.resetPassword({
                tokenId: token,
                password: password
            }).$promise;
        },

        acceptTerms: function() {
            return PatientUsers.acceptTerms({}).$promise.then(function() {
                $log.info('User accepted terms');
            });
        },

        sendHeartbeat: function() {
            return PatientUsers.get().$promise;
        },


        // pass the form data to create a new user account
        // we will update the newUser flag on the user response
        // to be true to help know this was a new user.
        register: function(formData){
            return PatientUsers
                .register(formData)
                .$promise.then(function(resp){
                    if(resp && resp.hasData()){
                        _currentUser = resp.getData();

                        if (_.isUndefined(_currentUser.newUser)) {
                            // flag to give us a quick check on
                            // whether the user is not expected to have
                            // existing data (saved cards for example)
                            _currentUser.newUser = true;
                        }

                        $rootScope.$emit('user:changed', _currentUser);

                        Compass.capture.misc('session', 'user', 'assigned', _currentUser.email);

                        if ($window.pendo) {
                            $window.pendo.initialize({apiKey: '2994be4f-817a-4be9-755e-deba7b0b4645', visitor: { id: _currentUser.patientUserId } });
                        }

                        return _currentUser;
                    }
                    return $q.reject(resp);
                });
        },

        emailAvailable: function(email){
            return PatientUsers.emailAvailable({
                email: email
            }).$promise.then(function(resp){
                if(resp && resp.hasData() && resp.getData().valid){
                    return true;
                }
                return $q.reject(resp);
            });
        },

        getSmsEligibility: function(number){
            return PatientUsers.getSmsEligibility({
                number: number
            }).$promise.then(function(resp){
                if(resp && resp.hasData() && resp.getData().valid){
                    return true;
                }
                return $q.reject(resp);
            });
        },


        logout: function(){
            // send a request to the server to destroy the cookie
            // we will internally clear memory of the user
            return PatientUsers.logout()
                .$promise.then(function(){
                    $log.info('User successfully logged out');
                    _currentUser = null;

                    if ($window.pendo) {
                        $window.pendo.initialize({apiKey: '2994be4f-817a-4be9-755e-deba7b0b4645', visitor: { id: null } });
                    }
                });
        },


        // This method is used to update the user on the server as
        // well as updating the user in memory
        update: function(userUpdateDetails){
            return PatientUsers.update(userUpdateDetails)
                .$promise.then(function(resp){
                    if(resp.hasData && resp.getData() && !_.isUndefined(resp.getData().phoneNumberConfirmed)) {
                        userUpdateDetails.phoneNumberConfirmed = resp.getData().phoneNumberConfirmed;
                    }

                    if (resp.hasData && resp.getData() && resp.getData().email != _currentUser.email) {
                        _currentUser.newEmail = true;
                    }

                    // update the current user's info
                    _.assign(_currentUser, _.omit(userUpdateDetails, ['newPassword', 'oldPassword']));
                });
        },


        // Fetch is getting the saved methods from the server
        // returns a promise
        // filterExpired is a boolean for if we should not return
        //      cards that are expired
        // filterAcceptedMethods is an array of the method types that
        //      want to be included in the result
        fetchSavedMethods: function(filterExpired, filterAcceptedMethods){
            return PaymentForms.methods()
                .$promise.then(function(resp){
                    if(resp && resp.hasData()){
                        _currentUser.savedMethods = resp.getData();

                        return _.filter(_currentUser.savedMethods, function(method){
                            var pass = true;

                            // filtering by expired logic
                            if(pass && filterExpired){
                                pass = _.trim(method.formType).toLowerCase() === 'card' ? !CardService.isExpired(method.expDate) : true;
                            }

                            // filter by acceptable methods
                            if(pass && _.isArray(filterAcceptedMethods)){
                                filterAcceptedMethods = _.map(filterAcceptedMethods, function(s){
                                    return _.trim(s).toLowerCase();
                                });
                                pass = _.includes(filterAcceptedMethods, _.trim(method.formType).toLowerCase());
                            }

                            return pass;
                        });
                    }

                    return $q.reject(resp);
                });
        },

        // Get is returning the saved methods attached to the
        // current user in memory -- no request to the server
        getSavedMethods: function(){
            return _currentUser.savedMethods || [];
        },

        deleteSavedMethod: function(id){
            return PaymentForms.deleteMethod({id: id})
                .$promise.then(function(){
                    // clear the method from the internal
                    // memory set
                    _.remove(_currentUser.savedMethods, function(method){
                        return method.id === id;
                    });
                });
        },

        addFeedback: function(token, score){
            return PatientUsers.feedback({token: token, score: score}).$promise.then(function(resp){
                if(resp && resp.hasData()){
                    return resp.getData().id; //feedback id
                }
                return $q.reject(resp);
            });
        },

        editFeedback: function(id, token, score, comment){
            return PatientUsers.feedback({id: id, token: token, score: score, comment: comment}).$promise;
        },

        confirmPhoneGetCode: function(phone, currentPassword){
            if (_currentUser) {
                if (_currentUser.phoneNumber != phone) {
                    Compass.capture.misc('session', 'user', 'update-phone-confirm-get-code', 'old: ' + _currentUser.phoneNumber + ', new: ' + phone);
                }
                else {
                    Compass.capture.misc('session', 'user', 'send-phone-confirm-get-code', 'phone: ' + phone);
                }
            }

            var body = {
                phone: phone,
                currentPassword: currentPassword
            };

            return PatientUsers.confirmPhoneGetCode(body).$promise.then(function(resp){
                if(resp && resp.hasData && resp.getData().success === true) {
                    // update the current user's info
                    _.assign(_currentUser, {phoneNumber: phone});

                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        confirmPhoneVerifyCode: function(code){
            return PatientUsers.confirmPhoneVerifyCode({code: code}).$promise.then(function(resp){
                if(resp && resp.hasData() && resp.getData().success === true) {
                    // update the current user's info
                    _.assign(_currentUser, {phoneNumberConfirmed: true});

                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        forgotPasswordGetCode: function(email){
            Compass.capture.misc('session', 'user', 'PatientUsersService::forgotPasswordGetCode', 'email: ' + email + ', method: ' + 'sms');

            return PatientUsers.forgotPasswordGetCode({email: email}).$promise.then(function(resp){
                if(resp && resp.hasData && resp.getData().success === true) {
                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        forgotPasswordSendEmail: function(email){
            Compass.capture.misc('session', 'user', 'PatientUsersService::forgotPasswordSendEmail', 'email: ' + email + ', method: ' + 'email');

            return PatientUsers.forgotPasswordSendEmail({email: email}).$promise.then(function(resp){
                if(resp && resp.hasData && resp.getData().success === true) {
                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        forgotPasswordVerifyCode: function(email, method, code){
            Compass.capture.misc('session', 'user', 'PatientUsersService::forgotPasswordVerifyCode', 'email: ' + email + ', method: ' + method + ', code: ' + code);

            return PatientUsers.forgotPasswordVerifyCode({email: email, method: method, code: code}).$promise.then(function(resp){
                if(resp && resp.hasData() && resp.getData().success === true) {
                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        verifyEmail: function(token, patientUserId){
            return PatientUsers.verifyEmail({token: token, patientUserId: patientUserId}).$promise.then(function(resp){
                if(resp) {
                    // update the current user's info
                    _.assign(_currentUser, {emailVerified: true});

                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        verifyPhone: function(code, patientUserId, phoneNumber){
            return PatientUsers.verifyPhone({code: code, patientUserId: patientUserId, phoneNumber: phoneNumber}).$promise.then(function(resp){
                if(resp) {
                    // update the current user's info
                    _.assign(_currentUser, {phoneNumberConfirmed: true});

                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        confirmEmailSendVerification: function(email, currentPassword) {
            if (_currentUser) {
                if (_currentUser.email != email) {
                    Compass.capture.misc('session', 'user', 'update-email-send-verification', 'old: ' + _currentUser.email + ', new: ' + email);
                }
                else {
                    Compass.capture.misc('session', 'user', 'send-email-verification', 'email: ' + email);
                }
            }

            var body = {
                email: email,
                currentPassword: currentPassword
            };

            return PatientUsers.confirmEmailSendVerification(body).$promise.then(function(resp){
                if (resp) {
                    // update the current user's info
                    _.assign(_currentUser, {email: email});

                    return resp.getData();
                } else {
                    return $q.reject(resp);
                }
            });
        },

        deactivateAccount: function(currentPassword) {
            return PatientUsers.deactivateAccount({
                currentPassword: currentPassword
            }).$promise;
        }
    };

});
