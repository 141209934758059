angular.module('PatientApp').controller('TermsOfServiceCtrl', function($rootScope, $scope, AuthService, PatientUsersService) {
    $scope.acceptTerms = function() {
        PatientUsersService.acceptTerms();
        $scope.showTerms = false;
    };

    $rootScope.$on('user:changed', function(event, user) {
        if ((!user.latestTOSVersion) && (!user.bdLogin)) {
            $scope.showTerms = true;
        }
    });
});
