angular.module('PatientApp').service('ProvidersService', function($q, $log, $resource, $filter, $rootScope, _, endpoints){
    
    var Providers = $resource(endpoints.providers.primary.url, { id: '@id' }),

        // sync status is a way for us to know if we have or are currently
        // running sync on the providers list. Helps when resolving deep linking
        // dependency issues
        _syncStatus = 'not-synced',
        _syncedPromises = [],
        _providers = [],
        _flush = function(){
            _syncStatus = 'not-synced';
            _syncedPromises = [];
            _providers = [];
        },

        _getProviderById = function(id){
            return _.first(_.filter(_providers, function(p){
                return p.id.toString() === id.toString();
            }));
        },

        _getAccountByNumber = function(acctNumber) {
            var _account = null;
            _.forEach(_providers, function(provider){
                _.forEach(provider.accounts, function(account){
                    if (account.accountNumber == acctNumber) {
                        _account = account;
                    }
                });
            });
            return _account;
        },

        _providersMerge = function(currentProviders, updatedProviders){
            var _pros = [];

            _.forEach(updatedProviders, function(newProvider){

                var currentProvider = _.find(currentProviders, function(p){
                    return p.id === newProvider.id;
                });

                if (newProvider.websites) {
                    // see if a protocol was in the url.  if not, add a safe fallback for the provider link to work properly
                    newProvider.websites = _.map(newProvider.websites, function(website) {
                        website.url = _.startsWith(website.url, 'http') ? website.url : ('http://' + website.url);
                        return website;
                    });
                }

                // if we arent tracking this provider already, add and dont worry about 
                // syncing with an existing one
                if(!currentProvider){
                    _pros.push(newProvider);
                    return;
                }

                // this provider has already been loaded before
                // sync everything over to the existing provider reference


                // sync accounts
                newProvider.accounts = _.map(newProvider.accounts, function(updatedAccount){

                    var matchingAccount = _.find(currentProvider.accounts, function(a){
                        return a.accountId === updatedAccount.accountId;
                    });

                    return _.omit(_.merge({}, matchingAccount, updatedAccount), '$$hashKey');
                });

                // finally merge together all other fields 
                // exclude the accounts from this, as we have already merged them 
                // inpto newProvider
                _pros.push(_.merge({}, _.omit(currentProvider, 'accounts'), newProvider));

            });

            return _pros;
        };


    // Register this service to clear it's data when asked to
    $rootScope.$on('data:clear', _flush);

    return {

        // sync fetches the providers from the
        // server but syncs the differences of what we have
        // already loaded and any new data that the server
        // returns
        sync : function(){
            var deferred = $q.defer();

            _syncStatus = 'running';

            // make sure this is the first callback 
            _syncedPromises.unshift(deferred);

            Providers.get().$promise
                .then(function(resp){
                    
                    var updatedProviders,
                        today = new Date(),
                        due;

                    _syncStatus = 'synced';

                    if(resp && resp.hasData()){
                        
                        updatedProviders = resp.getData();

                        _.forEach(updatedProviders, function(provider){
                            
                            // convenience field to standardize this check
                            provider.canMessage = provider.active && _.includes(provider.features, 'messaging');

                            provider.friendlyDueAmount = {amount: provider.balance};
                            
                            _.forEach(provider.accounts, function(acc){
                                acc.friendlyDueDate = $filter('translate')('labels.dueArg', {date: $filter('dateFormat')(acc.dueDate, 'longDate')});
                                acc.friendlyAccountNumber = $filter('accountNumber')( acc.accountNumber );
                                if (acc.dueDate) {
                                    due = new Date(acc.dueDate);
                                    // compare on midnight of the dates
                                    today.setHours(0,0,0,0);
                                    due.setHours(0,0,0,0);

                                    acc.isPastDue = provider.active && (due < today) && !provider.patientDueDateOverrideText;
                                } else {
                                    acc.isPastDue = false;
                                }
                            });


                        });
                        
                        // for now, merge handles the sync for us
                        // we may need to optimize this after further testing
                        // debugger;
                        _providers = _providersMerge(_providers, updatedProviders);
                    }
                    _.remove(_syncedPromises, function(promise){
                        promise.resolve(_providers);
                        return true;
                    });
                })
                .catch(function( er ){

                    _syncStatus = 'not-synced';

                    $log.error(er);

                    _.remove(_syncedPromises, function(promise){
                        return promise.reject('unable to fetch providers');
                    });
                });

            return deferred.promise;
        },


        // use getProviders when you need the providers array 
        // without worrying about the sync status. It will resolve
        // handling that syncing state itself
        getProviders: function(){
            var deferred = $q.defer();

            // using a status object to handle errors in fetching
            // the providers and deep linking to the providers details pages
            if(_syncStatus === 'not-synced' ){
                // called from deep link
                _syncedPromises.push(deferred);
                this.sync();
            }else if(_syncStatus === 'running') {
                // called when we are in the middle of a sync 
                // and access this page
                // TODO: research, is this possible?
                _syncedPromises.push(deferred);
            }else {
                deferred.resolve();
            }

            return deferred.promise.then(function(){
                return _providers;
            });
        },

        // get a single providers details
        getProviderById: function(id){
            return this.getProviders().then(function(){
                var provider = _getProviderById(id);

                // only continue then chain if
                // we have a provider
                if(!provider){
                    return $q.reject();
                }
                return provider;
            });
        },

        flushAcctActivity: function(acctNumber) {
            this.getProviders().then(function(){
                var account = _getAccountByNumber(acctNumber);
                if (account) {
                    account.activity = null;
                    delete account.activityLimit;
                    delete account.activityFetched;
                }
            });
        },

        // clear the data known about the providers
        // use sparing and only when there are not
        // other available options to update a specific
        // set of data
        flushProviders: function(){
            _flush();
        }
    };
});
