angular.module('PatientApp').controller('BrowserInfoCtrl', function($scope, $rootScope, $filter, $http, BrowserInfoService){
    
    $scope.promptVisible = false;
    $scope.ipDetailsLoaded = false;
    $scope.copyText = 'Copy To Clipboard';

    // Opens the modal...
    // Note: the BrowserInfoService is a publish/subcribe service.  This onOpenModal function registers a subscriber (callback) to the openModal event.
    BrowserInfoService.onOpenModal(function(){
        $scope.promptVisible = true;
        $scope.browserDetails = getBrowser();
        BrowserInfoService.getIPData(function(response){
            $scope.ipDetails = response.data;
            $scope.ipDetailsLoaded = true;
        }, function(){
            $scope.ipDetailsLoaded = true;
        });
    });

    var _close = function(){
        $scope.promptVisible = false;
    };

    $scope.cancel = function(){
        _close();
    };

    function getBrowser()
    {
        // From http://www.javascripter.net/faq/browsern.htm
        var nAgt = navigator.userAgent;
        var browserName  = navigator.appName;
        var fullVersion  = ''+parseFloat(navigator.appVersion); 
        var majorVersion = parseInt(navigator.appVersion,10);
        var nameOffset,verOffset,ix;

        // In Opera 15+, the true version is after "OPR/" 
        if ((verOffset=nAgt.indexOf('OPR/'))!=-1) {
            browserName = 'Opera';
            fullVersion = nAgt.substring(verOffset+4);
        }
        // In older Opera, the true version is after "Opera" or after "Version"
        else if ((verOffset=nAgt.indexOf('Opera'))!=-1) {
            browserName = 'Opera';
            fullVersion = nAgt.substring(verOffset+6);
            if ((verOffset=nAgt.indexOf('Version'))!=-1){
                fullVersion = nAgt.substring(verOffset+8);
            }
        }
        // In MSIE, the true version is after "MSIE" in userAgent
        else if ((verOffset=nAgt.indexOf('MSIE'))!=-1) {
            browserName = 'Microsoft Internet Explorer';
            fullVersion = nAgt.substring(verOffset+5);
        }
        // In Chrome, the true version is after "Chrome" 
        else if ((verOffset=nAgt.indexOf('Chrome'))!=-1) {
            browserName = 'Chrome';
            fullVersion = nAgt.substring(verOffset+7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 
        else if ((verOffset=nAgt.indexOf('Safari'))!=-1) {
            browserName = 'Safari';
            fullVersion = nAgt.substring(verOffset+7);
            if ((verOffset=nAgt.indexOf('Version'))!=-1){
                fullVersion = nAgt.substring(verOffset+8);
            }
        }
        // In Firefox, the true version is after "Firefox" 
        else if ((verOffset=nAgt.indexOf('Firefox'))!=-1) {
            browserName = 'Firefox';
            fullVersion = nAgt.substring(verOffset+8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
                  (verOffset=nAgt.lastIndexOf('/')) ) 
        {
            browserName = nAgt.substring(nameOffset,verOffset);
            fullVersion = nAgt.substring(verOffset+1);
            if (browserName.toLowerCase()==browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present
        if ((ix=fullVersion.indexOf(';'))!=-1){
            fullVersion=fullVersion.substring(0,ix);
        }
        if ((ix=fullVersion.indexOf(' '))!=-1){
            fullVersion=fullVersion.substring(0,ix);
        }

        majorVersion = parseInt(''+fullVersion,10);
        if (isNaN(majorVersion)) {
            fullVersion  = ''+parseFloat(navigator.appVersion); 
            majorVersion = parseInt(navigator.appVersion,10);
        }

        return {
            'browser_name': browserName,
            'full_version': fullVersion,
            'major_version': majorVersion,
            'app_name': navigator.appName,
            'user_agent': navigator.userAgent
        };
    }

    $scope.copyBrowserInfoToClipboard = function()
    {
        // Get all of our data in one place
        var data = {
            'Browser': $scope.browserDetails.browser_name,
            'Browser Version': $scope.browserDetails.full_version,
            'User Agent': $scope.browserDetails.user_agent,
            'IP': $scope.ipDetails.ip,
            'Location': $scope.ipDetails.city+', '+$scope.ipDetails.region+', '+$scope.ipDetails.country_name,
            'ISP': $scope.ipDetails.organisation
        };

        // Stringify it
        var str = JSON.stringify(data);
        
        // Create a textarea.  This is nessecary for executing the "copy to clipboard" functionality
        var el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        // Copy the text
        document.execCommand('copy');
        // Remove the textarea
        document.body.removeChild(el);

        // Save the initial copy text, update the copy text to "copied!" then, after 3 seconds, reset the text.
        var og = $scope.copyText;
        $scope.copyText = 'Copied!';
        setTimeout(function(){
            $scope.copyText = og;
        }, 3000);
    };

});