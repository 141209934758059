// ReasonsService is used to map reason ids with their corresponding
// wording.


angular.module('PatientApp').factory('ReasonsService', function(_, $filter) {

    var itemizedBillReasonId = 10;

    // REASON NAMES
    // contactName = "Contact Us"
    // messageName = "Compose Message"
    var reasonsObj = [{
        id: 2,
        contactName: $filter('translate')('contact.questionAbout.reasons.specificCharge'),
        messageName: $filter('translate')('message.reasons.specificCharge')
    }, {
        id: 7,
        contactName: $filter('translate')('contact.questionAbout.reasons.paymentPlan'),
        messageName: $filter('translate')('message.reasons.paymentPlan')
    }, {
        id: 3,
        contactName: $filter('translate')('contact.questionAbout.reasons.duplicate'),
        messageName: $filter('translate')('message.reasons.duplicate')
    }, {
        id: 4,
        contactName: $filter('translate')('contact.questionAbout.reasons.missingPayments'),
        messageName: $filter('translate')('message.reasons.missingPayments')
    }, {
        id: 1,
        contactName: $filter('translate')('contact.questionAbout.reasons.updateInsurance'),
        messageName: $filter('translate')('message.reasons.updateInsurance')
    }, {
        id: 8,
        contactName: $filter('translate')('contact.questionAbout.reasons.insuranceQuestion'),
        messageName: $filter('translate')('message.reasons.insuranceQuestion')
    }, {
        id: itemizedBillReasonId,
        contactName: $filter('translate')('contact.questionAbout.reasons.itemizedBill'),
        messageName: $filter('translate')('message.reasons.itemizedBill')
    }, {
        id: 9,
        contactName: $filter('translate')('contact.questionAbout.reasons.financialAssistance'),
        messageName: $filter('translate')('message.reasons.financialAssistance')
    }, {
        id: 6,
        contactName: $filter('translate')('contact.questionAbout.reasons.other'),
        messageName: $filter('translate')('message.reasons.other')
    }];

    return {
        // reason ids & names for "Contact Us"
        getContactReasons: function() {
            var contactReasons = _.map(reasonsObj, function(reason) {
                return _.pick(reason, 'id', 'contactName');
            });
            return contactReasons;
        },

        getContactReasonById: function(id) {
            var reason = _.find(this.getContactReasons(), { 'id': id });
            if (reason) {
                return reason.contactName;
            }
            return reason;
        },

        // reason ids & names for "Compose Message"
        getMessageReasons: function() {
            var messageReasons = _.map(reasonsObj, function(reason) {
                return _.pick(reason, 'id', 'messageName');
            });
            return messageReasons;
        },

        getAllReasons: function() {
            return reasonsObj;
        },

        getItemizedBillReasonId: function() {
            return itemizedBillReasonId;
        }
    };
});
