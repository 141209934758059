angular.module('PatientApp').controller('LoginCtrl', function($rootScope, $scope, $log, $filter, $state, $timeout, _, PatientUsersService, ErrorsService, BillsService, Compass){

    var _deferWantingLogin = null,
        _resolveWithUser = function(user){
            if(_deferWantingLogin){
                _deferWantingLogin.resolve(user);
            }
        },
        _reject = function(){
            if(_deferWantingLogin){
                _deferWantingLogin.reject('User did not authenticate');
            }
        },

        _resetForms = function(){
            $scope.register = {
                receivesSms: true
            };
            $scope.login = {
                email: '',
                password: ''
            };
            $scope.forgotPassword = {};
            $scope.resetPassword = {};

            $scope.submissionNotifications = {};
            $scope.resetNotifications = {};

            $scope.forms.loginForm.$setPristine();
            $scope.forms.createForm.$setPristine();
            $scope.forms.forgotForm.$setPristine();
            $scope.forms.verifyForm.$setPristine();
            $scope.forms.resetPasswordForm.$setPristine();

            $scope.codeInvalid = false;
            $scope.codeNotSent = false;
            $scope.serverError = false;
        },

        _applyOptions = function(options){
            // inside this function will list all of the options we care about

            // selects the default section to open upon viewing the modal
            if(options.openSection){

                options.openSection = options.openSection.toLowerCase();

                $scope.sections.showLogin = false;
                $scope.sections.showForgotPassword = false;
                $scope.sections.showForgotPasswordMethod = false;
                $scope.sections.showForgotPasswordVerify = false;
                $scope.sections.showUpdatePassword = false;
                $scope.sections.showForgotPasswordEmailSent = false;

                if(options.openSection === 'login'){
                    $scope.sections.showLogin = true;   
                }else if(options.openSection === 'forgotpassword') {
                    $scope.sections.showForgotPassword = true;
                }else if(options.openSection === 'forgotpasswordmethod') {
                    $scope.sections.showForgotPasswordMethod = true;
                }else if(options.openSection === 'forgotpasswordverify') {
                    $scope.sections.showForgotPasswordVerify = true;
                }else if(options.openSection === 'resetpassword') {
                    $scope.sections.showUpdatePassword = true;
                }else if(options.openSection === 'forgotpasswordemailsent') {
                    $scope.sections.showForgotPasswordEmailSent = true;
                }
            }
            
            if(options.prefillEmail) {
                $scope.login.email = options.prefillEmail;
            }
        };


    $scope.promptUserLogin = false; 


    $rootScope.$on('login:showPrompt', function(event, defer, options) { 
        
        if(!defer){
            $log.error('Pass a deferred object via $emit');
        }

        _resetForms();

        _deferWantingLogin = defer;

        var currentBill = BillsService.getCurrentBill();
        $scope.showPayAsGuestOption = currentBill && currentBill.providerDetails.hasGuestPay;

        if(!_.isEmpty(options)){
            _applyOptions(options);
        }else {
            //defaults
            $scope.sections.showLogin = false;
            $scope.sections.showForgotPassword = false;
            $scope.sections.showForgotPasswordMethod = false;
            $scope.sections.showForgotPasswordVerify = false;
            $scope.sections.showUpdatePassword = false;
            $scope.sections.showForgotPasswordEmailSent = false;
        }


        $scope.promptUserLogin = true;
        
    });


    // $rootScope.$on('login:closePrompt', function(event) { 
    //  $scope.promptUserLogin = false;
    // });
    
    $scope.submissionNotifications = {};

    // contains the login sections
    $scope.sections = {};

    // object needed to keep the form in scope properly
    $scope.forms = {
        loginForm: {},
        createForm: {},
        forgotForm: {},
        verifyForm: {},
        resetPasswordForm: {}
    };

    $scope.register = {
        receivesSms: true
    };
    $scope.login = {
        email: '',
        password: ''
    };


    // intial state of the submission
    $scope.submitting = false;
    $scope.sendingToBillPay = false;
    $scope.resumbitPostAsyncValidation = false;
    $scope.registerDialog = 'actions.createAccount';
    $scope.loginDialog = 'actions.login';
    $scope.payAsGuestButtonText = $filter('translate')('actions.payAsGuest');

    // When this state loads, make sure the forms are in a clean state
    $scope.$on('$stateChangeSuccess', function(){
        $scope.submitting = false;
        $scope.resumbitPostAsyncValidation = false;
        $scope.registerDialog = 'actions.createAccount';
        $scope.loginDialog = 'actions.login';
    });


    $scope.loginCancelled = function(){
        if($scope.promptUserLogin){
            $scope.promptUserLogin = false; 
        }
        _reject();
    };

    $scope.submitLogin = function(){

        $scope.submissionNotifications = {};

        if($scope.forms.loginForm.$invalid || $scope.submitting){
            return;
        }

        $scope.submitting = true;
        $scope.loginDialog = 'actions.loggingIn';

        PatientUsersService.login($scope.login).then(function(user){
            if(user){
                _resolveWithUser(user);

                // todo: compare animation framerates to see
                // if it makes more sense to close prompt before
                // or after the resolve (which typically has a bg anim)
                _resetForms();
                $scope.promptUserLogin = false;
                
            }
        })
        .catch(function(resp){
            // could not find bill. Handle error
            switch(ErrorsService.resolve(resp)){
                case ErrorsService.errors.INVALID_PARAMETERS:
                    $scope.submissionNotifications.loginFailed = true;
                    break;
                case ErrorsService.errors.LOGIN_BLOCKED:
                    $scope.submissionNotifications.userBlocked = true;
                    break;
            }

            $scope.login.password = '';
            $scope.forms.loginForm.$submitted = false;
            $scope.submitting = false;
            $scope.loginDialog = 'actions.login';
        });
    };

    $scope.submitAsGuest = function() {
        if($scope.sendingToBillPay) {
            return;
        }
        var translate = $filter('translate');
        $scope.sendingToBillPay = true;
        $scope.payAsGuestButtonText = translate('actions.sendingToGuestPay');
        BillsService.payAsGuest()
        .catch(function(message) {
            $scope.submissionNotifications.guestPayErrored = true;
            $timeout(function () {
                $scope.submissionNotifications.guestPayErrored = false;
            }, 5000);
            $log.error(message);
        })
        .finally(function() {
            $scope.payAsGuestButtonText = translate('actions.payAsGuest');
            $scope.sendingToBillPay = false;
        });
    };

    $scope.submitRegister = function(continuingSubmission){

        // reset submitting status if we are invalid but resuming
        if($scope.forms.createForm.$invalid && $scope.submitting){
            $scope.submitting = false;
            $scope.registerDialog = 'actions.createAccount';
        }

        // supress duplicate submission attempts
        if($scope.forms.createForm.$invalid || ($scope.submitting && !continuingSubmission)){
            return;
        }

        $scope.submitting = true;
        $scope.registerDialog = 'actions.creatingAccount';

        // $pending is set when we are waiting for the async validator
        // to come back. Show submitting but when we get the validator
        // response, we will resubmit the form
        if($scope.forms.createForm.email.$pending || $scope.forms.createForm.phone.$pending){
            $scope.resumbitPostAsyncValidation = true;
            return;
        }

        PatientUsersService.register($scope.register).then(function(user){
            if(user){

                _resolveWithUser(user);

                _resetForms();

                // todo: compare animation framerates to see
                // if it makes more sense to close prompt before
                // or after the resolve (which typically has a bg anim)
                $scope.promptUserLogin = false;
            }
        })
        .catch(function(message){
            $log.error(message);
            $scope.submitting = false;
            $scope.registerDialog = 'actions.createAccount';
        });
    };


    // Callback for email and phone validation on login form
    // this is needed to handle resubmitting register
    // when we try to submit registration form before we 
    // have recieved the async validation
    $scope.asyncValidationComplete = function(){
        if($scope.resumbitPostAsyncValidation){
            $scope.resumbitPostAsyncValidation = false;
            $scope.submitRegister(/*continuingSubmission*/ true);
        }
    };





    // FORGOT PASSWORD FLOW
    $scope.forgotPassword = { email: '' };

    $scope.sections.showForgotPassword = false;
    $scope.retrieveDialog = $filter('translate')('actions.retrievePassword');
    $scope.retrieving = false;
    $scope.resumbitPostEmailRegistrationCheck = false;

    $scope.toggleForgotPassword = function(){

        // if the user has inputed an email from
        // another form but we don't have one assigned to the 
        // forget form yet, we will use that as the initial value
        if(!$scope.forgotPassword.email){
            $scope.forgotPassword.email = $scope.login.email || $scope.register.email || '';
        }

        _applyOptions({
            openSection: 'forgotPassword'
        });
    };

    $scope.submitForgot = function(continuingSubmission){        
        // reset submitting status if we are invalid but resuming
        if($scope.forms.forgotForm.$invalid && $scope.retrieving){
            $scope.retrieving = false;
            $scope.retrieveDialog = $filter('translate')('actions.retrievePassword');
        }

        // handle making sure we dont resubmit
        if($scope.forms.forgotForm.$invalid || ($scope.retrieving && !continuingSubmission)){
            return;
        }

        // tell the user we are processing
        $scope.retrieving = true;
        $scope.retrieveDialog = $filter('translate')('labels.retrievingPassword');

        // if we are waiting to finish async validation
        // update flag that will trigger a resubmission when
        // it is finished
        if($scope.forms.forgotForm.email.$pending){
            $scope.resumbitPostEmailRegistrationCheck = true;
            return;
        }


        // submit data
        PatientUsersService.forgotPassword($scope.forgotPassword.email)
            .then(function(resp){
                Compass.capture.misc('session', 'user', 'LoginCtrl::forgotPassword.then', 'email: ' + $scope.forgotPassword.email);

                $scope.forgotPassword.smsAvailable = resp.data.hasVerifiedPhone;
                $scope.forgotPassword.maskedPhone = resp.data.maskedPhone;

                _applyOptions({
                    openSection: 'forgotPasswordMethod'
                });
            })
            .finally(function(){
                $scope.retrieveDialog = $filter('translate')('actions.retrievePassword');
                $scope.retrieving = false;
            });
    };

    $scope.submitForgotMethod = function(method){
        $scope.forgotPassword.method = method;

        Compass.capture.misc('session', 'user', 'LoginCtrl::submitForgotMethod', 'email: ' + $scope.forgotPassword.email + ', method: ' + method);

        var forgotPasswordPromise, openSection = '';

        switch(method){
            case 'sms':
                forgotPasswordPromise = PatientUsersService.forgotPasswordGetCode($scope.forgotPassword.email);
                openSection = 'forgotPasswordVerify';
                break;
            case 'email':
                forgotPasswordPromise = PatientUsersService.forgotPasswordSendEmail($scope.forgotPassword.email);
                openSection = 'forgotPasswordEmailSent';
                break;
            default:
                $scope.codeNotSent = true;
                $timeout(function () {
                    $scope.codeNotSent = false;
                }, 5000);
                return;
        }

        forgotPasswordPromise.then(function() {
            _applyOptions({
                openSection: openSection
            });
        })
        .catch(function(resp) {
            if(resp.hasData && !!resp.getData().error) {
                $scope.serverErrorMessage = resp.getData().error;
                $scope.serverError = true;
                $timeout(function(){
                    $scope.serverError = false;
                }, 5000);
            } else {
                $scope.codeNotSent = true;
                $timeout(function () {
                    $scope.codeNotSent = false;
                }, 5000);
            }
        });
    };

    $scope.sendAgain = function(){
        _applyOptions({
            openSection: 'forgotPasswordMethod'
        });
    };

    $scope.submitForgotVerify = function(){
        if ($scope.forms.verifyForm.$invalid || $scope.verifying) {
            return;
        }

        $scope.verifying = true;

        Compass.capture.misc('session', 'user', 'LoginCtrl::submitForgotVerify', 'email: ' + $scope.forgotPassword.email + ', method: ' + $scope.forgotPassword.method + ', code: ' + $scope.forgotPassword.code);

        PatientUsersService.forgotPasswordVerifyCode($scope.forgotPassword.email, $scope.forgotPassword.method, $scope.forgotPassword.code)
            .then(function(resp){
                // $scope.resetPassword.email = $scope.forgotPassword.email;
                $scope.resetPassword.token = resp.token;

                _applyOptions({
                    openSection: 'resetPassword'
                });
            })
            .catch(function(resp) {
                if(resp.hasData && !!resp.getData().error) {
                    $scope.serverErrorMessage = resp.getData().error;
                    $scope.serverError = true;
                    $timeout(function(){
                        $scope.serverError = false;
                    }, 5000);
                } else {
                    $scope.codeInvalid = true;
                    $timeout(function(){
                        $scope.codeInvalid = false;
                    }, 5000);
                }
                $scope.forgotPassword.code = '';
                $scope.forms.verifyForm.$setPristine();
            })
            .finally(function(){
                $scope.verifying = false;
            });
    };

    // Callback for email validation on forgot password form
    // this is needed to handle resubmitting register
    // when we try to submit registration form before we 
    // have received the async validation
    $scope.emailRegisteredCheckFinished = function(){
        Compass.capture.misc('session', 'user', 'LoginCtrl::emailRegisteredCheckFinished', 'outside');

        if($scope.resumbitPostEmailRegistrationCheck){
            Compass.capture.misc('session', 'user', 'LoginCtrl::emailRegisteredCheckFinished', 'inside');
            $scope.resumbitPostEmailRegistrationCheck = false;
            $scope.submitForgot(/*continuingSubmission*/ true);
        }
    };



    // UPDATE PASSWORD FLOW
    $scope.resetPassword = {};
    $scope.resetNotifications = {};
    $scope.resetting = false;
    $scope.resetDialog = $filter('translate')('actions.resetPassword');


    $scope.submitReset = function(){

        //if we're getting here from the legacy email route, grab the token from the state params
        if(!$scope.resetPassword.token) {
            $scope.resetPassword.token = $state.params.tokenId;
        }
        
        if($scope.forms.resetPasswordForm.$invalid || $scope.resetting){
            return;
        }

        $scope.resetting = true;
        $scope.resetDialog = $filter('translate')('labels.resettingPassword');

        // var email = $scope.resetPassword.email;

        PatientUsersService.resetPassword($scope.resetPassword.token, $scope.resetPassword.password)
            .then(function(){

                // clean all of the forms to make sure there are
                // no other error states
                _resetForms();


                // we we transition to login, make sure the email that we used to reset is there
                // $scope.login.email = email;

                // add dialog to let the user know to login
                $scope.submissionNotifications.resetPasswordComplete = true;

                _applyOptions({
                    openSection: 'login'
                });

            })
            .catch(function(resp){
                switch(ErrorsService.resolve(resp)){
                    case ErrorsService.errors.INVALID_PARAMETERS:
                        $scope.resetNotifications.invalidAttempt = true;
                        break;
                    case ErrorsService.errors.INVALID_TOKEN:
                        $scope.resetNotifications.invalidAttempt = true;
                        break;
                }
            })
            .finally(function(){

                //remove the forgot details from the url
                if($state.params.tokenId) {
                    $state.go('app.default');
                }

                $scope.resetting = false;
                $scope.resetDialog = $filter('translate')('actions.resetPassword');
            });

    };

    $scope.switchTo = function(view) {
        _resetForms();
        _applyOptions({ openSection: view });
    };

});