angular.module('PatientApp').factory('ExperimentService', function($log, $resource, $q, $rootScope, endpoints, PatientUsersService) {


    var Experiment = $resource(null, null, {
        getVariation: {
            method: 'GET',
            url: endpoints.experiment.getVariation.url
        },
        addEvent: {
            method: 'POST',
            url: endpoints.experiment.addEvent.url
        }
    });

    /*
        Map of key/value pairs that look like this:
        experimentNameKey: {
            mobile: bool, // should the experiment run on mobile?
            desktop: bool, // should the experiment run on desktop?
            initOnLogin: true, // do we need to get the variation and kick off the experiment in PW? (false if kicked off in email)
            loadForState: 'some.state', // optional - a state should wait for this data (see waitForExperimentsToLoad below)
        },
    */
    var experiments = {
        scrollableDisclaimers: {
            mobile: false,
            desktop: true,
            initOnLogin: true
        },
        hsaMethod: {
            mobile: true,
            desktop: true,
            initOnLogin: true
        },
        financeButtonText: {
            mobile: true,
            desktop: true,
            initOnLogin: true,
            loadForState: 'app.payment.start'
        },
        financingInEbill: {
            mobile: true,
            desktop: true,
            initOnLogin: false
        }
    };

    var experimentExists = function(name) {
        return experiments.hasOwnProperty(name);
    };

    var isExperimentLoaded = function(name) {
        return experimentExists(name) && (experiments[name].isLoaded || experiments[name].initOnLogin === false);
    };

    var isExperimentStarted = function(name) {
        return isExperimentLoaded(name) && (experiments[name].isStarted || experiments[name].initOnLogin === false);
    };

    var cacheData = function(name, resp) {
        if (!experimentExists(name)) {
            experiments[name] = {};
        }

        experiments[name].data = resp.data;
        experiments[name].variation = resp.variation;
        experiments[name].isLoaded = true;
    };

    var addEventHelper = function(name, disposition, obj) {
        var identifier = PatientUsersService.getCurrentUser() ? PatientUsersService.getCurrentUser().patientUserId : obj.identifier;
        return Experiment.addEvent({
            name: name
        }, {
            identifier: identifier + '',
            disposition: disposition
        });
    };

    var getVariationHelper = function(name, obj) {
        var deferred = $q.defer();
        var identifier = PatientUsersService.getCurrentUser() ? PatientUsersService.getCurrentUser().patientUserId : obj.identifier;
        if (!experimentExists(name)) {
            experiments[name] = {};
        }
        experiments[name].promise = deferred.promise;
        if (isExperimentLoaded(name)) {
            deferred.resolve(experiments[name]);
        } else {
            Experiment.getVariation({
                name: name,
                identifier: identifier
            }).$promise
            .then(function(resp) {
                if (resp) {
                    cacheData(name, resp);
                    deferred.resolve({
                        variation: resp.variation,
                        data: resp.data
                    });
                } else {
                    deferred.reject(resp);
                }
            }, function(error) {
                deferred.reject(error);
            });
        }
        return deferred.promise;
    };

    var initAll = function() {
        var isMobile = /(phone|mobile)/i.test(navigator.userAgent);
        angular.forEach(experiments, function(details, name) {
            if (details.initOnLogin && ((details.mobile && isMobile) || (details.desktop && !isMobile))) {
                getVariationHelper(name);
            }
        });
    };

    var clearCache = function() {
        angular.forEach(experiments, function(details, name) {
            experiments[name].isLoaded = false;
            experiments[name].isStarted = false;
        });
    };

    // Register this service to clear it's data when asked to
    $rootScope.$on('data:clear', function(){
        clearCache();
    });

    // Reload all experiments when the user changes
    $rootScope.$on('user:changed', function(event, user) {
        clearCache();
        if (!user.bdLogin) {
            initAll();
        }
    });

    return {
        getVariation: getVariationHelper,

        isExperimentLoaded: isExperimentLoaded,

        getExperimentData: function(name) {
            return isExperimentLoaded(name) ? experiments[name] : undefined;
        },

        sendStart: function(name, obj) {
            if (isExperimentLoaded(name)) {
                return addEventHelper(name, 'indeterminate', obj)
                .$promise.then(function(resp) {
                    experiments[name].isStarted = true;
                    return $q.when(resp);
                });
            } else {
                return $q.reject('Experiment has not yet been loaded.');
            }
        },

        sendSuccess: function(name, obj) {
            if (isExperimentStarted(name)) {
                return addEventHelper(name, 'success', obj)
                .$promise.then(function(resp) {
                    return $q.when(resp);
                });
            } else {
                return $q.reject('Experiment has not yet been started.');
            }
        },

        sendFailure: function(name, obj) {
            if (isExperimentStarted(name)) {
                return addEventHelper(name, 'failure', obj)
                .$promise.then(function(resp) {
                    return $q.when(resp);
                });
            } else {
                return $q.reject('Experiment has not yet been started.');
            }
        },

        waitForExperimentsToLoad: function(state) {
            var promises = [];
            var deferred = $q.defer();
            var isMobile = /(phone|mobile)/i.test(navigator.userAgent);

            angular.forEach(experiments, function(details, name) {
                if ((details.mobile && isMobile) || (details.desktop && !isMobile)) {
                    if (details.loadForState === state) {
                        $log.info('waiting for ' + name);
                        promises.push(details.promise);
                    }
                }
            });

            // We don't want to hold everything up if the call fails for some reason, so always resolve
            $q.all(promises).finally(function() {
                deferred.resolve();
            });

            return deferred.promise;
        }
    };
});