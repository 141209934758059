angular.module('PatientApp').controller('ComposeMessageCtrl', function($rootScope, $scope, $log, $filter, $state, _, MessagesService, ProvidersService, BillsService, PatientUsersService, ReasonsService){


    var _emptyDetails = {
            secureCode: null,
            accountId : null,
            providerId : null,
            message: null,
            reason: ''
        },

        _reset = function(){

            // this removes the current in memory values for the
            // message and scode
            $scope.messageDetails = _.clone(_emptyDetails);

            $scope.forms.composeForm.$setPristine();
            // start with a blank slate
            $scope.askForProvider = false;
            $scope.askForAccount = false;
            $scope.showMessageInputs = false;
            $scope.providerMessagingDisabled = false;

            $scope.selectedProvider = null;
            $scope.selectedAccount = null;

            $scope.providers = [];
            $scope.accounts = [];
        },


        // checks to see if we have multiple providers
        // then asks the user to select which provider if
        // we see they have multiple ones. Otherwise it will
        // send the user to the next step which does a similar
        // check but for accounts
        _gatherProviderDetails = function(){

            ProvidersService.getProviders().then(function(providers){

                $scope.noProviders = false;

                if(providers.length === 0){
                    $scope.noProviders = true;
                    $log.error('No providers to help build the view');
                    return;
                }else if(providers.length === 1){
                    $scope.selectedProvider = providers[0];
                    $scope.messageDetails.providerId = providers[0].id;
                    _gatherAccountDetails();
                }else {
                    $scope.providers = providers;
                    $scope.askForProvider = true;
                }


            }).catch(function(er){
                $log.error(er);
            });

        },


        // like the provider details function, we want to check to see
        // if we have multiple accounts, and ask the user to pick which
        // account they are referring to if there is more than 1
        _gatherAccountDetails = function(){

            if($scope.selectedProvider === null){
                $log.error('No providers to attach to this message');
                return;
            }
            var accountsLen = $scope.selectedProvider.accounts.length;


            // before loading the actual accounts, make sure the provider can
            // actually accept messages to the accounts
            if(!$scope.selectedProvider.canMessage){
                $scope.providerMessagingDisabled = true;
                return;
            }

            if(accountsLen === 1){
                $scope.selectedAccount = $scope.selectedProvider.accounts[0];
                $scope.messageDetails.accountId = $scope.selectedProvider.accounts[0].accountId;
                $scope.messageDetails.secureCode = $scope.selectedProvider.accounts[0].secureCode;

                $scope.showMessageInputs = true;

            }else {

                _.forEach($scope.selectedProvider.accounts, function(acc){
                    acc.friendlyAccountBalance = $filter('translate')( 'labels.argBalance', {balance: $filter('currency')(acc.amountDue)} );
                });

                $scope.askForAccount = true;

                $scope.accounts = $scope.selectedProvider.accounts;
            }
        },


        // this function is used to resolve all of the details for a
        // provider and account if we pass an scode. This is what happens
        // from message provider on the dashboard.
        _gatherMessageDetailsProvidersSCode = function(sCode){
            ProvidersService.getProviders().then(function(providers){

                var _account,
                    _provider;
                _.forEach(providers, function( provider){
                    var account = _.first(_.filter(provider.accounts, function(acc){
                        return acc.secureCode === sCode;
                    }));

                    if(account){
                        _account = account;
                        _provider = provider;
                    }
                });

                if(!_account){
                    $log.error('Unable to find account for scode given');
                    return;
                }

                $scope.selectedProvider = _provider;
                $scope.messageDetails.providerId = _provider.id;
                $scope.selectedAccount = _account;
                $scope.messageDetails.accountId = _account.accountId;
                $scope.messageDetails.secureCode = _account.secureCode;

                $scope.showMessageInputs = true;

            }).catch(function(er){
                $log.error(er);
            });

        },

        // this function is used build the message details
        // based on the current bill
        _gatherMessageDetailsCurrentBill = function(){

            var _currentBill = BillsService.getCurrentBill();

            if(!_currentBill){
                $log.error('Asked to open a current bill but did not see one bills service');
                $scope.showCompose = false;
                return;
            }


            $scope.messageDetails.secureCode = _currentBill.secureCode;

            $scope.account = {
                friendlyAccountNumber: $filter('accountNumber')( _currentBill.accountNumber ),
                friendlyAccountBalance: $filter('translate')( 'labels.argBalance', {balance: $filter('currency')(_currentBill.accountBalance.amount)} )
            };

            // these selections are known to be off. we just need them
            // to be providerish and accountish. So we can get the
            // name, account number and account balance off of them
            $scope.selectedProvider = _currentBill.providerDetails;
            $scope.provider = _currentBill.providerDetails;
            $scope.selectedAccount = _currentBill.accountBalance;
            $scope.selectedAccount.friendlyAccountNumber = $scope.account.friendlyAccountNumber;

            $scope.showMessageInputs = true;
        };


    $scope.providers = [];
    $scope.selectedProvider = null;
    $scope.warningMessageLength = 4900;
    $scope.maxMessageLength = 5000;
    $scope.reasonsObj = ReasonsService.getMessageReasons();
    // global listeners to watch out for something wanting to start a composition
    $scope.showCompose = false;

    // initial state
    $scope.forms = { composeForm: null };

    // $emit this event to trigger the composition modal into view.
    // The gatherByData can come in 3 ways
    //      String :: we assume that if gatherByData is a string it is an sCode
    //      Boolean :: we assume that if you pass true boolean primitive that
    //              you want us to build the info off of the current bill
    //      Anything else, we simply ignore but by triggering this event
    //          we load the ui to be built off the user'r providers and accounts
    //
    // Passing the method this way allows us to be exact with our expectations
    //  on what's available as well as reduces performance load by building the
    //  ui on data we have available to us currently instead of an ajax call.
    $rootScope.$on('message:compose', function( event, gatherByData ){

        // show popup
        // if we get account details, prepopulate our modal
        // with those details
        _reset();

        $scope.showCompose = true;

        if(_.isBoolean(gatherByData)) {
            _gatherMessageDetailsCurrentBill();
        }else if(_.isString(gatherByData)) {
            _gatherMessageDetailsProvidersSCode(gatherByData);
        }else if(_.isObject(gatherByData)) {
            $scope.messageDetails.reason = gatherByData.reason;
            $scope.messageDetails.message = gatherByData.message;
            if (gatherByData.sCode) {
                _gatherMessageDetailsProvidersSCode(gatherByData.sCode);
            }
            else {
                _gatherMessageDetailsCurrentBill();
            }
        } else {
            _gatherProviderDetails();
        }
    });


    $scope.cancelComposition = function(){
        $scope.showCompose = false;
        // clear state params
        $state.go('.', {autofill: false, data: null});
    };

    $scope.selectProvider = function(id){
        $scope.selectedProvider = _.first(_.filter($scope.providers, function(provider){
            return provider.id === id;
        }));
        $scope.askForProvider = false;
        $scope.messageDetails.providerId = id;

        _gatherAccountDetails();
    };

    $scope.selectAccount = function(id){
        $scope.selectedAccount = _.first(_.filter($scope.selectedProvider.accounts || [], function(account){
            return account.accountId === id;
        }));
        $scope.askForAccount = false;
        $scope.messageDetails.accountId = id;
        $scope.messageDetails.secureCode = $scope.selectedAccount.secureCode;

        $scope.showMessageInputs = true;
    };


    $scope.sendDialog = $filter('translate')('actions.sendMessage');
    $scope.sending = false;

    $scope.sendMessage = function(){

        if($scope.forms.composeForm.$invalid || !$scope.messageDetails.message || $scope.sending ){
            return;
        }

        $scope.sending = true;
        $scope.sendDialog = $filter('translate')('actions.sending');


        MessagesService.send($scope.messageDetails)
            .then(function(messageId){

                $scope.showCompose = false;

                $rootScope.$emit('simpleModal:showPrompt', {
                    header: $filter('translate')('message.messageSent'),
                    subcontent: $filter('translate')('dialogs.copySentTo', {email: PatientUsersService.getCurrentUser().email}),
                    intent: 'success',
                    actions: [{
                        label: $filter('translate')('actions.viewMessage'),
                        clickHandler: function(){
                            $state.go('app.messages.details', {threadId: messageId});
                        }
                    }]
                });

                // clear state params
                $state.go('.', {autofill: false, data: null});

                // some pages need to refresh their content because of
                // this event. So let them know it happened
                $rootScope.$emit('message:newThreadCreated', {
                    providerId: $scope.messageDetails.providerId,
                    accountId: $scope.messageDetails.accountId
                });
            })
            .finally(function(){
                $scope.sendDialog = $filter('translate')('actions.sendMessage');
                $scope.sending = false;
            });
    };


});
