angular.module('PatientApp').factory('NotificationsService', function($q, $log, $filter, $resource, $rootScope, _, endpoints){



    var Notifications = $resource(endpoints.notifications.primary.url, null, {
            fetchNotifications: {
                method: 'GET'
            },

            updateNotificationReadLog: {
                method: 'POST',
                url: endpoints.notifications.updateNotificationReadLog.url
            }
        }),

        _fetched = false,
        _numNew = null,
        _notifications = [];


    // Register this service to clear it's data when asked to
    $rootScope.$on('data:clear', function(){
        _fetched = false;
        _numNew = null;
        _notifications = [];
    });


    return {
        setSeen: function() {
            _numNew = $filter('filter')(_notifications, { isRead: false }).length;
            $rootScope.$emit('notifications:seen');
        },
        hasNewNotifications: function() {
            return _numNew > 0;
        },
        getNumberOfNewNotifications: function() {
            return _numNew;
        },
        fetchNotifications: function(){
            _fetched = false;

            return Notifications.fetchNotifications().$promise
                .then(function(resp){
                    if(resp && resp.hasData()){
                        var notifData = resp.getData();

                        var _oldNotifications = _notifications;
                        _notifications = notifData.notifications;
                        _.forEach(_notifications, function(notification){
                            notification.friendlyCreatedTime = $filter('dateFormat')(notification.timeCreated, 'MMM d, yyyy - hh:mm a');
                        });

                        if (!_.isEmpty(_notifications) && _oldNotifications.length !== _notifications.length) {
                            // only update this when we think that something has actually changed.  here's a situation that we'd like to avoid: 
                            //   - new acct + no notifications
                            //   - add a bill.  notifications get updated and the 'new' indicator gets set
                            //   - user loads notifications and indicator goes away
                            //   - user adds a bill from either a new provider (w/ no relevant notifications) or from the same provider.
                            //   - new indicator should NOT be set.  i.e. indicator status should be whatever is set within the controller.
                            _numNew = notifData.numNew;
                        }
                    }

                    return _notifications;
                })
                .finally(function(){
                    // flag to know that we hit the server for notifications
                    _fetched = true;
                });
        },

        getNotifications: function(forceFetch) {
            var deferred = $q.defer();
            if (!_fetched || forceFetch) {
                this.fetchNotifications().then(function(){
                    // get some complex values in a friendlier form
                    deferred.resolve(_notifications);
                }).
                catch(function(er){
                    deferred.reject(er);
                });
            } else {
                deferred.resolve(_notifications);
            }
            return deferred.promise;
        },

        updateNotificationReadLog: function(id, isRead){
            Notifications.updateNotificationReadLog({
                id: id,
                isRead: isRead
            });
        }
    };

});