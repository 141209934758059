
// MoneyUtilService is used to do any money calculation and comparison
// All money operations should be done here so we can make sure it is safe
// and isolated to one area

angular.module('PatientApp').factory('MoneyUtilsService', function(_){

    var _toCents = function(val){
            return parseFloat(val) * 100;
        },

        _backToFloat = function(val){
            // note we do not try to handle rounding issues here
            // as anything using this to present data will be 
            // passed through a currency filter
            return val / 100;
        };


    return {
        add : function(/*...*/){
            return _backToFloat(_.sum(_.map(arguments, function(num){
                return _toCents(num);
            })));
        },

        subtract: function(/*...*/){
            return _backToFloat(_.reduce(_.map(arguments, function(num){
                return _toCents(num);
            }), function(difference, cents){
                return difference - cents;
            }));
        },

        equals: function(/*...*/){
            var _args = arguments;
            return _.every(_args, function( num ){
                return _toCents(num) === _toCents(_args[0]);
            });
        },

        greaterThanOrEqual: function( a, b ){
            return _toCents(a) >= _toCents(b);
        },

        lessThanOrEqual: function( a, b ){
            return this.greaterThanOrEqual(b, a);
        },

        lessThan: function( a, b ){
            return _toCents(a) < _toCents(b);
        },

        greaterThan: function( a, b ){
            return _toCents(a) > _toCents(b);
        }
    };

});