angular.module('PatientApp').directive('findBillDialog', function (){
    return {
        // only <find-bill-dialog>...</find-bill-dialog> allowed
        restrict : 'E',

        scope: {
            buttonText: '@',
            shcLabel: '@',
            amountLabel: '@',
            submitHandler: '@',
            onFind: '&'
        },

        compile: function(element, attrs) {
            if (!attrs.buttonText) {
                attrs.buttonText = 'actions.payBill';
            }
            if (!attrs.shcLabel) {
                attrs.shcLabel = 'labels.secureCodeOnBill';
            }
            if (!attrs.amountLabel) {
                attrs.amountLabel = 'labels.balanceOnBill';
            }
        },

        templateUrl: 'templates/forms/find-bill.tpl.html'
    };

});
