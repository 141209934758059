/*
     Ajax Setup - this is to help cover the common uses of the
     ajax lifecyle. Performance tracking, unauthenticated reponses,
     etc. There should be no endpoint specific logic in this setup.
     As well, you should not need to worry about the items in this
     setup where you call $http because they will be handled for you.
*/

angular.module('PatientApp').config(function($httpProvider){

    //$httpProvider.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8';
    $httpProvider.defaults.withCredentials = true;
    // for api requests we are going to add
    // some helper functions to the response
    // to remove the need for common checks on the
    // data array for example.
    $httpProvider.defaults.transformResponse.push(function(value){


        if(angular.isObject(value) && 'data' in value){

            // having data means it is not an empty array
            // or the value is not
            value.hasData = function(){
                return (angular.isArray(this.data) && this.data.length > 0) || !!value.data;
            };

            // this is a convenience method for
            // getting the responses data.
            // It has been needed as the contract expectations
            // have changed and this should be the layer
            // to help mitigate having to change the
            value.getData = function(){
                return this.data;
            };
        }

        return value;
    });



    $httpProvider.interceptors.push(function($rootScope, $log, $q, ErrorsService, endpoints, ServerStatusService) {
        return {
            // optional method
            'request': function(config) {
                if (config.method === 'POST' && ServerStatusService.getXsrfToken()) {
                    //If there's no data yet, we want to be sure to add the token (we need it in logout for example)
                    if (!config.data) {
                        config.data = {};
                    }
                    //add the XSRF token to the POST variable.  This can also be done
                    //as an HTTP header - but there are issues with CORS not allowing certain 
                    //headers (and different behavior from different browsers)
                    config.data['XSRF-TOKEN'] = ServerStatusService.getXsrfToken();
                }

                return config;
            },

            // optional method
            'requestError': function(rejection) {
                return $q.reject(rejection);
            },

            // optional method
            'response': function(response) {

                // the checking for the data field required from the
                // code contract specs
                var serverReponse = angular.isDefined(response.data.data) ? response.data : null;

                if(serverReponse){
                    // handle logging server messages

                    if(serverReponse.devMessage){
                        $log.info('SERVER MESSAGE:', serverReponse.devMessage);
                    }

                    if(serverReponse.gatewayStatus){
                        ServerStatusService.updatePaymentStatus(serverReponse.gatewayStatus);
                    }

                    if(serverReponse.xsrfToken){
                        ServerStatusService.updateXsrfToken(serverReponse.xsrfToken);
                    }

                    if(serverReponse.data && serverReponse.data.autologoutStatus){
                        //autologoutstatus is delivered as part of the main payload - so, we have to look within the data property.
                        //broadcast to autologoutService.  could have been set directly on the service itself but there would be a circular
                        //dependancy if autologoutService were injected here
                        $rootScope.$emit('autologoutService:refresh', serverReponse.data.autologoutStatus);
                    }

                    // reject with errorCode
                    if(serverReponse.errorCode){

                        if(ErrorsService.resolve(serverReponse) === ErrorsService.errors.UNAUTHORIZED_ACCESS){

                            // we also get an unauthorized response from our fetchUser call.
                            // When we open the app for the first time, we try to get the currently
                            // logged in user but since we know the user is not logged in on that call,
                            // won't emit unauthed
                            if(response.config.url !== endpoints.patientUser.primary.url || response.config.method !== 'GET'){
                                $rootScope.$emit('unauthedAccessResponse');
                            }
                        }

                        return $q.reject(serverReponse);
                    }
                }

                return response;
            }
        };
    });
});
