angular.module('PatientApp').directive('currency', function($timeout, $filter, _, KeyboardService, MoneyUtilsService){

    var _getFormattedString = function(newInput, maxLength, blurred, deleting){

        newInput = (newInput || '').toString();


        var cleaned = newInput.replace(/[^\d\$\.]+/g, ''),
            hasDecimal = cleaned.indexOf('.') > -1,
            sections = cleaned.replace(/[\$]+/g, '').split('.'),
            dollars = sections.shift(),
            cents = (sections.shift() || '').slice(0,2), // two decimal places
            output = '';


        // since we allow dollar signs we have to clean
        // up whenever they are inputted other than the first
        // item
        if(cleaned.lastIndexOf('$') > 0){
            cleaned = '$' + cleaned.replace(/\$+/g, '');
        }


        // state changes that just need to be reflected back
        if(deleting){
            return newInput;
        }else if(cleaned === '$'){
            return cleaned;
        }

        

        if((cleaned.length === 1 && cleaned === '.') || (dollars === '0' && !hasDecimal)){
            
            // if we think they are going to attempt to start
            // inputting cents, let's reformat the dollar and 
            // currency section
            output = blurred ? '$0.00' : '$0.';

        }else if(_.isEmpty(dollars) && _.isEmpty(cents)){
            // handle zero state and allow placeholder to be visible
            return '';
        }else {

            // format the dollars section
            if(!_.isEmpty(dollars)){
                output = $filter('currency')(dollars, '$', 0);
            }else {
                output = '$0';
            }


            // format the cents section
            // if we have a decimal, we need to make sure it's
            // reflected and if we are blurring we need to make sure
            // we have properly padded the zeros
            if(hasDecimal || blurred){
                
                output += '.';

                // handle adding zeros on blurred inputs
                if(blurred){
                    if(cents.length === 0){
                        output += '00';
                    }else if(cents.length === 1 ){
                        output +=  cents + '0';
                    }else {
                        output += cents;
                    }
                }else {
                    output += cents;
                }
                
            }
        }

        // in certain flows, we add values to the input
        // programatically. We need to make sure we never
        // add more characters to the input than is allowed 
        // via the maxlength attribute
        return maxLength === -1 ? output : output.slice(0, maxLength);
    };

    return {
        require: 'ngModel',

        scope: {
            min: '=min',
            max: '=max',
            resetTo: '=resetTo'
        },

        link: function(scope, el, attrs, ngModel){

            var maxLength = attrs.maxlength || -1,
                runValidators = function(explicitValue){
                    var cVal = (explicitValue || ngModel.$modelValue || '').toString();

                    if (isNaN(cVal) || isNaN(parseFloat(cVal))) {
                        return;
                    }

                    if (typeof scope.min !== 'undefined') {
                        ngModel.$setValidity('min', MoneyUtilsService.greaterThanOrEqual(cVal, scope.min));
                    }

                    if (typeof scope.max !== 'undefined') {
                        ngModel.$setValidity('max', MoneyUtilsService.lessThanOrEqual(cVal, scope.max));
                    }
                },
                getVal = function() {
                    var val = _getFormattedString(el.val(), maxLength).replace(/[^\d\.]+/g, '');
                    return (parseFloat(val) || 0).toString();
                };

            el.on('paste', function(){
                $timeout(function(){
                    el.val(_getFormattedString(el.val(), maxLength));
                }, 50);
            });
    
            // reject easily blacklisted characters
            el.on('keydown', function(event){
                var key = KeyboardService.parseEvent(event),
                    elVal = el.val(),
                    currentDecimalPos = elVal.indexOf('.');
                
                // number pads seem to register as letters incorrectly but
                // we can make sure they don't qualify before checking letter
                if(!key.isNumber() && key.isLetter()){
                    event.preventDefault();
                }

                // don't allow the user to type more than two decimal positions
                // we are added this logic here so we never commit the letter to 
                // the input before taking it away
                if(key.isNumber() && currentDecimalPos > -1 && this.selectionStart > currentDecimalPos){
                    if(elVal.split('.')[1].length === 2){
                        event.preventDefault();
                    }
                }
            });


            el.on('keyup', function(event){
                
                var key = KeyboardService.parseEvent(event),
                    start = this.selectionStart,
                    end = this.selectionEnd,
                    deleting = key.is('backspace', 'delete'),
                    atEnd = this.selectionStart === el.val().length,
                    newVal = _getFormattedString(el.val(), maxLength, /*blurred*/ false, deleting);

                el.val(newVal);

                if(atEnd){
                    this.setSelectionRange(newVal.length, newVal.length);
                }else {
                    this.setSelectionRange(start, end);
                }
            });

            el.on('blur', function(){

                var original = el.val(),
                    updated,
                    useVal = (ngModel.$modelValue || 0).toString(); // value to format with updated

                // if we dont have a model value and have to reset
                // see if we have an explicit value we should use to reset
                if(!ngModel.$modelValue && scope.resetTo !== undefined){
                    useVal = (scope.resetTo || '').toString();
                }
                
                updated = _getFormattedString(useVal, maxLength, /*blurred*/ true);
                // only if we changed the value after
                // user left should we call apply
                if(original !== updated){
                    ngModel.$setViewValue(updated);
                    el.val(updated);
                    runValidators(updated.replace(/[^\d\.]+/g, ''));
                }
            });

            el.on('focus', function () {
                // empty this for the use case where
                // users want to change this value starting
                // from the zero state
                if(el.val() === '$0.00'){
                    el.val(''); 
                }
            });
            
            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function () {
                runValidators();
            });

            ngModel.$formatters.push(function(newInput){
                // on initializing the view with the value, if we dont have
                // an actual value to present, try to fall back to the resetTo
                // scope variable, otherwise allow the placeholder to be visible
                return _getFormattedString(newInput || scope.resetTo || '', maxLength, /*blurred*/ true);
            });

            ngModel.$parsers.push(function(viewValue){
                var val = viewValue ? _getFormattedString(viewValue, maxLength).replace(/[^\d\.]+/g, '') : 0;
                return (parseFloat(val) || 0);
            });

            runValidators(getVal()); //initialize validator state
        }
    };
});
