/*
This service is responsible for logging users out of the site when they've been inactive for too long.  Prior to being auto-logged-out, they are
given a warning message which tells them that they will soon be logged-out.  Activity is recorded in the form of clicks and keydowns.  When either of these
events happen, the time of the event is recorded in local storage where all tabs for the session can access it.  This values is stored here because it needs to
be accessible for all tabs.  Writing to the local storage causes an event to be fired in other tabs - which use the event to refresh their local copy of the value
(for performance reasons).  Within each tab, there's a polling loop which is trigged once per second.  It checks to see if it is time to show the 
warning dialog or if it is time to log the user out of the application.  

There is another timer which sends the server an ajax heartbeat as long as the user is active.  This is how the client and server stay in sync.

There is also logic in here which sync's the logged-in status across all tabs.  Both log-ins and logouts are performed via postback.  Upon re-loading the site and script, 
write the logged-in status of the current tab to the local storage.  Other tabs will receive the value via the local storage event and compare this value to their own 
status.  If it's changed, then the tab responds by redirecting to the appropriate page (either logout of the default provider page).
*/
angular.module('PatientApp').service('AutoLogoutService', function($interval, $rootScope, $filter, $http, $document, $window, $log, _, AuthService, PatientUsersService){

    var _DEFAULT_SESSIONTIMEOUT = 3600000,
        _DEFAULT_CLIENT_MODAL_GRACE_PERIOD = 120000,
        _DEFAULT_HB_FREQ = 3480000,

        _timeUntilWarning,
        _lastInteractionTime,
        _windowIsLoggedInVal,
        _sessionTimeout,
        _hbFreq,
        _dlgVisible = false,
        _hbPromise,
        _enabled = false, //OFF by default.  will most-likely be turned-on shortly on when we hear from the server

        _writeToStorage = function(key, value) {
            localStorage.setItem(key, value);
        },

        _handleStorageUpdate = function(e) {
            if (e.key === 'lastInteractionTime') { 
                _lastInteractionTime = parseInt(e.newValue);  //keep lastInteractionTime in sync with other tabs
                _keepSessionAlive(false);
            }else if (e.key === 'loggedIn') { 
                if (!parseInt(e.newValue) && AuthService.hasAccess()) {
                    $log.log('other tab logged-out.  logging-out...');
                    AuthService.unauthenticateUser(/*showModal*/ true);//manually logged-out on another tab.  log-out here too
                }
                // else if (parseInt(e.newValue) && !AuthService.hasAccess()) {
                //      // not yet implemented.  may not be important in SPA-land
                // }
            }
        },

        _mainLoop = function() {
            if (AuthService.hasAccess() && _enabled) {
                var inactiveTimeInMS = Date.now() - _lastInteractionTime;
                if (inactiveTimeInMS >= _sessionTimeout) { 
                    _logoutOnServer(); 
                } else if (inactiveTimeInMS >= _timeUntilWarning) { 
                    _popupModal(); 
                }
            }

            if (_windowIsLoggedInVal !== AuthService.hasAccess()) {
                _writeToStorage('loggedIn', AuthService.hasAccess() ? 1 : 0);  //auth status changed.  broadcast to other tabs
                _windowIsLoggedInVal = AuthService.hasAccess();
            }
        },

        _sendHeartbeat = function() {
            // as long as client session is still active, keep refreshing server session 
            if (AuthService.hasAccess()) {
                PatientUsersService.sendHeartbeat(); //return value doesn't matter
            }
        },

        _keepSessionAlive = function(broadcastToOtherTabs) {
            if (AuthService.hasAccess()) {
                _lastInteractionTime = Date.now();
                _closeDialogIfVisible();
                if (broadcastToOtherTabs) { 
                    _writeToStorage('lastInteractionTime', _lastInteractionTime); //push to other tabs
                } 
            }
        },

        _closeDialogIfVisible = function() {
            if (_dlgVisible) {
                //if we're at the point where we're going to log the user out, then the dialog should definitely be visible
                $rootScope.$emit('simpleModal:hidePrompt');
                _dlgVisible = false;
            }
        },

        _logoutOnServer = function() {
            if (AuthService.hasAccess()) { 
                _closeDialogIfVisible();
                AuthService.unauthenticateUser(/*showModal*/ true);
            }
        },

        _popupModal = function() {
            if (AuthService.hasAccess() && !_dlgVisible) {
                $rootScope.$emit('simpleModal:showPrompt', {
                    header: $filter('translate')('dialogs.logoutWarningHeader'),
                    subcontent: $filter('translate')('dialogs.logoutWarningSubcontent'),
                    intent: 'information',
                    meta: 'warning',
                    actions: [{
                        label: $filter('translate')('actions.stayLoggedIn')
                    }],
                    closeHandler: function() {
                        _keepSessionAlive(true);
                        _dlgVisible = false;
                    }
                });
                _dlgVisible = true;
            }
        },

        _config = function(enabled, sessionTimeout, clientModalGracePeriod, hbFreq) {
            if (!sessionTimeout || sessionTimeout <= 0) { 
                $log.log('invalid value for sessionTimeout: ' + sessionTimeout); 
                return; 
            }
            if (!clientModalGracePeriod || clientModalGracePeriod <= 0) { 
                $log.log('invalid value for clientModalGracePeriod: ' + clientModalGracePeriod); 
                return; 
            }
            if (!hbFreq || hbFreq <= 0) { 
                $log.log('invalid value for hbFreq: ' + hbFreq); 
                return; 
            }

            var timeUntilWarning = (sessionTimeout - clientModalGracePeriod);
            if (timeUntilWarning <= 0) { 
                $log.log('invalid value for timeUntilWarning: ' + timeUntilWarning); 
                return; 
            }

            _enabled = enabled;
            _sessionTimeout = sessionTimeout;
            _timeUntilWarning = timeUntilWarning;

            //leave the interval alone unless the value is actually changing
            if (_hbFreq !== hbFreq) {
                if (_hbPromise) {
                    $log.log('changing heartbeat frequency from ' + _hbFreq + ' to ' + hbFreq);
                    //must cancel existing interval before we create a new one
                    $interval.cancel(_hbPromise);
                    _hbPromise = undefined;
                }
                _hbFreq = hbFreq;
                _hbPromise = $interval(_sendHeartbeat, hbFreq);
            }
        };

    this.start = function() {
        this.config(false, _DEFAULT_SESSIONTIMEOUT, _DEFAULT_CLIENT_MODAL_GRACE_PERIOD, _DEFAULT_HB_FREQ);
        
        $document.find('body').on('mousemove keydown DOMMouseScroll mousewheel mousedown touchstart touchmove scroll', _.debounce(function() {
            if (!_dlgVisible) {
                _keepSessionAlive(true);
            }
        }, 200));

        angular.element($window).on('storage', _handleStorageUpdate);

        _keepSessionAlive(true);
        $interval(_mainLoop, 1000);
    };

    this.config = _config;

    //hack used by unit tests
    this.setLastInteractionTime = function(lastInteractionTime) {
        _lastInteractionTime = lastInteractionTime;
    };

    $rootScope.$on('autologoutService:refresh', function(event, autologoutStatus){
        _config(autologoutStatus.autoLogout, autologoutStatus.sessionTimeout, autologoutStatus.clientModalGracePeriod, autologoutStatus.hbFreq);
    });
});