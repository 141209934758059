/*
    The OptionModalCtrl Controller provides a way to present a list of options in a modal.  Options as well as their
    associated callbacks are parameterized
*/
angular.module('PatientApp').controller('OptionModalCtrl', function($scope, $rootScope, _, $timeout){
    var _reset = function() {
            $scope.currStep = 'option';
            $scope.errorMessage = '';
            _resetConfirmBtn();
        },
        _resetConfirmBtn = function() {
            $scope.confirming = false;
            $scope.currConfirmButtonText = $scope.modal.confirmButtonLanguage;
        };

    $scope.modal = {
        visible: false
    };

    $scope.selectedOption = null;

    $rootScope.$on('optionModal:showPrompt', function(event, promptProperties) {
        $scope.modal = _.assign({
            optionHeader: '',
            confirmHeader: '',
            options: [],
            includeClasses: '',
            confirmButtonLanguage: '',
            confirmingButtonLanguage: '',
            errorHandler: null,
            successHandler: null
        }, promptProperties);

        _reset();
        
        //adding a delay here makes the "jitter" go away when the dialog is re-opened
        $timeout(function(){
            $scope.visible = true;

            //quasi-hack that allows us to pre-select an option and head straight to the confirm screen
            _.forEach($scope.modal.options, function(option) {
                if (option.selected) {
                    $scope.selectOption(option);
                }
            });
        }, 10);
    });

    $rootScope.$on('optionModal:hidePrompt', function() {
        $scope.visible = false;
    });

    $scope.selectOption = function(option) {
        $scope.selectedOption = option;
        if ($scope.selectedOption.requireConfirmation) {
            $scope.currStep = 'confirm';
        }
        else {
            //we're done w/ the dlg
            $scope.selectedOption.clickHandler();
            $scope.close();
        }
    };

    $scope.confirm = function() {
        if ($scope.confirming) {
            return; //short-circuit double-submits
        }

        $scope.confirming = true;
        $scope.currConfirmButtonText = $scope.modal.confirmingButtonLanguage;

        $scope.selectedOption.clickHandler()
            .then(function(resp) {
                $scope.close();
                if ($scope.modal.successHandler) {
                    return $scope.modal.successHandler(resp);
                }
            }).catch(function(resp) {
                if ($scope.modal.errorHandler) {
                    var errorResponse = $scope.modal.errorHandler(resp);
                    if (errorResponse && errorResponse.message) {
                        $scope.errorMessage = errorResponse.message;
                    }
                }
                _resetConfirmBtn();
            });
    };

    $scope.close = function() {
        $scope.visible = false;
    };
});