angular.module('PatientApp').factory('LoadingIndicatorService', function($rootScope, $interval, _){
    
    var _stack = [],

        // time (ms) we wait before actually
        // making the indicator visible
        _waitTime = 1500,

        _preping = false,

        // We don't want to show a flash of 
        // loading everytime we call loading.
        // So we need to wait for a period of time
        // before we make the indicator visible
        _prepareToShow = function(){
            
            if(_preping || _factory.isVisible){
                return;
            }
            
            _preping = true;


            // _waitTime is configurable via options in factory
            // -- this is really to help testing
            if(_waitTime === 0){
                _checkAndShow();
            }else {
                $interval(_checkAndShow, _waitTime, 1);
            }
        },

        _checkAndShow = function(){
            if(_stack.length > 0){
                _show();
            }else {
                // make sure anytime we expect
                // the ui to be closed, that it is
                _hide();
            }
            _preping = false;
        },

        _show = function(){
            _factory.isVisible = true;
        },

        _hide = function(){

            if(!_factory.isVisible || _stack.length !== 0){
                return;
            }
            
            _factory.isVisible = false;
        },

        _factory = {
        
            loading: function(){

                var key = _.uniqueId('loader_');

                _stack.push(key);

                _prepareToShow();

                return key;
            },

            complete: function(key){
                _.pull(_stack, key);
                _hide();
            },

            // options to change internals of
            // this service. These should likely
            // never be needed outside of unit testing
            options: function(spec){
                if(spec.waitTime !== undefined){
                    _waitTime = spec.waitTime;
                }
            },

            // DO NOT directly use this
            // field to show the loader
            // user loading.. or complete.. above
            isVisible: false
        };

    return _factory;
});