angular.module('PatientApp').controller('ConfirmPhoneNumberCtrl', function($scope, $rootScope, $state, $log, $filter, $timeout, _, ErrorsService, PatientUsersService){

    var _reset = function(){
            var user = PatientUsersService.getCurrentUser();

            $scope.fields.phoneNumber = user.phoneNumber;
            $scope.fields.code = '';

            if ($scope.forms && $scope.forms.confirmPhoneForm) {
                $scope.forms.confirmPhoneForm.$setPristine();
            }

            $scope.submitButtonText = $filter('translate')('confirmPhone.submitButtonText');
            $scope.submitting = false;
            $scope.resumbitPostAsyncValidation = false;
            $scope.submitErrorMessage = '';
            $scope.showMoreInfo = false;
            $scope.step = 'number';
        },

        _hidePrompt = function(){
            $scope.promptVisible = false;
        },

        _destroyShowPrompt = $rootScope.$on('confirmPhone:prompt', function(){
            _reset();
            $scope.promptVisible = true;
        }),

        _resetNotifications = function(){
            $scope.codeInvalid = false;
            $scope.codeNotSent = false;
        },

        _confirmPhoneNumber = function(currentPassword) {
            $scope.promptVisible = true;
            $scope.submitting = true;

            if($scope.forms.confirmPhoneForm.phoneNumber.$pending){
                $scope.resumbitPostAsyncValidation = true;
                return;
            }

            $scope.submitButtonText = $filter('translate')('confirmPhone.submittingButtonText');

            PatientUsersService.confirmPhoneGetCode($scope.fields.phoneNumber, currentPassword)
            .then(function(){
                $scope.step = 'code';
                $scope.submitButtonText = $filter('translate')('confirmPhone.verifyButtonText');
            })
            .catch(function() {
                $scope.submitButtonText = $filter('translate')('confirmPhone.submitButtonText');
                $scope.codeNotSent = true;
                $timeout(function(){
                    $scope.codeNotSent = false;
                }, 5000);
            })
            .finally(function(){
                $scope.submitting = false;
                $scope.showMoreInfo = false;
            });
        };

    $scope.$on('$destroy', function() {
        _destroyShowPrompt();
    });

    $scope.forms = {};
    $scope.fields = {};

    $scope.close = function(){
        _hidePrompt();
    };

    $scope.toggleMoreInfo = function(){
        $scope.showMoreInfo = !$scope.showMoreInfo;
    };

    $scope.asyncValidationComplete = function(){
        if($scope.resumbitPostAsyncValidation){
            $scope.resumbitPostAsyncValidation = false;
            $scope.confirmPhoneNumber(/*continuingSubmission*/ true);
        }
    };

    $scope.confirmPhoneNumber = function(continuingSubmission){
        _resetNotifications();

        //angular is supposed to be setting this property (but is not for some reason).  this accomplishes the same thing.
        $scope.forms.confirmPhoneForm.$submitted = true;

        if ($scope.forms.confirmPhoneForm.$invalid || ($scope.submitting && !continuingSubmission)) {
            $scope.submitting = false;
            return;
        }

        if ($scope.fields.phoneNumber != PatientUsersService.getCurrentUser().phoneNumber) {
            // hide this prompt temporarily while we prompt the user for their password
            _hidePrompt();
            $rootScope.$emit('confirmPassword:prompt', {
                'callback': _confirmPhoneNumber
            });
        } else {
            _confirmPhoneNumber();
        }
    };

    $scope.verifyCode = function(){
        _resetNotifications();

        //angular is supposed to be setting this property (but is not for some reason).  this accomplishes the same thing.
        $scope.forms.verifyCodeForm.$submitted = true;

        if ($scope.forms.verifyCodeForm.$invalid || $scope.submitting) {
            return;
        }

        $scope.submitting = true;
        $scope.submitButtonText = $filter('translate')('confirmPhone.verifyingButtonText');

        PatientUsersService.confirmPhoneVerifyCode($scope.fields.code)
            .then(function(){
                $rootScope.$emit('confirmPhone:confirmed');    //tell all of our fans

                _hidePrompt();

                $scope.$emit('simpleModal:showPrompt', {
                    header: $filter('translate')('confirmPhone.successHeader'),
                    intent: 'success',
                    autoExpire: true
                });
            })
            .catch(function() {
                $scope.codeInvalid = true;
                $timeout(function(){
                    $scope.codeInvalid = false;
                }, 5000);
                $scope.fields.code = '';
            })
            .finally(function(){
                $scope.step = 'number';
                $scope.submitting = false;
                $scope.submitButtonText = $filter('translate')('confirmPhone.submitButtonText');
            });
    };

    $scope.sendAgain = function(){
        $scope.step = 'number';
        $scope.submitButtonText = $filter('translate')('confirmPhone.submitButtonText');
    };
});
