/* Simple wrapper to give us compass as an injectable dependency */
angular.module('PatientApp').value('Compass', window.Compass || {
    
    // top level api members that might not be available during
    // runtimes like karma tests

    capture: {
        timing: angular.noop,
        event: angular.noop,
        misc: angular.noop,
        metricValue: angular.noop,
        success: angular.noop,
        failure: angular.noop,
        DOMEvent: angular.noop,
        JSError: angular.noop
    }
});