angular.module('PatientApp')
    .directive('progressBar', [function() {
        return {
            restrict: 'E',
            scope: {
                'progress': '='
            },
            replace: true,

            link: function(scope, el, attrs){
                
                var update = function(){
                    // el.children('.progress-state').css({'width': scope.progress + '%'});
                    //alert(el.children('.progress-state').length);
                    var percentage = ((scope.progress || 0) - 100);
                    el.children('.progress-state').css({
                        'transform': 'translate3d(' + percentage + '%, 0, 0)',
                        '-webkit-transform': 'translate3d(' + percentage + '%, 0, 0)'
                    });

                    if(scope.progress === 100 && !!attrs.blowAway === true){ // jshint ignore:line
                        scope.blowItAway = true;
                    }else {
                        scope.blowItAway = false;
                    }
                };

                scope.$watch('progress', function (progChange) {
                    scope.progress = progChange;
                    update();
                });

            },

            template: '<div class="progress-bar" ng-class="{\'progress-blow-away\': blowItAway}"><div class="progress-state">&nbsp;</div></div>'
        };
    }]);