angular.module('PatientApp').controller('PaymentFormFlowCtrl', function($scope, $rootScope, $state, $filter, $window, $log, _, PaymentFormsService, PatientUsersService, CardService, ErrorsService, ServerStatusService, Compass){

    var paymentForm,
        _hidePrompt = function() {
            $scope.promptVisible = false;
            _reset();
        },
        _reset = function() {
            // holds the form references for the payment flow templates
            $scope.processingNotifications = {};

            $scope.method.reset();
            $scope.billing.reset();

            paymentForm = $scope.paymentForm = PaymentFormsService.getInstance();
        },
        _navigationAllowed = true;

    $scope.forms = {};
    $scope.processingNotifications = {};

    $rootScope.$on('paymentForm:prompt', function() {
        $scope.promptVisible = true;
        $scope.currentStep = 'method';
        _reset();

        Compass.capture.event('paymentForm', 'modal', 'opened');
    });

    $scope.close = function(){
        _hidePrompt();
    };


    paymentForm = $scope.paymentForm = PaymentFormsService.getInstance();

    // the following is quasi-hack to get around the fact that our forms are submitting even
    // while async validators are still running.  we care about this because if this form is submitted
    // prior to the validator finishing, the routing number doesn't get bound to the model and we end-up 
    // submitting an eCheck without a routing number.  the work-around here is to short-circuit the
    // controller logic which binds + moves to the next step, record the user's intention to move to the next
    // step and replay their intention once we hear back from the validator.
    $scope.resubmitPostRoutingNumberCheck = false;
    $scope.routingNumberValidated = function() {
        if ($scope.resubmitPostRoutingNumberCheck) {
            $scope.resubmitPostRoutingNumberCheck = false;
            $scope.method.submit();
        }
    };

    $scope.canMoveStep = function(newStep) {
        if (!_navigationAllowed) {
            return false;
        }
        if ($scope.currentStep == newStep) {
            return true;
        }

        var newStepIsAccessible = false;
        if (newStep == 'method') {
            newStepIsAccessible = true; //can always come back here
        }
        else if (newStep == 'billing') {
            newStepIsAccessible = paymentForm.validity.method();
        }
        else if (newStep == 'confirm') {
            newStepIsAccessible = paymentForm.validity.method() && paymentForm.validity.billing();
        }

        return newStepIsAccessible;
    };

    $scope.moveStep = function(newStep) {
        if (!$scope.canMoveStep(newStep)) {
            return; //up to us to manually enforce disabled behavior in links
        }

        $scope.currentStep = newStep;

        if (newStep == 'confirm') {
            //make sure using latest from previous wizard steps
            $scope.method.submit();
            $scope.billing.submit();
        }
    };

    $scope.method = {
        notifications: {
            cardsHidden: false,
            eChecksHidden : false,
            cardsExpired: false
        },

        // Apple Card Scan variables and prcessors
        scannedCardYear: '',
        scannedCardMonth:'',
        scannedCardName: '',
        processScannedCard : function(){
            if ($scope.method.scannedCardMonth && $scope.method.scannedCardYear) {
                // format the scanned expiration which comes in as MM and YYYY
                $scope.method.card.expiration = [$scope.method.scannedCardMonth, $scope.method.scannedCardYear.slice(-2)].join('');
                // trigger the custom format event so angular will reformat the injected card number
                angular.element(document.getElementById('cc_number')).triggerHandler('format');
            }
            if ($scope.method.scannedCardName) {
                //apply scan sends us a single name field.  we have to do our best to split it into first and last name parts.  to handle situations where
                //we're sent three names, we're going to assume that the first and second words are considered the first name.
                var nameParts = $scope.method.scannedCardName.split(' ');
                $scope.method.card.firstName = _.dropRight(nameParts, /*elements to drop*/ 1).join(' ');
                $scope.method.card.lastName = _.last(nameParts);
            }
        },

        applyBank: function(bank) {
            $scope.method.eCheck.bankName = bank;
        },

        currentForm: paymentForm.isFormAccepted('eCheck') ? 'eCheck' : 'card',

        hideNotifications: function() {
            $scope.method.notifications.cardsHidden = false;
            $scope.method.notifications.eChecksHidden = false;
        },

        newMethodSubmitted: false,

        card: {
            firstName: '',
            lastName: ''
        },

        eCheck: {
            firstName: '',
            lastName: ''
        },

        cardIcon: function() {
            var issuer = paymentForm.getMethod.issuer($scope.method.card.number);
            return CardService.getIcon(issuer);
        },

        toggleCard: function() {
            $scope.method.currentForm = 'card';
        },
        toggleECheck: function() {
            $scope.method.currentForm = 'eCheck';
        },
        toggleHsa: function() {
            $scope.method.currentForm = 'hsa';
        },
        submit : function() {
            if ($scope.method.currentForm === 'card' || $scope.method.currentForm === 'hsa') {
                if ($scope.forms.ccForm.$invalid) {
                    return false;
                }

                paymentForm.setMethod.card($scope.method.card);
            } 
            else {
                if ($scope.forms.echeckForm.$invalid){
                    return;
                }

                if ($scope.forms.echeckForm.routing.$pending) {
                    $scope.resubmitPostRoutingNumberCheck = true;
                    return false;
                }

                paymentForm.setMethod.eCheck($scope.method.eCheck);
            }

            $scope.method.newMethodSubmitted = true;
            return true;
        },
        submitAndMove: function() {
            if ($scope.method.submit()) {
                $scope.moveStep('billing');
            }
        },

        reset: function() {
            $scope.method.currentForm = paymentForm.isFormAccepted('eCheck') ? 'eCheck' : 'card';
            $scope.method.hideNotifications();

            if ($scope.forms.echeckForm) {
                $scope.forms.echeckForm.$setPristine();
                $scope.forms.echeckForm.$setUntouched();
            }
            if ($scope.forms.ccForm) {
                $scope.forms.ccForm.$setPristine();
                $scope.forms.ccForm.$setUntouched();
            }

            var user = PatientUsersService.getCurrentUser();
            $scope.method.card = {};
            $scope.method.eCheck = {};

            if (user) {
                $scope.method.card.firstName = user.firstName;
                $scope.method.card.lastName = user.lastName;
                $scope.method.eCheck.firstName = user.firstName;
                $scope.method.eCheck.lastName = user.lastName;
            }
        },

        clear: function() {
            $scope.method.currentForm = paymentForm.isFormAccepted('eCheck') ? 'eCheck' : 'card';
            $scope.method.newMethodSubmitted = false;
        }
    };

    $scope.billing = {
        fields : {},

        submit: function() {
            if ($scope.forms.billing.$invalid){
                return false;
            }

            paymentForm.setBilling.useForm($scope.billing.fields);
            return true;
        },
        submitAndMove: function() {
            if ($scope.billing.submit()) {
                $scope.moveStep('confirm');
            }
        },

        reset: function() {
            if ($scope.forms.billing) {
                $scope.forms.billing.$setPristine();
                $scope.forms.billing.$setUntouched();
            }

            $scope.billing.fields = {};
        }
    };

    $scope.confirm = {
        confirmDialog: $filter('translate')('actions.processPaymentForm'),
        submitting: false,
        gatewayRetryRequested: false,
        reset: function() {
            if ($scope.confirm.gatewayRetryRequested) {
                $scope.confirm.confirmDialog = $filter('translate')('actions.retryProcessPaymentForm');
            } else {
                $scope.confirm.confirmDialog = $filter('translate')('actions.processPaymentForm');
            }
            $scope.confirm.submitting = false;

            _navigationAllowed = true;
        },
        submitPaymentForm: function(){

            // don't allow people to double submit payment button
            if($scope.confirm.submitting){
                return;
            }

            $scope.processingNotifications = {};

            $scope.confirm.confirmDialog = $filter('translate')('labels.submittingPaymentForm');
            $scope.confirm.submitting = true;
            _navigationAllowed = false;

            paymentForm.process(/*resuming*/ $scope.confirm.gatewayRetryRequested).then(function(resp){
                $scope.confirm.gatewayRetryRequested = false;
                Compass.capture.success('paymentForm', 'added');
                Compass.capture.event('paymentForm', 'modal', 'complete');

                //hide modal but then tell consumers so that they can do whatever is appropriate for their context
                _hidePrompt();
                $scope.$emit('paymentForm:added', {
                    paymentFormId: resp ? resp.paymentFormId : null
                });
            })
            .catch(function(resp){

                Compass.capture.failure('paymentForm', 'added');
                Compass.capture.event('paymentForm', 'failureReason', ErrorsService.resolve(resp) || 'NO_REASON_AVAILABLE');

                switch(ErrorsService.resolve(resp)){
                    case ErrorsService.errors.METHOD_UNAVAILABLE:
                        if (paymentForm.isFormAccepted('card') || paymentForm.isFormAccepted('eCheck')) {
                            //other methods available.  filter the methods that are shown and encourage them to go back to methods screen
                            $scope.processingNotifications.cardsUnavailable =  !paymentForm.isFormAccepted('card');
                            $scope.processingNotifications.eChecksUnavailable = !paymentForm.isFormAccepted('eCheck');
                            $scope.method.clear();
                        } else {
                            //no payment form is available.  user is allowed to go back and change their method, but it will most likely not work and they
                            //will keep getting error message until they give up.
                            $scope.processingNotifications.processingError = true;
                            $scope.method.clear();
                        }
                        break;

                    case ErrorsService.errors.PAYMENT_INVALID_CARD_NUMBER:
                    case ErrorsService.errors.PAYMENT_CARD_EXPIRED:
                        $scope.processingNotifications.invalidCardInformation = true;
                        break;

                    case ErrorsService.errors.UNKNOWN_ERROR:
                    case ErrorsService.errors.INVALID_PARAMETERS:
                    case ErrorsService.errors.PAYMENT_PROCESSING_ERROR:
                    case ErrorsService.errors.NETWORK_ERROR:
                        $scope.processingNotifications.processingError = true;
                        $scope.confirm.gatewayRetryRequested = false;
                        break;
                    default:
                        $scope.processingNotifications.processingError = true;
                        break;
                }
            })
            .finally(function(){
                $scope.confirm.reset();
            });
        }
    };

    _reset();
});
