angular.module('PatientApp').factory('PaymentsService', function( $q, $resource, $filter, $log, _, endpoints, BillsService, CardService, PaymentGatewayService, ServerStatusService ){

    var Payments = $resource(endpoints.payments.primary.url, null, {
            fetchPaymentDetails: {
                method: 'GET',
                params: {
                    paymentId: '@paymentId'
                }
            },

            cancelPayment: {
                method: 'DELETE',
                params: {
                    paymentId: '@paymentId'
                }
            }
        }),

        Payment = function Payment(){

            var _payment = {
                    secureCode: null,
                    amount: null,
                    amountOptionType: null,
                    installmentCount: null,
                    date: null,
                    future: false,
                    method: {},
                    billing: {}
                },

                _this = this,

                _dirtyFields = [],

                _completed = false,

                _bill,

                _limits = {
                    minAmount: 0.01,
                    methods: ['card', 'eCheck'],
                    maxScheduleDays: 42
                },

                _getLastFourDigitsOfCurrentMethod = function(){

                    var num = '';

                    if(_payment.method.card){
                        num = _payment.method.card.number.toString();
                    }else {
                        num = _payment.method.eCheck.accountNumber.toString();
                    }

                    // get the last four only
                    return num.slice( -4 );
                },

                // used mainly to populate the UI states in a
                // consistent way. This is not a part of the
                // payment process
                _buildMethodForm = function(){
                    var method = {};

                    if(_payment.method.savedMethod){
                        method = _payment.method.savedMethod;
                    }else {
                        method = {
                            formType: _payment.method.card ? 'card' : 'echeck',
                            cardType: _payment.method.card ? CardService.getIssuer(_payment.method.card.number) : '',
                            expDate: _payment.method.card ? _payment.method.card.expiration : '',
                            last4Digits: _getLastFourDigitsOfCurrentMethod(),
                            bankName: _payment.method.card ? '' : (_payment.method.eCheck.bankName || '')
                        };
                    }

                    return method;
                },

                _getFormattedDate = function(date){
                    var dateObj = new Date(date),
                        month = dateObj.getMonth() + 1, //months from 1-12
                        day = dateObj.getDate(),
                        year = dateObj.getFullYear();

                    return year + '/' + month + '/' + day;
                };


            // testing hook to give us a way to
            // see into the state of the payment as it will
            // be sent in processing
            this.__payment__ = function(){
                return JSON.parse(JSON.stringify(_payment));
            };



            // for a give bill, take the data attached
            // to it to apply the limits set from the provider
            this.applyBillVariant = function(bill){

                if(!bill){
                    return this;
                }

                // make sure we have a full bill object
                _bill = BillsService.createBill(bill);


                var limits = _bill.getProviderDetails().limits;

                // apply limits to this payment
                if(limits){
                    if(limits.methods){
                        _limits.methods = limits.methods;
                    }

                    // if(limits.paymentMinAmount){
                    //      _limits.minAmount = parseFloat(limits.paymentMinAmount);
                    // }

                    if(!_.isNaN(_.parseInt(limits.paymentMaxScheduleDays))){
                        _limits.maxScheduleDays = _.parseInt(limits.paymentMaxScheduleDays);
                    }
                }

                // bill for which this payment is for
                _payment.secureCode = _bill.secureCode;

                return this;
            };






            //*********************
            // Amount Calculation
            //*********************
            this.setAmount = {

                clear: function(){
                    _payment.amount = null;
                    _payment.amountOptionType = null;
                    _payment.installmentCount = null;
                },

                // for the given bill pay full
                // either the total, discounts, or dbu discounts
                billOptionAmount: function( index ){
                    var option = _bill.getPaymentOption(index);

                    // is this a different amount than we
                    // previously had
                    if(_payment.amount !== option.amount){
                        _dirtyFields.push('amount');
                    }

                    _payment.amount = option.amount;
                    _payment.amountOptionType = option.type;

                    //plans (variable # of installments) is only available when paying the full amt via custom-amt.  This is to work-around the occasional scenario where
                    //the only amount options available are discounted
                    _payment.installmentCount = null;

                    // note amounts with discounts should check the
                    // timebox for that discount and update the date
                    // selection appropriately if it's previous selection
                    // conflicts with the discount applicability date
                    if(_payment.date && option.maxDate){

                        if(new Date(option.maxDate) < new Date(_payment.date)){
                            // clear the date which will be reflected
                            // in the date validity check
                            _payment.date = null;
                        }
                    }

                    return this;
                },

                // anything not full
                custom: function( amount, installmentCount ){
                    if (installmentCount === undefined) {
                        installmentCount = null;
                    }

                    var acctBalance = 0;
                    if (_bill.accountBalance && _bill.accountBalance.amount) {
                        acctBalance = _bill.accountBalance.amount;
                    }

                    var max = 0;

                    // should have a simple check on
                    // min amount and max amount that can be applied
                    if(amount >= _limits.minAmount){

                        // is this a different amount than we
                        // previously had
                        if(_payment.amount !== amount){
                            _dirtyFields.push('amount');
                        }

                        //max sure we do not apply an amount higher than any
                        // available option
                        max = Math.max(acctBalance, _.reduce(_bill.getPaymentOptions(), function( prevVal, option ){
                            return Math.max(prevVal || 0, option.amount);
                        }, /*accumulator (initial val)*/ 0 ));

                        _payment.amount = amount > max ? null : amount;
                        _payment.amountOptionType = amount > max ? null : 'O';
                        _payment.installmentCount = installmentCount;
                    }else {
                        _payment.amount = null;
                        _payment.amountOptionType = null;
                        _payment.installmentCount = null;
                    }

                    // unlike billOptionAmounts if a date was selected
                    // prior to this being set, it will always have been a valid
                    // date so we don't need to validate the date selections for
                    // conflicts

                    return this;
                }
            };

            this.getAmount = function(){
                return _payment.amount;
            };

            this.getAmountOptionType = function(){
                return _payment.amountOptionType;
            };

            this.getInstallmentCount = function() {
                return _payment.installmentCount;
            };

            this.getAmountSummary = function(){
                if (_payment.installmentCount) {
                    return $filter('translate')('payment.confirm.planAmount', { installmentAmount: $filter('currency')(this.getInstallmentAmount()), installmentCount: _payment.installmentCount });
                }
                else {
                    return $filter('currency')(_payment.amount);
                }
            };


            this.getInstallmentAmount = function() {
                return calculateInstallmentAmount(_payment.amount, _payment.installmentCount); //round-up
            };


            //*********************
            // Date Calculation
            //*********************
            this.setDate = {
                clear: function(){
                    _payment.date = null;
                    _payment.future = false;
                },
                today: function(){

                    var today = _getFormattedDate(new Date());

                    // is this a different day than we
                    // previously had
                    if(_payment.date !== today){
                        _dirtyFields.push('date');
                    }

                    _payment.date = today;
                    _payment.future = false;
                },

                later: function(date){
                    var laterDate = _getFormattedDate(new Date(date));

                    // is this a different day than we
                    // previously had
                    if(_payment.date !== laterDate){
                        _dirtyFields.push('date');
                    }

                    _payment.date = laterDate;
                    _payment.future = true;
                }
            };

            this.getDate = function(){
                return _payment.date;
            };

            this.getDateSummary = function(){
                return $filter('dateFormat')(_payment.date, 'longDate');
            };

            this.scheduledFuture = function() {
                return _payment.future;
            };

            this.isExpiring = function(expiration) {
                var scheduledDate,
                    month,
                    year,
                    expiry,
                    expDate;


                scheduledDate = new Date(this.getDate());
                if (expiration) {
                    expDate = expiration;
                } else if (_payment.method.savedMethod) {
                    expDate = _payment.method.savedMethod.expDate;
                } else if (_payment.method.card) {
                    expDate = _payment.method.card.expiration;
                }

                if (!expDate) {
                    return false;
                }

                month = _.parseInt(expDate.slice(0, 2));
                year = _.parseInt(expDate.slice(-2));

                if (year < 70) {
                    year = '20' + (year > 9 ? year : '0' + year);
                } else {
                    year = '19' + (year > 9 ? year : '0' + year);
                }

                expiry = new Date(year, month);
                // Months in JS are zero indexed, so we're automatically one month ahead
                expiry.setDate(0); // Last day of preceeding month
                expiry.setHours(23);
                expiry.setMinutes(59);

                return expiry < scheduledDate;
            };


            //*********************
            // Method Fields
            //*********************
            this.setMethod = {
                clear: function(){
                    delete _payment.method.savedMethod;
                    delete _payment.method.card;
                    delete _payment.method.eCheck;
                },
                savedMethod: function(method){

                    // is this a different method than we
                    // previously had
                    if(!_.isEqual(_payment.method.savedMethod, method)){
                        _dirtyFields.push('method');
                    }

                    this.clear();
                    _payment.method.savedMethod = method;
                },
                card : function(form){

                    // is this a different method than we
                    // previously had
                    if(!_.isEqual(_payment.method.card, form)){
                        _dirtyFields.push('method');
                    }

                    this.clear();
                    _payment.method.card = _.cloneDeep(form);
                },
                eCheck : function(form){

                    // is this a different method than we
                    // previously had
                    if(!_.isEqual(_payment.method.eCheck, form)){
                        _dirtyFields.push('method');
                    }

                    this.clear();
                    _payment.method.eCheck = _.cloneDeep(form);
                }
            };

            this.getMethod = {
                shortDesc : function(){
                    if(!_this.validity.method()){
                        return '';
                    }
                    // VISA ****9576
                    // eCheck ****02939
                    return $filter('methodDesc')(_buildMethodForm(), 'short', /* non breaking spacer */'&nbsp;');
                },

                desc : function(){
                    if(!_this.validity.method()){
                        return '';
                    }
                    // VISA ****9576
                    // eCheck Bank of America ****02939
                    return $filter('methodDesc')(_buildMethodForm());
                },

                issuer: function(cardNumber){

                    var number = cardNumber;

                    if(!number && _payment.method.card){
                        number = _payment.method.card.number;
                    }

                    return CardService.getIssuer(number);
                },

                isForm: function(formType) {
                    switch(_.trim(formType).toLowerCase()){
                        case 'card':
                            return !!_payment.method.card || (_payment.method.savedMethod && _.trim(_payment.method.savedMethod.formType).toLowerCase() === 'card');
                        case 'echeck':
                            return !!_payment.method.eCheck || (_payment.method.savedMethod && _.trim(_payment.method.savedMethod.formType).toLowerCase() === 'echeck');
                        case 'financing':
                            return !!_payment.method.financing || (_payment.method.savedMethod && _.trim(_payment.method.savedMethod.formType).toLowerCase() === 'financing');
                        default:
                            return false;
                    }
                },

                id: function(){
                    return _payment.method.savedMethod.id;
                }
            };


            //*********************
            // Billing Fields
            //*********************
            this.setBilling = {
                useForm : function(form){

                    // did we make a value change to the billing
                    // address -- case change is not a "value change"
                    if(!_.isEqual(_payment.billing, form)){
                        _dirtyFields.push('billing');
                    }

                    _payment.billing = _.cloneDeep(form);
                },
                clear : function(){
                    _payment.billing = {};
                }
            };
            this.getBilling = function(){
                return _payment.billing;
            };


            //*********************
            // Validity checks for payment areas
            //*********************
            this.validity = {
                amount: function(){
                    return !!_payment.amount;
                },
                date : function(){
                    return !!_payment.date;
                },
                method : function(){
                    // todo, impl card and echeck validity
                    // add token considerations
                    return !!_payment.method && !!(_payment.method.card || _payment.method.eCheck || _payment.method.savedMethod);
                },
                billing: function(){
                    return !!_payment.billing && (!!_payment.method.savedMethod || !_.isEmpty(_payment.billing.address));
                }
            };



            //*********************
            // Processing Steps
            //*********************
            this.processPayment = function(resuming){


                // we reset dirty to false when
                // we make a payment so we can track if
                // any changes were made, after a process attempt
                _dirtyFields = [];

                var processPath = function(){
                    return resuming ? PaymentGatewayService.resume() : PaymentGatewayService.process( _payment );
                };

                return processPath().then(function(result){
                    _completed = true;
                    return result;
                });
            };

            // manually set complete status in other flows
            this.setCompleted = function(isComplete){
                _completed = isComplete;
            };

            // simple check utility to know that we have successfully pushed
            // through this payment
            this.completed = function(){
                return _completed;
            };

            // check to see if the fields for a payment have
            // changed since the last attempt to process.
            // If we have not processed yet, everything starts off as
            // not dirty but when we fill in the fields, they become dirty
            //
            // if you pass specificField, we will check to see if that field
            // itself is dirty, otherwaise we check to see if ANY field is dirty
            this.preProcessDirty = function(specificField){

                if(_.isString(specificField)){
                    return _.includes(_dirtyFields, specificField);
                }

                return _dirtyFields.length > 0;
            };

            //*********************
            // Utility functions
            //*********************

            this.getCurrentPaymentOptionMaxDate = function(paymentOptionIndex){

                var options = _bill.getPaymentOptions(),
                    scheduledMax = new Date(),
                    dueDate = new Date(_bill.dueDate);

                // payment options already have precalculated
                // max dates -- if they don't calculated it
                if(_.isNumber(paymentOptionIndex) && options[paymentOptionIndex].maxDate !== null && options[paymentOptionIndex].maxDate !== undefined){
                    return new Date(options[paymentOptionIndex].maxDate);
                }

                // today plus max schedule days is the max schedule days date
                scheduledMax.setDate( scheduledMax.getDate() + _.parseInt(_limits.maxScheduleDays) );


                return dueDate > scheduledMax ? dueDate : scheduledMax;
            };

            this.providerAcceptsForm = function( formString ){
                formString = _.isString(formString) ? _.trim(formString.toLowerCase()) : '';

                return _.findIndex(_limits.methods, function(method){
                    return _.trim(method.toLowerCase()) === formString;
                }) > -1;
            };

            this.isFormAccepted = function( formString ){
                formString = _.isString(formString) ? _.trim(formString.toLowerCase()) : '';

                return ServerStatusService.getPaymentStatus(formString) && this.providerAcceptsForm(formString);
            };
        };

    var calculateInstallmentAmount = function(amount, installmentCount){
        return Math.ceil(amount * 100 / installmentCount) / 100;
    };




    return {
        createPayment: function(){
            return new Payment();
        },

        isPayment: function( payment ){
            return payment instanceof Payment;
        },

        fetchPaymentDetails: function(paymentId){
            return Payments.fetchPaymentDetails({paymentId: paymentId}).$promise.then(function(resp){
                if(resp && resp.hasData()){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },

        cancelPayment: function(paymentId){
            return Payments.cancelPayment({paymentId: paymentId}).$promise.then(function(resp){
                if(resp && resp.hasData()){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },
        calculateInstallmentAmt: function(amount, installmentCount) {
            return calculateInstallmentAmount(amount, installmentCount);
        },

        // static field to compare against the available states for a given payment
        paymentStates: {
            UNCONFIRMED: 'UNCONFIRMED',
            ABANDONED: 'ABANDONED',
            CANCELLED: 'CANCELLED',
            SCHEDULED: 'SCHEDULED',
            HELD: 'HELD',
            DECLINED: 'DECLINED',
            REPROCESSED: 'REPROCESSED',
            PROCESSED: 'PROCESSED',
            VOIDED: 'VOIDED',
            UNDISBURSED: 'UNDISBURSED',
            DISBURSED: 'DISBURSED',
            UNKNOWN: 'UNKNOWN'
        },

        reversalReasons: {
            REFUND: 'REFUND',
            CHARGEBACK: 'CHARGEBACK',
            FAILED_ECHECK: 'FAILED_ECHECK',
            BOUNCED_CHECK: 'BOUNCED_CHECK'
        }

    };
});
