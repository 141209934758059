angular.module('PatientApp').factory('FlowService', function($log, $rootScope, $state, $timeout, $window, _){
    
    var Flow = function(){

        var _routeBind = null,
            _leavingBoundCheck = null,
            _navigationAllowed = true,
            _steps = [],
            _currentStepIndex = 0,
            _farthestIndex = 0,
            _this = this,
            undoReverseState = function(){
                _this.reverseTransition = false;
            },

            // this flag is used to track when we are
            // navigating through the continue or goTo 
            // function calls. This really indicates when
            // something triggered navigation via an 
            // external source like browser chrome navigation
            _internalNavigation = false,

            _navigate = function(route){

                $log.log('navigate::reversed:', _this.reverseTransition);
                _internalNavigation = true;

                $state.go(route).then(function() {
                    // after successful state change, reset reverseTransition
                    // using timeout to allow for transitions to flush
                    // todo impl with ngAnimate checks
                    if(_this.reverseTransition){
                        $timeout(undoReverseState, 1200);
                    }
                });

                _updateProgress();
            },

            // jumper is here as a safe guard for 
            // confusion between the state router and
            // the user interacting with a flow
            _jumpCounter = 0,

            _progress = 0,

            _updateProgress = function(){
                if( _steps.length === 0 ){
                    _progress = 0;
                } else {
                    // check for progress overrides and use those, else calculate based on steps
                    if (_this && _this.currentStep && _this.currentStep.stepNum && _this.currentStep.numSteps) {
                        _progress = Math.floor(100 * _this.currentStep.stepNum / _this.currentStep.numSteps);
                    } else {
                        _progress = Math.floor(100 * (_currentStepIndex + 1) / _steps.length);
                    }
                }
            },

            // check the state changes and make sure we 
            // always keep the state within the route bound
            // this can happen if we are in a flow then navigate out
            // of it. We want to clean up our listeners in that case
            _removeSuccessListener = $rootScope.$on('$stateChangeSuccess', function(){

                if(_routeBind && !$state.includes(_routeBind)){
                    $log.log('Leaving flow via routing means, get the user perform Flow cleanup');
                    // invoking these methods removes the listeners
                    // we only want to bind to these listeners while
                    // we are in a flow
                    _removeStartListener();
                    _removeSuccessListener();
                }
            }),

            // account for external changes to the flow state via pieces 
            // that interact with the state router
            _removeStartListener = $rootScope.$on('$stateChangeStart', function stateChangeFlowListener(event, toState, toParams, fromState){
                // we only care about navigation that was
                // not triggered by us
                if(_internalNavigation || ++_jumpCounter > 100){
                    _internalNavigation = false;
                    return;
                }

                if(_jumpCounter > 99){
                    throw new Error('We encountered the state and interaction confusion issue.');
                }

                var oldStateStepIndex = _.findIndex(_steps, function(step){
                        return step.routeState === fromState.name;
                    }),

                    newStateStepIndex = _.findIndex(_steps, function(step){
                        return step.routeState === toState.name;
                    });

                $log.log(oldStateStepIndex, newStateStepIndex, _currentStepIndex, _farthestIndex);
                
                if(newStateStepIndex === -1){

                    // if we are not allowed to leave
                    // according to the flow owner, we will cancel leaving the flow
                    if(_leavingBoundCheck && _leavingBoundCheck()){
                        
                        // originally we used only event.preventDefault to stop the user from 
                        // navigating forward. But since the browser back is being used, the browser
                        // history stack is already changed so to actually prevent movement in history
                        // we would need to go forward in history.
                        // A side effect of using preventDefault is mentioned above but it overwrites the
                        // previous entry in the history. This is a compromise that we accept for now
                        // as Firefox currently does not support the core feature of going to the parent
                        // branch without the use of preventDefault
                        event.preventDefault();
                        $window.history.forward();
                        return;
                    }

                    $log.log('step is invalid -- could not find index for the route state in flow');
                    $log.log('If this is not in the routeBind path, we will handle cleanup in the success handler');
                    
                    return;
                }


                // we have an interesting state where the user can make it to a step >= 3
                // and navigate back via browser back btn which will take them to a previous step
                // then they could take an action that invalidates this step. then navigate forward 
                // to the third step via the browser forward button. Now they are on a step with
                // an invalid state on the steps behind

                // if we have an invalid state that is not the farthest. Don't do a route
                // transition. We should stay where we are until we fix it (this should
                // only be a factor with browser navigation)
                if(oldStateStepIndex !== _farthestIndex && !_steps[oldStateStepIndex].canExit()){
                    
                    event.preventDefault();
                    
                    $log.log('Seems we have an invalid state at index', oldStateStepIndex);
                    return;
                }


                if(newStateStepIndex > _farthestIndex && !_steps[newStateStepIndex].visited){
                    // do not allow user to jump to a future step
                    // via the browser ui
                    // NOTE: an assumption is made that the _farthestIndex
                    //  is set in the continue call before this is triggered
                    // Also check that the step is not visited because there may be a subFlow with a later index

                    _this.goTo(_farthestIndex);
                } else if( newStateStepIndex !== _currentStepIndex ){
                    event.preventDefault();
                                   
                    if (_steps[oldStateStepIndex].onBack && newStateStepIndex < oldStateStepIndex) {
                        // override for if user is going back in browser 
                        _steps[oldStateStepIndex].onBack();
                    } else {
                        _this.goTo(newStateStepIndex);
                    }

                } else if(newStateStepIndex === _farthestIndex && _currentStepIndex !== _farthestIndex){
                    // we need to go forward to the furthest step
                    _this.goTo(_farthestIndex);
                }

                _updateProgress();
            });


        $log.log('Creating a new flow');

        // quick reference to the step properties
        this.currentStep = null;

        // flag to indicate if we are going backwards
        // and thus should toggle a reversed style transition
        this.reverseTransition = false;


        // convenience method to get the flows current progress 
        // through all of the steps
        this.getProgress = function(){
            return _progress;
        };

        // we need a way for the flow user to tell us
        // that we are starting and no more steps are expected
        // to be added
        this.init = function(){
            _updateProgress();

            return this;
        };


        // binding to a route allows us to only have this flow
        // live in memroy while we are inside of that route.
        // the leavingFlowCb allows us to check with the 
        // flow creator to see if we are allowed to leave the bound right
        this.bindToRoute = function( route, leavingFlowCheck ){
            
            _routeBind = route;

            _leavingBoundCheck = leavingFlowCheck || null;

            return this;
        };


        // Add a step to our flow
        // this is chainable so we can have a better visible
        // breakdown of the flow's steps
        // 
        // EXAMPLE:
        // newStep = {
        //  canExit: Function,  // required - called when attempting to move away from step
        //  name: String,       // required - used to map states if we dont have an index 
        //  onEnter: Function   // optional - called when attempting to open a step
        // }
        this.step = function(newStep){

            // an empty step, does nothing
            if(!newStep){
                return this;
            }

            //make sure we have the needed pieces
            if(!newStep.canExit){
                $log.log('Step needs a canExit function');
                return this;
            }

            if(!newStep.name){
                $log.log('Step needs a name');
                return this;
            }

            // mark that we were apply to apply this
            // step to the flow
            newStep.registered = true;

            // set the first item's current state
            if(_steps.length === 0){
                newStep.current = true;
                this.currentStep = newStep;
                _updateProgress();
            }

            newStep.indexVal = _steps.length;

            _steps.push(newStep);

            return this;
        };

        // go to the next step in the flow
        // or continue to the farthest reached section
        this.continue = function(){

            $log.log('continue');

            var nextStep;

            if(_currentStepIndex >= _steps.length - 1){
                // can't continue if we are on the last step
                return;
            }

            // Check if we're on the "last" step based on numSteps and stepNum fields
            if (_this && _this.currentStep && _this.currentStep.stepNum && _this.currentStep.numSteps && _this.currentStep.stepNum === _this.currentStep.numSteps) {
                return;
            }

            if(!this.currentStep.canExit() || !_navigationAllowed){
                $log.log('canExit condition not met');
                // make sure the step is ok with continuing
                return;
            }

            // we are good to continue

            // previous step update
            this.currentStep.current = false;
            this.currentStep.visited = true; // visited means we have navigated away

            _currentStepIndex++;

            // continue means if we have jumped back a 
            // step by whatever means we need to continue
            // to the farthest step in the process that we
            // have made it to, unless we're in a subflow
            if(_currentStepIndex > _farthestIndex && !this.currentStep.isSubFlow){
                // we are in the normal control process
                // but mark that we have a new "farthest"
                _farthestIndex = _currentStepIndex;
            }else if(_currentStepIndex < _farthestIndex) {
                // we are continuing from a step that is
                // not from the farthest reached step
                // so we will jump them back to the farthest step
                // that needs action

                // we need to add checks from where we are to the 
                // farthest index to make sure we can still canExit
                // from them. In case stepping back invalidated
                // that check.
                
                while(_currentStepIndex <= _farthestIndex){
                    if(!_steps[_currentStepIndex].canExit() || _currentStepIndex === _farthestIndex){
                        break;
                    }else {
                        _currentStepIndex++;
                    }
                }
            }

            nextStep = _steps[_currentStepIndex];
            nextStep.current = true;
            nextStep.visited = true;
            this.currentStep = nextStep;

            // actual transition processes
            // navigate route
            if(nextStep.routeState){
                _navigate(nextStep.routeState);
            }

            // invoke the steps enter process
            if(nextStep.onEnter){
                nextStep.onEnter(); 
            }

            return this;
        };


        // jump ahead in the flow to a sub flow
        this.goToSubFlow = function(){

            $log.log('goToSubFlow');

            if(!_navigationAllowed){
                $log.log('navigation is not allowed');
                return;
            }

            if (!_this.currentStep.subFlow) {
                $log.log('current step does not have a subFlow');
                return;
            }

            var nextStepId = _.findIndex(_steps, function(step){
                    return step.name === _this.currentStep.subFlow;
                }),
                nextStep = _steps[nextStepId];

            this.currentStep.current = false;
            this.currentStep.visited = true;

            _currentStepIndex = nextStepId;
            nextStep.current = true;
            nextStep.visited = true;
            this.currentStep = nextStep;

            // actual transition processes
            // navigate route
            if(nextStep.routeState){
                _navigate(nextStep.routeState);
            }

            // invoke the steps enter process
            if(nextStep.onEnter){
                nextStep.onEnter(); 
            }

            return this;
        };


        this.isCurrent = function(stepIdentifier){
            if(_.isString(stepIdentifier)){
                return this.currentStep.name === stepIdentifier;
            }
            return _currentStepIndex === stepIdentifier;
        };


        this.canGoTo = function(stepIdentifier){

            // make sure we weren't explicitly 
            // told not to navigate
            if(!_navigationAllowed){
                return false;
            }

            var current = this.isCurrent(stepIdentifier),
                // the current step is always navigatable
                canNavigateTo = current,

                step = 0;

            if(!current){

                if(_.isString(stepIdentifier)){
                    step = _.find(_steps, function(step){
                        return step.name === stepIdentifier;
                    });
                }else {
                    step = _steps[stepIdentifier];
                }
                // only allow navigation to the steps that we
                // already visited
                canNavigateTo = step && step.visited === true;
            }
            return canNavigateTo;
        };

        // stepIdentifier is the name of the step or the index
        this.goTo = function(stepIdentifier){

            var stepIndex = (_.isString(stepIdentifier) ? 
                                _.findIndex(_steps, function(step){
                                    return step.name === stepIdentifier;
                                }) : stepIdentifier),
                step,

                // our transition effect needs to 
                // go from left to right if we are going 
                // back in steps
                reverseTransition = stepIndex < _currentStepIndex;

            // navigate within our step bounds
            if(stepIndex < 0 || stepIndex >= _steps.length){
                return;
            }

            // if we are navigating away from a previous step
            // make sure we are still allowed to exit that step
            // this is to catch any changes once a user goes back 
            // to a previous step -- but don't worry about checking
            // if we are on the fathest step - continue will do that for us
            if(stepIndex !== _currentStepIndex && _currentStepIndex < _farthestIndex && !this.currentStep.canExit()){
                return;
            }

            // let the consumer know we are going back
            if(reverseTransition){
                _this.reverseTransition = true;
            }

            // make sure we can navigate away
            if( this.canGoTo(stepIdentifier)){
                $log.log('Going to', stepIdentifier);

                step = _steps[stepIndex];
                
                // old current 
                this.currentStep.current = false;
                this.currentStep.visited = true;

                // set new current before navigating so progress update is accurate
                _currentStepIndex = stepIndex;
                step.current = true;
                this.currentStep = step;

                if(step.routeState){
                    _navigate(step.routeState);
                }

                if(step.onEnter){
                    step.onEnter(); 
                }

            } else {
                $log.warn('Step "' + stepIdentifier + '" is not allowed.');
            }

            return this;
        };

        this.goToPreviousStep = function(){

            this.goTo(_currentStepIndex - 1);

            return this;
        };

        this.allowNavigation = function(allowed){
            if(_.isBoolean(allowed)){
                _navigationAllowed = allowed;
            }
            return _navigationAllowed;
        };

        return this;
    };

    return {
        createFlow : function(){
            return new Flow();
        }
    };

});