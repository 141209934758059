angular.module('PatientApp').factory('ContactService', function($q, $resource, $rootScope, endpoints) {


    var Contact = $resource(endpoints.contact.primary.url, null, {
        send: {
            method: 'POST'
        }
    });

    return {
        send: function(contactInfo) {
            var deferred = $q.defer();

            Contact.send({
                patientEmail:   contactInfo.email,
                patientName:    contactInfo.firstName + ' ' + contactInfo.lastName,
                subject:        contactInfo.subject,
                message:        contactInfo.message
            }).$promise.then(function(resp)  {
                if (resp && resp.hasData()) {
                    deferred.resolve(resp.getData().status);
                } else {
                    deferred.reject(resp);
                }
            }).catch(function() {
                deferred.reject();
            });

            return deferred.promise;

        }
    };
});