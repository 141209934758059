
// routingNumber directive to apply validators to inputs
// that need routing verification with the server

angular.module('PatientApp').directive('routingNumber', function($q, $resource, $interval, endpoints){

    var RoutingPaymentForms = $resource(endpoints.paymentForms.routing.url, { routingNumber: '@routingNumber'});

    return {
        scope: {
            onBankFound: '&',
            onRoutingNumberCheckFinished: '='
        },
        restrict: 'A',
        require: 'ngModel',
        link: function (scope, element, attrs, ngModel) {

            ngModel.$asyncValidators.validRouting = function (modelValue, viewValue) {

                var deferred = $q.defer(),
                    currentValue = modelValue || viewValue;
                
                // allow other checks to handle length issues
                if(!currentValue || currentValue.length < 9){
                    deferred.resolve();
                } else if (currentValue == '000000000') { //reject encrypted eCheck routing number
                    deferred.reject();
                } else {

                    RoutingPaymentForms.get({routingNumber: currentValue}).$promise
                        .then(function (resp) {
                            if(resp && resp.hasData()){

                                // push the bank info back up to controller
                                /* istanbul ignore else */
                                if(scope.onBankFound){                              
                                    scope.onBankFound(resp.getData());                                  
                                }

                                deferred.resolve();

                            } else {
                                deferred.reject();
                            }
                        })
                        .catch(function(){
                            deferred.reject();
                        });
                }

                deferred.promise.finally(function(){
                    // let anyone who cares, know we finished validating
                    // we have to do this in a timeout style function because
                    // we need to call the callback once the check is done
                    // AND the $pending state is changed and that state
                    // changes after the promise control flow, so we must take
                    // it out of that flow, then execute
                    $interval(function(){
                        if(scope.onRoutingNumberCheckFinished){
                            scope.onRoutingNumberCheckFinished();
                        }
                    }, 0, 1);
                    
                });

                return deferred.promise;
            };
        }
    };
});