angular.module('PatientApp').controller('ConfirmEmailCtrl', function($scope, $rootScope, $filter, _, PatientUsersService, $timeout){

    var _reset = function(){
            var user = PatientUsersService.getCurrentUser();

            $scope.fields = {};
            $scope.fields.email = user.email;

            if ($scope.forms && $scope.forms.confirmEmailForm) {
                $scope.forms.confirmEmailForm.$setPristine();
            }

            $scope.submitButtonText = $filter('translate')('confirmEmail.submitButtonText');
            $scope.resumbitPostEmailValidation = false;
            $scope.submitting = false;
        },

        _hidePrompt = function(){
            $scope.promptVisible = false;
        },
        _destroyShowPrompt = $rootScope.$on('confirmEmail:prompt', function(){
            _reset();
            $scope.promptVisible = true;
        }),
        _confirmEmail = function(currentPassword) {
            $scope.promptVisible = true;
            $scope.submitting = true;
            $scope.submitButtonText = $filter('translate')('confirmEmail.submittingButtonText');

            PatientUsersService.confirmEmailSendVerification($scope.fields.email, currentPassword)
            .then(function(){
                _hidePrompt();

                $scope.$emit('simpleModal:showPrompt', {
                    header: $filter('translate')('confirmEmail.successHeader'),
                    intent: 'success',
                    autoExpire: true
                });

                $rootScope.$emit('userSettings:updated'); //needed to refresh the notification
            })
            .catch(function() {
                $scope.emailNotSent = true;

                $timeout(function(){
                    $scope.emailNotSent = false;
                }, 3000);
            })
            .finally(function(){
                _reset();
            });
        };

    $scope.$on('$destroy', function() {
        _destroyShowPrompt();
    });

    $scope.forms = {};

    $scope.close = function(){
        _hidePrompt();
    };

    // Callback for email validation on login form
    // this is needed to handle resubmitting register
    // when we try to submit registration form before we 
    // have received the async validation
    $scope.emailValidated = function(){
        if($scope.resumbitPostEmailValidation){
            $scope.resumbitPostEmailValidation = false;
            $scope.confirmEmail(/*continuingSubmission*/ true);
        }
    };


    $scope.confirmEmail = function(continuingSubmission){
        if ($scope.forms.confirmEmailForm.email.$pending){
            $scope.resumbitPostEmailValidation = true;
            return;
        }

        //angular is supposed to be setting this property (but is not for some reason).  this accomplishes the same thing.
        $scope.forms.confirmEmailForm.$submitted = true;

        if ($scope.forms.confirmEmailForm.$invalid || ($scope.submitting && !continuingSubmission)) {
            return;
        }

        if ($scope.fields.email != PatientUsersService.getCurrentUser().email) {
            // hide this prompt temporarily while we prompt the user for their password
            _hidePrompt();
            $rootScope.$emit('confirmPassword:prompt', {
                'callback': _confirmEmail
            });
        } else {
            _confirmEmail();
        }

    };

});
