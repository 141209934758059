angular.module('PatientApp').directive('logo', function($window, endpoints){

    return {
        // only <logo>...</logo> allowed
        restrict : 'E',
        scope: {
            providerId: '=',
            altText: '='
        },
        replace: true,
        link: function($scope, el, attrs){

            $scope.$watch('providerId', function(id){

                // convenience logic for backendless so we have some
                // sort of logo available
                if($window.backendless){

                    // Fake provider logos for testing
                    switch(id){
                        case 789: // american
                            $scope.providerLogo = '<img version-src="assets/americanhealth.png" alt="' + attrs.altText + '"/>';
                            break;
                        case 888: // power phys
                            $scope.providerLogo = '<img version-src="assets/powerphy.png" alt="' + attrs.altText + '"/>';
                            break;
                        case 999: // gen card
                            $scope.providerLogo = '<img version-src="assets/gencardiology.png" alt="' + attrs.altText + '"/>';
                            break;
                        default:
                            $scope.providerLogo = '<img version-src="assets/nautilus.png" alt="' + attrs.altText + '"/>';
                            break;

                    }

                    return;
                }

                if(angular.isDefined(id)){
                    $scope.providerLogo = '<img src="' + endpoints.providers.logo.url + '/' + id + '" alt="' + attrs.altText + '"/>';
                }
            });
        },

        template: '<div class="logo-container" compile="providerLogo"></div>'
    };

});