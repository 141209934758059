angular.module('PatientApp').controller('MessagesCtrl', function($scope, $rootScope, $log, $stateParams, MessagesService, PatientUsersService, ProvidersService, LoadingIndicatorService){

    var _user = PatientUsersService.getCurrentUser(),
        _loadMessages = function(refreshMessages){
            _loadingKey = LoadingIndicatorService.loading();
            MessagesService.getMessages(refreshMessages).then(function(messages){
                $scope.messages = messages;
                $scope.showZeroState = messages.length === 0;
            }).finally(function(){
                LoadingIndicatorService.complete(_loadingKey);
            });
        },

        _loadingKey,

        // we are listening for this so we can reflect in the ui
        // the new threads that were added
        _threadCreationListener = $rootScope.$on('message:newThreadCreated', function(){
            $log.log('New Thread Created, refetch messages');
            _loadMessages(true);
        });

    $scope.messages = [];

    _loadMessages(true);


    // load all providers so we know if we need to allow a
    // compose message button
    ProvidersService.getProviders().then(function(providers){
        $scope.showCompose = providers.length > 0;
    });


    // destroy our scope listener
    $scope.$on('$destroy', function() {
        _threadCreationListener();
    });

    if (_user.firstName.length <= 12) {
        $scope.usersKey = _user.firstName ? _user.firstName.slice(-1) !== 's' ? 'message.argsMessages' : 'message.argsMessagesEndsWithS' : 'message.plural';
    } else {
        $scope.usersKey = 'message.plural';
    }
    $scope.translationData = {
        name: _user.firstName
    };

    if($stateParams.autofill) {
        $scope.$emit('message:compose', $stateParams.data);
    }
});