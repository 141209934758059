angular.module('PatientApp').directive('patientcoLogo', function(BrowserInfoService){

    var newLogo = '<svg id="patientco_logo_{{::$id}}" data-name="Patientco Logo {{::$id}}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 107.46 107.46">'+
                        '<defs>'+
                            '<style>'+
                                // These CSS styles are prefaced with the unique (due to ::$id) id 
                                // of the parent svg tag.  This is because style tags have global 
                                // scope.  Without this prefix, if more than two logos appear on a 
                                // single page, the css styles of the last one will apply to every 
                                // logo.  Adding the unique svg id before each cls class ensures 
                                // that the correct styles are applied to each indivdual logo.
                                'svg#patientco_logo_{{::$id}} .cls-1 {'+
                                    'fill:{{pFill}};'+
                                '}'+
                                'svg#patientco_logo_{{::$id}} .cls-2 {'+
                                    'fill:{{crossFill}};'+
                                '}'+
                                'svg#patientco_logo_{{::$id}} .cls-2 {'+
                                    'fill-rule:evenodd;'+
                                '}'+
                            '</style>'+
                        '</defs>'+
                        '<title>Patientco Logo</title>'+
                        '<path class="cls-1" d="M147.32,39.43v66.7" transform="translate(-20.07 -18.67)"/>'+
                        '<path class="cls-1" d="M73.8,31.62A40.9,40.9,0,0,0,33,72.4v53.73H20.07V72.4A53.73,53.73,0,1,1,73.8,126.13a53.33,53.33,0,0,1-27.49-7.63v-16A40.77,40.77,0,1,0,73.8,31.62Z" transform="translate(-20.07 -18.67)"/>'+
                        '<path class="cls-2" d="M73.8,44.91h0A27.57,27.57,0,0,0,46.31,72.4h0A27.57,27.57,0,0,0,73.8,99.89h0A27.57,27.57,0,0,0,101.29,72.4h0A27.57,27.57,0,0,0,73.8,44.91Zm6.47,45.22H67.33V78.87H56.07V65.93H67.33V54.67H80.27V65.93H91.53V78.87H80.27Z" transform="translate(-20.07 -18.67)"/>'+
                    '</svg>',
        oldLogo = '<svg class="patientco-logo" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="11 10 81 80" enable-background="new 0 0 100 100" xml:space="preserve">' +
                '<path fill="{{pFill}}" d="M41.4 86.8c-0.5 0-0.8 0.4-0.8 0.8c0 0.5 0.4 0.8 0.8 0.8c0.5 0 0.8-0.4 0.8-0.8 C42.3 87.1 41.9 86.8 41.4 86.8z"/>' +
                '<path fill="{{pFill}}" d="M55.3 86.6c-0.8 0-1.4 0.6-1.4 1.4c0 0.8 0.6 1.4 1.4 1.4c0.8 0 1.4-0.6 1.4-1.4 C56.7 87.3 56.1 86.6 55.3 86.6z"/>' +
                '<path fill="{{pFill}}" d="M45.5 87.2c-0.6 0-1 0.5-1 1c0 0.6 0.5 1 1 1c0.6 0 1-0.5 1-1C46.5 87.6 46.1 87.2 45.5 87.2z"/>' +
                '<path fill="{{pFill}}" d="M50.2 87.1c-0.6 0-1.2 0.5-1.2 1.2c0 0.7 0.5 1.2 1.2 1.2c0.6 0 1.2-0.5 1.2-1.2 C51.4 87.6 50.8 87.1 50.2 87.1z"/>' +
                '<circle fill="{{pFill}}" cx="61.2" cy="87.2" r="1.9"/>' +
                '<path fill="{{pFill}}" d="M37.7 85.4c-0.5 0-0.8 0.4-0.8 0.8c0 0.5 0.4 0.8 0.8 0.8c0.5 0 0.8-0.4 0.8-0.8 C38.5 85.8 38.1 85.4 37.7 85.4z"/>' +
                '<path fill="{{pFill}}" d="M11.1 50.5c0 3.6 0.5 7.1 1.4 10.6c0 0.1 0.4 1.1 0.6 1.7c0.1 0.3 0.6 0.3 0.6 0.3l2.8-1.7 c0 0 0.5-0.2 0.3-0.6c-0.2-0.3-0.4-0.7-0.4-0.8c-0.8-3.1-1.2-6.2-1.2-9.4c0-19.9 16.2-36.1 36.1-36.1c19.9 0 36.1 16.2 36.1 36.1 c0 12-5.9 23.1-15.8 29.9c-0.1 0.1-0.6 0.5-0.9 0.7c-0.1 0.1-0.1 0.5-0.1 0.5l1.7 2.5l0.2 0.2c0 0 0.2 0.3 0.5 0 c0.3-0.2 0.6-0.5 0.7-0.5c11.1-7.5 17.8-19.9 17.8-33.3c0-22.1-18-40.1-40.1-40.1C29.1 10.4 11.1 28.4 11.1 50.5z"/>' +
                '<path fill="{{pFill}}" d="M26.6 65l-12.3 8.4c1.3 2 2.8 4.3 4.1 6.5c2.9 4.8 8.5 4.5 12.1 1.9c4.3-3.2 1.9-8.5 1.9-8.5 S29 68.6 26.6 65z"/>' +
                '<path fill="{{pFill}}" d="M34.3 83.7c-0.4 0-0.7 0.3-0.7 0.7c0 0.4 0.3 0.7 0.7 0.7c0.4 0 0.7-0.3 0.7-0.7 C34.9 84 34.6 83.7 34.3 83.7z"/>' +
                '<path fill="{{pFill}}" d="M67.2 82.8c-1.1 0-2 0.9-2 2c0 1.1 0.9 2 2 2c1.1 0 2-0.9 2-2C69.1 83.7 68.3 82.8 67.2 82.8z"/>' +
                '<path fill="{{pFill}}" d="M25.7 63.7c-0.7-1.1-1.3-1.9-1.5-2.4C23 59.1 21 59.8 21 59.8l-3.2 2.1c1.2 1.8 2.9 4.2 3.4 4.8L25.7 63.7z "/>' +
                '<path fill="{{pFill}}" d="M11.5 69.1c0 0 0.8 1.2 1.9 3l6.5-4.4l-3.4-4.9l-5.4 3.5C10.3 67.2 11.5 69.1 11.5 69.1z"/>' +
                '<polygon fill="{{crossFill}}" points="43.5,74.4 58.5,74.4 58.5,57.2 75.8,57.2 75.8,42.2 58.5,42.2 58.5,25 43.5,25 43.5,42.2 26.3,42.2 26.3,57.2 43.5,57.2"/>' +
            '</svg>';

    return {
        scope: true,
        link: function(scope, elem, attrs){
            // Check if this instance has the 'footer' attribute set
            scope.isFooter = 'footer' in attrs;
            // Only attach the click event binding to an instance is it has the foot attribute
            if(scope.isFooter){
                // When the logo is clicked three times, it triggers the openModal function on the BrowserInfoService.  This executes any subscribers to this event.
                var timesClicked = 0;
                elem.bind('click', function() {
                    timesClicked++;
                    if(timesClicked >= 3){
                        BrowserInfoService.openModal();
                        timesClicked = 0;
                    }
                });
            }

            // Check if this instance has the color attribute set
            scope.color = attrs['color'] || false;

            scope.pFill = '#10356b'; // Standard patientco blue
            scope.crossFill = '#1dab51'; // Standard patientco green

            switch(scope.color){
                case 'white':
                    scope.pFill = '#fff';
                    scope.crossFill = '#fff';
                    break;
                case 'grayscale':
                    scope.pFill = '#616161';
                    scope.crossFill = '#616161';
                    break;
                case 'green':
                    scope.pFill = '#1aa951';
                    scope.crossFill = '#1aa951';
                    break;
                case 'blue':
                    scope.pFill = '#3F5D88';
                    scope.crossFill = '#3F5D88';
                    break;
                default:
                    break;
            }
        },
        template: function(element, attrs) {
            return attrs.hasOwnProperty('oldlogo') ? oldLogo : newLogo;
        }
                    
    };
});