angular.module('PatientApp').service('NMIGatewayService', function($window, $q, $log, $interval, ErrorsService, Compass){


    // To get around the CORS Direct POST issue with NMI
    // we will create an iframe that will proxy this process and
    // will actually perform the expected redirects inside of itself.
    // We will then be updated when the redirections are complete

    /* istanbul ignore next */
    var _useProxyIframe = (function(){
        var _iframe = null,
            _proxyData = {},
            _proxyDefer = null,
            _proxy = null,
            _expectLoad = false,
            _proxyTimeout = 30 * 1000, // 30 seconds,
            _timeoutTimer,
            _gatewayResponded = false,

            _proxyUrl = '/gatewayProxies/request.html',

            // clean up all of the set data
            _cleanUp = function(){

                $window.document.body.removeChild(_iframe);
                _proxyData = {};
                _proxyDefer = null;
                _iframe = null;
                _expectLoad = false;
                _gatewayResponded = false;
                $interval.cancel(_timeoutTimer);
            },

            // callback for the iframe onload
            // initiates all of the actual communication
            _proxyFrameReady = function(){
                // only sending once
                if(!_expectLoad){
                    return;
                }
                _expectLoad = false;
                
                _proxy.postMessage(JSON.stringify(_proxyData), window.location.origin);
                
                $window.patientapp = $window.patientapp || {};

                $window.patientapp.proxyResponse = function(){
                    Compass.capture.success('payment', 'gateway', 'step2Proxy');
                    _gatewayResponded = true;
                    _proxyDefer.resolve();
                    _cleanUp();
                };
            },

            // if we see that the gateway did not callback within
            // our timeframe,  we need to reject our promise
            _proxyResponseTimeoutCheck = function(){
                if(!_gatewayResponded){
                    $log.log('Proxy timed out...');
                    _proxyDefer.reject(ErrorsService.errors.NETWORK_ERROR);
                }
            };




        // Note, we require the user of window.location.origin which is not supported
        // in some legacy browsers. Specifically IE9 needs it
        // This polyfill is to help fix that lack of support
        if (!window.location.origin) {
            window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
        }

        return function(action, data){

            _proxyDefer = $q.defer();




            // data passed to proxy that's needed to build a form
            // to post to gatway
            _proxyData = {
                formAction: action,
                data: data
            };

            _expectLoad = true;

            
            if(action.indexOf('TIMEOUT') > -1){
                
                // this is logic is used to allow
                // us to test the timeout in the same
                // area of the code that it lives in
                // For testing only, we need to execute the timeout immediately
                $interval = function(f){ 
                    f(); 
                };
                
            }else if(_iframe === null) {

                _iframe = $window.document.createElement('iframe');
                _iframe.src = _proxyUrl;

                // we will have the contentWindow after load happens so make it go back through the cycle onload triggered 
                _iframe.onload = _proxyFrameReady;
                _iframe.style.height = '0';
                _iframe.style.width = '0';
                _iframe.style.display = 'none';
                
                $window.document.body.appendChild(_iframe);

                _proxy = _iframe.contentWindow;
            }else {
                // always make sure we start from the initial url
                _iframe.src = _proxyUrl;
            }

            
            _timeoutTimer = $interval(_proxyResponseTimeoutCheck, _proxyTimeout, 1);


            return _proxyDefer.promise;
        };
    })();


    return {

        // postToGateway encapsulates the logic of this specific
        // gateway's posting procedures. We use the gatewayDetails
        // to build the different posting pieces and the payment is used
        // to populate other important bits of the form request
        postToGateway : function( gatewayDetails, payment ){
            
            var deferred = $q.defer(),
                nmiData = {
                    'billing-cc-number' : '',
                    'billing-cc-exp' : '',
                    'billing-account-name' : '',
                    'billing-account-number' : '',
                    'billing-routing-number' : '',
                    'billing-cvv' : '',
                    'billing-first-name' : '',
                    'billing-last-name' : '',
                    'billing-address1' : payment.billing.address,
                    'billing-address2' : payment.billing.address2 || '',
                    'billing-city' : payment.billing.city,
                    'billing-state' : payment.billing.state,
                    'billing-postal'  : payment.billing.zip,
                    'billing-phone' : payment.billing.phone || '',
                    'billing-email' : payment.billing.email || '',
                    'billing-micr' : ''
                },

                // when we resolve this post, we need
                // to pass these details along with any scaffolding
                // that is already passed to the confirmation endpoint
                acknowledgeDetails = {
                    tokenId: gatewayDetails.formAction.split('/').pop()
                };

            if(payment.method.card){
                nmiData['billing-first-name'] = payment.method.card.firstName;
                nmiData['billing-last-name'] = payment.method.card.lastName;
                nmiData['billing-cc-number'] = payment.method.card.number;
                nmiData['billing-cc-exp'] = payment.method.card.expiration;
            }else {
                nmiData['billing-first-name'] = payment.method.eCheck.firstName;
                nmiData['billing-last-name'] = payment.method.eCheck.lastName;
                nmiData['billing-account-name'] = payment.method.eCheck.firstName + ' ' + payment.method.eCheck.lastName;
                nmiData['billing-account-number'] = payment.method.eCheck.accountNumber;
                nmiData['billing-routing-number'] = payment.method.eCheck.routing;
            }

            Compass.capture.misc('payment', 'gateway-fields', 'field-given', {
                billingFirstName:     !!(nmiData['billing-first-name'] && nmiData['billing-first-name'].toString().trim()),
                billingLastName:      !!(nmiData['billing-last-name'] && nmiData['billing-last-name'].toString().trim()),
                billingAccountName:   !!(nmiData['billing-account-name'] && nmiData['billing-account-name'].toString().trim()),
                billingAccountNumber: !!(nmiData['billing-account-number'] && nmiData['billing-account-number'].toString().trim()),
                billingRoutingNumber: !!(nmiData['billing-routing-number'] && nmiData['billing-routing-number'].toString().trim()),
                billingCCNumber:      !!(nmiData['billing-cc-number'] && nmiData['billing-cc-number'].toString().trim()),
                billingCCExp:         !!(nmiData['billing-cc-exp'] && nmiData['billing-cc-exp'].toString().trim()),
                billingMethodCard:    !!(payment.method.card)
            });

            // Coding gods forgive me for I have added code to make testing easier
            // Karma is having a hard time handling the iframe proxy stuff.
            // we are adding this bit to help keep the tests approachable from Karma
            if(gatewayDetails.formAction.indexOf('KARMA') > -1){
                deferred.resolve(acknowledgeDetails);
            }else {

                /* istanbul ignore next */
                _useProxyIframe(gatewayDetails.formAction, nmiData)
                    .then(function(){
                        deferred.resolve(acknowledgeDetails);
                    })
                    .catch(function(resp){
                        deferred.reject(resp);
                    });
            }
            

            return deferred.promise;
        }
    };
});