angular.module('PatientApp').controller('AccountVerificationCtrl', function($scope, $rootScope, $state, $log, $filter, _, ErrorsService, LinkedAccountsService, BillsService, Compass){

    var _reset = function(){
            $scope.fields = {};
            $scope.fields.birthdate = '';
            $scope.fields.SSN = '';

            if ($scope.forms && $scope.forms.verifyAcctForm) {
                $scope.forms.verifyAcctForm.$setPristine();
            }

            $scope.submitButtonText = $filter('translate')('encounterLinking.verifyInformation');
            $scope.submitting = false;
            $scope.submitErrorMessage = '';
            $scope.linkBlocked = false;
        },

        // this scode is tracked if a user comes from an ebill email to
        // verify and link. Its existance means we need to go into the bill
        // flow for it if we verify an account associated with a matching value
        _intendedScodeToLinkAndPay,

        _hidePrompt = function(){
            $scope.promptVisible = false;
        },
        _destroyShowPrompt = $rootScope.$on('accountVerification:prompt', function(){

            var linkedAccts = LinkedAccountsService.getAllUnverified();

            _reset();

            Compass.capture.event('account-linking', 'verify-modal-interaction', 'opened');
            Compass.capture.metricValue('account-linking', 'verify-modal-interaction', 'patients-presented-count', linkedAccts.length);

            if (linkedAccts.length === 1) {
                $scope.selectAccount(linkedAccts[0]);
            } else {
                $scope.accounts = _.chain(linkedAccts)
                    .sortBy('patientName')
                    .value();
                $scope.showVerifyForm = false;
            }

            $scope.promptVisible = true;
        }),

        _destoryShowAndPayPrompt = $rootScope.$on('accountVerification:prompt:proceedToPay', function(topic, scodeToPay){

            _intendedScodeToLinkAndPay = scodeToPay;

            // let's make sure we have unverified before continuing
            // note: if we find the user has unverified accounts we are going
            // to show them regardless if the scode is attached to one
            // of them. B/c if they don't have an account associated with the
            // scode from the ebill, it's likely they will never get to this
            // point b/c the ebill flow will likely find the bill available to
            // the user.
            LinkedAccountsService.fetchUnverified().then(function(){
                if(LinkedAccountsService.hasUnverified()){
                    $rootScope.$broadcast('accountVerification:prompt');
                }
            });

        });

    $scope.$on('$destroy', function() {
        _destroyShowPrompt();
        _destoryShowAndPayPrompt();
    });

    $scope.forms = {};

    $scope.close = function(){

        Compass.capture.event('account-linking', 'verify-modal-interaction', 'closed');

        _hidePrompt();
    };

    $scope.selectAccount = function(acct) {
        var linkName; 
        if(acct.authType=='guarantor'){
            linkName=acct.guarantorName;
            $scope.subheader = $filter('translate')('encounterLinking.verifyAcctSubtitleGuarantor', { patientName: acct.patientName, providerGroup: acct.providerGroup });
     
        } else if(acct.authType=='patient'){
            linkName=acct.patientName;
            $scope.subheader = $filter('translate')('encounterLinking.verifyAcctSubtitlePatient', { patientName: acct.patientName, providerGroup: acct.providerGroup });
        }
        $scope.currAcctInfo = acct;
        $scope.header = $filter('translate')('encounterLinking.verifyAcctTitle', { linkingName: linkName }); 
        $scope.showVerifyForm = true;
    };

    $scope.verifyAcct = function(){

        if($scope.forms.verifyAcctForm.$invalid || $scope.submitting) {

            if($scope.forms.verifyAcctForm.$invalid){
                Compass.capture.failure('account-linking', 'verify-submission', 'INVALID_FORM');
            }

            return;
        }

        $scope.submitting = true;
        $scope.submitButtonText = $filter('translate')('encounterLinking.verifyingInformation');

        var formattedBirthdate = $filter('dateFormat')($scope.fields.birthdate, 'yy-MM-dd'),
            numberOfNewAccts = LinkedAccountsService.getNumberOfNewAccts($scope.currAcctInfo.id),
            newAccountsScodes = LinkedAccountsService.getNewAcctsSecureCodes($scope.currAcctInfo.id),
            billToLinkAfterVerify = LinkedAccountsService.getBillToLinkAfterVerify($scope.currAcctInfo.id, _intendedScodeToLinkAndPay),
            haveAnyMasterLinksBeenVerifiedYet = LinkedAccountsService.haveAnyMasterLinksBeenVerifiedYet(),
            successHeader = '',
            subcontent = '',
            buttonText = '',
            compassAction = '',
            patientName = $filter('capitalize')($scope.currAcctInfo.patientName || $scope.currAcctInfo.guarantorName);


        Compass.capture.metricValue('account-linking', 'post-verify', 'new-accounts-count', numberOfNewAccts);
        Compass.capture.misc('account-linking', 'post-verify', 'new-accounts-securecodes', JSON.stringify(newAccountsScodes));
        Compass.capture.misc('account-linking', 'post-verify', 'new-accounts-provider-group', $scope.currAcctInfo.providerGroup);

        if(_intendedScodeToLinkAndPay){
            Compass.capture.misc('account-linking', 'post-verify', 'intent-to-pay-scode', _intendedScodeToLinkAndPay);
        }


        if(_intendedScodeToLinkAndPay && _.contains(newAccountsScodes, _intendedScodeToLinkAndPay.toUpperCase())){

            successHeader = $filter('translate')('encounterLinking.verificationHeader');
            subcontent = $filter('translate')('encounterLinking.verificationSubheader', { patientName: patientName, providerGroup: $scope.currAcctInfo.providerGroup });
            buttonText = $filter('translate')('encounterLinking.continueToPay');
            compassAction = 'pay-intended-ebill-link';

        }else if (numberOfNewAccts === 0) {
            if (!haveAnyMasterLinksBeenVerifiedYet) {
                subcontent = $filter('translate')('encounterLinking.verificationSubheader', { patientName: patientName, providerGroup: $scope.currAcctInfo.providerGroup });
            } else {
                subcontent = $filter('translate')('encounterLinking.proactiveExperiencedVerificationSubheader', { patientName: patientName, providerGroup: $scope.currAcctInfo.providerGroup });
            }
            successHeader = $filter('translate')('encounterLinking.proactiveVerificationHeader', {patientName : patientName});
            buttonText = $filter('translate')('encounterLinking.close');
            compassAction = 'ack-no-new-accounts-linked';

        } else if (numberOfNewAccts === 1) {
            successHeader = $filter('translate')('encounterLinking.verificationHeader');
            subcontent = $filter('translate')('encounterLinking.verificationSubheader', { patientName: patientName, providerGroup: $scope.currAcctInfo.providerGroup });
            buttonText = $filter('translate')('actions.viewAccount');
            compassAction = 'view-single-new-account';
        } else {
            successHeader = $filter('translate')('encounterLinking.verificationHeaderPlural');
            subcontent = $filter('translate')('encounterLinking.verificationSubheader', { patientName: patientName, providerGroup: $scope.currAcctInfo.providerGroup });
            buttonText = $filter('translate')('actions.viewAccounts');
            compassAction = 'view-multiple-new-accounts';
        }

        LinkedAccountsService.authenticateAccount($scope.currAcctInfo.id, formattedBirthdate, $scope.fields.SSN)
            .then(function(){
                _hidePrompt();

                Compass.capture.success('account-linking', 'verify-submission', 'processed');

                $scope.$emit('simpleModal:showPrompt', {
                    header: successHeader,
                    subcontent: subcontent,
                    intent: 'success',
                    actions: [{
                        label: buttonText,
                        compassAction: compassAction,
                        clickHandler: function(){

                            if (billToLinkAfterVerify) {

                                BillsService.getAccountBill(billToLinkAfterVerify).then(function(){
                                    $state.go('app.verify'); // we got the bill
                                }).catch(function(){
                                    $log.error('Unable to get the ebill');
                                    $state.go('app.dashboard', null, {reload: true});
                                });

                                // after we go to this bill don't go directly
                                // to paying this bill again on other tries
                                if(_intendedScodeToLinkAndPay === billToLinkAfterVerify){
                                    _intendedScodeToLinkAndPay = null;
                                }
                            } else {
                                //force reload because we may already be on this state and the data will need to be re-fetched
                                $state.go('app.dashboard', null, {reload: true});
                            }
                        }
                    }]
                });
            })
            .catch(function(resp){
                var err = ErrorsService.resolve(resp);
                switch(err){
                    case ErrorsService.errors.LINK_BLOCKED:
                        $scope.submitErrorMessage = $filter('translate')('encounterLinking.verificationBlocked', { patientName: patientName });
                        $scope.linkBlocked = true;
                        break;

                    case ErrorsService.errors.INVALID_PARAMETERS:
                    case ErrorsService.errors.LINK_FAILURE:
                    default:
                        //we can get here if we we get an invalid-params error OR if the success flag wasn't set
                        $scope.submitErrorMessage = $filter('translate')('encounterLinking.verificationFailed', { patientName: patientName });
                        break;
                }

                Compass.capture.failure('account-linking', 'verify-submission', err || 'NO_ERROR_CODE');

            })
            .finally(function(){
                $scope.submitting = false;
                $scope.submitButtonText = $filter('translate')('encounterLinking.verifyInformation');
            });
    };


});
