angular.module('PatientApp').factory('BillsService', function($q, $resource, $filter, $log, $interpolate, $rootScope, $window, _, endpoints, MoneyUtilsService, LinkedAccountsService, Compass){

    var Bills = $resource(endpoints.bills.primary.url, null, {
            /*custom actions*/
            find: {
                method: 'POST',
                url: endpoints.bills.find.url
            },

            link: {
                method: 'POST',
                url: endpoints.bills.link.url
            },

            fetchFromAccount: {
                method: 'POST',
                url: endpoints.bills.shc.url,
                params: {
                    secureCode: '@secureCode'
                }
            },

            fetchCCIBill: {
                method: 'POST',
                url: endpoints.bills.cci.url
            },

            findAuthOptions: {
                method: 'POST',
                url: endpoints.bills.findAuthOptions.url
            },

            firstBillGetCode: {
                method: 'POST',
                url: endpoints.bills.firstBillGetCode.url
            },

            findNew: {
                method: 'POST',
                url: endpoints.bills.findNew.url
            },

            payAsGuest: {
                method: 'POST',
                url: endpoints.bills.payAsGuest.url
            }
        }),


        // construct a bill object that can make fetching items like
        // amount types and discount information easy.
        _currentBill = null,

        // Bill object used to help grab/calculate pieces of the
        // bill response from the server into more consumable methods
        Bill = function Bill( billServerResponse, scodeUsedForSearch ){

            // we really want to build off of the bill response
            // to eliminate redundant property naming issues
            _.assign(this, billServerResponse);

            var _billSource = billServerResponse,
                _paymentOptions = null,
                _scodeUsedForSearch = (scodeUsedForSearch && scodeUsedForSearch.length === 11) ? scodeUsedForSearch : _billSource.secureCode;


            // Access of the bill original data can be found here.
            // We are giving this a double underscore identifier so to
            // deter from usage. Plese add a facade layer on this constructor
            // if there is a field on the __bill__ that you need publicly
            // available.
            this.__bill__ = _billSource;


            // Get the payment options that are also allowed on the
            // bill. We consider a custom amount and the bill amount as
            // items that will always be presented to the user.
            this.getPaymentOptions = function(){

                if(_paymentOptions !== null){
                    return _paymentOptions;
                }
                _paymentOptions = [];

                _.map(_billSource.paymentOptions, function(option){

                    var key = '',
                        translationVars = {
                            calculatedTotal: $filter('currency')(option.amount),
                            discountPolicy: option.discountPolicy
                        };

                    // Type shortcode
                    switch(option.type.toUpperCase()){
                        case 'CB':
                            key = 'payment.amount.payAccountBalArg';
                            break;
                        case 'CBD':
                            key = 'payment.amount.payAccountBalArgDiscount';
                            break;
                        case 'SB':
                            key = 'payment.amount.payBillBalArg';
                            break;
                        case 'SBD':
                            key = 'payment.amount.payBillBalArgDiscount';
                            break;
                    }

                    option.dialog = $filter('translate')(key, translationVars);

                    _paymentOptions.push(option);
                });
                return _paymentOptions;
            };

            // get the specific details for a given payment option
            this.getPaymentOption = function(index){
                if(_paymentOptions === null){
                    // hydrate the options if this
                    // is called before get paymentOptions
                    this.getPaymentOptions();
                }
                return _paymentOptions[index];
            };

            // get the details for the provider associated with this bill
            this.getProviderDetails = function(){
                return _billSource.providerDetails;
            };

            // get the details for the provider associated with this bill
            this.getAccountDetails = function(){
                return _billSource.accountDetails;
            };

            // get the address from the bill if it meets the minimum
            // criteria
            this.getGuarantorAddress = function(){

                var details = _billSource.guarantorDetails;

                if(!details){
                    return null;
                }

                // we require a minimum set of points before we will use this address
                // todo: might make sense to allow clicking of patial complete address
                // which will prepopulate the billing form where you can complete it
                if(details.address && details.city && details.state && details.zip){
                    return {
                        address: details.address,
                        address2: details.address2,
                        city: details.city,
                        state: details.state,
                        zip: details.zip
                    };
                }

                return null;
            };

            this.isProviderActive = function(){
                return _billSource.providerDetails.active;
            };

            this.canMessageAboutBill = function(){
                return this.isProviderActive() && _.includes(_billSource.providerDetails.features, 'messaging');
            };

            // is today past the (latest -- implicit)bills due date
            this.isPastDue = function(){
                // dueDate override means that the bill can never be past due
                if(!this.isProviderActive() || !_billSource.dueDate || _billSource.providerDetails.patientDueDateOverrideText) {
                    return false;
                }

                var today = new Date(),
                    due = new Date(_billSource.dueDate);

                // compare on midnight of the dates
                today.setHours(0,0,0,0);
                due.setHours(0,0,0,0);

                return due < today;
            };

            this.hasZeroBalance = function(){
                return parseFloat(_billSource.accountBalance.amount || 0) === 0;
            };

            this.hasNegativeBalance = function(){
                return parseFloat(_billSource.accountBalance.amount || 0) < 0;
            };

            // get the diff of what the bill has vs what
            // is owed on the account
            this.balanceDiff = function(){
                return MoneyUtilsService.subtract(_billSource.accountBalance.amount, _billSource.billAmount);
            };

            this.reflectsDbu = function(){

                if(!this.isProviderActive()){
                    return false;
                }

                return _.indexOf(['dbu', 'mbu'], _.trim(_billSource.accountBalance.lastAction).toLowerCase()) >= 0 &&
                            !MoneyUtilsService.equals(_billSource.accountBalance.amount, _billSource.billAmount);
            };

            // when a user uses an scode to search for a bill and
            // there is a newer bill on the account, we will get the latest
            // bill. This function tells us if this is the case
            this.newerBillReturned = function(){
                return _billSource.secureCode !== _scodeUsedForSearch;
            };

            this.hasFinancingOption = function(){
                return this.isProviderActive() && _billSource.hasFinancing;
            };
        };



    // Register this service to clear it's data when asked to
    $rootScope.$on('data:clear', function(){
        _currentBill = null;
    });

    return {

        createBill : function( bill ){

            // if we are passing an already created bill
            // object just return it
            if(bill instanceof Bill){
                return bill;
            }

            return new Bill(bill);
        },


        /**
        If we have a current bill in memory share it
        */
        getCurrentBill: function(){
            return _currentBill;
        },

        /**
        remove the current bill from memory
        */
        flushCurrentBill: function(){
            _currentBill = null;
        },

        /**
        Look up a bill based on scode and amount with our
        server. Should be called when finding the bill from
        the landing screen for an unauthed user.
        */
        find: function(sCode, amount){
            return Bills.find({secureCode: sCode, amount: amount}).$promise.then(function(resp){
                if(resp && resp.hasData()){

                    _currentBill = new Bill(resp.getData(), sCode);
                    // only returning the bill
                    return _currentBill;
                }

                return $q.reject(resp);
            });
        },


        /**
        Look up the bill similar to the find method but our intent
        is also to link the bill with the currently logged in user
        */
        link: function(sCode, amount){

            return Bills.link({secureCode: sCode, amount: amount}).$promise.then(function(resp){
                if(resp && resp.hasData()){

                    _currentBill = new Bill(resp.getData(), sCode);

                    // mark bill as explicitly linked
                    _currentBill.linked = true;

                    LinkedAccountsService.fetchUnverified(true); //refresh our list in case we now have a new acct connected to a new masterLink

                    return _currentBill;
                }
                return $q.reject(resp);
            });
        },

        /**
        Look up bill based on the sCode alone. This search will
        look only under the currently logged in user's account to find
        a bill that matches on that.
        */
        getAccountBill: function(sCode){

            return Bills.fetchFromAccount({secureCode: sCode}).$promise.then(function(resp){
                if(resp && resp.hasData()){

                    _currentBill = new Bill(resp.getData(), sCode);

                    // mark bill as explicitly linked
                    _currentBill.linked = true;

                    return _currentBill;
                }
                return $q.reject(resp);
            });
        },


        // Look up a bill based on the CCI pub id
        getCCIBill: function(pubId){
            return Bills.fetchCCIBill({ pubId: pubId }).$promise.then(function(resp){
                if(resp && resp.hasData()){

                    _currentBill = new Bill(resp.getData());

                    return _currentBill;
                }

                return $q.reject(resp);
            });
        },

        // utility to open the pdf in the correct location
        openPdf: function(sCode){

            if(!sCode){
                $log.error('sCode not provided');
                return;
            }

            Compass.capture.event('bill', 'view-bill', 'open');
            Compass.capture.misc('bill', 'view-bill', 'secureCode', sCode);

            $window.open(endpoints.bills.pdf.url + sCode, '_blank');
        },

        // Get verification options from firstBill token
        findAuthOptions: function(token){
            return Bills.findAuthOptions({token: token}).$promise.then(function(resp){
                if(resp && resp.hasData()){
                    return resp.getData();
                }

                return $q.reject(resp);
            });
        },

        findNew: function(token, verificationCode, guarantorLast4SSN, guarantorDOB) {
            return Bills.findNew({
                token: token,
                verificationCode: verificationCode,
                guarantorLast4SSN: guarantorLast4SSN,
                guarantorDOB: guarantorDOB
            })
            .$promise.then(function(resp) {
                if (resp && resp.hasData()){
                    _currentBill = new Bill(resp.getData());
                    return _currentBill;
                }

                return $q.reject(resp);
            });
        },

        firstBillGetCode: function(token) {
            return Bills.firstBillGetCode({
                billToken: token
            })
            .$promise.then(function(resp) {
                if (resp && resp.hasData() && resp.getData().success === true){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },

        payAsGuest: function(){
            if (_currentBill === null) {
                return $q.reject('Bill must be loaded to pay as guest.');
            }

            return Bills.payAsGuest({
                secureCode: _currentBill.secureCode,
                amount: _currentBill.billAmount
            }).$promise.then(function(resp) {
                if (resp && resp.hasData() && resp.getData().redirectUrl){
                    Compass.capture.success('bill', 'payAsGuest');
                    $window.location.href = resp.getData().redirectUrl;
                    return resp.getData();
                }
                Compass.capture.failure('bill', 'payAsGuest');
                return $q.reject(resp);
            }, function(resp) {
                Compass.capture.failure('bill', 'payAsGuest');
                return $q.reject(resp);
            });
        }
    };
});
