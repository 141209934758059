angular.module('PatientApp').factory('LinkedAccountsService', function($q, $log, $resource, $filter, $rootScope, _, endpoints){

    var MasterLinks = $resource(endpoints.masterLinks.primary.url, null, {
            verify: {
                method: 'POST',
                url: endpoints.masterLinks.verify.url,
                params: {
                    id: '@id'
                }
            }
        }),
        _unverifiedAccounts = [],
        _fetchAttempted = false,
        _fetchDefer = null,
        _haveAnyMasterLinksBeenVerifiedYet = false;

    // Register this service to clear it's data when asked to
    $rootScope.$on('data:clear', function(){
        _unverifiedAccounts = [];
        _fetchAttempted = false;
        _fetchDefer = null;
        _haveAnyMasterLinksBeenVerifiedYet = false;
    });

    return {
        hasUnverified: function(encounterGroupId, onlyWithNewAccts) {
            return !!_.find(_unverifiedAccounts, function(a){
                return (!encounterGroupId || _.parseInt(a.providerGroupId) === _.parseInt(encounterGroupId)) && (!onlyWithNewAccts ||  a.additionalBills.length > 0);
            });
        },

        getNotificationText: function(encounterGroupId){
            var matchingMasterLinks = _.filter(_unverifiedAccounts, function(acct){
                    return !encounterGroupId || _.parseInt(acct.providerGroupId) === _.parseInt(encounterGroupId);
                }),
                proactiveMasterLinks = _.filter(matchingMasterLinks, function(acct){
                    return acct.additionalBills && acct.additionalBills.length === 0;
                }),
                proactiveProviderGroups = _.chain(proactiveMasterLinks)
                    .map(function(acct) {
                        return acct.providerGroup;
                    })
                    .uniq()
                    .sort()
                    .value();

            //if all of the MLs are proactive, then the user gets a special message.  i.e. if we have one proactive ML and one ML with
            //existing accts, just show the standard notification message. if we're showing the notification again after the user has
            //already verified account, modify the language slightly to indicate that there are more accts to verify.
            if (!_.isEmpty(proactiveMasterLinks) && proactiveMasterLinks.length === matchingMasterLinks.length) {
                return $filter('translate')('encounterLinking.proactiveLinkableAccounts', {providerGroupList : proactiveProviderGroups});
            } else if (_haveAnyMasterLinksBeenVerifiedYet) {
                return $filter('translate')('encounterLinking.experiencedLinkableAccounts');
            }
            return $filter('translate')('encounterLinking.linkableAccounts');
        },

        getNumberOfNewAccts: function(masterLinkId) {
            return this.getNewAcctsSecureCodes(masterLinkId).length;
        },

        getNewAcctsSecureCodes: function(masterLinkId){
            var masterLink = _.find(_unverifiedAccounts, function(a){
                return _.parseInt(a.id) === _.parseInt(masterLinkId);
            });
            return masterLink.additionalBills || [];
        },

        getBillToLinkAfterVerify: function(masterLinkId, scodeWithPayIntent) {
            //only go to bill-link page if there is a single bill about to be added to the PatientWallet
            var masterLink = _.find(_unverifiedAccounts, function(a){
                return _.parseInt(a.id) === _.parseInt(masterLinkId);
            });

            if(masterLink.additionalBills){
                if (scodeWithPayIntent && _.contains(masterLink.additionalBills, scodeWithPayIntent.toUpperCase())) {
                    return scodeWithPayIntent;
                } else if (masterLink.additionalBills.length === 1) {
                    return masterLink.additionalBills[0];
                }
            }

            return null;
        },

        haveAnyMasterLinksBeenVerifiedYet: function() {
            return _haveAnyMasterLinksBeenVerifiedYet;
        },

        getAllUnverified: function(){
            return _unverifiedAccounts;
        },

        fetchUnverified: function(forceRefresh){

            // reuse this promise if we need more than
            // one fetch of the data at a time
            if(!_fetchDefer || forceRefresh){
                _fetchDefer = $q.defer();
            }

            if(!_fetchAttempted || forceRefresh){

                MasterLinks.get().$promise.then(function(resp){
                    _fetchAttempted = true;
                    if(resp && resp.hasData()){
                        var theAccounts,theAuthType,extendedAccounts;
                        theAccounts = resp.getData();
                        extendedAccounts = _.map(theAccounts, function(element) { 
                            theAuthType='patient'; //default
                            if(_.includes(element.fields,'guarantordob') && _.includes(element.fields,'guarantorssn')){
                                theAuthType='guarantor';
                            } else if(_.includes(element.fields,'patientdob') && _.includes(element.fields,'patientssn')){
                                theAuthType='patient';
                            } else if(_.includes(element.fields,'patientdob') && _.includes(element.fields,'guarantorssn')){
                                theAuthType='patient';
                            } else{
                                //This is a weird case and should never happen unless the backend got messed up, but we are going to filter these out
                                theAuthType='badfields';
                            }
                            return _.extend({}, element, {authType: theAuthType});
                        });
                        extendedAccounts = _.filter(extendedAccounts,function(acct) {
                            return !(acct.authType=='badfields'); //Don't show stuff that can't possibly auth anyway
                        });
                        _unverifiedAccounts = extendedAccounts;
                        _fetchDefer.resolve(_unverifiedAccounts);
                    }else {
                        _fetchDefer.reject(resp);
                    }
                });

            }else {
                _fetchDefer.resolve(_unverifiedAccounts);
            }

            return _fetchDefer.promise;
        },

        authenticateAccount: function(masterLinkId, fieldOneValue, fieldTwoValue){
            var masterLink = _.find(_unverifiedAccounts, function(a){
                    return _.parseInt(a.id) === _.parseInt(masterLinkId);
                }),
                fields = {};

            fields[masterLink.fields[0]] = fieldOneValue;
            fields[masterLink.fields[1]] = fieldTwoValue;

            return MasterLinks.verify({id: masterLinkId, fields: fields}).$promise.then(function(resp){
                if(resp && resp.hasData() && resp.getData()['success']){
                    _unverifiedAccounts = _.without(_unverifiedAccounts, masterLink); //verified now. no longer in list
                    _haveAnyMasterLinksBeenVerifiedYet = true;
                    $rootScope.$emit('accountVerification:updated');    //tell all of our fans
                    return resp;
                }
                return $q.reject(resp);
            });
        }
    };
});
