
// For the transworld integration, they call the "SecureHealthCode"
// a differnt name and we need to show their name where it is needed.
// Instead of passing their name and our name as a variable to translation
// we have this filter that is meant to find the occurance of our name
// and replace it with theirs. This isolates the updating and 
// allows us to pipe the translated value directrly to this filter
// and update it.

angular.module('PatientApp').filter('applySecureCodeNaming', function($filter, $rootScope){

    // this string could be localized in the future
    // account for that by using the translated version
    // instead of hardcoding it
    var twName = $filter('translate')('transworld.clientRefNum'),
        scodeMatch = 'SecureHealthCode';

    return function(translatedString){
        
        // currenly we set this internal memory of the
        // tw state in the routing onEnter phase
        if($rootScope.transworld){
            return translatedString.replace(scodeMatch, twName);
        }

        return translatedString;
    };

});