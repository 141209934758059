angular.module('PatientApp').config(['$translateProvider', 'Config', function($translateProvider, Config) {
    // our build process handles gathering the languages
    // this will handle setting the prefence of languages
    var localLang = localStorage.getItem('currentLanguage');
    if (localLang && Config.languages && Config.languages.indexOf(localLang) > -1) {
        $translateProvider.preferredLanguage(localLang);
    } else {
        $translateProvider.preferredLanguage('en');
        localStorage.setItem('currentLanguage', 'en');
    }

    $translateProvider.useMissingTranslationHandlerLog();

}]);
