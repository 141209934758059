'use strict';
//should be global 'use strict'; for the bundle file

//this is routing for legacy urls. it's in javascript because we're serving this from a CDN
var routes = {
    "\\/payment\\/receipt\\/([A-Z0-9]{8})\\/([0-9]+)"   : {"redirect": "/#/dashboard/provider/VAR1/receipt/VAR2", "complex": true},
    "\\/bill\\/pay"                                     : {"redirect": "/#/ebill", "complex": false},
    "\\/message\\/view\\/.*"                            : {"redirect": "/#/messages", "complex": false},
    "\\/account\\/createPassword\\/\\?email.*token="    : {"redirect": "/#/forgot/", "complex": false},
    "\\/openlink\\/"                                    : {"redirect": "/#/", "complex": false},
    "\\/c\\?"                                           : {"redirect": "/services/v1/bills/cciRedirect?", "complex": false},
    "\^\/tw$"                                           : {"redirect": "/#/tw", "complex": false},
    "\\/bill\\/first"                                   : {"redirect": "/#/firstBill", "complex": false}
};

for(var route in routes) {
    if(!routes.hasOwnProperty(route)) {
        continue;
    }

    //get the uri including the query string
    var uri = window.location.href.replace(window.location.origin, ""),
        //build a regex that will split the query string out into a separate capture group
        re = new RegExp(route + "((?:\\?|\\/)?.*)"),
        //test the uri against the regex
        matches = re.exec(uri),
        //define a variable for the path that we will assemble
        redirectPath;

    //check if the URI matches this routing pattern
    if(matches) {
        redirectPath = routes[route]['redirect'];

        //if we have a complex route replace the VAR declarations with capture groups
        if(routes[route]['complex']) {
            //minus one here because we're always capturing the full query string as the last group
            for (var i = 1; i < matches.length - 1; i++) {
                redirectPath = redirectPath.replace('VAR' + i, matches[i]);
            }
        }

        //redirect to the appropriate path and append whatever query string was passed in originally
        window.location = window.location.origin + redirectPath + matches[matches.length - 1];
        throw new Error('This is not an error. This is just to abort javascript');
    }
}

//our app module with dependencies for normal development
angular.module('PatientApp', ["ui.router","ngResource","ngMessages","ngSanitize","ngAnimate","pascalprecht.translate","ngResize","720kb.datepicker","smoothScroll","ng.shims.placeholder","newrelic-timing","Templates-Phase1","Templates-Phase2"] );