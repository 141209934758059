angular.module('PatientApp').factory('AuthService', function($q, $log, $state, $rootScope, $filter, PatientUsersService){

    // When the app let's us know an unauthenticated response
    // was made we need to clear out the user session
    $rootScope.$on('unauthedAccessResponse', function(){
        _authService.unauthenticateUser();
    }); 


    var _authService = {
        // Some site areas are restricted to
        // users only. This is our check to make
        // sure this user is available to us
        hasAccess: function(){
            // we expect not having a user to return a null
            // but we are going to apply a more broader falsy check
            // than simply on null
            return !!PatientUsersService.getCurrentUser();
        },

        // Try to get user, remember state
        // if we have a user, continue but if
        // we don't, prompt for login the redirect back to state
        requireAuthedUser: function(loginOptions){

            var patientUser = PatientUsersService.getCurrentUser(),

                // this deferred used to answer the requireAuthedUser
                // requester's intent
                deferred = $q.defer();
            
            if(patientUser){
                deferred.resolve(patientUser);
            } else {
                // show login screen
                // LoginCtrl listens for this
                // and will handle resolving our 
                // deferred with a logged in user or
                // will reject if they never officially 
                // logged in or register
                $rootScope.$emit('login:showPrompt', deferred, loginOptions);
            }

            return deferred.promise;
        },


        // Send logout server request.
        // If we are unable to log the user out,
        // we will clear our cookie for the server
        unauthenticateUser : function(showModal){
            if (showModal) {
                $rootScope.$emit('simpleModal:showPrompt', {
                    header: $filter('translate')('dialogs.successfulLogout'),
                    subcontent: $filter('translate')('dialogs.thanksAndComeBack'),
                    intent: 'success',
                    autoExpire: true
                });
            }

            return PatientUsersService.logout()
                    .catch(function(){
                        $log.error('Unable to log the user out on server');

                        //TODO: DEV-5514 clear local cookie of session to mitigate issues
                    })
                    .then(function(){
                        // let the other parts of the application know to clear their data
                        $rootScope.$emit('data:clear');

                        // from hitting the logout button we should direct the
                        // state back to the home page if the current state requires login
                        //  TODO: move this logic to the logout controller
                        if (!$state.current || !$state.current.data || !$state.current.data.authNotRequired) {
                            $state.go('app.default');
                        }
                    });
        },


        // This is the mechanism which is used to poll
        // or check for a currently authenticated user 
        // as far as what the server knows about.
        fetchAuthenticatedUser : function(){
            return PatientUsersService.fetchUser();
        }
    };

    return _authService;
});
