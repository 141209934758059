angular.module('PatientApp').directive('modalNoClose', function(OverlayService){

    return {

        // only <modal-no-close>...</modal-no-close> allowed
        restrict : 'E',

        scope: {
            show: '=',
            whenClose: '&'
        },

        // replace the outerHTML with our generated content
        replace: true,

        // insert the innerHTML into our template wrapper
        transclude: true,

        keyboard: false,

        link: function($scope){
            var _showing = false,
                _overlayKey = null,

                _open = function(){
                    _showing = true;
                    _overlayKey = OverlayService.show(_overlayKey);
                };

            $scope.hideModal = function(){
                $scope.show = false;

                if(_overlayKey){
                    OverlayService.hide(_overlayKey);
                }
                $scope.whenClose();
                _showing = false;
            };

            // Handle toggling visibility areas
            if($scope.show){
                _open();
            }

            $scope.$watch('show', function (showingChange) {

                if(showingChange){
                    _open();
                }else if(!showingChange && _showing){
                    $scope.hideModal();
                }
            });

            $scope.$on('$destroy', function() {
                if(_showing){
                    $scope.hideModal();
                }
            });

        },

        template: '<div class="modal no-close" ng-class="{\'modal-visible\': show}"><div class="modal-content-container"><ng-transclude></ng-transclude></div> </div>'
    };

}).directive('modalNoCloseActions', function(){

    return {

        // only <modal-no-close>...</modal-no-close> allowed
        restrict : 'E',

        scope: {},

        // replace the outerHTML with our generated content
        replace: true,

        // insert the innerHTML into our template wrapper
        transclude: true,

        template: '<div class="modal-actions"><ng-transclude></ng-transclude></div>'
    };

});
