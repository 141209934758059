angular.module('PatientApp').directive('pastdate', function($timeout, $filter, _, KeyboardService){

    var _MAX_FORMATTED_LENGTH = 10,
        _currentCentury = Math.floor(new Date().getFullYear() / 100) * 100,
        _getFormattedString = function(inputStr, blur, backspace, position){
            var noFormatting = inputStr.replace(/(\s|\D)/g, ''),
                i = 0,
                curChar = '',
                output = '',
                len = 0;

            while(i < noFormatting.length){
                curChar = _.parseInt(noFormatting[i]);
                i++;

                if(isNaN(curChar)){
                    return;
                }

                // MONTH LOGIC
                if(len === 0){
                    // padded zero or 1 based 
                    if(_.includes([ 0, 1 ], curChar)){
                        output += curChar;
                    }else {
                        output += (position === 0 && backspace ? '' : '0') + curChar + '/';
                    }
                } else if ( len === 1 ){
                    if(_.includes([ 0, 1, 2 ], curChar)){
                        // account for 00 month entry
                        if(curChar !== 0 || (curChar === 0 && output[0] !== '0')){
                            // account for pressing back when we have only the slash
                            output += curChar + ( position === 3 && backspace ? '' : '/');
                        }

                    }else if(output[0] === '0') {
                        output += curChar + (position === 3 && backspace ? '' : '/');
                    }else {
                        // for inputting something that should roll over into the year
                        // like 15 should become 01 / 5
                        output = '0' + output + '/' + curChar;
                    }
                }

                // DAY LOGIC
                if(len === 3){
                    // months only start with 0, 1, 2 or 3
                    if(_.includes([ 0, 1, 2, 3 ], curChar)){
                        output += curChar;
                    }else {
                        output += (position === 3 && backspace ? '' : '0') + curChar + '/';
                    }
                } else if ( len === 4 ){
                    //any number is good here
                    output += curChar + (position === 6 && backspace ? '' : '/');
                }

                // YEAR LOGIC
                if(len >= 6){
                    output += curChar;
                }
                
                len = output.length;
            }

            if ((output.indexOf('/', output.length-1) !== -1) && backspace) {
                output = output.slice(0, output.length-1); //remove trailing slash when user hits backspace
            }

            return output.slice(0, _MAX_FORMATTED_LENGTH);
        },
        _parseViewValueParts = function(viewValue) {
            var today = new Date(),
                day,
                year, 
                yearFragment,
                month;

            if (viewValue) {
                viewValue = viewValue.replace(/(\s|\D)/g, '');

                if(viewValue.length === 6 || viewValue.length === 8) {
                    month = _.parseInt(viewValue.slice(0, 2));
                    day = _.parseInt(viewValue.slice(2, 4));
                    yearFragment = viewValue.slice(4);

                    if (yearFragment.length == 4) {
                        year = _.parseInt(yearFragment);
                    } else if (yearFragment.length == 2) {
                        //assume two-digit years refer to the current century.  if it's in the future, fall back to previous century. also, 
                        //month is zero-indexed
                        year = _currentCentury + _.parseInt(yearFragment);
                        if (new Date(year, month-1, day) > today) { 
                            year = (_currentCentury - 100) + _.parseInt(yearFragment);
                        }
                    }

                    return [year, month-1, day];
                }
            }

            return null;
        },
        _parseViewValue = function(viewValue) {
            var parts = _parseViewValueParts(viewValue);
            return parts ? new Date(parts[0], parts[1], parts[2]) : null;
        },
        _daysInMonth = function(m, y) { 
            // m is 0 indexed: 0-11
            switch (m) {
                case 1 :
                    return (y % 4 === 0 && y % 100) || y % 400 === 0 ? 29 : 28;
                case 8 : case 3 : case 5 : case 10 :
                    return 30;
                default :
                    return 31;
            }
        },
        _isValidDay = function(y, m, d) {
            return m >= 0 && m < 12 && d > 0 && d <= _daysInMonth(m, y);
        };

    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ngModel){

            var runValidators = function() {
                var val = (ngModel.$viewValue || '').toString(),
                    birthdateParts = _parseViewValueParts(val),
                    birthdate = _parseViewValue(val),
                    today = new Date(),
                    futureDate = birthdate > today,
                    requireAdult = attrs.hasOwnProperty('isadult'),
                    eighteenYearsAgo,
                    isAdult;

                ngModel.$setValidity('incomplete', (val.length === 0 || val.length === 8 || val.length === 10)); //if we were able to parse, we should be good here
                ngModel.$setValidity('required', !!birthdate); //if we were able to parse, we should be good here
                if (birthdateParts) {
                    ngModel.$setValidity('invalidDate', _isValidDay(birthdateParts[0], birthdateParts[1], birthdateParts[2])); //date doesn't exist
                }
                ngModel.$setValidity('futureDate', !futureDate);

                if (requireAdult) {
                    eighteenYearsAgo = new Date();
                    eighteenYearsAgo.setFullYear(today.getFullYear() - 18);
                    isAdult = birthdate <= eighteenYearsAgo;
                    ngModel.$setValidity('adultRequired', isAdult);
                }
            };

            // don't allow more than the formatted length
            el.attr('maxlength', _MAX_FORMATTED_LENGTH);

            el.on('paste', function(){
                $timeout(function(){
                    el.val(_getFormattedString(el.val()));
                }, 50);
            });
    
            // only accept numbers
            el.on('keydown', function(event){
                var key = KeyboardService.parseEvent(event);
                // we have no reason to use shift currently, so instead of 
                // trying to filter those out on keyup, prevent them now
                if((!key.isNumber() && !key.isEditorKey() && !key.hasModifier()) || key.is('shift')){
                    event.preventDefault();
                }
            });

            el.on('keyup', function(event){
                var original = el.val(),
                    start = this.selectionStart,
                    end = this.selectionEnd,
                    backspacing = event.keyCode === 8,
                    atEnd = this.selectionStart === el.val().length,
                    newVal = _getFormattedString(original, false, backspacing, start);

                if(original !== newVal){
                    ngModel.$setViewValue(newVal);
                    el.val(newVal);
                    runValidators();
                }

                if (atEnd) {
                    this.setSelectionRange(newVal.length, newVal.length);
                } else if(backspacing && start === 4) {
                    // if we backspace the slash section, we need to account for change in position
                    this.setSelectionRange(2, 2);
                } else {
                    this.setSelectionRange(start, end);
                }
            });

            el.on('blur', function(){
                var original = el.val(),
                    updated = _getFormattedString(original, true);

                // only if we changed the value after user left should we call apply
                if(original !== updated){
                    ngModel.$setViewValue(updated);
                    el.val(updated);
                    runValidators();
                }
            });
            
            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function () {
                // hack for when user goes back to a page with a filled out date where angular decides to update the
                // $viewValue and the el.val() to the $modelValue.toString()
                // we convert the date back to its intended format and update the two values
                if (ngModel.$viewValue && ngModel.$viewValue.length > 20 && ngModel.$modelValue instanceof Date) {
                    var date = $filter('date')(ngModel.$modelValue, 'MM/dd/yyyy');
                    el.val(date);
                    ngModel.$viewValue = date;
                }
                runValidators();
            });

            ngModel.$parsers.push(function(viewValue){
                return _parseViewValue(viewValue.replace(/(\s|\D)/g, '').slice(0, 8));
            });
        }
    };
});
