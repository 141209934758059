
// validEmail directive to apply validators to inputs
// that need email verification with the server

angular.module('PatientApp').directive('validEmail', function($q, $interval, PatientUsersService){
    
    // far less lax rules compared to the default angular regex
    var EMAIL_REGEXP = /^[_a-z0-9+\-']+(\.[_a-z0-9+\-']+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
    
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            onEmailCheckFinished: '='
        },
        link: function (scope, element, attrs, ngModel) {
            
            // this will overwrite the default Angular email validator
            ngModel.$validators.email = function(modelValue) {
                return ngModel.$isEmpty(modelValue) || EMAIL_REGEXP.test(modelValue.toLowerCase());
            };
            
            // Check if the email-available and/or email-registered directives are set
            var emailAvailable = angular.isDefined(attrs.emailAvailable),
                emailRegistered = angular.isDefined(attrs.emailRegistered);
            
            // only worry about server-side validations of one of the above directives are set
            if (emailAvailable || emailRegistered) {
                element.on('keydown', function() {
                    scope.needsValidation = true;
                });

                ngModel.$asyncValidators.emailAvailable = function (modelValue, viewValue) {
                    
                    var deferred = $q.defer(),
                        currentValue = modelValue || viewValue;
                    
                    // make sure we have an email
                    if(!currentValue){
                        scope.needsValidation = false;
                        deferred.resolve();
                    } else {

                        if(emailAvailable && PatientUsersService.getCurrentUser() && PatientUsersService.getCurrentUser().email === (currentValue || '').trim()){
                            deferred.resolve();
                        }

                        PatientUsersService.emailAvailable(currentValue)
                            .then(function () {
                                // If this email is available, then it is not registered
                                if (emailRegistered) {
                                    deferred.reject();
                                } else {
                                    deferred.resolve();
                                }
                            })
                            .catch(function(){
                                // If it is not available, then it must be registered
                                if (emailRegistered) {
                                    deferred.resolve();
                                } else {
                                    deferred.reject();
                                }
                            }).finally(function() {
                                scope.needsValidation = false;
                            });
                    }
                    

                    deferred.promise.finally(function(){
                        
                        // let anyone who cares, know we finished validating
                        // we have to do this in a timeout style function because
                        // we need to call the callback once the check is done
                        // AND the $pending state is changed and that state
                        // changes after the promise control flow, so we must take
                        // it out of that flow, then execute
                        $interval(function(){
                            if(scope.onEmailCheckFinished){
                                scope.onEmailCheckFinished();
                            }
                        }, 0, 1);
                        
                    });

                    return deferred.promise;
                };
            }
        }
    };
});