
angular.module('PatientApp')
    .run(function ($rootScope, $state, $location, $window, Config) {
        if (Config.env !== 'test' && $location.absUrl().split('#')[0] !== Config.rootUrl) {
            $window.location.href =  Config.rootUrl + $window.location.hash;
        }

        $state.previous = {};

        // The $stateRouter does not have a good way to handle knowing
        // the previous state so we will start tracking this here
        $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
            $state.previous = fromState;
        });
    })
    .config(function($stateProvider, $urlRouterProvider) {

        // We are using the ui-router to do state based
        // routing instead of direct url based routing
        // Check out the docs here: https://github.com/angular-ui/ui-router/wiki


        // registering states
        // Note: anything without a . in the name
        // is a top level state. So it will change
        // content in the #main-content ui-view. If
        // it does have a . in the name, it will be looking
        // for the nested ui-view inside of that state hierarchy
        $stateProvider

            // These are our default view pieces
            // anything that doesn't want these has to
            // opt out and specify the replacement piece
            .state('app', {
                abstract: true,
                views: {
                    '@' : {
                        templateUrl: 'templates/layout/standard.tpl.html'
                    },
                    'header@app': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },

                    'footer@app': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    },

                    'left-column@app': {
                        template: ''
                    },

                    'left-column-persist@app': {
                        template: ''
                    }
                }
            })

            // default is our landing page for new users
            .state('app.default', {
                url: '/',
                data: {
                    authNotRequired: true
                },
                views: {
                    // different layout entirely for this page
                    '@':{
                        templateUrl: 'templates/layout/landing.tpl.html'
                    },
                    'header@app.default': {
                        template: ''
                    },
                    'content@app.default': {
                        templateUrl: 'templates/default.tpl.html',
                        controller: 'DefaultCtrl'
                    },
                    'footer@app.default': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    }
                }
            })

            // clicking the link from the email
            .state('app.default.forgotPassword', {
                url: 'forgot/:tokenId'
            })

            .state('app.default.feedback', {
                url: 'feedback/:token?:score'
            })

            // transworld url
            .state('app.default.transworld', {
                url: 'tw',
                onEnter: ['$rootScope', function($rootScope){
                    $rootScope.transworld = true;
                }]
            })

            // cci find build redirection url
            .state('app.default.cci', {
                url: 'cci/:pubId'
            })

            // backdoor logins redirection endpoint
            .state('app.default.bd', {
                url: 'bd/:token'
            })

            // ebill redirection form link in new bill emails
            .state('app.default.ebill', {
                url: 'ebill/:shc?verified&goto'
            })

            // firstBill redirection from link in new bill emails/texts
            .state('app.default.firstBill', {
                url: 'firstBill/:token',
                data: {
                    authNotRequired: true
                }
            })

            // verify email link in welcome emails
            .state('app.default.verifyEmail', {
                url: 'verifyEmail/:token/:patientUserId?email'
            })

            // verify phone link in texts sent on register
            .state('app.default.verifyPhone', {
                url: 'verifyPhone/:code/:patientUserId/:phoneNumber'
            })

            // This state points to the logged in users
            // landing dashboard. It will contain things like the
            // providers listing, etc. But we keep it as a dashboard
            // so as to not limit or confuse its uses/capabilities
            .state('app.dashboard', {
                url: '/dashboard?trigger',
                views: {
                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole layout
                        controller: 'DashboardCtrl'
                    },
                    'content@app.dashboard': {
                        templateUrl: 'templates/dashboard/dashboard-content.tpl.html'
                    },

                    'header@app.dashboard': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },

                    'left-column@app.dashboard': {
                        templateUrl: 'templates/dashboard/dashboard-left-column.tpl.html'
                    },

                    'left-column-persist@app.dashboard': {
                        templateUrl: 'templates/dashboard/dashboard-left-column-persist.tpl.html'
                    },

                    'footer@app.dashboard': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    }
                }
            })

            .state('app.dashboard.provider', {
                abstract:true
            })
            .state('app.dashboard.provider.details', {
                url: '/provider/:id',
                views: {
                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole layout
                        controller: 'ProviderDetailsCtrl'
                    },
                    'content@app.dashboard.provider.details': {
                        templateUrl: 'templates/dashboard/dashboard-provider-details-content.tpl.html'
                    },
                    'left-column-persist@app.dashboard.provider.details': {
                        templateUrl: 'templates/dashboard/dashboard-provider-details-left-column-persist.tpl.html'
                    },
                    'left-column@app.dashboard.provider.details': {
                        templateUrl: 'templates/dashboard/dashboard-provider-details-left-column.tpl.html'
                    },
                    'header@app.dashboard.provider.details': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },
                    'footer@app.dashboard.provider.details': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    }
                }
            })

            .state('app.dashboard.provider.details.receipt', {
                url: '/receipt/{receiptId:int}',

                views: {
                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole layout
                        controller: 'ReceiptCtrl'
                    },
                    'content@app.dashboard.provider.details.receipt': {
                        templateUrl: 'templates/receipt/receipt-content.tpl.html'
                    },
                    'left-column-persist@app.dashboard.provider.details.receipt': {
                        templateUrl: 'templates/receipt/receipt-left-column-persist.tpl.html'
                    },
                    'left-column@app.dashboard.provider.details.receipt': {
                        templateUrl: 'templates/receipt/receipt-left-column.tpl.html'
                    },
                    'header@app.dashboard.provider.details.receipt': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },
                    'footer@app.dashboard.provider.details.receipt': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    }
                }
            })

            .state('app.dashboard.provider.details.plan-receipt', {
                url: '/plan/{planId:int}',

                views: {
                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole layout
                        controller: 'PlanReceiptCtrl'
                    },
                    'content@app.dashboard.provider.details.plan-receipt': {
                        templateUrl: 'templates/plan-receipt/plan-receipt-content.tpl.html'
                    },
                    'left-column-persist@app.dashboard.provider.details.plan-receipt': {
                        templateUrl: 'templates/plan-receipt/plan-receipt-left-column-persist.tpl.html'
                    },
                    'left-column@app.dashboard.provider.details.plan-receipt': {
                        templateUrl: 'templates/plan-receipt/plan-receipt-left-column.tpl.html'
                    },
                    'header@app.dashboard.provider.details.plan-receipt': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },
                    'footer@app.dashboard.provider.details.plan-receipt': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    }
                }
            })


            .state('app.messages', {
                url: '/messages',
                params: {
                    'autofill': false,
                    'data': {}
                },
                views: {
                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole layout
                        controller: 'MessagesCtrl'
                    },
                    'content@app.messages': {
                        templateUrl: 'templates/messages/messages-content.tpl.html'
                    },
                    'left-column-persist@app.messages': {
                        templateUrl: 'templates/messages/messages-left-column-persist.tpl.html'
                    },
                    'left-column@app.messages': {
                        templateUrl: 'templates/messages/messages-left-column.tpl.html'
                    },
                    'header@app.messages': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },
                    'footer@app.messages': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    }
                }
            })

            .state('app.messages.details', {
                url: '/:threadId',
                data: {
                    fillContentContainer : true
                },
                views: {
                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole layout
                        controller: 'MessageDetailsCtrl'
                    },
                    'content@app.messages.details': {
                        templateUrl: 'templates/messages/message-details-content.tpl.html'
                    },
                    'left-column-persist@app.messages.details': {
                        templateUrl: 'templates/messages/message-details-left-column-persist.tpl.html'
                    },
                    'left-column@app.messages.details': {
                        templateUrl: 'templates/messages/message-details-left-column.tpl.html'
                    },
                    'header@app.messages.details': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },
                    'footer@app.messages.details': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    }
                }
            })

            .state('app.settings', {
                url: '/settings',
                views: {
                    '@' : {
                        templateUrl: 'templates/layout/standard.tpl.html',
                        controller: 'SettingsCtrl'
                    },

                    'content@app.settings': {
                        templateUrl: 'templates/settings/contact.tpl.html'
                    },

                    'header@app.settings': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },

                    'footer@app.settings': {
                        templateUrl: 'templates/footer/standard.tpl.html'
                    },

                    'left-column@app.settings': {
                        templateUrl: 'templates/settings/settings-left-column.tpl.html'
                    },

                    'left-column-persist@app.settings': {
                        templateUrl: 'templates/settings/settings-left-column-persist.tpl.html'
                    }
                }
            })

            .state('app.settings.savedPaymentMethods', {
                url: '/savedPaymentMethods',
                views: {
                    'content@app.settings': {
                        templateUrl: 'templates/settings/saved-payment.tpl.html'
                    }
                }
            })
            .state('app.settings.accountManagement', {
                url: '/accountManagement',
                views: {
                    'content@app.settings': {
                        templateUrl: 'templates/settings/account-management.tpl.html'
                    }
                }
            })

            .state('app.verify', {
                url: '/verify',
                data: {
                    fillContentContainer : true,
                    authNotRequired: true
                },
                views: {

                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole layout
                        controller: 'VerifyCtrl'
                    },
                    'content@app.verify': {
                        templateUrl: 'templates/verify/verify-content.tpl.html'
                    },

                    'header@app.verify': {
                        template: ''
                    },

                    'left-column@app.verify': {
                        templateUrl: 'templates/verify/verify-left-column.tpl.html'
                    },

                    'left-column-persist@app.verify': {
                        templateUrl: 'templates/verify/verify-left-column-persist.tpl.html'
                    },

                    'footer@app.verify': {
                        templateUrl: 'templates/footer/minimal.tpl.html'
                    }
                }
            })

            .state('app.emailVerified', {
                url: '/emailVerified',
                views: {

                    '@': {
                        templateUrl: 'templates/full-page-notification/full-page-notification-main.tpl.html',
                        controller: 'FullPageNotificationCtrl'
                    },
                    'content@app.emailVerified': {
                        templateUrl: 'templates/full-page-notification/full-page-notification-content.tpl.html'
                    },

                    'header@app.emailVerified': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },

                    'footer@app.emailVerified': {
                        templateUrl: 'templates/footer/minimal.tpl.html'
                    }
                }
            })

            .state('app.phoneVerified', {
                url: '/phoneVerified',
                data: {
                    authNotRequired: true
                },
                views: {

                    '@': {
                        templateUrl: 'templates/full-page-notification/full-page-notification-main.tpl.html',
                        controller: 'FullPageNotificationCtrl'
                    },
                    'content@app.phoneVerified': {
                        templateUrl: 'templates/full-page-notification/full-page-notification-content.tpl.html'
                    },

                    'header@app.phoneVerified': {
                        templateUrl: 'templates/header/standard.tpl.html',
                        controller: 'HeaderCtrl'
                    },

                    'footer@app.phoneVerified': {
                        templateUrl: 'templates/footer/minimal.tpl.html'
                    }
                }
            })


            // -----------------------
            // Payment Flow State Management
            // -----------------------

            // container for the workflow
            // not directly linkable
            .state('app.payment', {
                abstract: true,
                data: {
                    fillContentContainer : true
                },
                views: {

                    // for different sections of the app it seems we will need to
                    // specify the layout per each large section of the app
                    // then we link all of the sub views off of that state
                    // this allows us to do state based controller and data
                    // inheritance

                    '@': {
                        templateUrl: 'templates/layout/standard.tpl.html',

                        // this controller needs to wrap the whole payment flow
                        controller: 'PaymentFlowCtrl'
                    },

                    'content@app.payment': {
                        templateUrl: 'templates/payment/payment-content.tpl.html'
                    },

                    'header@app.payment': {
                        templateUrl: 'templates/header/payment.tpl.html'
                    },

                    'left-column@app.payment': {
                        templateUrl: 'templates/payment/payment-left-column.tpl.html'
                    },

                    'left-column-persist@app.payment': {
                        templateUrl: 'templates/payment/payment-left-column-persist.tpl.html'
                    },

                    'footer@app.payment': {
                        templateUrl: 'templates/footer/minimal.tpl.html'
                    }
                }
            })


            // the entry point state that should be
            // hit when a payment for a bill starts
            .state('app.payment.start', {
                url: '/payment',

                // our payment work flow will start with the
                // amount -- inject it into the workbench subview to start
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/amount.tpl.html'
                    }
                },
                /**
                 * This attribute allows for parameters to be passed to a state invoker. In this 
                 * case we're passing the secureCode of the bill as a string, and a callback
                 * that will be called once the state is loaded (see onEnter below).  This callback
                 * can be used to resolve loading functions from the previous state.
                 */
                params: {
                    secureCode: null,
                    callback: null,
                    goto: null
                },
                /**
                 * 'resolve' specifies a list of promises or functions that will be resolved before
                 * the state is loaded. Here, we load the PatientUsersService because we need it in
                 * the next call. Then, we register a function 'saveMethods' that calls 
                 * 'fetchSavedMethods' and loads the response before we load the PaymentFlowCtrl.  
                 * This allows us to always have the saved payment forms for use in the payment 
                 * flow.
                 */
                resolve: {
                    PatientUsersService: 'PatientUsersService',
                    BillsService: 'BillsService',
                    ExperimentService: 'ExperimentService',
                    savedMethods: function(PatientUsersService){
                        return PatientUsersService.fetchSavedMethods();
                    },
                    bill: function(BillsService, $stateParams){
                        if ($stateParams.secureCode != null) {
                            return BillsService.getAccountBill($stateParams.secureCode);
                        } else {
                            return BillsService.getCurrentBill();
                        }
                    },
                    experiments: function(BillsService, ExperimentService) {
                        var bill = BillsService.getCurrentBill();
                        if (bill && bill.hasFinancingOption() && bill.getProviderDetails().allFinancingPartners.indexOf('Curae') > -1) {
                            return ExperimentService.waitForExperimentsToLoad('app.payment.start');
                        }
                    }
                },
                onEnter: function($stateParams) {
                    if ($stateParams.callback != null && typeof $stateParams.callback === 'function') {
                        $stateParams.callback();
                    }
                }
            })
            .state('app.payment.date', {
                url: '/payment/date',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/date.tpl.html'
                    }
                }
            })
            .state('app.payment.method', {
                url: '/payment/method',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/method.tpl.html'
                    }
                }
            })
            .state('app.payment.billing', {
                url: '/payment/billing',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/billing.tpl.html'
                    }
                }
            })
            .state('app.payment.confirm', {
                url: '/payment/confirm',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/confirm.tpl.html'
                    }
                }
            })
            .state('app.payment.financing', {
                abstract:true
            })
            .state('app.payment.financing.application', {
                url: '/payment/financing/application',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/financing/application.tpl.html'
                    }
                }
            })
            .state('app.payment.financing.offers', {
                url: '/payment/financing/offers',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/financing/offers.tpl.html'
                    }
                }
            })
            .state('app.payment.financing.summary', {
                url: '/payment/financing/summary',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/financing/summary.tpl.html'
                    }
                }
            })
            .state('app.payment.financing.confirm', {
                url: '/payment/financing/confirm',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/financing/confirm.tpl.html'
                    }
                }
            })
            .state('app.payment.financing.existingAccount', {
                url: '/payment/financing/existingAccount',
                views: {
                    'workbench@app.payment': {
                        templateUrl: 'templates/payment/financing/existingAccount.tpl.html'
                    }
                }
            });

        // location for any unmatched url
        $urlRouterProvider.otherwise('/');
    });
