angular.module('PatientApp').controller('AppRootCtrl', function ($scope, $log, $rootScope, $state, $translate, $timeout, $window, $q, _, NavService, AuthService,
                                                                 OverlayService, ContextHelperService, BillsService, smoothScroll, Url2ObjService, ExperimentService,
                                                                 PatientUsersService, ErrorsService, Config, AutoLogoutService, LoadingIndicatorService, Compass) {


    var _authChecked = false,
        _returnState = null,
        _returnStateParams = {},
        _appStateSettled = false,
        _LogTransitionErrors = function (event, unfoundState, fromState) {
            $log.error('Unabled find state:', unfoundState.to);
            $log.error('\tAttempted with params:', unfoundState.toParams);
            $log.error('\tUnreachable state requested from:', fromState.url);
        },
        _handleSuccessfulGetAccountBill = function() {
            // we got the bill!
            ExperimentService.sendSuccess('financingInEbill');
            if ($state.params.goto === 'plans' ||
                $state.params.goto === 'financing') {
                $state.go('app.payment.start', $state.params);
            } else {
                $state.go('app.verify');
            }
        };

    $translate('landing.title').then(function (translation) {
        $scope.title = translation;
    });

    // ---------------------
    // Resources needed to make up the UI
    // ---------------------
    $scope.nav = NavService;
    $scope.overlay = OverlayService;
    $scope.auth = AuthService;
    $scope.contextHelper = ContextHelperService;
    $scope.version = Config.version;
    $scope.releaseFolder = Config.releaseFolder;
    $scope.loadingIndicator = LoadingIndicatorService;
    $scope.currentYear = (new Date()).getFullYear();

    // ---------------------
    // Patientco Values rotation
    // ---------------------
    $scope.values = {
        number: _.random(1, 6)
    };


    // ---------------------
    // Routing checks
    //  Events can be found: https://github.com/angular-ui/ui-router/wiki#state-change-events
    // ---------------------
    $scope.$state = $state;

    $rootScope.showSwitchLanguage = Config.languages && Config.languages.length > 1;

    $rootScope.switchLanguage = function(){
        if (localStorage.getItem('currentLanguage') !== 'en') {
            localStorage.setItem('currentLanguage', 'en');
            $window.location.reload();
        } else {
            localStorage.setItem('currentLanguage', 'es');
            $window.location.reload();
        }
    };

    // Log errors for missing states. These are like 404 pages
    // but we don't get the luxury of the error being logged on the server
    // NewRelic and other observing plugins should handle notification of this
    // error to the server or analytics engines
    $rootScope.$on('$stateNotFound', _LogTransitionErrors);
    $rootScope.$on('$stateChangeError', _LogTransitionErrors);

    $rootScope.$on('$stateChangeStart', function (event, toState, toParams) {

        // when we change a state, cycle the values
        $scope.values.number = _.random(1, 6);

        // route based global ui change
        // this helps us decide in nested states
        // if we should have the full white background container
        // or allow the content to fill itself
        $scope.fillContent = toState.data && toState.data.fillContentContainer;


        // we want our authentication to fail secured. If you want
        // to allow state to be accessible without an authed user, apply the
        // correct authNotRequired data field
        if (!(toState.data && toState.data.authNotRequired) && !AuthService.hasAccess() && toState.name !== 'app.default') {

            $log.info('Currently the user is not authed and we are going to a state that requires access', toState.name);

            if (!_authChecked) {
                _returnState = toState.name;
                _returnStateParams = toParams;
            }

            event.preventDefault();

            $state.go('app.default');
        }

        NavService.close();

        // handle scrolling on page transitioning
        if (toState && !toState.noScroll) {
            smoothScroll(document.body, {duration: 400});
        }


        // we have to handle initialization of a cci user here
        // so we can redirect them to the correct location when they
        // start this process
        if (toState.name === 'app.default.cci') {

            // since we don't have access to params we need to get the
            // pubId oursevles
            event.preventDefault();

            BillsService.getCCIBill(Url2ObjService.parse($window.location.hash.slice(2), toState.url).pubId)
                .then(function () {
                    $state.go('app.verify');
                })
                .catch(function () {
                    $state.go('app.default');
                });
        }
    });


    // handle google analytics updates
    $rootScope.$on('$stateChangeSuccess', function (event, toState) {
        if (window._gaq && _appStateSettled) {
            window._gaq.push(['_trackPageview', toState.url]);
        }
    });

    // ---------------------
    // Initializing the App State
    // ---------------------


    // On load we need to check to see if we have
    // a currently logged in user and bring it into memory
    // TODO: figure out what we are going to do with initial load
    // and this returning asynchronously
    AuthService.fetchAuthenticatedUser().then(function () {

        var bdToken,
            ebillShc,
            unverifedEbillScode,
            verifyToken,
            verifyPatientUserId,
            verifyEmail,
            patientUser,
            verifyCode,
            verifyPhone;

        $log.log('Intial Auth fetch returned, this user is already authenticated', _returnState ? 'returning to ' + _returnState : '');


        // TODO: DEV-5520 we should investigate handling this
        // more elegantly
        if ($state.is('app.default')) {

            $state.go(_returnState ? _returnState : 'app.dashboard', _returnStateParams);


            // reset thes values
            _returnState = null;
            _returnStateParams = null;
        } else if ($state.is('app.default.bd') && $state.params.token) {

            bdToken = $state.params.token;

            // backdoor login requested but we currently have a user
            // that is signed in
            AuthService.unauthenticateUser().then(function () {
                return PatientUsersService.bdLogin(bdToken);
            }).then(function () {
                $state.go('app.dashboard');
            }).catch(function () {
                $state.go('app.default');
            });

        } else if ($state.is('app.default.ebill')) {

            ebillShc = $state.params.shc;
            unverifedEbillScode = $state.params.verified === '0' ? ebillShc : null;

            // Attempt to find the bill associated with this user
            BillsService.getAccountBill(ebillShc).then(function () {
                _handleSuccessfulGetAccountBill();
            }).catch(function (resp) {

                // So we have attempted to load this ebill from the
                // currently logged in users account. We are going to make
                // the assumption that the securecode not found error is because
                // it is not with the user, and not that it is invalid. So when
                // this happens, we will log the user out and attempt to log in the
                // user who the link is for.
                // The compromise here is that, if a user is logged in and changes
                // the shc to an invalid one, it will log them out.

                if (ErrorsService.resolve(resp) === ErrorsService.errors.SECURECODE_NOT_FOUND) {
                    $log.error('We were unable to load the ebill for the signed in users account.');

                    // log user out and ask for a new user
                    return AuthService.unauthenticateUser().then(function () {

                        $log.info('Logged user now get ebill user');

                        return AuthService.requireAuthedUser({
                            openSection: 'login'
                        });
                    }).then(function () {
                        // try one last time to get the bill for the
                        // user
                        return BillsService.getAccountBill(ebillShc);
                    }).then(function () {
                        _handleSuccessfulGetAccountBill();
                    });
                }

                // we failed to do a good bill lookup and can't mitigate
                return $q.reject();

            }).catch(function (resp) {

                if (ErrorsService.resolve(resp) === ErrorsService.errors.SECURECODE_NOT_FOUND) {

                    // we tried all means by which to find bill by scode
                    // we are adding the check and verify flow here as
                    // to not undermine the above logic for why we might
                    // need to log the user out to verify it was the right
                    // logged in user state for the browser being used
                    if (unverifedEbillScode) {
                        $state.go('app.dashboard');
                        $rootScope.$broadcast('accountVerification:prompt:proceedToPay', unverifedEbillScode);
                    }

                } else {
                    $log.error('Unable to get the ebill');
                    $state.go('app.dashboard');
                }
            });

        } else if ($state.is('app.default.firstBill')) {
            var firstBillToken = $state.params.token;

            BillsService.findAuthOptions(firstBillToken).then(function (resp) {
                $rootScope.$emit('firstBillVerification:prompt', {
                    'options': resp,
                    'token': firstBillToken
                });
            }).catch(function () {
                Compass.capture.failure('first-bill', 'token', '');
                $state.go('app.dashboard');
            });

        } else if ($state.is('app.default.verifyEmail')) {
            verifyToken = $state.params.token;
            verifyPatientUserId = parseInt($state.params.patientUserId, 10);
            verifyEmail = $state.params.email;
            patientUser = PatientUsersService.getCurrentUser();
            if (patientUser.patientUserId !== verifyPatientUserId) {
                // User is trying to verify an account that differs from the logged in user
                // Let's log the user out and try again after login
                AuthService.unauthenticateUser().then(function () {
                    return AuthService.requireAuthedUser({
                        openSection: 'login',
                        prefillEmail: verifyEmail
                    });
                }).then(function () {
                    // try one last time to verify
                    patientUser = PatientUsersService.getCurrentUser();
                    if (patientUser.patientUserId === verifyPatientUserId) {
                        PatientUsersService.verifyEmail(verifyToken, verifyPatientUserId)
                            .then(function () {
                                $log.info('Email has been verified!');
                                $state.go('app.emailVerified');
                            }).catch(function () {
                                // Didn't work, go home
                                $state.go('app.dashboard');
                            });
                    } else {
                        // I give up, go home
                        $state.go('app.dashboard');
                    }
                });
            } else {
                PatientUsersService.verifyEmail(verifyToken, verifyPatientUserId)
                    .then(function () {
                        $log.info('Email has been verified!');
                        $state.go('app.emailVerified');
                    }).catch(function () {
                        $log.error('Error verifying email');
                        $state.go('app.dashboard');
                    });
            }
        } else if ($state.is('app.default.verifyPhone')) {
            verifyCode = $state.params.code;
            verifyPatientUserId = parseInt($state.params.patientUserId, 10);
            verifyPhone = $state.params.phoneNumber;
            PatientUsersService.verifyPhone(verifyCode, verifyPatientUserId, verifyPhone)
                .then(function () {
                    $log.info('Phone number has been verified!');
                    $state.go('app.phoneVerified');
                }).catch(function () {
                    $log.error('Error verifying phone number');
                    $state.go('app.dashboard');
                });
        }
    })
        .catch(function () {

            var ebillShc,
                unverifedEbillScode,
                verifyToken,
                verifyPatientUserId,
                verifyEmail,
                patientUser,
                verifyCode,
                verifyPhone;

            // check to see if the user is trying to
            // backdo
            if ($state.is('app.default.bd') && $state.params.token) {
                // backdoor login requested
                PatientUsersService.bdLogin($state.params.token)
                    .then(function () {
                        $state.go('app.dashboard');
                    }).catch(function () {
                        $state.go('app.default');
                    });
                return;

            } else if ($state.is('app.default.ebill')) {

                // if the user is not logged in, we need to log
                // them in to get the ebill that is associated with
                // the account

                ebillShc = $state.params.shc;
                unverifedEbillScode = $state.params.verified === '0' ? ebillShc : null;

                AuthService.requireAuthedUser({
                    openSection: 'login'
                }).then(function () {
                    BillsService.getAccountBill(ebillShc).then(function () {
                        _handleSuccessfulGetAccountBill();
                    }).catch(function (resp) {

                        if (ErrorsService.resolve(resp) === ErrorsService.errors.SECURECODE_NOT_FOUND) {

                            // we tried all means by which to find bill by scode
                            // we are adding the check and verify flow here as
                            // to not undermine the above logic for why we might
                            // need to log the user out to verify it was the right
                            // logged in user state for the browser being used
                            if (unverifedEbillScode) {
                                $state.go('app.dashboard');
                                $rootScope.$broadcast('accountVerification:prompt:proceedToPay', unverifedEbillScode);
                            }

                        } else {
                            $log.error('Unable to get the ebill');
                            $state.go('app.dashboard');
                        }
                    });
                }).catch(function () {
                    $log.error('Could not log in to get ebill');
                    $state.go('app.default');
                });

                return;
            } else if ($state.is('app.default.firstBill')) {
                var firstBillToken = $state.params.token;

                BillsService.findAuthOptions(firstBillToken).then(function (resp) {
                    $rootScope.$emit('firstBillVerification:prompt', {
                        'options': resp,
                        'token': firstBillToken
                    });
                }).catch(function () {
                    Compass.capture.failure('first-bill', 'token', '');
                    $state.go('app.default');
                });

            } else if ($state.is('app.default.verifyEmail')) {
                verifyToken = $state.params.token;
                verifyPatientUserId = parseInt($state.params.patientUserId, 10);
                verifyEmail = $state.params.email;
                AuthService.requireAuthedUser({
                    openSection: 'login',
                    prefillEmail: verifyEmail
                }).then(function () {
                    patientUser = PatientUsersService.getCurrentUser();
                    if (patientUser.patientUserId === verifyPatientUserId) {
                        PatientUsersService.verifyEmail(verifyToken, verifyPatientUserId)
                            .then(function () {
                                $log.info('Email has been verified!');
                                $state.go('app.emailVerified');
                            }).catch(function () {
                                $log.error('Error verifying email');
                                $state.go('app.dashboard');
                            });
                    } else {
                        // User logged in as different user :facepalm:
                        $log.error('User logged in with email other than the one being verified.');
                        $state.go('app.dashboard');
                    }
                }).catch(function () {
                    $log.error('Could not log in to verify email');
                    $state.go('app.default');
                });
            } else if ($state.is('app.default.verifyPhone')) {
                verifyCode = $state.params.code;
                verifyPatientUserId = parseInt($state.params.patientUserId, 10);
                verifyPhone = $state.params.phoneNumber;
                PatientUsersService.verifyPhone(verifyCode, verifyPatientUserId, verifyPhone)
                    .then(function () {
                        $log.info('Phone number has been verified!');
                        $state.go('app.phoneVerified');
                    }).catch(function () {
                        $log.error('Error verifying phone number');
                        $state.go('app.dashboard');
                    });
            }

            // we make the assumption that we are on app.default state

            // for deep linking if we have a return state and we
            // will attempt to get an authed user and redirect back
            if (_returnState) {

                $log.log('Attempting deep link login access');

                AuthService.requireAuthedUser({
                    openSection: 'login'
                }).then(function () {
                    $state.go(_returnState ? _returnState : 'app.dashboard', _returnStateParams);

                    // reset thes values
                    _returnState = null;
                    _returnStateParams = null;
                });
            }
        })
        .finally(function () {
            _authChecked = true;

            // After we figure out the user's state and handle
            // redirection, we will allow for a little bit of
            // time to account for the redirection this promise
            // chain may also contain.
            $timeout(function () {
                _appStateSettled = true;
            }, 50);

            AutoLogoutService.start();
        });
});
