angular.module('PatientApp').factory('PaymentFormsService', function( $q, $resource, $filter, $log, _, endpoints, CardService, PaymentFormsGatewayService, ServerStatusService ){

    var PaymentForm = function PaymentForm(){

        var _paymentForm = {
                method: {},
                billing: {}
            },

            _this = this,

            _dirtyFields = [],

            _completed = false,

            _getLastFourDigitsOfCurrentMethod = function(){
                var num = '';

                if(_paymentForm.method.card){
                    num = _paymentForm.method.card.number.toString();
                }else {
                    num = _paymentForm.method.eCheck.accountNumber.toString();
                }

                // get the last four only
                return num.slice( -4 );
            },

            // used mainly to populate the UI states in a
            // consistent way. This is not a part of the
            // payment process
            _buildMethodForm = function(){
                return {
                    formType: _paymentForm.method.card ? 'card' : 'echeck',
                    cardType: _paymentForm.method.card ? CardService.getIssuer(_paymentForm.method.card.number) : '',
                    expDate: _paymentForm.method.card ? _paymentForm.method.card.expiration : '',
                    last4Digits: _getLastFourDigitsOfCurrentMethod(),
                    bankName: _paymentForm.method.card ? '' : (_paymentForm.method.eCheck.bankName || '')
                };
            };

        //*********************
        // Method Fields
        //*********************
        this.setMethod = {
            clear: function(){
                delete _paymentForm.method.card;
                delete _paymentForm.method.eCheck;
            },
            card : function(form){

                // is this a different method than we
                // previously had
                if(!_.isEqual(_paymentForm.method.card, form)){
                    _dirtyFields.push('method');
                }

                this.clear();
                _paymentForm.method.card = _.cloneDeep(form);
            },
            eCheck : function(form){

                // is this a different method than we
                // previously had
                if(!_.isEqual(_paymentForm.method.eCheck, form)){
                    _dirtyFields.push('method');
                }

                this.clear();
                _paymentForm.method.eCheck = _.cloneDeep(form);
            }
        };

        this.getMethod = {
            shortDesc : function(){
                if(!_this.validity.method()){
                    return '';
                }
                // VISA ****9576
                // eCheck ****02939
                return $filter('methodDesc')(_buildMethodForm(), 'short', /* non breaking spacer */'&nbsp;');
            },

            desc : function(){
                if(!_this.validity.method()){
                    return '';
                }
                // VISA ****9576
                // eCheck Bank of America ****02939
                return $filter('methodDesc')(_buildMethodForm());
            },

            issuer: function(cardNumber){

                var number = cardNumber;

                if(!number && _paymentForm.method.card){
                    number = _paymentForm.method.card.number;
                }

                return CardService.getIssuer(number);
            },

            isForm: function(formType) {
                switch(_.trim(formType).toLowerCase()){
                    case 'card':
                        return !!_paymentForm.method.card;
                    case 'echeck':
                        return !!_paymentForm.method.eCheck;
                    default:
                        return false;
                }
            }
        };

        //*********************
        // Billing Fields
        //*********************
        this.setBilling = {
            useForm : function(form){

                // did we make a value change to the billing
                // address -- case change is not a "value change"
                if(!_.isEqual(_paymentForm.billing, form)){
                    _dirtyFields.push('billing');
                }

                _paymentForm.billing = _.cloneDeep(form);
            },
            clear : function(){
                _paymentForm.billing = {};
            }
        };
        this.getBilling = function(){
            return _paymentForm.billing;
        };


        //*********************
        // Validity checks for payment areas
        //*********************
        this.validity = {
            method : function(){
                return !!_paymentForm.method && !!(_paymentForm.method.card || _paymentForm.method.eCheck);
            },
            billing: function(){
                return !!_paymentForm.billing && (!_.isEmpty(_paymentForm.billing.address));
            }
        };

        //*********************
        // Processing Steps
        //*********************
        this.process = function(resuming) {


            // we reset dirty to false when
            // we make a payment so we can track if
            // any changes were made, after a process attempt
            _dirtyFields = [];

            var processPath = function(){
                return resuming ? PaymentFormsGatewayService.resume() : PaymentFormsGatewayService.submit( _paymentForm );
            };

            return processPath().then(function(result){
                _completed = true;
                return result;
            });
        };

        // simple check utility to know that we have successfully pushed
        // through this payment
        this.completed = function(){
            return _completed;
        };

        // check to see if the fields for a payment have
        // changed since the last attempt to process.
        // If we have not processed yet, everything starts off as
        // not dirty but when we fill in the fields, they become dirty
        //
        // if you pass specificField, we will check to see if that field
        // itself is dirty, otherwaise we check to see if ANY field is dirty
        this.preProcessDirty = function(specificField){

            if(_.isString(specificField)){
                return _.includes(_dirtyFields, specificField);
            }

            return _dirtyFields.length > 0;
        };

        //*********************
        // Utility functions
        //*********************


        this.isFormAccepted = function( formString ){
            formString = _.isString(formString) ? _.trim(formString.toLowerCase()) : '';

            return ServerStatusService.getPaymentStatus(formString);
        };
    };

    return {
        getInstance: function(){
            return new PaymentForm();
        }
    };
});
