angular.module('PatientApp').factory('BrowserInfoService', function($q, $log, $state, $rootScope, $http){

    var callback;
    return {
        openModal: function(){
            try {
                callback();
            } catch(e) {
                /* eslint-disable no-console */
                console.error('BrowserInfoService.openModal() was called before a callback method had been specified.');
                /* eslint-enable no-console */
            }
        },
        onOpenModal: function(_callback){
            callback = _callback;
        },
        getIPData: function(successCallback, errorCallback){
            $http.get('https://api.ipdata.co/', {'withCredentials': false}).then(successCallback, errorCallback);
        }
    };

});