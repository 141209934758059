angular.module('PatientApp').directive('acceptedCards', function(){

    return {
        scope: false,
        link: function(){},
        template:   '<img id="amex" version-src="assets/images/amex_logo.svg"/>'+
                    '<img id="mastercard" version-src="assets/images/mastercard_logo.svg"/>'+
                    '<img id="visa" version-src="assets/images/visa_logo.svg"/>'+
                    '<img id="discover" version-src="assets/images/discover_logo.svg"/>'
    };
});