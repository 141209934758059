angular.module("PatientApp").config(["$translateProvider", function($translateProvider) {
$translateProvider.translations("en", {
    "dashboard" : {
        "dashboard": "Providers",
        "zeroStateTitle": "No Providers",
        "zeroStateBlurb": "Enter the details of your most recent Patientco bill to access up-to-date balances, past billing activity, and transaction details across your healthcare providers.",
        "argsDashboard": "{{name}}'s Providers",
        "argsDashboardEndsWithS": "{{name}}' Providers"
    },

    "encounterLinking" : {

        "linkableAccounts": "We've found additional accounts to add to your PatientWallet<sup>&reg;</sup>. <a href=\"\" class=\"link inline\" >Verify Accounts</a>",
        "proactiveLinkableAccounts": "Want us to automatically add accounts for {{providerGroupList | listCombine}} to your PatientWallet<sup>&reg;</sup>? <a href=\"\" class=\"link inline\">Verify Accounts</a>",
        "experiencedLinkableAccounts": "You have more accounts to add to your PatientWallet<sup>&reg;</sup>. <a href=\"\" class=\"link inline\" >Verify Accounts</a>",
        "verifyInformation": "Verify Information",
        "verifyingInformation": "Verifying Information...",
        "whichPatient": "Who would you like to verify?",
        "verifyAcctTitle": "Verify Information For<br /> {{linkingName}}",
        "verifyAcctSubtitlePatient": "Enter the security fields below to add {{patientName | possessive}} accounts from {{providerGroup}}.",
        "verifyAcctSubtitleGuarantor": "Enter your information below to add {{patientName | possessive}} accounts from {{providerGroup}}.",
        "birthdatePlaceholder": "Date of Birth (MM/DD/YYYY)",
        "SSNPlaceholder": "Last Four Digits of Social Security Number",
        "ssnHelpInfo": "<b>Note:</b> If you're adding accounts for an infant, use the guarantor's SSN",
        "moreInfo": "How does this work?",
        "verificationFailed": "The information you've entered does not match our records for {{patientName}}. Please try again.",
        "verificationBlocked": "You have attempted to add {{patientName | possessive}} account too many times.  For security reasons, your ability to add this account has been disabled temporarily.",
        "verificationHeader": "Account successfully added.",
        "verificationHeaderPlural": "Accounts successfully added.",
        "verificationSubheader": "In the future, we'll automatically add {{patientName | possessive}} bills for {{providerGroup}} to your PatientWallet<sup>&reg;</sup>.",
        "proactiveExperiencedVerificationSubheader": "{{patientName}} doesn't have any past accounts to add, but we'll automatically add their future bills for {{providerGroup}} to your PatientWallet<sup>&reg;</sup>.",
        "incompleteSSN": "Looks like you're missing a few digits. Please enter the last four numbers of your SSN.",
        "proactiveVerificationHeader": "{{patientName}} successfully verified.",
        "close": "Close",
        "continueToPay": "Continue to Bill"
    },

    "firstBill" : {
        "optionsHeader": "Verify Information For {{patientFirstName}}",
        "optionsSubheader": "{{providerName}} needs to verify your identity before you can access your bill.",
        "verifyEmailHeader": "Check your email!",
        "verifyEmailSubheader": "Please enter the code we sent to your email address.",
        "verifySmsHeader": "Check your phone!",
        "verifySmsSubheader": "Please enter the code we sent to your mobile device via text message.",
        "verifyPdivHeader": "Enter the security fields below to access {{patientFirstName | possessive}} bill from {{providerName}}",
        "birthdatePlaceholder": "Date of Birth (MM/DD/YYYY)",
        "SSNPlaceholder": "Last Four Digits of Social Security Number",
        "incompleteSSN": "Looks like you're missing a few digits. Please enter the last four numbers of your SSN.",
        "verifyViaEmailOption": "Email me a code ({{emailAddress}})",
        "verifyViaSmsOption": "Text me a code ({{phoneNumber}})",
        "verifyViaPdiv": "Enter date of birth & last four of SSN",
        "sendAgainButtonText": "I didn't receive a code",
        "sendingCodeButtonText": "Sending...",
        "back": "Back",
        "submitText": "Next",
        "submittingText": "Checking...",
        "successHeader": "You have been verified!",
        "successSubheader": "Click below to see your bill.",
        "successModalButton": "View Bill",
        "codeNotSent": "There was a problem sending that code. Please try again.",
        "codeSent": "Code has been sent.",
        "codeInvalid": "That code was invalid or expired. Please try again.",
        "pdivInvalid": "One or more fields did not match our records.  If you believe your information is correct, please contact your provider.",
        "bruteForce": "Account temporarily disabled due to too many failed attempts.",
        "unableToAuth": "We are unable to authenticate your account at this time.  Your bill should arrive in the mail soon.",
        "missingGuarantorName": "Patient"
    },

    "confirmPhone" : {
        "header": "Complete Account Registration",
        "numberSubheader": "Please verify your mobile phone number.",
        "codeSubheader": "Please enter the code we sent to your mobile device via SMS.",
        "submitButtonText": "Send Code",
        "submittingButtonText": "Sending...",
        "verifyButtonText": "Verify",
        "verifyingButtonText": "Verifying...",
        "sendAgainButtonText": "I didn't receive a code",
        "successHeader": "Thank you for verifying your phone number.",
        "learnMore": "Learn More",
        "hide": "Hide",
        "additionalInfo": "By verifying your phone number, you agree to allow Patientco to send you periodic text messages for any account-related activity. Patientco may use an automated system to send you electronic communications including text, phone, or email. Patientco does not have a separate charge for this service; however, subject to the terms and conditions of your mobile carrier, message and data rates may apply. By providing your consent to participate in this program, you approve any such charges from your mobile carrier. You may opt out of this program at any time by emailing Patientco at support@patientco.com.",
        "explanation": "Patientco will send you a text message with a unique code to verify your mobile phone number.",
        "errors": {
            "sentAgain": "Sorry about that! We sent you another code.",
            "codeNotSent": "There was a problem sending that code. Please try again.",
            "codeInvalid": "That code was invalid or expired. Please try again."
        }
    },

    "confirmEmail" : {
    	"header": "Verify Email Address",
    	"submitButtonText": "Send Email",
        "submittingButtonText": "Sending...",
        "explanation": "Is the email address listed correct? If not, please correct it above prior to clicking the \"Send Email\" button to update your account.",
        "successHeader": "Verification email sent.",
        "emailNotSent": "There was a problem sending that email. Please try again."
    },

    "receipt": {
        "receipt": "Receipt",
        "argPaidCallout": "<b>{{amount}}</b> Paid",
        "argScheduledCallOut": "<b>{{amount}}</b> Scheduled",
        "argRemainingCallOut": "<b>{{amount}}</b> Remaining",
        "transactionIdArg": "Transaction ID # {{transactionId}}",
        "balancePayment": "Balance Payment",
        "argDeclinedCallout": "<b>{{amount}}</b> Declined",
        "argCanceledCallout": "<b>{{amount}}</b> Canceled",
        "confirmCancelDialog": "Are you sure you want<br />to cancel this payment?",
        "cancelPaymentDesc": "{{amount}} scheduled for {{date}}",
        "fullRefund": "Full Refund",
        "partialRefund": "Partial Refund",
        "chargeback": "Chargeback",
        "failedECheck": "Failed eCheck",
        "bouncedCheck": "Bounced Check",
        "paymentMethodUpdated": "Payment method has been updated.",
        "paymentMethodHeld": "We were unable to process a payment.  Please select another payment method and try again.",
        "updateMethodScheduledPlanConfirmDialog": "The remaining payments in your plan will be updated to use {{method}}.",
        "updateMethodHeldPlanConfirmDialog": "The remaining payments in your plan will be updated to use {{method}}.<br/><br/>Any payments in the plan that were previously declined or held will be processed using the new payment method."
    },

    "newbill": {
        "whichAccount" : "Which bill would you like to pay?",
        "accountDue": "<b>{{providerName}}</b><br/>Account #{{accountNumber}} | {{amountDue}} due",
        "accountPastDue": "<b>{{providerName}}</b><br/>Account #{{accountNumber}} | {{amountDue}} past due"
    },

    "message": {
        "message" : "Message",
        "plural" : "Messages",
        "messageSent": "Your message has been sent!",
        "argsMessages": "{{name}}'s Messages",
        "argsMessagesEndsWithS": "{{name}}' Messages",
        "createAreaPlaceholder": "Start typing your reply...",
        "whoToContact": "Who would you like to contact?",
        "whichAccount": "Which account do you need help with?",
        "noProvidersToMessage": "Your PatientWallet<sup>&reg;</sup> is currently empty. Please add a new bill to get started.",
        "startMessagePlaceholder": "Start typing your message...",
        "messageClosedByProviderDialog": "This message thread is closed.",
        "startNewMessageDialog": "If you'd like to start another message thread, <span>click here</span>.",
        "argProviderCantMessage": "{{name}} does not support messaging through Patientco. If you would like to contact your provider, please call {{billingPhoneNumber}}. We apologize for any inconvenience.",
        "reasons": {
            "default":"Select a Reason",
            "specificCharge":"Question a Specific Charge",
            "duplicate":"Question Duplicate Charges/Bill",
            "paymentPlan":"Set up a Payment Plan",
            "missingPayments":"Inquire About Missing Payments",
            "other":"Other",
            "updateInsurance":"Update Information",
            "insuranceQuestion":"Ask an Insurance Question",
            "financialAssistance":"Inquire About Financial Assistance",
            "itemizedBill":"Request an Itemized Bill"
        },
        "zeroStateTitle": "No Messages",
        "zeroStateBlurb": "Patientco lets you securely communicate with all of your healthcare providers in one place. Ask a question about your bill, set up a payment plan, or just say thanks!"
    },

    "notification": {
        "notification": "Notification",
        "plural": "Notifications",
        "zeroStateTitle": "No Notifications"
    },

    "login": {
        "minCharactersRule" : "8 characters",
        "upperCaseRule" : "1 uppercase letter",
        "digitRule" : "1 number",
        "nextButton": "Next",
        "forgotPasswordQuestion": "Forgot your password?",
        "forgotPasswordQuestionSubcontent": "We've got you covered.",
        "forgotPasswordSentDialog": "Great! We've sent help to<br /><b>{{email}}</b>",
        "forgotPasswordMethodHeader": "How would you like to reset your password?",
        "forgotPasswordMethodEmailButton": "Email me ({{email}})",
        "forgotPasswordMethodSmsButton": "Text me ({{phone}})",
        "forgotPasswordMethodCode": "Please enter your verification code.",
        "forgotPasswordCheckEmailTitle": "Check your email and click the link.",
        "forgotPasswordEmailSentTryAgainButton": "Try Again",
        "forgotPasswordMethodSendAgainButton": "I didn't receive a code",
        "resetPasswordHeader": "Create your new password.",
        "resetPasswordCompleteDialog": "You may now log in using your email and new password.",
        "resetPasswordCheckEmailMessage": "We just sent an email containing a password reset link to<br /><b>{{email}}</b>.<br /><br />If you don't see it in your inbox, be sure to check your spam folder."
    },

    "setting": {
        "menu": {
            "contact": "Contact Information",
            "savedPaymentMethods": "Saved Payment Methods",
            "accountManagement": "Account Management"
        },
        "setting" : "Setting",
        "plural" : "Settings",
        "argsSettings": "{{name}}'s Settings",
        "argsSettingsEndsWithS": "{{name}}' Settings",
        "ebillDialog": "Email me when new bills are added to my account.",
        "smsDialog": "Text me when new bills are added to my account.",
        "argMethodDeleted": "{{method}}<br />has been deleted",
        "deleteMethodArg": "Delete {{detail}}",
        "confirmDeleteDialog": "Are you sure you want to delete this payment&nbsp;method?",
        "passwordChangedDialog": "Your password has been changed successfully.",
        "unremovableMethodHelp": "This payment method is being used to make a <br />scheduled payment and cannot be deleted.",
        "nonDeactivateableUserHelp": "You cannot deactivate your account because<br/> you have one or more scheduled payment(s).",
        "modals": {
            "email": {
                "header": "Email Communications",
                "content": "By enabling email notifications, you are giving Patientco permission to periodically email you new bills or reminders about bills with pending due dates. Email notification is an optional feature of Patientco that is disabled until you modify your notification settings in the “Settings” tab or on the registration page. You may choose to enable or disable this feature at any time, but changes may take up to 48 hours to become effective. Patientco reserves the right to discontinue eBill notifications at any time, in our sole discretion. Your Protected Health Information (PHI) will not be contained in any emails. Your PHI can only be viewed by logging into Patientco using your registered email address."
            },
            "sms": {
                "header": "SMS Communications",
                "content": "By enabling text message notifications, you agree to allow Patientco to send you periodic text messages for any account-related activity. Patientco may use an automated system to send you electronic communications including text, phone, or email. Patientco does not have a separate charge for this service; however, subject to the terms and conditions of your mobile carrier, message and data rates may apply. By providing your consent to participate in this program, you approve any such charges from your mobile carrier. You may opt out of this program at any time in the \"Settings\" tab in your PatientWallet<sup>&reg;</sup>."
            }
        },
        "methodAdded": "{{method}}<br/>has been added"
    },

    "landing": {
        "title": "Patientco | Pay Your Bill",
        "enterBills": "Enter the unique details from your bill.",
        "marketingBlurb": "Welcome to the PatientWallet<sup>&reg;</sup> from Patientco! We’ve partnered with your healthcare provider to give you the simplest, most secure payment experience possible.",
        "needHelpHeader": "Need help finding your bill?",
        "needHelpContent": "Click into the white boxes provided to enter your SecureHealthCode and Balance Due."
    },

    "contact": {
        "help": {
            "header": "I need help with:",
            "myBill": "Understanding my bill",
            "lostBill": "I lost my Patientco bill",
            "loggingIn": "Logging in",
            "itemizedBill": "I need an itemized bill",
            "resetPassword": "Reset my password"
        },
        "questionAbout": {
            "header": "I have a question about:",
            "reasons": {
                "specificCharge":"A charge on my bill",
                "duplicate":"A duplicate charge or bill",
                "paymentPlan":"Creating a payment plan",
                "missingPayments":"Missing payments",
                "other":"Other",
                "updateInsurance":"Updating Information",
                "insuranceQuestion":"Insurance",
                "financialAssistance":"Financial assistance",
                "itemizedBill":"Requesting an itemized bill"
            }
        },
        "rightPlace": {
            "header": "Let's get you to the right place.",
            "enterEmail": "Please enter your email address:",
            "enterBillDetails": "Please enter your bill details:"
        },
        "almostThere": {
            "header": "Almost there...",
            "password": "Please create a password:",
            "login": "Please log in to your account:"
        }

    },

    "actions": {
        "pay": "Pay",
        "makePayment": "Make A Payment",
        "viewMyBill" : "View Bill",
        "viewBill" : "View Bill",
        "viewPlan" : "View Plan",
        "viewAccounts" : "View Accounts",
        "viewAccount" : "View Account",
        "viewMessage" : "View Message",
        "view" : "View",
        "verify": "Verify",
        "verifying": "Verifying...",
        "submit": "Submit",
        "submitting": "Submitting...",
        "messageProvider": "Message Provider",
        "updatePaymentForm": "Change Payment Method",
        "createMessage": "Create New Message",
        "continue": "Continue",
        "continueSingle": "Make a One Time Payment",
        "continuePlan": "Create a Payment Plan",
        "processPayment": "Process Payment",
        "retryProcessPayment" : "Retry Process",
        "processPaymentFuture" : "Schedule Payment",
        "createAccount": "Create Account",
        "creatingAccount": "Creating Account...",
        "login" : "Log In",
        "loggingIn" : "Logging In...",
        "logout": "Log Out",
        "payNewBill": "Pay New Bill",
        "payBill": "Pay Bill",
        "payAsGuest": "Pay As Guest",
        "sendingToGuestPay": "Sending To Guest Pay...",
        "payAnotherBill": "Pay Another Bill",
        "openReceipt": "View Receipt",
        "findBill": "Find Bill",
        "findingBill": "Finding Bill...",
        "needHelp": "Need help?",
        "contactUs" : "Contact Us",
        "learnMore" : "Learn More",
        "goToDashboard": "View Providers",
        "open": "Open",
        "sendMessage": "Send Message",
        "sending": "Sending Message...",
        "printReceipt": "Print Receipt",
        "printPlan": "Print Plan Details",
        "back": "Back",
        "backToDashboard": "Back to Providers",
        "backToMessages": "Back to Messages",
        "backToArg": "Back to {{properNoun}}",
        "deleteCard": "Delete Card",
        "deleteECheck": "Delete eCheck",
        "addNewMethod": "Add New Method",
        "changePassword": "Change Password",
        "deactivateAccount": "Deactivate Account",
        "confirmPassword": "Confirm Password",
        "close": "Close",
        "cancel": "Cancel",
        "saveChanges": "Save Changes",
        "forgotPassword": "Forgot Your Password?",
        "retrievePassword": "Retrieve Password",
        "resetPassword": "Reset Password",
        "visitWebsite": "Visit Website",
        "cancelPayment": "Cancel Payment",
        "viewOlderActivity": "View Older Activity",
        "stayLoggedIn": "Stay Logged In",
        "sendFeedback": "Send Feedback",
        "sendingFeedback": "Sending...",
        "viewZeroBalanceAccts": "View {{count}} Paid Accounts",
        "enterSHCTooltip": "Please enter the SecureHealthCode that is printed on your bill",
        "enterAmountTooltip": "Please enter the Total Balance Due that is printed on your bill, even if you intend to actually pay a different amount",
        "confirmPhoneNumber": "Please verify your mobile phone number.",
        "confirmEmail": "Click here to verify your email address: {{email}}",
        "processPaymentForm": "Save Payment Method",
        "retryProcessPaymentForm" : "Retry Payment Method",
        "confirmAndProcess" : "Confirm & Process",
        "accept" : "Accept",
        "switchLanguage" : "Cambiar a Español"
    },

    "labels": {
        "providerName": "Provider Name",
        "argAccount":"{{num}}&nbsp;Account",
        "argAccounts": "{{num}}&nbsp;Accounts",
        "argNumPastDue": "{{num}}&nbsp;Past&nbsp;Due",
        "argNumAccountsPastDue": "{{num}}&nbsp;Accounts&nbsp;Past&nbsp;Due",
        "argNumAccountPastDue": "{{num}}&nbsp;Account&nbsp;Past&nbsp;Due",
        "accountNumber": "Account Number",
        "accountNumberConfirm": "Confirm Account Number",
        "guarantorName": "Guarantor Name",
        "name": "Name",
        "firstName": "First Name",
        "lastName": "Last Name",
        "email": "Email Address",
        "password": "Password",
        "passwordConfirm": "Confirm Password",
        "statementSent": "Bill Date",
        "balancDue": "Due Date",
        "dueDate": "Due Date",
        "dueArg": "Due {{date}}",
        "argDue": "{{amount | currency}} due",
        "pastDue": "Past Due",
        "billSummary" : "Bill Summary",
        "nameOnCard": "Name on Card",
        "cardNumber": "Card Number",
        "expirationFormat": "MM / YY",
        "routingNumber": "Routing Number",
        "accountHolderName": "Account Holder's Name",
        "accountNumberArg": "Account # {{number}}",
        "address": "Address",
        "address2": "Address Line 2 (optional)",
        "city": "City",
        "state": "State",
        "zip": "Zip Code",
        "date": "Date",
        "status": "Status",
        "description": "Description",
        "amount": "Amount",
        "secureCodeOnBill": "SecureHealthCode on Bill",
        "balanceOnBill": "Total Balance Due on Bill",
        "secureCode": "SecureHealthCode",
        "totalBalanceDue": "Total Balance Due",
        "argBalance": "{{balance}} Balance",
        "enterUniqueDetails": "Enter details from your new bill.",
        "badDebt": "Referred to external agency",
        "servicedByFinancingVendor": "Serviced by {{financedBy}}",
        "noBalance": "No Balance Due",
        "pendingBalance": "Pending",
        "remainingBalance": "Balance Remaining",
        "negativeBalance": "Contact your provider for information about your negative balance.",
        "yourBillAmount": "Bill Amount",
        "billAmount": "Bill Amount",
        "newBalance" : "Current Balance",
        "updating" : "Updating...",
        "loading" : "Loading...",
        "noAccountActivity" : "No account activity.",
        "contactProvider": "Provider Contact",
        "providerResources": "Provider Resources",
        "messageDetails": "Message Details",
        "argPaid": "{{amount}} Paid",
        "argScheduled": "{{amount}} Scheduled",
        "argTotal": "{{amount}} Total",
        "amountPaid": "Amount Paid",
        "details": "Details",
        "userDetails": "User Details",
        "futurePayment": "Future Payment",
        "createdArg": "Created {{date}}",
        "communicationPrefs": "Communication Preferences",
        "accountManagement": "Account Management",
        "savedMethods": "Saved Payment Methods",
        "financingMethods": "Financing Accounts",
        "noSavedMethods": "Saved payment methods will appear here.",
        "noFinancingMethods": "There are currently no financing accounts. Check with your provider to see if financing is available.",
        "phoneNumber": "Mobile Phone",
        "primaryPhone": "Primary Phone",
        "currentPassword": "Current Password",
        "newPassword": "New Password",
        "confirmNewPassword": "Confirm New Password",
        "submittingPayment": "Processing Payment...",
        "submittingPaymentForm": "Saving...",
        "retrievingPassword": "Retrieving Password...",
        "submittingPaymentFuture" : "Scheduling Payment...",
        "resettingPassword": "Resetting Password...",
        "savingChanges": "Saving Changes...",
        "acceptanceOfTerms": "I agree to Patientco's <a href=\"https://www.patientco.com/terms-of-use/\" target=\"_blank\">Terms of Use</a>",
        "acceptanceOfTermsRaw": "I agree to Patientco's Terms of Use",
        "changesSaved": "Changes Saved",
        "changesFailed": "Unable to save changes",
        "declinedPayment": "Declined Payment",
        "paymentDeclined": "Payment Declined",
        "argDeclined": "{{amount}} Declined",
        "canceledPayment": "Canceled Payment",
        "paymentCanceled": "Payment Canceled",
        "argCanceled": "{{amount}} Canceled",
        "providerDisabledExplanation": "{{provider.name}} is no longer working with Patientco to accept online payments. For assistance in making a payment, please call {{provider.name}} at {{provider.number}}. We apologize for any inconvenience.",
        "providerDisabledExplanationShort": "{{provider.name}} is no longer working with Patientco to accept online&nbsp;payments.",
        "paymentPlanTitle": "Plan Details",
        "paymentPlanStatus": "Payment Plan {{status}}",
        "verificationCode": "Verification Code",
        "receivesEbills": "Email me new bills & reminders",
        "receivesSms": "Text me new bills & reminders",
        "socialSecurityNumber": "Social Security Number",
        "birthdate": "Date of Birth (MM/DD/YYYY)",
        "income": "Monthly Household Income",
        "changePassword": "Do you need to change your password?",
        "deactivateAccount": "Do you need to deactivate your account?",
        "invalidPassword": "Invalid password",
        "moreInfo": "More Info"
    },

    "paymentForm": {
    	"method": {
    		"stepName": "Method"
    	},
    	"billing": {
    		"stepName": "Address"
    	},
    	"confirm": {
    		"stepName": "Confirmation"
    	}
    },

    "payment": {
        "amount": {
            "question" : "How much would you like to pay?",
            "selectDifferent" : "Please select a different payment option:",
            "stepName" : "Payment Amount",
            "payBillBalArg" : "Pay Bill Amount: <b>{{calculatedTotal}}</b>",
            "payBillBalArgDiscount" : "Pay Bill Amount with {{discountPolicy}}&nbsp;Discount:<br /><b>{{calculatedTotal}}</b>",
            "payAccountBalArg" : "Pay Account Balance: <b>{{calculatedTotal}}</b>",
            "payAccountBalArgDiscount" : "Pay Account Balance with {{discountPolicy}}&nbsp;Discount:<br /><b>{{calculatedTotal}}</b>",
            "payAnother" : "Pay Another Amount",
            "otherPaymentOptions" : "Review Other Payment Options",
            "payOnce": "Would you like to make a one-time payment?",
            "payOverTime": "Would you like to pay over time?",
            "planInstallmentOption" : "{{installmentAmount}}/month, {{installmentCount}} months",
            "messageProvider": "Message Provider",
            "reviewFinancingOptions" : "Review Financing Options",
            "lowMonthlyPaymentOptions" : "Low Monthly Payment Options",
            "paymentsAsLowAs" : "Payments As Low As {{monthlyPayment | currency}}/Month*",
            "paymentsAsLowAsDisclaimer" : "* {{monthlyPayment | currency}} minimum monthly payment is estimated based on {{amount | currency}} financed at {{apr}}% APR for {{duration}} months. Actual APR varies between 0% and 29.99% and repayment terms vary between 24 and 48 months, depending on your creditworthiness. Payments will vary depending on loan amount and offer received.",
            "getFinancingOptionsErrorMessage" : "An error occurred while trying to load your financing options. Please try again.",
            "noFinancialOptionsFound" : "Sorry, we did not find any financing options to offer you.",
            "planDisabledBecauseOfBadDebt" : "Payment plans cannot be created on accounts that have been referred to an external agency."
        },

        "date": {
            "question" : "When would you like to pay?",
            "stepName" : "Payment Date",
            "todayArg" : "Today: <b>{{todayDate}}</b>",
            "laterDate": "Pay At A Later Date"
        },

        "method": {
            "question" : "How would you like to pay?",
            "stepName" : "Payment Method",
            "cardMethod" : "Credit/Debit Card",
            "eCheckMethod" : "eCheck",
            "hsaMethod": "HSA/FSA Card",
            "userAnotherCard" : "Use Another Card",
            "userAnotherECheck" : "Use Another eCheck",
            "userAnotherCardOrECheck" : "Use Another Card or eCheck"
        },

        "billing": {
            "question" : "What's your billing address for <br />{{methodSummary}}?",
            "stepName" : "Billing Address",
            "useAnotherAddress" : "Use Another Billing Address"
        },

        "confirm": {
            "planAmount" : "{{installmentAmount}}/month for {{installmentCount}} months",
            "question" : "Does everything look right?",
            "stepName" : "Confirmation",
            "cardBilling" : "Card Billing Address",
            "eCheckBilling" : "eCheck Billing Address",
            "eCheckAuthorizationSingle": "I authorize Patientco on behalf of {{providerName}} to initiate a single electronic debit from my account in the amount of {{amountSummary}} on {{date}} from the bank account on record. I understand this authorization will remain in effect until I notify {{providerName}} that I wish to revoke it.",
            "eCheckAuthorizationPaymentPlan": "I authorize Patientco on behalf of {{providerName}} to initiate recurring electronic debits from my account in the amount of {{amountSummary}} starting on {{date}} from the bank account on record. I understand this authorization will remain in effect until I notify {{providerName}} that I wish to revoke it."
        },

        "friendlyType": {
            "standard": "Payment",
            "badDebt": "Bad Debt Payment",
            "balance": "Balance Payment",
            "copay": "Copay",
            "insurance": "Insurance Payment",
            "prepayment": "Prepayment",
            "other": "Other"
        },

        "financing": {
            "application": {
                "stepName" : "Financing Application",
                "financingApplyHeader" : "To see if offers are available, complete this form:",
                "electronicDisclosureConsentText" : "By checking the box, you certify that you have read, retained, and agree to the <a href='{{electronicDisclosureConsentLink}}' target='_blank'>Consent to Electronic Disclosures</a> and understand that this constitutes your electronic signature authorizing us to process this form on your behalf.",
                "electronicDisclosureConsentError": "Agreeing to the Consent of Electronic Disclosures is required.",
                "creditScoreNotice": "Viewing offers does not impact your credit score.",
                "financingViewOffers": "View My Offers",
                "monthlyIncomeHeader" : "Total Gross Monthly Household Income",
                "monthlyIncomeBody" : "Alimony, child support or separate maintenance income need not be included if you do not wish to have it considered as a basis for repaying this obligation.<br><br>Married Wisconsin Residents: Combine your and your spouse's information on this application.",
                "incomeGreaterThanZeroError": "Income must be greater than zero.",
                "disclaimersHeader": "Read the Disclaimers Below & Scroll Down to View Your Offers",
                "newAccountProceduresHeader": "Important Information About Procedures for Opening a New Account",
                "newAccountProceduresBody": "To help the government fight the funding of terrorism and money laundering activities, Federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you apply for a loan, we will ask your name, street address, email address, date of birth and other information which will allow us to identify you. We may also ask to see your driver's license or other identifying documents.",
                "stateLawNoticesHeader": "State Law Notices",
                "stateLawNoticesBody": "<p><b>California Residents:</b> If married, you may apply for a separate account.</p><p><b>Delaware Residents:</b> Service charges not in excess of those permitted by law will be charged on the outstanding balances from month to month.</p><p><b>Maryland Residents:</b> Finance Charges will be imposed in amounts or at rates not in excess of those permitted by law.</p><p><b>New York Residents:</b> New York residents may contact the New York State Department of Financial Services by telephone or visit its website for free information on comparative credit card rates, fees, and grace periods. You can call the New York State Department of Financial Services at 800-342-3736 or visit its website: www.dfs.ny.gov.</p><p><b>Ohio Residents:</b> The Ohio laws against discrimination require that all creditors make credit equally available to all credit-worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio civil rights commission administers compliance with this law.</p><p><b>Vermont Residents:</b> Lender is engaged in loan production.</p><p><b>Married Wisconsin Residents:</b> No provision of a marital property agreement, a unilateral statement under section 766.59 or a court decree under section 766.70 adversely affects the interest of the creditor unless the creditor, prior to the time the credit is granted, is furnished a copy of the agreement, statement or decree or has actual knowledge of the adverse provision when the obligation to the creditor is incurred. We are required to ask you to provide the name and address of your spouse.</p>",
                "electronicCommunicationConsentTitle": "Special Consent to Electronic Communication",
                "electronicCommunicationConsentBody": "By clicking \"View My Offers\" below and submitting an application electronically for processing, you expressly authorize the Lender to contact you about your application and any credit extended, or for any other lawful purpose, including, without limitation, customer service or collection, at any address or telephone number that you may, from time to time, provide (including any cellular telephone number or ported landline), and expressly including, without limitation, calls made using automatic dialing and announcing devices that may play recorded messages.",
                "submitApplicationConsentTitle": "Consent to Submit Application",
                "submitApplicationConsentBody": "By clicking \"View My Offers\" below and submitting an application electronically for processing, you hereby certify that you are at least 18 years of age (19 in AL or NE) and that everything you have stated above is true and accurate. You certify that you do not presently have a petition filed under federal bankruptcy laws (whether voluntary or involuntary) and do not anticipate filing a petition. If approved for credit, you agree to be bound by the terms of the Credit Agreement provided to you. You authorize The Bank of Missouri, its successors, assigns, and agents (the \"Lender\") to investigate your creditworthiness and to obtain a credit report on you for any lawful purpose, including any update, extension of credit, review or collection of your account, and you authorize the release of such information to the Lender. If you request, the Lender will tell you whether any credit report was requested, and if so, the name and address of the consumer reporting agency furnishing the report."
            },
            "offers": {
                "stepName" : "Available Offers",
                "financingOptionsHeaderMultiple" : "Congratulations, offers are available",
                "financingOptionsHeaderSingle" : "Congratulations! Here is your offer",
                "financingOptionHeaderCreditLimit" : " with a {{currencyCreditLimit}} Credit Limit.",
                "financingOptionCreditLimit" : "Credit Limit: {{currencyCreditLimit}}",
                "financingOptionHeaderDeferredInterest" : "If paid in full within {{deferredInterestMonths}} months",
                "financingOptionDeferredInterest" : "Deferred Interest: {{deferredInterestMonths}} months",
                "financingOptionNoInterest" : "No Interest",
                "financingOptionLowMonthly" : "Low Monthly Payment",
                "financingOptionAmountAndMonths" : "{{currencyMonthlyAmount}}/month for {{months}} months",
                "financingOptionAPR" : "Annual Percentage Rate: {{rate}}%",
                "financingOptionInterestBearing" : "{{currencyMonthlyAmount}}/month, {{months}} months, {{rate}}% interest",
                "financingOptionInterestFree" : "{{currencyMonthlyAmount}}/month, {{months}} months",
                "chooseFinancingOptionErrorMessage" : "An error occurred while communicating with our financing partner. Please try again.",
                "monthlyPaymentDisclaimer" : "Monthly Payment: The estimated monthly minimum payments include finance charges at an APR of the value shown on the corresponding offer tile and an estimated total payment the minimum monthly payment multiplied by the repayment term. Assuming the purchase amount of {{chargeAmount | currency}} and that all payments are timely made, the purchase transaction will be paid off within the number of months listed for repayment term on the corresponding offer tile.",
                "deferredInterestDisclaimer" : "Deferred Interest: Interest will be charged to your account from the purchase date if the qualifying purchase balance is not paid in full within the promotional period or if you make a late payment. You must pay more than your required minimum payment to avoid interest. See Cardholder Agreement for details."
            },
            "existingAccount": {
                "stepName" : "Confirmation",
                "existingAccountHeader" : "Based on the information provided, we have found an existing {{existingAccount.vendorName}} account.",
                "accountNumberLabel" : "Account Number:",
                "availableCreditLabel" : "Available Credit:",
                "authorizeTransactionSubheader" : "Authorize Transaction for {{chargeAmount | currency}}",
                "authorizeTransactionDisclaimer" : "By checking the box, you agree to allow {{existingAccount.vendorName}} to initiate a transaction for the appropriate bill amount.  If your line of credit is greater than your bill amount, you will be able to use it towards future bills with {{providerName}} if you would like.",
                "submitExistingAccountConfirmationErrorMessage" : "An error occurred while communicating with our financing partner. Please try again.",
                "partialPaymentDisclaimer" : "Please note you will have a remaining balance of {{existingAccount.amountDueNotCovered | currency}}."
            },
            "summary": {
                "stepName" : "Summary of Credit Terms",
                "financingSummaryHeader" : "Review & Accept Your Offer:",
                "acceptOfferDisclaimer" : "Once you click Accept Offer, your {{vendorName}} account will be created and you will not be able to select a different offer.",
                "acceptOfferButton" : "Accept Offer",
                "reviewTerms" : "Before selecting your offer, please review the <a href='{{termsUrl}}' target='_blank'>Summary of Credit Terms</a> which summarizes the key terms of the Cardholder Agreement that will govern your {{vendor}} revolving account."
            },
            "confirm": {
                "stepName" : "Confirmation",
                "financingConfirmHeader" : "Almost Done!",
                "cardholderAgreementSubheader" : "Read & Save Your Cardholder Agreement:",
                "cardholderAgreementDisclaimer" : "By checking the box, you agree that you have (i) read and understand your <a href='{{cardholderAgreementUrl}}' target='_blank'>Cardholder Agreement</a> including the terms of any deferred interest offer and agree to be bound by its terms, and (ii) have provided the correct email address in order to retain a copy of the Cardholder Agreement for your records.",
                "authorizeTransactionSubheader" : "Authorize Transaction for {{chargeAmount | currency}}",
                "authorizeTransactionDisclaimer" : "By checking the box, you agree to allow {{vendorChosen}} to initiate a transaction for {{chargeAmount | currency}}. If your line of credit is greater than this amount, you will be able to use it towards future bills with {{providerName}} if you would like.",
                "partialPaymentDisclaimer" : "Please note you will have a remaining balance of {{selectedOption.amountDueNotCovered | currency}}."
            },
            "success": {
                "financingRedirectHeader" : "We will redirect you to our financing partner shortly...",
                "financeRedirectSubContent" : "<br>If you are not redirected automatically please <a id='redirectUrl' href='{{redirectUrl}}' target='_blank'>click here</a> to continue to our financing partner.",
                "financingSuccessHeader" : "Thank you for financing through {{vendor}}!",
                "financeSuccessSubContent" : "{{vendor}} will contact you directly with account details. If you have any questions, please contact {{vendor}} directly by phone at <b>{{vendorPhone}}</b> or by email at <b>{{vendorEmail}}</b>."
            },
            "paymentForm": {
                "infoHeader": "{{vendor}} Card",
                "infoContent": "<p>Based on your existing account with {{vendor}}, you are able to use your open line of credit to finance your bills with <b>{{provider}}</b>.</p><p>Available Credit: {{availableCredit | currency}}</p><p>For questions about your {{vendor}} account, please call {{vendorPhone}} or email {{vendorEmail}}.</p>"
            }
        }
    },

    "dialogs" : {
        "confirmPasswordHeader": "Confirm Password",
        "confirmPasswordSubheader": "For security reasons, please enter your password.",
        "deactivateAccountHeader": "Deactivate Account",
        "deactivateAccountSubheader": "For security reasons, please enter your password to continue.",
        "deactivateAccountWarning": "Warning! Once you deactivate your account, you will lose access to your PatientWallet<sup>&reg;</sup> and all data associated with it.",
        "deactivateAccountSuccessHeader": "Your account has been deactivated.",
        "deactivateAccountSuccessSubheader": "If you have deactivated your account in error, please contact us at 1-844-422-4779 or support@patientco.com.",
        "thanksForPayment" : "Thank you for your payment!",
        "emailSentTo": "We've sent an email receipt to<br />{{email}}",
        "copySentTo": "When your provider responds, we'll send an email to<br />{{email}}",
        "successfulLogout": "You've successfully logged out.",
        "thanksAndComeBack" : "Thank you for using Patientco.<br />We look forward to having you back!",
        "welcome": "Welcome to Patientco!",
        "happyYouAreHere": "Create an account to get started.",
        "thanksForComingBack": "We're glad to have you back.",
        "leavingPaymentFlow" : "Are you sure you want to leave this page? Your payment has not been processed.",
        "yourBalanceWasUpdated" : "Your balance has been updated!",
        "dbuReasonHeaderUp": "Why has my balance gone up?",
        "dbuReasonHeaderDown": "Why has my balance gone down?",
        "dbuReason": "Since your last bill, there has been new activity on your account. In most cases, the updated balance reflects new charges, payments, or insurance contribution changes.",
        "dbuStillPayable": "Don't worry, you can still pay<br />the amount printed on your bill.",
        "newerBillLoaded": "The details you've entered are from an old bill. For your convenience, we have loaded the most recent bill that reflects your latest information.",
        "demoDialog": "Demo Environment",
        "gatewayDown": "We are currently experiencing difficulty processing payments.  We apologize for the inconvenience, please try again later.",
        "cardsHidden": "We are currently experiencing difficulty processing credit card payments. Please make your payment using an echeck.",
        "eChecksHidden": "We are currently experiencing difficulty processing echeck payments. Please make your payment using a credit card.",
        "cardsExpired": "At least one of your credit cards has expired, or will expire before the date your scheduled payment date.",
        "contactTitle": "Thanks for reaching out!",
        "contactSubtitle": "We're excited to hear from you.",
        "phiWarning": "For your security, please exclude personal health information.",
        "contactSent": "Thank you for your message!",
        "contactThanks": "We'll get back to you as soon as possible.",
        "termsTitle": "Terms of Use",
        "welcomeBack": "Welcome back, {{name}}!",
        "termsUpdated": "Updates to Terms of Use<br/>& Privacy Policy",
        "logoutWarningHeader": "Your session is about to expire",
        "logoutWarningSubcontent": "For your security, this online session will end shortly due to inactivity.",
        "retryProcessPayment" : "We were unable to fully process your payment. Please retry your submission below. Don't worry, we will only charge your card once.",
        "feedbackTitleReceiptLink": "How was your online payment experience?",
        "feedbackTitleClosedMessageLink": "How was your customer service from {{providerName}}?",
        "feedbackSubtitlePaymentHappy": "Awesome! Any other comments?",
        "feedbackSubtitlePaymentSad": "Sorry to hear that! How can we improve?",
        "feedbackSubtitleClosedMessageHappy": "Great! Any other comments?",
        "feedbackSubtitleClosedMessageSad": "Sorry to hear that! What could your provider do better?",
        "feedbackCommentPlaceholder": "Start typing feedback...",
        "thanksForFeedback" : "Thank you for your feedback!",
        "accountIsFinanced": "This account is currently being serviced by {{financedBy}}.",
        "contactFinancingVendor": "To make a payment or for help with your account, please contact {{financedBy}} directly.",
        "updatePaymentFormHeader": "Select Payment Method",
        "updatePaymentFormConfirmHeader": "Change Payment Method",
        "updatePaymentMethodButtonText": "Confirm",
        "updatingPaymentMethodButtonText": "Updating...",
        "updateAndApplyPaymentMethodButtonText": "Confirm",
        "updateAndApplyingPaymentMethodButtonText": "Processing...",
        "confirmingButtonText": "Confirming...",
        "addAnotherCard" : "Add Another Card",
        "addAnotherECheck" : "Add Another eCheck",
        "addAnotherCardOrECheck" : "Add Another Card or eCheck",
        "updatesToTermsText" : "We have updated our <a href=\"https://www.patientco.com/terms-of-use/\" target=\"_blank\">Terms of Use</a> and <a href=\"https://www.patientco.com/privacy/\" target=\"_blank\">Privacy Policy</a> to make it easier for you to understand what information we collect and why. <br/><br/> As a friendly reminder, Patientco will never share your information with third party marketers, and you can always update your Communication Preferences within your Settings.  We encourage you to review our new <a href=\"https://www.patientco.com/terms-of-use/\" target=\"_blank\">Terms of Use</a> and <a href=\"https://www.patientco.com/privacy/\" target=\"_blank\">Privacy Policy</a>. <br/><br/> To accept the new <a href=\"https://www.patientco.com/terms-of-use/\" target=\"_blank\">Terms of Use</a> and <a href=\"https://www.patientco.com/privacy/\" target=\"_blank\">Privacy Policy</a>, click \"Accept\" below."
    },

    "values" : {
        "valueArg": "Patientco Value #{{number}}",
        "descriptions": {
            "1": "Put patients first. Patients are the heart of everything we do at Patientco.",
            "2": "Work to serve. We do whatever it takes to make patients happy.",
            "3": "Be honest: We do what we say we’re going to do.",
            "4": "Create win-wins. We believe in a world where patients and providers work together.",
            "5": "Strive for Simplicity. We fight to make it easy for patients to manage their healthcare expenses.",
            "6": "Make healthcare better. We exist to make patients' healthcare experience better every day."
        }
    },

    "links" : {
        "whyPatientco" : "About Patientco",
        "news" : "News",
        "termsOfService" : "Terms of Use",
        "careers" : "Careers",
        "privacyPolicy" : "Privacy Policy"
    },

    "misc" : {
        "conjunction": "and",
        "on": "on"
    },

    "errors" : {
        "email" : "Please enter an email address that matches standard format. Here's an example: james.smith@example.com.",
        "emailUnavailable": "You've entered an email address that has already been registered. Try logging in to access your information.",
        "emailInUse": "You've entered an email address that has already been registered.",
        "password" : "Your password must contain at least 8 characters, 1 uppercase letter, and 1 number.",
        "confirmPassword" : "It looks like the passwords you have entered don't match! Double check to make sure both entries are exactly the same.",
        "allNumericSCode": "Try entering the SecureHealthCode printed in green on your bill. It's located right above the blue one you've entered.",
        "scodeRequired": "Please enter the green SecureHealthCode located in the top right section of your bill below the Patientco logo.",
        "amountRequired": "Please enter the total balance printed on your bill. We'll ask for your desired payment amount in the next step.",
        "belowMinAmount": "You've entered a bill with a negative amount. Please enter the total balance printed on your bill.",
        "unableToFindBill": "We cannot find a bill matching the SecureHealthCode and total balance you've entered. Please check to make sure your details are correct.",
        "billThrottled": "You have attempted to find a bill too many times. For security reasons, your ability to search for bills has been disabled for a few minutes.",
        "unableToLogin": "The email address or password you've entered is incorrect.",
        "loginThrottled": "You have attempted to login too many times. For security reasons, your ability to login has been disabled for a few minutes.",
        "loginEmailRequired": "Please enter an email address to help us find your account.",
        "loginPasswordRequired": "Please enter your account password to log in to Patientco.",
        "minAmountArg": "Your payment amount must be greater than {{minAmount}}",
        "maxAmountArg": "Your payment amount must be less than or equal to your account balance of {{maxAmount}}",
        "dateRequired": "Please select a date from the calendar.",
        "ccRequired": "Please enter a credit or debit card number.",
        "ccSeemsInvalid": "Please enter a valid card number. You may have missing numbers or numbers that are out of order in your current input.",
        "ccUnknownCardType": "You've entered a card type that is not accepted by Patientco. Try using a Visa, MasterCard, Discover, or American Express card.",
        "ccExpired": "Based on the date you've entered, your card has expired and cannot be charged.",
        "ccPrematureExpiration": "You've entered a card that expires before your payment date. Please use a card that expires after {{payMonth}}/{{payYear}}.",
        "genericRequired": "Please complete this field to continue.",
        "genericCheckboxRequired": "Please read the disclaimer and check this box to continue.",
        "guestPayNotAvailable": "Guest pay is currently unavailable. Please make an account to proceed.",
        "invalidRoutingNumber": "The routing number you've entered is not valid.",
        "minimumAccountNumber": "Account numbers must be at least 4 digits.",
        "mismatchedAccountNumber": "The account numbers you've entered don't match. Please double check to make sure the numbers are exactly the same.",
        "unableToReset": "We are unable to reset your password. Make sure you are using the most recent Recover Password link in your email.",
        "paymentProcessingError" : "We are unable to process your payment. Please contact Patientco at 1-844-422-4779, to complete your payment.",
        "paymentInvalidCardInformation" : "The card information you inputted, is invalid. Please review the Card Number and Expiration for your payment method.",
        "paymentCardDeclined" : "We are unable to process your payment due to a declined card. Please edit your payment method and try again.",
        "paymentDuplicatePayment" : "It looks like you are trying to submit a duplicate payment. If this is intentional, please contact Patientco at 1-844-422-4779.",
        "confirmExpiredCard": "It looks like you're trying to pay with a card that has expired, or will expire before your scheduled payment date. Please edit your payment method and try again.",
        "confirmPrematureExpiration": "It looks like you're trying to schedule a payment after your credit card expires. Please edit your payment method and try again.",
        "phoneFormat": "It looks like you are missing some numbers in your phone number. Please enter a number that matches the format of XXX-XXX-XXXX",
        "smsEligible": "This number is not eligible to receive text messages. Please enter a valid US mobile number. If you don't have a mobile number, leave blank.",
        "termsAcceptanceRequired": "For legal reasons, we require all users to accept our Terms of Use.",
        "invalidPassword": "The Current Password you have given does not match the value we have in our system. Please check it and try again.",
        "unableToChangePassword": "We are unable to update your password at this time. Please verify your details and try again.",
        "nonexistentEmail": "There is no Patientco account associated with this email.",
        "minZipCode": "Please enter a 5 digit zip code to continue.",
        "fiveHundred": "It looks like the server encountered an error while processing your request. Please try again in a few minutes.",
        "runningOutOfCharacters": "You have used {{current}} / {{allowed}} characters",
        "reasonRequired":  "Please select a message reason",
        "messageRequired": "Please enter your message to continue",
        "futureBirthdate": "You've entered a DOB that takes place in the future.",
        "incompleteBirthdate": "Please enter a full DOB (MM/DD/YYYY).",
        "invalidDate": "Date doesn't exist.",
        "adultRequired": "You must be at least 18 years of age to continue.",
        "unknown": "An unknown error occurred. Try again. If you continue to have problems, please contact Patientco at 1-844-422-4779.",
        "paymentFormProcessingError" : "We are unable to add your payment method. Please contact Patientco at 1-844-422-4779.",
        "paymentFormInvalidCardInformation" : "Your card information appears to be invalid. Please review the Card Number and Expiration for your payment method.",
        "ssnFormat": "It looks like you are missing some numbers in your social security number. Please enter a number that matches the format of XXX-XX-XXXX",
        "poBoxNotAllowed": "We do not allow PO Boxes at this time."
    },

    "accountEvents": {
        "bill" : "Bill Sent",
        "collection" : "collection",
        "collection-stop" : "collection-stop",
        "collection-update" : "collection-update",
        "collection-extraction": "collection-extraction",
        "correspondence" : "Correspondence Received",
        "correspondenceOnly" : "correspondenceOnly",
        "message" : "Message Sent: {{message}}",
        "changeOfAddress" : "Address Changed",
        "payment" : "Payment Made",
        "paymentPlan" : "Payment Scheduled",
        "returnMail" : "Returned Mail",
        "reversePayment" : "Refund Processed",
        "balanceUpdate" : "Balance Updated",
        "voidedPayment" : "Payment Voided",
        "declinedPayment" : "Payment Declined",
        "canceledPayment" : "Payment Canceled",
        "voidedPaymentPlan" : "Payment Plan Voided",
        "canceledPaymentPlan" : "Payment Plan Canceled"
    },

    "transworld": {
        "clientRefNum": "Client Reference #",
        "clientRefNumRequired": "Please enter the Client Reference # at the top right section of your bill.",
        "allNumericlientRefNum": "Try entering the Client Reference # printed on your bill. It's located in the top right section of your bill."
    },

    "fullPageNotifications": {
        "header": {
            "thankYou": "Thank you!"
        },
        "subheader": {
            "emailVerified": "Your email address has been verified.",
            "phoneVerified": "Your phone number has been verified."
        },
        "message": {
            "welcome": "We're happy to have you here! You've joined a community of 2 million consumers who are taking control of their healthcare expenses."
        }
    }
}
);

$translateProvider.translations("es", {
  "dashboard": {
    "dashboard": "Proveedores",
    "zeroStateTitle": "No hay proveedores",
    "zeroStateBlurb": "Ingrese los detalles de su factura más reciente de Patientco para acceder a los saldos actualizados, la actividad de facturación pasada y los detalles de la transacción entre sus proveedores de atención médica",
    "argsDashboard": "{{name}}'s Proveedores",
    "argsDashboardEndsWithS": "{{name}}' Proveedores"
  },
  "encounterLinking": {
    "linkableAccounts": "Hemos encontrado cuentas adicionales para agregar a su PatientWallet<sup>&reg;</sup>. <a href=\"\" class=\"link inline\"> Verificar cuentas </a>",
    "proactiveLinkableAccounts": "¿Desea que agreguemos automáticamente las cuentas de {{providerGroupList | listCombine}} a su PatientWallet<sup>&reg;</sup>? <a href=\"\" class=\"link inline\"> Verificar cuentas < / a>",
    "experiencedLinkableAccounts": "Tiene más cuentas para agregar a su PatientWallet<sup>&reg;</sup>. <a href=\"\" class=\"link inline\"> Verificar cuentas </a>",
    "verifyInformation": "Verificar información",
    "verifyingInformation": "Información de verificación...",
    "whichPatient": "¿A quién le gustaría verificar?",
    "verifyAcctTitle": "Información de verificación para <br /> {{linkingName}}",
    "verifyAcctSubtitlePatient": "Ingrese los campos de seguridad a continuación para agregar {{patientName | possessive}} cuentas de {{providerGroup}}.",
    "verifyAcctSubtitleGuarantor": "Ingrese su información a continuación para agregar {{patientName | possessive}} cuentas de {{providerGroup}}.",
    "birthdatePlaceholder": "Fecha de nacimiento (MM / DD / YYYY)",
    "SSNPlaceholder": "últimos cuatro dígitos del número de seguro social",
    "ssnHelpInfo": "<b> Nota: </b> Si agrega cuentas para un infante, use el SSN del garante",
    "moreInfo": "Cómo funciona este trabajo?",
    "verificationFailed": "La información que ha ingresado no coincide con nuestros registros para {{patientName}}. Inténtelo de nuevo.",
    "verificationBlocked": "Ha intentado agregar {{patientName | possessive}} cuenta demasiadas veces. Por razones de seguridad, su capacidad para agregar esta cuenta se ha deshabilitado temporalmente",
    "verificationHeader": "Cuenta agregada con éxito",
    "verificationHeaderPlural": "Cuentas agregadas con éxito",
    "verificationSubheader": "En el futuro, agregaremos automáticamente {{patientName | possessive}} facturas de {{providerGroup}} a su PatientWallet<sup>&reg;</sup>.",
    "proactiveExperiencedVerificationSubheader": "{{patientName}} no tiene cuentas anteriores para agregar, pero agregaremos automáticamente sus cuentas futuras para {{providerGroup}} a su PatientWallet<sup>&reg;</sup>.",
    "incompleteSSN": "Parece que le faltan unos pocos dígitos. Por favor ingrese los últimos cuatro números de su número de seguro social.",
    "proactiveVerificationHeader": "{{PatientName}} verificado exitosamente.",
    "close": "Cerrar",
    "continueToPay": "Continue to Bill"
  },
  "firstBill": {
    "optionsHeader": "Verificar información para {{patientFirstName}}",
    "optionsSubheader": "Cómo le gustaría ¿Verifique su identidad?",
    "verifyEmailHeader": "¡Revise su correo electrónico!",
    "verifyEmailSubheader": "Ingrese el código que le enviamos a su dirección de correo electrónico.",
    "verifySmsHeader": "¡Revise su teléfono!",
    "verifySmsSubheader": "Ingrese el código que enviamos a su dispositivo móvil por mensaje de texto.",
    "verifyPdivHeader": "Ingrese los campos de seguridad a continuación para acceder a {{patientFirstName | possessive}} factura de {{providerName}}",
    "birthdatePlaceholder": "Fecha de nacimiento (MM / DD / YYYY)",
    "SSNPlaceholder": "últimos cuatro dígitos del número de seguro social",
    "incompleteSSN": "Parece que le faltan unos pocos dígitos. Ingrese los últimos cuatro números de su SSN.",
    "verifyViaEmailOption": "Envíeme un código por correo electrónico ({{emailAddress}})",
    "verifyViaSmsOption": "Envíeme un código de texto ({{phoneNumber}})",
    "verifyViaPdiv": "Ingrese la fecha de nacimiento y los últimos cuatro de SSN",
    "sendAgainButtonText": "No recibí un código",
    "sendingCodeButtonText": "Enviando...",
    "back": "Atrás",
    "submitText": "Siguiente",
    "submittingText": "Comprobando...",
    "successHeader": "Ha sido verificado!",
    "successSubheader": "Haga clic a continuación para ver su factura.",
    "successModalButton": "Ver factura",
    "codeNotSent": "Hubo un problema al enviar ese código. Vuelva a intentarlo.",
    "codeSent": "El código ha sido enviado.",
    "codeInvalid": "El código no era válido o caducó. Inténtelo de nuevo.",
    "pdivInvalid": "Uno o más campos no coinciden con nuestros registros. Si cree que su información es correcta, comuníquese con su proveedor.",
    "bruteForce": "La cuenta se deshabilitó temporalmente debido a demasiados intentos fallidos.",
    "unableToAuth": "No podemos autenticar su cuenta en este momento. Su factura llegará pronto por correo.",
    "missingGuarantorName": "Paciente"
  },
  "confirmPhone": {
    "header": "Registro completo de la cuenta",
    "numberSubheader": "Verifique su número de teléfono móvil",
    "codeSubheader": "Ingrese el código que enviamos a su dispositivo móvil por SMS",
    "submitButtonText": "Enviar código",
    "submittingButtonText": "Enviando...",
    "verifyButtonText": "Verificar",
    "verifyingButtonText": "Verificando...",
    "sendAgainButtonText": "No recibí un código",
    "successHeader": "Gracias por verificar su número de teléfono",
    "learnMore": "Más información",
    "hide": "Ocultar",
    "additionalInfo": "Al verificar su número de teléfono, usted acepta que Patientco le envíe mensajes de texto periódicos para cualquier actividad relacionada con la cuenta. Patientco puede usar un sistema automatizado para enviarle comunicaciones electrónicas que incluyen texto, teléfono o correo electrónico. Patientco no tiene un cargo por separado para este servicio; sin embargo, sujeto a los términos y condiciones de su operador de telefonía móvil, pueden aplicarse tarifas de mensajes y datos. Al proporcionar su consentimiento para participar en este programa, usted aprueba cualquier cargo de su proveedor de telefonía móvil. Puede optar por no participar en este programa en cualquier momento enviando un correo electrónico a Patientco a support@patientco.com.",
    "explanation": "Patientco le enviará un mensaje de texto con un código único para verificar su número de teléfono móvil.",
    "errors": {
      "sentAgain": "¡Lo siento! Te enviamos otro código.",
      "codeNotSent": "Hubo un problema al enviar ese código. Por favor, inténtelo de nuevo.",
      "codeInvalid": "Ese código no era válido o expiró Vuelva a intentarlo."
    }
  },
  "confirmEmail": {
    "header": "Verificar dirección de correo electrónico",
    "submitButtonText": "Enviar correo electrónico",
    "submittingButtonText": "Enviando...",
    "explanation": "¿Es correcta la dirección de correo electrónico? De lo contrario, corríjalo antes de hacer clic en el botón \"Enviar correo electrónico\" para actualizar su cuenta",
    "successHeader": "Correo electrónico de verificación enviado",
    "emailNotSent": "Hubo un problema al enviar ese correo electrónico. Inténtelo de nuevo."
  },
  "receipt": {
    "receipt": "Recibo",
    "argPaidCallout": "<b> {{amount}} </b> Pagado",
    "argScheduledCallOut": "<b> {{amount}} </b> Programado",
    "argRemainingCallOut": "<b> {{amount}} </b> Restante",
    "transactionIdArg": "ID de transacción # {{transactionId}}",
    "balancePayment": "Pago de saldo",
    "argDeclinedCallout": "<b> {{amount}} </b> Rechazado",
    "argCanceledCallout": "<b> {{amount}} < / b> Cancelado",
    "confirmCancelDialog": "¿Está seguro de que desea <br /> cancelar este pago?",
    "cancelPaymentDesc": "{{amount}} programado para {{date}}",
    "fullRefund": "Reembolso completo",
    "partialRefund": "Reembolso parcial",
    "chargeback": "Devolución de cargo",
    "failedECheck": "Failed eCheck",
    "bouncedCheck": "Bounce Compruebe",
    "paymentMethodUpdated": "Se ha actualizado el método de pago.",
    "paymentMethodHeld": "No hemos podido procesar un pago. Seleccione otro método de pago y vuelva a intentarlo.",
    "updateMethodScheduledPlanConfirmDialog": "Los pagos restantes en su plan se actualizarán para usar {{method}}.",
    "updateMethodHeldPlanConfirmDialog": "Los pagos restantes en su plan se actualizarán para usar {{method}}. < br /> <br/> Todos los pagos en el plan que fueron rechazados o retenidos previamente se procesarán usando el nuevo método de pago."
  },
  "newbill": {
    "whichAccount": "¿Qué factura le gustaría pagar?",
    "accountDue": "<b> {{providerName}} < / b> <br/> Cuenta # {{accountNumber}} | {{amountDue}} due",
    "accountPastDue": "<b> {{providerName}} </b> <br/> Cuenta # {{accountNumber}} | {{amountDue}} vencido"
  },
  "message": {
    "message": "Mensaje",
    "plural": "Mensajes",
    "messageSent": "¡Su mensaje ha sido enviado!",
    "argsMessages": "Mensajes de {{name}}",
    "argsMessagesEndsWithS": "Mensajes de {{name}}",
    "createAreaPlaceholder": "Comience a escribir su respuesta...",
    "whoToContact": "¿A quién le gustaría contactar?",
    "whichAccount": "¿Con qué cuenta necesita ayuda?",
    "noProvidersToMessage": "Su PatientWallet<sup>&reg;</sup> está actualmente vacía. Agregue una nueva factura para comenzar.",
    "startMessagePlaceholder": "Comience a escribir su mensaje...",
    "messageClosedByProviderDialog": "Este hilo de mensajes está cerrado.",
    "startNewMessageDialog": "Si desea iniciar otro hilo de mensajes, <span> haga clic aquí </ span >.",
    "argProviderCantMessage": "{{name}} no admite mensajes a través de Patientco. Si desea comunicarse con su proveedor, llame a {{billingPhoneNumber}}. Nos disculpamos por cualquier inconveniente.",
    "reasons": {
      "default": "Seleccione un motivo",
      "specificCharge": "Cuestione un cargo específico",
      "duplicate": "Cargos / facturas duplicadas de preguntas",
      "paymentPlan": "Configure un plan de pago",
      "missingPayments": "Consulte acerca de pagos faltantes",
      "other": "Otro",
      "updateInsurance": "Actualización La información",
      "insuranceQuestion": "Haga una pregunta sobre el seguro",
      "financialAssistance": "Solicite ayuda financiera",
      "itemizedBill": "Solicite una factura detallada"
    },
    "zeroStateTitle": "Sin mensajes",
    "zeroStateBlurb": "Patientco le permite comunicarse de manera segura con todos sus proveedores de atención médica en un solo lugar. Haz una pregunta sobre tu factura, configura un plan de pago o simplemente di las gracias"
  },
  "notification": {
    "notification": "Notificación",
    "plural": "Notificaciones",
    "zeroStateTitle": "Sin notificaciones"
  },
  "login": {
    "minCharactersRule": "8 caracteres",
    "upperCaseRule": "1 letra mayúscula",
    "digitRule": "1 número",
    "nextButton": "Siguiente",
    "forgotPasswordQuestion": "¿Olvidó su contraseña?",
    "forgotPasswordQuestionSubcontent": "Lo cubrimos",
    "forgotPasswordSentDialog": "¡Genial! Hemos enviado ayuda a <br /> <b> {{email}} </b>",
    "forgotPasswordMethodHeader": "¿Cómo quieres restablecer tu contraseña?",
    "forgotPasswordMethodEmailButton": "Enviarme un correo electrónico ({{email}}))",
    "forgotPasswordMethodSmsButton": "Texto me ({{phone}})",
    "forgotPasswordMethodCode": "Por favor ingrese su código de verificación",
    "forgotPasswordCheckEmailTitle": "Revise su correo electrónico y haga clic en el enlace",
    "forgotPasswordEmailSentTryAgainButton": "Intente nuevamente",
    "forgotPasswordMethodSendAgainButton": "No recibí un código",
    "resetPasswordHeader": "Cree su nueva contraseña .",
    "resetPasswordCompleteDialog": "Ahora puede iniciar sesión con su correo electrónico y nueva contraseña.",
    "resetPasswordCheckEmailMessage": "Acabamos de enviar un correo electrónico con un enlace para restablecer la contraseña a <br /> <b> {{email}} </b>. <Br / > <br /> Si no lo ve en su bandeja de entrada, asegúrese de revisar su carpeta de correo no deseado."
  },
  "setting": {
    "menu": {
      "contact": "Información de contacto",
      "savedPaymentMethods": "Métodos de pago guardados",
      "accountManagement": "Administración de cuentas"
    },
    "setting": "Configuración",
    "plural": "Configuración",
    "argsSettings": "{{name}} 's Settings",
    "argsSettingsEndsWithS": "{{name}}' Settings",
    "ebillDialog": "Envíeme un correo electrónico cuando se agreguen nuevas facturas a mi cuenta.",
    "smsDialog": "Envíeme un mensaje de texto cuando se agreguen nuevas facturas a mi cuenta.",
    "argMethodDeleted": "{{method}} <br /> se ha eliminado",
    "deleteMethodArg": "Delete {{detail}}",
    "confirmDeleteDialog": "¿Está seguro de que desea eliminar este pago método?",
    "passwordChangedDialog": "Su contraseña se ha cambiado correctamente.",
    "unremovableMethodHelp": "Este el método de pago se utiliza para realizar un pago programado <br /> y no se puede eliminar.",
    "nonDeactivateableUserHelp": "No se puede cancelar ivate su cuenta porque <br/> usted tiene uno o más pagos programados.",
    "modals": {
      "email": {
        "header": "Comunicaciones por correo electrónico",
        "content": "Al habilitar las notificaciones por correo electrónico, le otorga a Patientco permiso para enviarle periódicamente por correo electrónico las nuevas facturas o recordatorios sobre las facturas con vencimiento pendiente. fechas. La notificación por correo electrónico es una función opcional de Patientco que se deshabilita hasta que modifique la configuración de notificación en la pestaña \"Configuración\" o en la página de registro. Puede optar por habilitar o deshabilitar esta función en cualquier momento, pero los cambios pueden demorar hasta 48 horas para entrar en vigencia. Patientco se reserva el derecho de interrumpir las notificaciones de la factura electrónica en cualquier momento, a nuestra entera discreción. Su información médica protegida (PHI) no se incluirá en ningún correo electrónico. Su PHI solo se puede ver iniciando sesión en Patientco usando su dirección de correo electrónico registrada."
      },
      "sms": {
        "header": "Comunicaciones SMS",
        "content": "Al habilitar las notificaciones de mensajes de texto, usted acepta que Patientco le envíe mensajes de texto periódicos para cualquier actividad relacionada con la cuenta. Patientco puede usar un sistema automatizado para enviarle comunicaciones electrónicas que incluyen texto, teléfono o correo electrónico. Patientco no tiene un cargo por separado para este servicio; sin embargo, sujeto a los términos y condiciones de su operador de telefonía móvil, pueden aplicarse tarifas de mensajes y datos. Al proporcionar su consentimiento para participar en este programa, usted aprueba cualquier cargo de su proveedor de telefonía móvil. Puede optar por no participar en este programa en cualquier momento en la pestaña \"Configuración\" en su PatientWallet<sup>&reg;</sup>."
      }
    },
    "methodAdded": "{{method}} <br/> se ha agregado"
  },
  "landing": {
    "title": "Patientco | Pague su factura",
    "enterBills": "Ingrese los detalles únicos de su factura",
    "marketingBlurb": "Bienvenido a PatientWallet<sup>&reg;</sup> de Patientco! Nos hemos asociado con su proveedor de atención médica para brindarle la experiencia de pago más simple y segura posible.",
    "needHelpHeader": "¿Necesita ayuda para encontrar su factura?",
    "needHelpContent": "Haga clic en las casillas blancas proporcionadas para ingresar su SecureHealthCode y Balance Due",
    "logoTransition": "Patientco ¡tiene un nuevo aspecto!"
  },
  "contact": {
    "help": {
      "header": "Necesito ayuda con:",
      "myBill": "Comprender mi factura",
      "lostBill": "Perdí mi factura de Patientco",
      "loggingIn": "Iniciar sesión",
      "itemizedBill": "Necesito una factura detallada",
      "resetPassword": "Restablecer mi contraseña"
    },
    "questionAbout": {
      "header": "Yo tiene una pregunta sobre: ​​",
      "reasons": {
        "specificCharge": "Un cargo en mi factura",
        "duplicate": "Un cargo o factura duplicada",
        "paymentPlan": "Creación de un plan de pago",
        "missingPayments": "Pagos faltantes",
        "other": "Otro",
        "updateInsurance": "Información de actualización",
        "insuranceQuestion": "Seguro",
        "financialAssistance": "Financiero asistencia",
        "itemizedBill": "Solicitando una factura detallada"
      }
    },
    "rightPlace": {
      "header": "Vamos a llevarlo al lugar correcto",
      "enterEmail": "Ingrese su dirección de correo electrónico:",
      "enterBillDetails": "Ingrese los detalles de su factura:"
    },
    "almostThere": {
      "header": "Casi allí...",
      "password": "Cree una contraseña:",
      "login": "Inicie sesión en su cuenta:"
    }
  },
  "actions": {
    "pay": "Pagar",
    "makePayment": "Realizar un pago",
    "viewMyBill": "Ver factura",
    "viewBill": "Ver factura",
    "viewPlan" : "Ver Plan",
    "viewAccounts": "Ver cuentas",
    "viewAccount": "Ver cuenta",
    "viewMessage": "Ver mensaje",
    "view": "Ver",
    "verify": "Verificar",
    "verifying": "Verificar...",
    "submit": "Enviar",
    "submitting": "Enviar...",
    "messageProvider": "Proveedor de mensajes",
    "updatePaymentForm": "Cambiar el método de pago",
    "createMessage": "Crear nuevo mensaje",
    "continue": "Continuar",
    "continueSingle": "Crear un Pago único",
    "continuePlan": "Crear un plan de pago",
    "processPayment": "Procesar pago",
    "retryProcessPayment": "Reintentar proceso",
    "processPaymentFuture": "Programar pago",
    "createAccount": "Crear cuenta",
    "creatingAccount": "Crear cuenta...",
    "login": "Iniciar sesión",
    "loggingIn": "Iniciar sesión... .",
    "logout": "Cerrar sesión",
    "payNewBill": "Pagar nueva factura",
    "payBill": "Pagar factura",
    "payAnotherBill": "Pagar otra factura",
    "openReceipt": "Ver recibo",
    "findBill": "Encontrar factura",
    "findingBill": "Encontrar factura...",
    "needHelp": "¿Necesita ayuda?",
    "contactUs": "Contáctenos",
    "learnMore": "Obtenga más información",
    "goToDashboard": "Ver proveedores",
    "open": "Abrir",
    "sendMessage": "Enviar mensaje",
    "sending": "Enviando mensaje...",
    "printReceipt": "Imprimir recibo",
    "printPlan": "Imprimir detalles del plan",
    "back": "Volver",
    "backToDashboard": "Volver a proveedores",
    "backToMessages": "Volver a los mensajes",
    "backToArg": "Volver a {{properNoun}}",
    "deleteCard": "Eliminar tarjeta",
    "deleteECheck": "Eliminar eCheck",
    "addNewMethod": "Agregar nuevo método",
    "changePassword": "Cambiar contraseña",
    "deactivateAccount": "Desactivar Cuenta",
    "confirmPassword": "Confirmar contraseña",
    "close": "Cerrar",
    "cancel": "Cancelar",
    "saveChanges": "Guardar cambios",
    "forgotPassword": "¿Olvidó su contraseña?",
    "retrievePassword": "Recuperar contraseña",
    "resetPassword": "Restablecer contraseña",
    "visitWebsite": "Visitar sitio web",
    "cancelPayment": "Cancelar pago",
    "viewOlderActivity": "Ver Actividad anterior",
    "stayLoggedIn": "Manténgase conectado",
    "sendFeedback": "Enviar comentarios",
    "sendingFeedback": "Enviando...",
    "viewZeroBalanceAccts": "Ver {{count}} Cuentas pagadas",
    "enterSHCTooltip": "Ingrese el SecureHealthCode que está impreso en su factura",
    "enterAmountTooltip": "Ingrese el El saldo total adeudado que está impreso en su factura, incluso si tiene la intención de pagar una cantidad diferente",
    "confirmPhoneNumber": "Verifique su número de teléfono móvil",
    "confirmEmail": "Haga clic aquí para verificar su dirección de correo electrónico: {{email}}",
    "processPaymentForm": "Guardar Método de pago",
    "retryProcessPaymentForm": "Volver a intentar método de pago",
    "confirmAndProcess": "Confirmar y procesar",
    "accept" : "Aceptar",
    "switchLanguage" : "Switch to English"
  },
  "labels": {
    "providerName": "Nombre del proveedor",
    "argAccount": "{{num}}&nbsp;Cuenta",
    "argAccounts": "{{num}}&nbsp;Cuentas",
    "argNumPastDue": "{{num}}&nbsp;Pasado&nbsp;Due",
    "argNumAccountsPastDue": "{{num}}&nbsp;Cuentas&nbsp;Pasado&nbsp;Due",
    "argNumAccountPastDue": "{{num}}&nbsp;Account&nbsp;Pasado&nbsp;Due",
    "accountNumber": "Número de cuenta",
    "accountNumberConfirm": "Confirmar número de cuenta",
    "guarantorName": "Nombre del garante",
    "name": "Nombre",
    "firstName": "Nombre",
    "lastName": "Apellido",
    "email": "Dirección de correo electrónico",
    "password": "Contraseña",
    "passwordConfirm": "Confirmar contraseña",
    "statementSent": "Fecha de facturación",
    "balancDue": "Fecha de vencimiento",
    "dueDate": "Fecha de vencimiento",
    "dueArg": "Fecha de vencimiento {{date}}",
    "argDue": "{{amount | currency}} vencido",
    "pastDue": "Vencido vencido",
    "billSummary": "Resumen de la factura",
    "nameOnCard": "Nombre en la tarjeta",
    "cardNumber": "Número de tarjeta",
    "expirationFormat": "MM / YY",
    "routingNumber": "Número de ruta",
    "accountHolderName": "Nombre del titular de la cuenta",
    "accountNumberArg": "Número de cuenta {{number}}",
    "address": "Dirección",
    "address2": "Línea de dirección 2 ( opcional)",
    "city": "Ciudad",
    "state": "Estado",
    "zip": "Código postal",
    "date": "Fecha",
    "status": "Estado",
    "description": "Descripción",
    "amount": "Cantidad",
    "secureCodeOnBill": "SecureHealthCode en la Factura",
    "balanceOnBill": "Saldo Total Pendiente en la Factura",
    "secureCode": "SecureHealthCode",
    "totalBalanceDue": "Saldo Total Adeudado",
    "argBalance": "{{balance}} Saldo",
    "enterUniqueDetails": "Ingrese los detalles de su nueva factura",
    "badDebt": "Remitido a una agencia externa",
    "servicedByFinancingVendor": "Reparado por {{financedBy}}",
    "noBalance": "Sin saldo adeudado",
    "pendingBalance": "Pendiente",
    "remainingBalance": "Saldo restante",
    "negativeBalance": "Comuníquese con su proveedor para obtener información sobre su saldo negativo",
    "yourBillAmount": "Cantidad de facturas",
    "billAmount": "Cantidad de facturas",
    "newBalance": "Saldo actual",
    "updating": "Actualizando...",
    "loading": "Cargando...",
    "noAccountActivity": "No hay actividad en la cuenta",
    "contactProvider": "Contacto del proveedor",
    "providerResources": "Recursos del proveedor",
    "messageDetails": "Detalles del mensaje",
    "argPaid": "{{amount}} Pagado",
    "argScheduled": "{{amount}} Programado",
    "argTotal": "{{amount}} Total",
    "amountPaid": "Cantidad pagada",
    "details": "Detalles",
    "userDetails": "Detalles del usuario",
    "futurePayment": "Pago futuro",
    "createdArg": "Creado {{date}}",
    "communicationPrefs": "Preferencias de comunicación",
    "accountManagement": "Administración de cuentas",
    "savedMethods": "Métodos de pago guardados",
    "financingMethods": "Cuentas de financiamiento",
    "noSavedMethods": "Guardado los métodos de pago aparecerán aquí.",
    "noFinancingMethods": "Actualmente no hay financiación ac cuenta. Consulte con su proveedor para ver si hay financiamiento disponible",
    "phoneNumber": "Teléfono móvil",
    "primaryPhone": "Teléfono principal",
    "currentPassword": "Contraseña actual",
    "newPassword": "Nueva contraseña",
    "confirmNewPassword": "Confirmar nueva contraseña",
    "submittingPayment": "Procesando pago...",
    "submittingPaymentForm": "Ahorro. ..",
    "retrievingPassword": "Recuperar contraseña...",
    "submittingPaymentFuture": "Programar pago...",
    "resettingPassword": "Restablecer contraseña...",
    "savingChanges": "Guardar cambios...",
    "acceptanceOfTerms": "Estoy de acuerdo con el activador <a name = \"testing\" de Patientco- modal = \"términos\"> Términos de uso </a>",
    "acceptanceOfTermsRaw": "Acepto los Términos de uso de Patientco",
    "changesSaved": "Cambios guardados",
    "changesFailed": "No se pueden guardar los cambios",
    "declinedPayment": "Pago rechazado",
    "paymentDeclined": "Pago rechazado",
    "argDeclined": "{{amount}} Rechazado",
    "canceledPayment": "Pago cancelado",
    "paymentCanceled": "Pago cancelado",
    "argCanceled": "{{amount}} Cancelado",
    "providerDisabledExplanation": "{{provider.name}} ya no está trabajando con Patientco para aceptar pagos en línea. Para obtener ayuda para realizar un pago, llame a {{provider.name}} al {{provider.number}}. Nos disculpamos por cualquier inconveniente.",
    "providerDisabledExplanationShort": "{{provider.name}} ya no está trabajando con Patientco para aceptar pagos en línea",
    "paymentPlanTitle": "Detalles del plan",
    "paymentPlanStatus": "Plan de pagos {{status}}",
    "verificationCode": "Código de verificación",
    "receivesEbills": "Envíeme por correo electrónico nuevas facturas y recordatorios",
    "receivesSms": "Envíeme un mensaje de texto nuevas facturas y recordatorios",
    "socialSecurityNumber": "Número de Seguro Social",
    "birthdate": "Fecha de nacimiento (MM / DD / AAAA)",
    "income": "Ingreso mensual del hogar",
    "changePassword": "¿Necesita cambiar su ¿contraseña?",
    "deactivateAccount": "¿Necesita desactivar su cuenta?",
    "invalidPassword": "Contraseña no válida",
    "moreInfo": "Más información"
  },
  "paymentForm": {
    "method": {
      "stepName": "Método"
    },
    "billing": {
      "stepName": "Dirección"
    },
    "confirm": {
      "stepName": "Confirmación"
    }
  },
  "payment": {
    "amount": {
      "question": "¿Cuánto desea pagar?",
      "selectDifferent": "Por favor, seleccione una opción de pago diferente:",
      "stepName": "Monto de pago",
      "payBillBalArg": "Monto de factura de pago: <b> {{calculatedTotal}} </b>",
      "payBillBalArgDiscount": "Monto de factura de pago con {{discountPolicy}}&nbsp;Descuento: <br /> <b> {{calculatedTotal}} </b>",
      "payAccountBalArg": "Pagar saldo de la cuenta: <b> {{calculatedTotal}} </b>",
      "payAccountBalArgDiscount": "Pagar saldo de la cuenta con {{discountPolicy}}&nbsp;Descuento: <br / > <b> {{calculatedTotal}} </b>",
      "payAnother": "Pagar otra cantidad",
      "otherPaymentOptions": "Revisar otras opciones de pago",
      "payOnce": "¿Desea realizar un pago único?",
      "payOverTime": "¿Desea pagar en el tiempo? ?",
      "planInstallmentOption": "{{installmentAmount}} / month, {{installmentCount}} months",
      "messageProvider": "Message Provider",
      "reviewFinancingOptions": "Review Financing Options",
      "lowMonthlyPaymentOptions" : "Opciones de Pago Mensuales Bajas",
      "paymentsAsLowAs" : "Pagos Tan Bajos Como {{monthlyPayment | currency}}/Mes*",
      "paymentsAsLowAsDisclaimer" : "* El pago mensual mínimo de {{monthlyPayment | currency}} se estima en base a {{amount | currency}} financiados al {{apr}}% TAE durante {{duration}} meses. La tasa de porcentaje anual real varía entre 0% y 29.99% y los plazos de pago varían entre 24 y 48 meses, dependiendo de su solvencia. Los pagos variarán según el monto del préstamo y la oferta recibida.",
      "getFinancingOptionsErrorMessage": "Ocurrió un error al intentar cargar sus opciones de financiamiento. Vuelva a intentarlo.",
      "noFinancialOptionsFound": "Lo sentimos, no encontramos ninguna opción de financiamiento para ofrecerte.",
      "planDisabledBecauseOfBadDebt": "Fecha de pago"
    },
    "date": {
      "question": "¿Cuándo le gustaría pagar?",
      "stepName": "Fecha de pago",
      "todayArg": "Fecha: <b> {{todayDate}} </b>",
      "laterDate": "Pagar otra fecha"
    },
    "method": {
      "question": "¿Cómo desea pagar?",
      "stepName": "Método de pago",
      "cardMethod": "Tarjeta de crédito / débito",
      "eCheckMethod": "Cheque electrónico",
      "userAnotherCard": "Usar otra tarjeta",
      "userAnotherECheck": "Usar otro cheque electrónico",
      "userAnotherCardOrECheck": "Usar otra tarjeta o cheque electrónico"
    },
    "billing": {
      "question": "¿Cuál es su dirección de facturación para <br /> {{methodSummary}}?",
      "stepName": "Dirección de facturación",
      "useAnotherAddress": "Usar otra dirección de facturación"
    },
    "confirm": {
      "planAmount": "{{installmentAmount}} / month for {{installmentCount}} meses",
      "question": "¿Se ve todo bien?",
      "stepName": "Confirmación",
      "cardBilling": "Dirección de facturación de la tarjeta",
      "eCheckBilling": "Dirección de facturación eCheck"
    },
    "friendlyType": {
      "standard": "Pago",
      "badDebt": "Pago de deuda incobrable",
      "balance": "Pago de saldo",
      "copay": "Copago",
      "insurance": "Pago de seguro",
      "prepayment": "Pago adelantado",
      "other": "Otro"
    },
    "financing": {
      "application": {
        "stepName": "Solicitud de financiamiento",
        "financingApplyHeader": "Para ver si hay ofertas disponibles, complete este formulario:",
        "electronicDisclosureConsentText": "Al marcar la casilla, usted certifica que ha leído, retenido y acepta el <a href='{{electronicDisclosureConsentLink}} target''_blank'> Consentimiento para revelaciones electrónicas </a> y entiendo que esto constituye su firma electrónica nos autoriza a procesar este formulario en su nombre",
        "electronicDisclosureConsentError": "Es necesario aceptar el consentimiento de las Divulgaciones electrónicas",
        "creditScoreNotice": "Ver ofertas no afecta su puntaje de crédito",
        "financingViewOffers": "Ver mis ofertas",
        "monthlyIncomeHeader": "Total bruto mensual Ingresos familiares",
        "monthlyIncomeBody": "No se deben incluir los ingresos de pensión alimenticia, manutención infantil o mantenimiento por separado si no desea que se considere como base para el pago de esta obligación. <br> <br> Residentes casados ​​de Wisconsin: combine su información y la de su cónyuge en esta solicitud.",
        "incomeGreaterThanZeroError": "El ingreso debe ser mayor que cero.",
        "disclaimersHeader": "Lea las exenciones de responsabilidad a continuación y desplácese hacia abajo para ver sus ofertas",
        "newAccountProceduresHeader": "Información importante sobre los procedimientos para abrir una nueva cuenta",
        "newAccountProceduresBody": "Para ayudar al gobierno a combatir la financiación de las actividades de terrorismo y lavado de dinero, la ley federal exige que todas las instituciones financieras obtengan, verifiquen y registren información que identifique a cada persona que abre una cuenta. Qué significa esto para usted: cuando solicite un préstamo, le pediremos su nombre, domicilio, dirección de correo electrónico, fecha de nacimiento y otra información que nos permitirá identificarlo. También podemos pedir ver su licencia de conducir u otros documentos de identificación",
        "stateLawNoticesHeader": "Avisos de la Ley del Estado",
        "stateLawNoticesBody": "<p> <b> Residentes de California: </b> Si está casado, puede solicitar una cuenta separada. </ P > <p> <b> Residentes de Delaware: </b> Los cargos por servicio que no excedan de los permitidos por la ley se cargarán en los saldos pendientes de mes a mes. </p> <p> <b> Residentes de Maryland: < / b> Los cargos por financiamiento se impondrán en montos o tasas que no excedan de los permitidos por la ley. </p> <p> <b> Residentes de Nueva York: </b> los residentes de Nueva York pueden comunicarse con el Departamento de Estado de Nueva York de Servicios financieros por teléfono o visite su sitio web para obtener información gratuita sobre las tarifas de las tarjetas de crédito, las tarifas y los períodos de gracia. Puede llamar al Departamento de Servicios Financieros del Estado de Nueva York al 800-342-3736 o visitar su sitio web: www.dfs.ny.gov. </p> <p> <b> Residentes de Ohio: </b> Las leyes de Ohio contra la discriminación se requiere que todos los acreedores hagan que el crédito esté igualmente disponible para todos los clientes solventes, y que las agencias de informes crediticios mantengan historiales de crédito separados para cada individuo que lo solicite. La comisión de derechos civiles de Ohio administra el cumplimiento de esta ley. </p> <p> <b> Residentes de Vermont: </b> El prestamista se dedica a la producción de préstamos. </p> <p> <b> Residentes casados ​​de Wisconsin: < / b> Ninguna disposición de un acuerdo de propiedad matrimonial, una declaración unilateral bajo la sección 766.59 o un decreto judicial bajo la sección 766.70 afecta adversamente el interés del acreedor a menos que el acreedor, antes del momento en que se otorgue el crédito, se le proporcione una copia del acuerdo, declaración o decreto o tiene conocimiento real de la disposición adversa cuando se incurre en la obligación con el acreedor. Estamos obligados a pedirle que proporcione el nombre y la dirección de su cónyuge. </p>",
        "electronicCommunicationConsentTitle": "Consentimiento especial a la comunicación electrónica",
        "electronicCommunicationConsentBody": "Al hacer clic en \"Ver mis ofertas\" y al enviar una solicitud para su procesamiento electrónico, usted autoriza expresamente El Prestamista se comunicará con usted sobre su solicitud y cualquier crédito extendido, o para cualquier otro propósito legal, incluyendo, sin limitación, servicio al cliente o cobro, en cualquier dirección o número de teléfono que pueda, de vez en cuando, proporcionar (incluyendo cualquier número de teléfono o línea telefónica fija portada), e incluye expresamente, sin limitación, las llamadas realizadas mediante marcación y anuncio automáticos de dispositivos que pueden reproducir mensajes grabados.",
        "submitApplicationConsentTitle": "Consentimiento para enviar la solicitud",
        "submitApplicationConsentBody": "Al hacer clic en \"Ver mis ofertas\" a continuación y enviar una solicitud electrónicamente para su procesamiento, por la presente certifica que tiene al menos 18 años de edad (19 en AL o NE) y que todo lo que mencionó anteriormente es verdadero y preciso. Usted certifica que actualmente no tiene una petición presentada en virtud de las leyes federales de bancarrota (ya sea voluntaria o involuntaria) y no prevé presentar una petición. Si se aprueba para crédito, usted acepta estar sujeto a los términos del Acuerdo de Crédito que se le proporcionó. Usted autoriza El Banco de Missouri, sus sucesores, cesionarios y agentes (el \"Prestamista\") para investigar su solvencia y obtener un informe de crédito sobre usted para cualquier propósito legal, incluida cualquier actualización, extensión de crédito, revisión o cobro de su cuenta. y usted autoriza la divulgación de dicha información al Prestamista. Si lo solicita, el Prestador le dirá si se solicitó algún informe de crédito y, de ser así, el nombre y la dirección de la agencia de informes del consumidor que proporcionó el informe."
      },
      "offers": {
        "stepName": "Ofertas disponibles",
        "financingOptionsHeaderMultiple": "¡Felicitaciones, las ofertas están disponibles!",
        "financingOptionsHeaderSingle": "¡Felicidades! ¡Aquí está su oferta!",
        "financingOptionCreditLimit": "Límite de crédito: {{currencyCreditLimit}}",
        "financingOptionAmountAndMonths": "{{currencyMonthlyAmount}} / month for {{months}} months",
        "financingOptionAPR": "Tasa porcentual anual: {{rate}}%",
        "financingOptionDeferredInterest": "Deferred Interés: {{deferredInterestMonths}} meses",
        "financingOptionInterestBearing": "{{currencyMonthlyAmount}} / month, {{months}} months, {{rate}}% interest",
        "financingOptionInterestFree": "{{currencyMonthlyAmount}} / month, {{months}} months",
        "chooseFinancingOptionErrorMessage": "Se produjo un error al comunicarse con nuestro socio financiero. Inténtelo de nuevo.",
        "monthlyPaymentDisclaimer": "Pago mensual: los pagos mínimos mensuales estimados incluyen cargos financieros a un APR del valor que se muestra en la oferta correspondiente y un pago total estimado, el pago mensual mínimo multiplicado por el plazo de reembolso. Asumiendo el monto de la compra de {{chargeAmount | currency}} y que todos los pagos se realicen en forma oportuna, la transacción de compra se pagará dentro del número de meses enumerados para el plazo de reembolso en la casilla de oferta correspondiente.",
        "deferredInterestDisclaimer": "Interés diferido: los intereses se cargarán a su cuenta desde la fecha de compra si el saldo de la compra no se paga en su totalidad dentro del período promocional o si realiza un pago atrasado. Debe pagar más que su pago mínimo requerido para evitar intereses. Consulte el Acuerdo del titular de la tarjeta para obtener más detalles."
      },
      "existingAccount": {
        "stepName": "Confirmación",
        "existingAccountHeader": "Según la información proporcionada, hemos encontrado una cuenta {{existingAccount.vendorName}} existente.",
        "accountNumberLabel": "Número de cuenta:",
        "availableCreditLabel": "Crédito disponible:",
        "authorizeTransactionSubheader": "Autorizar la transacción para {{chargeAmount | currency}}",
        "authorizeTransactionDisclaimer": "Al marcar la casilla, usted acepta permitir que {{existingAccount.vendorName}} inicie una transacción por el monto de la factura correspondiente. Si su línea de crédito es mayor que el monto de su factura, podrá usarla para facturas futuras con {{providerName}} si lo desea.",
        "submitExistingAccountConfirmationErrorMessage": "Se produjo un error al comunicarse con nuestro socio financiero. Vuelva a intentarlo.",
        "partialPaymentDisclaimer": "Tenga en cuenta que tendrá un saldo restante de {{existingAccount.amountDueNotCovered | currency}}."
      },
      "summary": {
        "stepName": "Resumen de términos de crédito",
        "financingSummaryHeader": "Revisar y aceptar su oferta:",
        "acceptOfferDisclaimer": "Una vez que haga clic en Aceptar oferta, se creará su cuenta {{vendorName}} y no podrá seleccionar una oferta diferente .",
        "acceptOfferButton": "Aceptar oferta",
        "reviewTerms": "Antes de seleccionar su oferta, consulte el <a href='{{termsUrl}}' target='_blank'> Resumen de términos de crédito </a> que resume los términos clave del titular de la tarjeta Acuerdo que regirá su cuenta rotatoria {{vendor}}."
      },
      "confirm": {
        "stepName": "Confirmación",
        "financingConfirmHeader": "¡Ya casi terminé!",
        "cardholderAgreementSubheader": "Acuerdo de Leer y guardar su titular de la tarjeta:",
        "cardholderAgreementDisclaimer": "Al marcar la casilla, acepta que (i) ha leído y entienda su <a href='{{cardholderAgreementUrl}}' target='_blank'> Acuerdo del Titular de la Tarjeta </a> incluyendo los términos de cualquier oferta de interés diferido y acepte estar sujeto a sus términos, y (ii) han proporcionado el corrija la dirección de correo electrónico para conservar una copia del Acuerdo del titular de la tarjeta para sus registros.",
        "authorizeTransactionSubheader": "Autorizar la transacción para {{chargeAmount | currency}}",
        "authorizeTransactionDisclaimer": "Al marcar la casilla, usted acepta permitir que {{vendorChosen}} inicie una transacción para {{chargeAmount | currency}}. Si su línea de crédito es mayor que esta cantidad, podrá usarla para futuras facturas con {{providerName}} si lo desea.",
        "partialPaymentDisclaimer": "Tenga en cuenta que tendrá un saldo restante de {{selectedOption.amountDueNotCovered | currency}}."
      },
      "success": {
        "financingRedirectHeader": "Le redirigiremos a nuestro socio financiero en breve...",
        "financeRedirectSubContent": "<br> Si no se le redirige automáticamente, por favor <a id = 'redirectUrl' href = '{{redirectUrl}}' target = '_blank'> haga clic aquí </a> para continuar con nuestro socio financiero.",
        "financingSuccessHeader": "Gracias por financiar a través de {{vendor}}!",
        "financeSuccessSubContent": "{{vendor}} se comunicará con usted directamente con los detalles de la cuenta. Si tiene alguna pregunta, póngase en contacto con {{vendor}} directamente por teléfono en <b> {{vendorPhone}} </b> o por correo electrónico en <b> {{vendorEmail}} </b>."
      },
      "paymentForm": {
        "infoHeader": "Tarjeta de {{proveedor}}",
        "infoContent": "<p> Según su cuenta existente con {{vendor}}, puede usar su línea de crédito abierta para financiar sus facturas con <b> {{provider}} </b> . </p> <p> Crédito disponible: {{availableCredit | currency}} </p> <p> Si tiene preguntas sobre su cuenta {{vendor}}, llame a {{vendorPhone}} o envíe un correo electrónico a {{vendorEmail}}. </p>"
      }
    }
  },
  "dialogs": {
    "confirmPasswordHeader": "Confirmar contraseña",
    "confirmPasswordSubheader": "Para seguridad razones, ingrese su contraseña.",
    "deactivateAccountHeader": "Desactivar cuenta",
    "deactivateAccountSubheader": "Por razones de seguridad, ingrese su contraseña para continuar.",
    "deactivateAccountWarning": "¡Advertencia! Una vez que desactiva su cuenta, perderá el acceso a su PatientWallet<sup>&reg;</sup> y a todos los datos asociados con ella.",
    "deactivateAccountSuccessHeader": "Su cuenta ha sido desactivada.",
    "deactivateAccountSuccessSubheader": "Si ha desactivado su cuenta por error, por favor contáctenos al 1-844-422-4779 o support@patientco.com.",
    "thanksForPayment": "¡Gracias por su pago!",
    "emailSentTo": "Hemos enviado un recibo por correo electrónico a <br /> {{email}}",
    "copySentTo": "Cuando su proveedor responda, le enviaremos un correo electrónico a <br /> {{email}}",
    "successfulLogout": "Has cerrado sesión correctamente.",
    "thanksAndComeBack": "Gracias por usar Patientco. <br /> ¡Esperamos volver a recibirlo!",
    "welcome": "¡Bienvenido a Patientco!",
    "happyYouAreHere": "Crea una cuenta para comenzar.",
    "thanksForComingBack": "Nos complace que hayas vuelto.",
    "leavingPaymentFlow": "¿Estás seguro de que quieres abandonar esta página? Su pago no se ha procesado.",
    "yourBalanceWasUpdated": "¡Su saldo se ha actualizado!",
    "dbuReasonHeaderUp": "¿Por qué mi saldo ha aumentado?",
    "dbuReasonHeaderDown": "¿Por qué ha disminuido mi saldo?",
    "dbuReason": "Desde su última factura, ha habido una nueva actividad en su cuenta. En la mayoría de los casos, el saldo actualizado refleja nuevos cargos, pagos o cambios en la contribución al seguro.",
    "dbuStillPayable": "No se preocupe, aún puede pagar <br /> el monto impreso en su factura.",
    "newerBillLoaded": "Los detalles que ingresó son de un antiguo proyecto de ley. Para su comodidad, hemos cargado la factura más reciente que refleja su información más reciente.",
    "demoDialog": "Entorno de demostración",
    "gatewayDown": "Actualmente tenemos dificultades para procesar los pagos. Pedimos disculpas por las molestias, por favor, inténtelo de nuevo más tarde.",
    "cardsHidden": "Actualmente tenemos dificultades para procesar los pagos con tarjeta de crédito. Realice su pago utilizando un cheque electrónico.",
    "eChecksHidden": "Actualmente estamos experimentando dificultades para procesar los pagos de cheque electrónico. Haga su pago con una tarjeta de crédito",
    "cardsExpired": "Al menos una de sus tarjetas de crédito ha caducado, o expirará antes de la fecha de su fecha de pago programada.",
    "contactTitle": "¡Gracias por contactar!",
    "contactSubtitle": "Nos complace escuchar de usted",
    "phiWarning": "Para su seguridad, por favor excluya la información de salud personal",
    "contactSent": "¡Gracias por su mensaje!",
    "contactThanks": "Nos pondremos en contacto con usted lo antes posible",
    "termsTitle": "Términos de uso",
    "welcomeBack": "Bienvenido atrás, {{name}}!",
    "termsUpdated": "Nuestros Términos de uso se han actualizado desde su último inicio de sesión.",
    "logoutWarningHeader": "Su sesión está a punto de caducar",
    "logoutWarningSubcontent": "Para su seguridad, esta sesión en línea finalizará en breve debido a la inactividad.",
    "retryProcessPayment": "No pudimos procesar completamente su pago. Por favor, vuelva a intentar su presentación a continuación. No se preocupe, solo cargaremos su tarjeta una vez.",
    "feedbackTitleReceiptLink": "¿Cómo fue su experiencia de pago en línea?",
    "feedbackTitleClosedMessageLink": "¿Cómo fue su servicio al cliente de {{providerName}}?",
    "feedbackSubtitlePaymentHappy": "¡Impresionante! ¿Algún otro comentario?",
    "feedbackSubtitlePaymentSad": "Lamento escuchar eso! ¿Cómo podemos mejorar?",
    "feedbackSubtitleClosedMessageHappy": "¡Genial! ¿Algún otro comentario?",
    "feedbackSubtitleClosedMessageSad": "Lamento escuchar eso! ¿Qué podría mejorar su proveedor?",
    "feedbackCommentPlaceholder": "Comience a escribir comentarios...",
    "thanksForFeedback": "¡Gracias por sus comentarios!",
    "accountIsFinanced": "Esta cuenta está siendo atendida actualmente por {{financedBy}}.",
    "contactFinancingVendor": "Para realizar un pago o para Para ayudar con su cuenta, comuníquese directamente con {{financedBy}}.",
    "updatePaymentFormHeader": "Seleccionar método de pago",
    "updatePaymentFormConfirmHeader": "Cambiar método de pago",
    "updatePaymentMethodButtonText": "Confirmar",
    "updatingPaymentMethodButtonText": "Actualizando...",
    "updateAndApplyPaymentMethodButtonText": "Confirmar",
    "updateAndApplyingPaymentMethodButtonText": "Procesando...",
    "confirmingButtonText": "Confirmando...",
    "addAnotherCard": "Agregar otra tarjeta",
    "addAnotherECheck": "Agregar otra comprobación electrónica",
    "addAnotherCardOrECheck": "Agregar otra tarjeta o verificación electrónica",
    "updatesToTermsText" : "Hemos actualizado nuestros <a href=\"https://www.patientco.com/terms-of-use/\" target=\"_blank\"> Términos de uso </a> y <a href=\"https://www.patientco.com/privacy/\" target=\"_blank\"> Política de privacidad </a> para que le resulte más fácil comprender qué información recopilamos y por qué. <br/> <br/> Como un recordatorio amistoso, Patientco nunca compartirá su información con comercializadores de terceros, y siempre puede actualizar sus Preferencias de comunicación dentro de su Configuración. Le recomendamos que revise nuestros nuevos <a href=\"https://www.patientco.com/terms-of-use/\" target=\"_blank\"> Términos de uso </a> y <a href=\"https: //www.patientco.com/privacy/\" target=\"_blank\"> Política de privacidad </a>. <br/> <br/> Para aceptar los nuevos <a href=\"https://www.patientco.com/terms-of-use/\" target=\"_blank\"> Términos de uso </ a > y <a href=\"https://www.patientco.com/privacy/\" target=\"_blank\"> Política de privacidad </a>, haga clic en \"Aceptar\" a continuación."
  },
  "values": {
    "valueArg": "Valor de Patientco # {{number}}",
    "descriptions": {
      "1": "Poner primero a los pacientes. Los pacientes son el corazón de todo lo que hacemos en Patientco.",
      "2": "Trabajar para servir. Hacemos lo que sea necesario para hacer felices a los pacientes",
      "3": "Sé honesto: hacemos lo que decimos que vamos a hacer.",
      "4": "Crea \"ganar-ganar\". Creemos en un mundo donde los pacientes y los proveedores trabajan juntos",
      "5": "Luchen por la simplicidad. Luchamos para que a los pacientes les resulte más fácil administrar sus gastos de atención médica.",
      "6": "Mejorar la atención médica. Existimos para mejorar la experiencia de salud de los pacientes cada día"
    }
  },
  "links": {
    "whyPatientco": "Acerca de Patientco",
    "news": "Noticias",
    "termsOfService": "Términos de uso",
    "careers": "Carreras profesionales",
    "privacyPolicy": "Política de privacidad"
  },
  "misc": {
    "conjunction": "y",
    "on": "en"
  },
  "errors": {
    "email": "Ingrese un Dirección de correo electrónico que coincide con el formato estándar. Aquí hay un ejemplo: james.smith@example.com.",
    "emailUnavailable": "Ha ingresado una dirección de correo electrónico que ya ha sido registrada. Intente iniciar sesión para acceder a su información.",
    "emailInUse": "Ha ingresado una dirección de correo electrónico que ya ha sido registrada.",
    "password": "Su contraseña debe contener al menos 8 caracteres, 1 letra mayúscula y 1 número.",
    "confirmPassword": "Parece que las contraseñas que has introducido no coinciden! Verifique dos veces para asegurarse de que ambas entradas sean exactamente iguales.",
    "allNumericSCode": "Intente ingresar el SecureHealthCode impreso en verde en su factura. Está ubicado justo encima del azul que ingresó.",
    "scodeRequired": "Ingrese el SecureHealthCode verde ubicado en la sección superior derecha de su factura debajo del logotipo de Patientco.",
    "amountRequired": "Ingrese el saldo total impreso en su factura. Le pediremos el monto de pago deseado en el siguiente paso.",
    "belowMinAmount": "Ha ingresado una factura con un monto negativo. Ingrese el saldo total impreso en su factura.",
    "unableToFindBill": "No podemos encontrar una factura que coincida con SecureHealthCode y el saldo total que ingresó. Verifique para asegurarse de que sus datos sean correctos.",
    "billThrottled": "Ha intentado encontrar una factura demasiadas veces. Por razones de seguridad, su capacidad para buscar facturas se ha desactivado durante unos minutos.",
    "unableToLogin": "La dirección de correo electrónico o la contraseña que ingresó es incorrecta.",
    "loginThrottled": "Ha intentado iniciar sesión muchas veces. Por razones de seguridad, su capacidad de inicio de sesión se ha inhabilitado por unos minutos",
    "loginEmailRequired": "Ingrese una dirección de correo electrónico para ayudarnos a encontrar su cuenta",
    "loginPasswordRequired": "Ingrese la contraseña de su cuenta para iniciar sesión en Patientco.",
    "minAmountArg": "Su pago el monto debe ser mayor que {{minAmount}}",
    "maxAmountArg": "El monto de su pago debe ser menor o igual al saldo de su cuenta de {{maxAmount}}",
    "dateRequired": "Seleccione una fecha del calendario.",
    "ccRequired": "Ingrese un crédito o número de tarjeta de débito.",
    "ccSeemsInvalid": "Por favor, ingrese una válida número de tarjeta. Es posible que falten números o que estén fuera de orden en su entrada actual.",
    "ccUnknownCardType": "Ha ingresado un tipo de tarjeta que no es aceptado por Patientco. Intente usar una tarjeta Visa, MasterCard, Discover o American Express.",
    "ccExpired": "Según la fecha que ingresó, su tarjeta ha caducado y no se puede cargar.",
    "ccPrematureExpiration": "Ha ingresado una tarjeta que caduca antes de su fecha de pago . Utilice una tarjeta que caduque después de {{payMonth}} / {{payYear}}.",
    "genericRequired": "Complete este campo para continuar.",
    "genericCheckboxRequired": "Lea la exención de responsabilidad y marque esta casilla para continuar.",
    "invalidRoutingNumber": "El número de ruta que No se ha introducido",
    "minimumAccountNumber": "Los números de cuenta deben tener al menos 4 dígitos.",
    "mismatchedAccountNumber": "Los números de cuenta que ingresó no coinciden. Verifique dos veces para asegurarse de que los números sean exactamente iguales.",
    "unableToReset": "No podemos restablecer su contraseña. Asegúrese de estar utilizando el enlace de Recuperar contraseña más reciente en su correo electrónico.",
    "paymentProcessingError": "No podemos procesar su pago. Comuníquese con Patientco al 1-844-422-4779 para completar su pago.",
    "paymentInvalidCardInformation": "La información de la tarjeta que ingresó no es válida. Revise el número de tarjeta y el vencimiento de su método de pago.",
    "paymentCardDeclined": "No podemos procesar su pago debido a una tarjeta rechazada. Edite su método de pago y vuelva a intentarlo.",
    "paymentDuplicatePayment": "Parece que está intentando enviar un pago duplicado. Si esto es intencional, comuníquese con Patientco al 1-844-422-4779.",
    "confirmExpiredCard": "Parece que está intentando pagar con una tarjeta que ha caducado o que vencerá antes de la fecha de pago programada. Edite su método de pago y vuelva a intentarlo.",
    "confirmPrematureExpiration": "Parece que está intentando programar un pago después de que caduque su tarjeta de crédito. Edite su método de pago y vuelva a intentarlo.",
    "phoneFormat": "Parece que le faltan algunos números en su número de teléfono. Ingrese un número que coincida con el formato XXX-XXX-XXXX",
    "smsEligible": "Este número no es elegible para recibir mensajes de texto. Por favor ingrese un número de teléfono móvil válido en los Estados Unidos. Si no tiene un número de teléfono móvil, déjelo en blanco.",
    "termsAcceptanceRequired": "Por motivos legales, requerimos que todos los usuarios acepten nuestros Términos de uso.",
    "invalidPassword": "La contraseña actual que proporcionó no coincide con el valor que tenemos en nuestro sistema . Por favor, compruébelo y vuelva a intentarlo.",
    "unableToChangePassword": "No podemos actualizar su contraseña en este momento. Verifique sus detalles y vuelva a intentarlo.",
    "nonexistentEmail": "No hay una cuenta de Patientco asociada con este correo electrónico.",
    "minZipCode": "Ingrese un código postal de 5 dígitos para continuar.",
    "fiveHundred": "Parece que el servidor encontró un error al procesar su solicitud. Inténtelo de nuevo en unos minutos.",
    "runningOutOfCharacters": "Ha usado {{current}} / {{allowed}} caracteres",
    "reasonRequired": "Seleccione el motivo del mensaje",
    "messageRequired": "Ingrese su mensaje para continuar",
    "futureBirthdate": "Ha ingresado un DOB que tendrá lugar en el futuro.",
    "incompleteBirthdate": "Ingrese un DOB completo (MM / DD / YYYY).",
    "invalidDate": "La fecha no existe.",
    "unknown": "Se produjo un error desconocido. Inténtalo de nuevo. Si continúa teniendo problemas, comuníquese con Patientco al 1-844-422-4779.",
    "paymentFormProcessingError": "No podemos agregar su método de pago. Comuníquese con Patientco al 1-844-422-4779.",
    "paymentFormInvalidCardInformation": "La información de su tarjeta parece no ser válida. Revise el número de tarjeta y el vencimiento de su método de pago.",
    "ssnFormat": "Parece que le faltan algunos números en su número de seguro social. Ingrese un número que coincida con el formato XXX-XX-XXXX"
  },
  "accountEvents": {
    "bill": "Bill Sent",
    "collection": "collection",
    "collection-stop": "collection-stop",
    "collection-update": "collection-update",
    "collection-extraction": "collection-extract",
    "correspondence": "Correspondence Received",
    "correspondenceOnly": "correspondenceOnly",
    "message": "Mensaje enviado: {{message}}",
    "changeOfAddress": "Cambio de dirección",
    "payment": "Pago realizado",
    "paymentPlan": "Pago programado",
    "returnMail": "Correo devuelto",
    "reversePayment": "Reembolso procesado",
    "balanceUpdate": "Saldo actualizado",
    "voidedPayment": "Pago anulado",
    "declinedPayment": "Pago Rechazado",
    "canceledPayment": "Pago cancelado",
    "voidedPaymentPlan": "Plan de pago anulado",
    "canceledPaymentPlan": "Plan de pago cancelado"
  },
  "transworld": {
    "clientRefNum": "Número de referencia del cliente",
    "clientRefNumRequired": "Ingrese el Número de referencia del cliente en la parte superior derecha de su factura",
    "allNumericlientRefNum": "Intente ingresar la Referencia del cliente # impreso en su factura. Se encuentra en la sección superior derecha de su factura"
  },
  "fullPageNotifications": {
    "emailVerified": {
      "header": "¡Gracias!",
      "subheader": "Su dirección de correo electrónico ha sido verificada.",
      "mainButtonText": "Continuar",
      "message": "¡Nos complace tenerlo aquí! Se unió a una comunidad de 2 millones de consumidores que están tomando el control de sus gastos de atención médica."
    }
  }
});

$translateProvider.translations("fr", {
  "dashboard": {
    "dashboard": "Fournisseurs",
    "zeroStateTitle": "Aucun fournisseur",
    "zeroStateBlurb": "Entrez les détails de votre dernière facture Patientco pour accéder aux soldes à jour, aux activités de facturation passées et aux détails des transactions de vos fournisseurs de soins de santé.",
    "argsDashboard": "{{Name}}'s Fournisseurs",
    "argsDashboardEndsWithS": "{{name}}' Fournisseurs"
  },
  "encounterLinking": {
    "linkableAccounts": "'Nous avons trouvé des comptes supplémentaires à ajouter à vos comptes PatientWallet<sup>&reg;</sup>. <a href=\"\" class='link inline'> Vérifier les comptes </a>",
    "proactiveLinkableAccounts": "Nous souhaitons que nous ajoutions automatiquement les comptes de {{providerGroupList | listCombine}} à votre compte PatientWallet<sup>&reg;</sup>? <a href='' class='link inline'> Vérifier les comptes < / a>",
    "experiencedLinkableAccounts": "Vous devez ajouter davantage de comptes à votre compte PatientWallet<sup>&reg;</sup>. <a href='' class='link inline'> Vérifier les comptes </a>",
    "verifyInformation": "Vérifier les informations ",
    "verifyingInformation": "Vérification des informations ...",
    "whichPatient": "Qui souhaitez-vous vérifier?",
    "verifyAcctTitle": "Vérifier les informations pour <br /> {{linkingName}}",
    "verifyAcctSubtitlePatient": "Entrez les champs de sécurité ci-dessous pour ajouter des comptes {{PatientName | possessive}} from {{providerGroup}}.",
    "verifyAcctSubtitleGuarantor": "Entrez vos informations ci-dessous pour ajouter {{PatientName | possessive}} comptes de {{providerGroup}}.",
    "birthdatePlaceholder": "Date de naissance (MM / JJ / AAAA)",
    "SSNPlaceholder": "Les quatre derniers chiffres du numéro de sécurité sociale",
    "ssnHelpInfo": "<b> Remarque: </ b> Si vous ajoutez des comptes pour un enfant, utilisez le numéro de sécurité sociale du répondant",
    "moreInfo": "Comment ce travail?",
    "verificationFailed": "Les informations que vous avez entrées ne correspondent pas à nos enregistrements de {{patientName}}. Veuillez réessayer.",
    "verificationBlocked": "Vous avez tenté d'ajouter {{patientName | possessif}} compte trop de fois. Pour des raisons de sécurité, votre capacité à ajouter ce compte a été désactivée temporairement.",
    "verificationHeader": "Compte ajouté avec succès.",
    "verificationHeaderPlural": "Comptes ajoutés avec succès.",
    "verificationSubheader": "À l'avenir, nous ajouterons automatiquement {{PatientName | | possessive}} factures pour {{fournisseurGroup}} à votre PatientWallet<sup>&reg;</sup>.",
    "proactiveExperiencedVerificationSubheader": "{{patientName}} n'a aucun compte passé à ajouter, mais nous ajouterons automatiquement ses futures factures pour {{providerGroup}} sur votre PatientWallet<sup>&reg;</sup>.",
    "incompleteSSN": "On dirait qu'il vous manque quelques chiffres. Veuillez saisir les quatre derniers numéros de votre SSN.",
    "proactiveVerificationHeader": "{{PatientName}} vérifié avec succès.",
    "close": "Fermer",
    "continueToPay": "Continuer à facturer"
  },
  "firstBill": {
    "optionsHeader": "Vérifier les informations pour {{patientFirstName}}",
    "optionsSubheader": "Comment souhaitez-vous vérifier votre identité?",
    "verifyEmailHeader": "Vérifiez votre email!",
    "verifyEmailSubheader": "Veuillez saisir le code que nous avons envoyé à votre adresse e-mail.",
    "verifySmsHeader": "Vérifiez votre téléphone!",
    "verifySmsSubheader": "Veuillez saisir le code que nous avons envoyé à votre appareil mobile par SMS. ",
    "verifyPdivHeader": "Entrez les champs de sécurité ci-dessous pour accéder à {{patientFirstName | possessif}} facture de {{fournisseurName}}",
    "birthdatePlaceholder": "Date de naissance (MM / JJ / AAAA)",
    "SSNPlaceholder": "Quatre derniers chiffres du numéro de sécurité sociale",
    "incompleteSSN": "On dirait qu'il vous manque quelques chiffres. Veuillez saisir les quatre derniers chiffres de votre SSN.",
    "verifyViaEmailOption": "Envoyez-moi un code par email ({{emailAddress}})",
    "verifyViaSmsOption": "Envoyez moi un code par SMS ({{phoneNumber}})",
    "verifyViaPdiv": "Entrez la date de naissance et les quatre derniers numéros du SSN",
    "sendAgainButtonText": "Je n'ai pas reçu de code",
    "sendingCodeButtonText": "Envoi en cours ...",
    "back": "Retour",
    "submitText": "Suivant",
    "submittingText": "Vérification en cours ...",
    "successHeader": "Vous avez été vérifié!",
    "successSubheader": "Cliquez ci-dessous pour voir votre facture.",
    "successModalButton": "View Bill",
    "codeNotSent": "Un problème est survenu lors de l'envoi de ce code. Veuillez réessayer.",
    "codeSent": "Le code a été envoyé.",
    "codeInvalid": "Ce code était invalide ou a expiré. Veuillez réessayer.",
    "pdivInvalid": "Un ou plusieurs champs ne correspondaient pas à nos enregistrements. Si vous pensez que vos informations sont correctes, veuillez contacter votre fournisseur.",
    "bruteForce": "Compte temporairement désactivé en raison d'un trop grand nombre de tentatives infructueuses.",
    "unableToAuth": "Nous ne pouvons pas authentifier votre compte pour le moment. Votre facture devrait bientôt arriver par courrier.",
    "missingGuarantorName": "Patient"
  },
  "confirmPhone": {
    "header": "Enregistrement complet du compte",
    "numberSubheader": "Veuillez vérifier votre numéro de téléphone mobile",
    "codeSubheader": "Veuillez saisir le code que nous avons envoyé à votre appareil mobile par SMS.",
    "submitButtonText": "Envoyer le code",
    "submittingButtonText": "Envoi en cours ...",
    "verifyButtonText": "Vérifier",
    "verifyingButtonText": "Vérification en cours",
    "sendAgainButtonText": "Je n'ai pas reçu de code",
    "successHeader": "Merci d'avoir vérifié votre numéro de téléphone.",
    "learnMore": "En savoir plus",
    "hide": "Masquer",
    "additionalInfo": "En vérifiant votre numéro de téléphone, vous acceptez d'autoriser Patientco à vous envoyer des SMS périodiques pour toute activité liée au compte. Patientco peut utiliser un système automatisé pour vous envoyer des communications électroniques comprenant du texte, du téléphone ou des courriels. Patientco n'a pas de frais distincts pour ce service; toutefois, sous réserve des termes et conditions de votre les taux de l'opérateur de téléphonie mobile, des messages et des données peuvent s'appliquer. En donnant votre consentement à participer à ce programme, vous approuvez de tels frais auprès de votre opérateur de téléphonie mobile. Vous pouvez vous désinscrire de ce programme à tout moment en envoyant un e-mail à Patientco à l'adresse support@patientco.com.",
    "explanation": "Patientco vous enverra un SMS avec un code unique pour vérifier votre numéro de téléphone mobile.",
    "errors": {
      "sentAgain": "Désolé! Nous vous avons envoyé un autre code.",
      "codeNotSent": "Un problème est survenu lors de l’envoi de ce code. Veuillez réessayer.",
      "codeInvalid": "Ce code était invalide ou a expiré. Veuillez réessayer."
    }
  },
  "confirmEmail": {
    "header": "Vérifier l'adresse e-mail",
    "submitButtonText": "Envoyer un e-mail",
    "submittingButtonText": "Envoi en cours ...",
    "explanation": "L'adresse e-mail indiquée est-elle correcte? Sinon, corrigez-le avant de cliquer sur le bouton 'Envoyer un e-mail' pour mettre à jour votre compte.",
    "successHeader": "E-mail de vérification envoyé.",
    "emailNotSent": "Un problème est survenu lors de l'envoi de cet e-mail. Veuillez réessayer."
  },
  "receipt": {
    "receipt": "Reçu",
    "argPaidCallout": "<b> {{montant}} </ b> payé",
    "argScheduledCallOut": "<b> {{montant}} </ b> programmé",
    "argRemainingCallOut": "<b> {{montant} } </ b> restant",
    "transactionIdArg": "ID de transaction # {{transactionId}}",
    "balancePayment": "paiement du solde",
    "argDeclinedCallout": "<b> {{montant}} </ b> refusé",
    "argCanceledCallout": "<b> {{montant}} < / b> Annulé",
    "confirmCancelDialog": "Voulez-vous vraiment <br /> annuler ce paiement?",
    "cancelPaymentDesc": "{{montant}} prévu pour {{date}}",
    "fullRefund": "Remboursement intégral",
    "partialRefund": "Remboursement partiel",
    "chargeback": "Remboursement",
    "failedECheck": "Echec de l'eCheck",
    "bouncedCheck": "Chèque sans provision",
    "paymentMethodUpdated": "Le mode de paiement a été mis à jour.",
    "paymentMethodHeld": "Nous n'avons pas pu traiter un paiement. Veuillez sélectionner un autre mode de paiement et réessayer.",
    "updateMethodScheduledPlanConfirmDialog": "Les paiements restants de votre plan seront mis à jour pour utiliser {{méthode}}.",
    "updateMethodHeldPlanConfirmDialog": "Les paiements restants de votre plan seront mis à jour pour utiliser {{méthode}}. < br /> <br/> Tous les paiements du plan précédemment refusés ou retenus seront traités en utilisant le nouveau mode de paiement."
  },
  "newbill": {
    "whichAccount": "Quelle facture souhaitez-vous payer?",
    "accountDue": "<b> {{nom du fournisseur}} < / b> <br/> Compte n ° {{accountNumber}} | {{amountDue}} due",
    "accountPastDue": "<b> {{nom du fournisseur}} </ b> <br/> N ° de compte {{accountNumber}} | {{amountDue}} en retard"
  },
  "message": {
    "message": "Message",
    "plural": "Messages",
    "messageSent": "Votre message a été envoyé!",
    "argsMessages": "Messages de {{name}}",
    "argsMessagesEndsWithS": "{{name}} 'Messages",
    "createAreaPlaceholder": "Commencez à taper votre réponse ...",
    "whoToContact": "Qui souhaitez-vous contacter?",
    "whichAccount": "Avec quel compte avez-vous besoin d'aide?",
    "noProvidersToMessage": "Votre PatientWallet<sup>&reg;</sup> est actuellement vide. Veuillez ajouter une nouvelle facture pour commencer.",
    "startMessagePlaceholder": "Commencez à taper votre message ...",
    "messageClosedByProviderDialog": "Ce fil de discussion est fermé.",
    "startNewMessageDialog": "Si vous souhaitez démarrer un autre fil de message, <span> cliquez ici </ span >.",
    "argProviderCantMessage": "{{name}} ne prend pas en charge la messagerie par Patientco. Si vous souhaitez contacter votre fournisseur, appelez {{billingPhoneNumber}}. Nous vous prions de nous excuser pour tout inconvénient.",
    "reasons": {
      "default": "Sélectionnez une raison",
      "specificCharge": "Interrogez une accusation en particulier",
      "duplicate": "Interroger les accusations / factures en double",
      "paymentPlan": "Configurer un plan de paiement",
      "missingPayments": "Informez-vous des paiements manquants",
      "other": "Autre",
      "updateInsurance": "Mise à jour Informations",
      "insuranceQuestion": "Poser une question à l’assurance",
      "financialAssistance": "Demander de l’aide financière",
      "itemizedBill": "Demander une facture détaillée"
    },
    "zeroStateTitle": "Aucun message",
    "zeroStateBlurb": "Patientco vous permet de communiquer en toute sécurité avec tous vos prestataires de soins de santé en un seul endroit. Posez une question sur votre facture, définissez un plan de paiement ou dites simplement merci!"
  },
  "notification": {
    "notification": "Notification",
    "plural": "Notifications",
    "zeroStateTitle": "Aucune notification"
  },
  "login": {
    "minCharactersRule": "8 caractères",
    "upperCaseRule": "1 lettre majuscule",
    "digitRule": "1 numéro",
    "nextButton": "Suivant",
    "forgotPasswordQuestion": "Mot de passe oublié?",
    "forgotPasswordQuestionSubcontent": "Nous sommes là pour vous.",
    "forgotPasswordSentDialog": "Génial! Nous avons envoyé de l'aide à <br /> <b> {{email}} </ b>",
    "forgotPasswordMethodHeader": "Comment souhaitez-vous réinitialiser votre mot de passe?",
    "forgotPasswordMethodEmailButton": "Envoyez-moi un e-mail ({{email}})",
    "forgotPasswordMethodSmsButton": "Texte moi ({{phone}})",
    "forgotPasswordMethodCode": "Veuillez saisir votre code de vérification.",
    "forgotPasswordCheckEmailTitle": "Consultez votre messagerie électronique et cliquez sur le lien.",
    "forgotPasswordEmailSentTryAgainButton": "Réessayer",
    "forgotPasswordMethodSendAgainButton": "Je n'ai pas reçu de code",
    "resetPasswordHeader": "Créez votre nouveau mot de passe .",
    "resetPasswordCompleteDialog": "Vous pouvez maintenant vous connecter en utilisant votre email et votre nouveau mot de passe.",
    "resetPasswordCheckEmailMessage": "Nous venons d'envoyer un email contenant un lien de réinitialisation du mot de passe vers <br /> <b> {{email}} </ b>. <Br / > <br /> Si vous ne le voyez pas dans votre boîte de réception, vérifiez votre dossier de courrier indésirable."
  },
  "setting": {
    "menu": {
      "contact": "Informations de contact",
      "savedPaymentMethods": "Méthodes de paiement enregistrées",
      "accountManagement": "Gestion du compte"
    },
    "setting": "Paramétrage",
    "plural": "Paramètres",
    "argsSettings": "Paramètres de {{name}}",
    "argsSettingsEndsWithS": "Paramètres de {{name}}",
    "ebillDialog": "Envoyez-moi un e-mail lorsque de nouvelles factures sont ajoutées à mon compte.",
    "smsDialog": "Envoyez-moi un SMS lorsque de nouvelles factures sont ajoutées à mon compte.",
    "argMethodDeleted": "{{méthode}} <br/> a été supprimé",
    "deleteMethodArg": "Supprimer {{détail}}",
    "confirmDeleteDialog": "Voulez-vous vraiment supprimer cette méthode de paiement?",
    "passwordChangedDialog": "Votre mot de passe a bien été modifié.",
    "unremovableMethodHelp": "Ce mode de paiement est utilisé pour effectuer Un <br /> paiement programmé ne peut pas être supprimé.",
    "nonDeactivateableUserHelp": "Vous ne pouvez pas désactiver votre compte car <br/> vous avez un ou plusieurs paiements programmés.",
    "modals": {
      "email": {
        "header": "Communications par e-mail",
        "content": "En activant les notifications par e-mail, Nous autorisons Patientco à vous envoyer périodiquement par e-mail de nouvelles factures ou des rappels concernant des factures avec des dates d'échéance en attente. La notification par courrier électronique est une fonctionnalité facultative de Patientco qui est désactivée jusqu'à ce que vous modifiiez vos paramètres de notification dans l'onglet «Paramètres» ou sur la page d'enregistrement. Vous pouvez activer ou désactiver cette fonctionnalité à tout moment, mais les modifications peuvent prendre jusqu'à 48 heures pour devenir effectives. Patientco se réserve le droit de mettre fin aux notifications eBill à tout moment, à notre seule discrétion. Vos informations de santé protégées (PHI) ne seront contenues dans aucun courrier électronique. Votre PHI ne peut être visualisé qu'en vous connectant à Patientco à l'aide de votre adresse e-mail enregistrée."
      },
      "sms": {
        "header": "Communications SMS",
        "content": "En activant les notifications par SMS, vous autorisez Patientco à vous envoyer des SMS périodiques pour toute activité liée à votre compte. Patientco peut utiliser un système automatisé pour vous envoyer des communications électroniques comprenant du texte, du téléphone ou des courriels. Patientco n'a pas de frais distincts pour ce service; Toutefois, sous réserve des conditions générales de votre opérateur de téléphonie mobile, des débits de messagerie et de données peuvent s'appliquer. En donnant votre consentement à participer à ce programme, vous approuvez de tels frais auprès de votre opérateur de téléphonie mobile. Vous pouvez vous désinscrire de ce programme à tout moment dans l'onglet 'Paramètres' de votre compte PatientWallet<sup>&reg;</sup>."
      }
    },
    "methodAdded": "{{Méthode}} <br/> a été ajouté"
  },
  "landing": {
    "title": "Patientco | Payez votre facture",
    "enterBills": "Entrez les détails uniques de votre facture.",
    "marketingBlurb": "Bienvenue dans PatientWallet<sup>&reg;</sup> de Patientco! Nous nous sommes associés à votre fournisseur de soins de santé pour vous offrir l'expérience de paiement la plus simple et la plus sécurisée possible.",
    "needHelpHeader": "Vous avez besoin d'aide pour trouver votre facture?",
    "needHelpContent": "Cliquez dans les cases blanches fournies pour saisir votre SecureHealthCode et votre solde dû.",
    "logoTransition": "Patientco a un nouveau look!"
  },
  "contact": {
    "help": {
      "header": "J'ai besoin d'aide pour:",
      "myBill": "Comprendre ma facture",
      "lostBill": "J'ai perdu ma facture Patientco",
      "loggingIn": "Connexion",
      "itemizedBill": "J'ai besoin d'une facture détaillée",
      "resetPassword": "Réinitialiser mon mot de passe"
    },
    "questionAbout": {
      "header": "Je Vous avez une question sur:",
      "reasons": {
        "specificCharge": "Une facture sur ma facture",
        "duplicate": "Une redevance ou une facture en double",
        "paymentPlan": "Créer un plan de paiement",
        "missingPayments": "Paiements manquants",
        "other": "Autre",
        "updateInsurance": "Informations de mise à jour",
        "insuranceQuestion": "Assurance",
        "financialAssistance": "Financier assistance",
        "itemizedBill": "Demander une facture détaillée"
      }
    },
    "rightPlace": {
      "header": "Nous allons vous amener au bon endroit.",
      "enterEmail": "Veuillez saisir votre adresse e-mail:",
      "enterBillDetails": "Veuillez saisir les détails de votre facture:"
    },
    "almostThere": {
      "header": "Presque là ...",
      "password": "Veuillez créer un mot de passe:",
      "login": "Veuillez vous connecter à votre compte:"
    }
  },
  "actions": {
    "pay": "Payer",
    "makePayment": "Effectuer un paiement",
    "viewMyBill": "Visualiser la facture",
    "viewBill": "Visualiser la facture",
    "viewAccounts": "Visualiser les comptes",
    "viewAccount": "Afficher le compte",
    "viewMessage": "Afficher le message",
    "view": "Afficher",
    "verify": "Vérifier",
    "verifying": "Vérifier ...",
    "submit": "Soumettre",
    "submitting": "Soumettre ...",
    "messageProvider": "Fournisseur de message",
    "updatePaymentForm": "Modifier le mode de paiement",
    "createMessage": "Créer un nouveau moi' ssage",
    "continue": "Continuer",
    "continueSingle": "Effectuer un paiement ponctuel",
    "continuePlan": "Créer un plan de paiement",
    "processPayment": "Traiter le paiement",
    "retryProcessPayment": "Nouvelle tentative de traitement",
    "processPaymentFuture": "Planifier le paiement",
    "createAccount": "Créer un compte",
    "creatingAccount": "Créer un compte ...",
    "login": "Connexion",
    "loggingIn": "Connexion ...",
    "logout": "Déconnexion",
    "payNewBill": "Payer une nouvelle facture",
    "payBill": "Payer une facture",
    "payAnotherBill": "Payer une autre facture",
    "openReceipt": "Visualiser le reçu",
    "findBill": "Trouver la facture",
    "findingBill": "Trouver la facture'. .",
    "needHelp": "Besoin d'aide?",
    "contactUs": "Contactez-nous",
    "learnMore": "En savoir plus",
    "goToDashboard": "Afficher les fournisseurs",
    "open": "Ouvrir",
    "sendMessage": "Envoyer un message",
    "sending": "Envoi du message ...",
    "printReceipt": "Imprimer le reçu",
    "printPlan": "Imprimer les détails du plan",
    "back": "Retour",
    "backToDashboard": "Retour aux fournisseurs",
    "backToMessages": "Retour aux messages",
    "backToArg": "Retour à {{properNoun}}",
    "deleteCard": "Supprimer la carte",
    "deleteECheck": "Supprimer l'eCheck",
    "addNewMethod": "Ajouter une nouvelle méthode",
    "changePassword": "Changer le mot de passe",
    "deactivateAccount": "Désactiver le compte",
    "confirmPassword": "Confirmer le mot de passe",
    "close": "Fermer",
    "cancel": "Annuler",
    "saveChanges": "Enregistrer les modifications",
    "forgotPassword": "Mot de passe oublié?",
    "retrievePassword": "Récupérer le mot de passe",
    "resetPassword": "Réinitialiser le mot de passe",
    "visitWebsite": "Visiter le site Web",
    "cancelPayment": "Annuler le paiement",
    "viewOlderActivity": "Afficher les activités antérieures",
    "stayLoggedIn": "Restez connecté",
    "sendFeedback": "Envoyer des commentaires",
    "sendingFeedback": "Envoi en cours ...",
    "viewZeroBalanceAccts": "Afficher {{count}} Comptes payés",
    "enterSHCTooltip": "Veuillez saisir le code SecureHealthCode imprimé sur votre facture",
    "enterAmountTooltip": "Veuillez saisir le solde total dû qui est imprimé sur votre facture, même si vous envisagez de payer un montant différent",
    "confirmPhoneNumber": "Veuillez vérifier votre numéro de téléphone mobile.",
    "confirmEmail": "Cliquez ici pour vérifier votre adresse e-mail: {{email}}",
    "processPaymentForm": "Enregistrer le mode de paiement",
    "retryProcessPaymentForm": "Réessayer le mode de paiement",
    "confirmAndProcess": "Confirmer et traiter",
    "accept" : "Acceptez",
    "switchLanguage" : "Switch to English"
  },
  "labels": {
    "providerName": "Nom du fournisseur",
    "argAccount": "{{num}} &nbsp; Compte",
    "argAccounts": "{{num}} &nbsp; Comptes",
    "argNumPastDue": "{{num}} n ° 'Passé'. ",
    "argNumAccountsPastDue": "{{num}} &nbsp; Comptes &nbsp; Passés en retard",
    "argNumAccountPastDue": "{{num}} &nbsp; Compte &nbsp; Passés en retard",
    "accountNumber": "Numéro de compte",
    "accountNumberConfirm": "Confirmer le numéro de compte",
    "guarantorName": "Nom du répondant",
    "name": "Nom",
    "firstName": "Prénom",
    "lastName": "Nom",
    "email": "Adresse électronique",
    "password": "Mot de passe",
    "passwordConfirm": "Confirmer le mot de passe",
    "statementSent": "Date facture",
    "balancDue": "Date d'échéance",
    "dueDate": "Date d'échéance",
    "dueArg": "Date d'échéance {{date}}",
    "argDue": "{{montant}} dû",
    "pastDue": "Echéance",
    "billSummary": "Récapitulatif de facture",
    "nameOnCard": "Nom sur la carte",
    "cardNumber": "Numéro de carte",
    "expirationFormat": "MM / AA",
    "routingNumber": "Numéro de routage",
    "accountHolderName": "Nom du titulaire du compte",
    "accountNumberArg": "N ° de compte { {numéro}}",
    "address": "Adresse",
    "address2": "Ligne d'adresse 2 (facultatif)",
    "city": "Ville",
    "state": "État",
    "zip": "Code postal",
    "date": "Date",
    "status": "Statut",
    "description": "Description",
    "amount": "Quantité",
    "secureCodeOnBill": "SecureHealthCode sur facture",
    "balanceOnBill": "Total du solde dû sur la facture",
    "secureCode": "SecureHealthCode",
    "totalBalanceDue": "Total du solde dû",
    "argBalance": "{{solde}} Solde",
    "enterUniqueDetails": "Entrez les détails de votre nouvelle facture. ",
    "badDebt": "Référé à une agence externe",
    "servicedByFinancingVendor": "desservi par {{ofinBy}}",
    "noBalance": "aucun solde dû",
    "pendingBalance": "en attente",
    "remainingBalance": "solde restant",
    "negativeBalance": "contactez votre fournisseur pour obtenir des informations sur votre solde négatif.",
    "yourBillAmount": "Montant de la facture",
    "billAmount": "Montant de la facture",
    "newBalance": "Solde actuel",
    "updating": "Mise à jour ...",
    "loading": "Chargement ...",
    "noAccountActivity": "Aucune activité de compte.",
    "contactProvider": "Contact fournisseur",
    "providerResources": "Ressources du fournisseur",
    "messageDetails": "Détails du message",
    "argPaid": "{{ montant}} payé",
    "argScheduled": "{{montant}} planifié",
    "argTotal": "{{montant}} total",
    "amountPaid": "montant payé",
    "details": "détails",
    "userDetails": "détails de l'utilisateur",
    "futurePayment": "paiement futur",
    "createdArg": "créé le {{date}}",
    "communicationPrefs": "Préférences de communication",
    "accountManagement": "Gestion des comptes",
    "savedMethods": "Méthodes de paiement enregistrées",
    "financingMethods": "Comptes de financement",
    "noSavedMethods": "Les méthodes de paiement enregistrées apparaissent ici.",
    "noFinancingMethods": "Il n'existe actuellement aucun compte de financement. Vérifiez auprès de votre fournisseur si le financement est disponible.",
    "phoneNumber": "Téléphone mobile",
    "primaryPhone": "Téléphone principal",
    "currentPassword": "Mot de passe actuel",
    "newPassword": "Nouveau mot de passe",
    "confirmNewPassword": "Confirmer le nouveau mot de passe",
    "submittingPayment": "Traitement du paiement ...",
    "submittingPaymentForm": "Enregistrement. ..",
    "retrievingPassword": "Récupération du mot de passe ...",
    "submittingPaymentFuture": "Planification du paiement ...",
    "resettingPassword": "Réinitialisation du mot de passe ...",
    "savingChanges": "Enregistrement des modifications ...",
    "acceptanceOfTerms": "J'accepte le déclencheur <a name = 'testing' de Patientco- modal = 'conditions'> Conditions d'utilisation </a>",
    "acceptanceOfTermsRaw": "J'accepte les conditions d'utilisation de Patientco",
    "changesSaved": "Modifications enregistrées",
    "changesFailed": "Impossible d'enregistrer les modifications",
    "declinedPayment": "Paiement refusé",
    "paymentDeclined": "Paiement refusé",
    "argDeclined": "{{ montant}} Refusé",
    "canceledPayment": "Paiement annulé",
    "paymentCanceled": "Paiement annulé",
    "argCanceled": "{{montant}} annulé",
    "providerDisabledExplanation": "{{fournisseur.nom}}} ne travaille plus avec Patientco pour accepter les paiements en ligne. Pour obtenir de l'aide pour effectuer un paiement, appelez {{provider.name}} à l'adresse {{provider.number}}. Nous nous excusons pour tout inconvénient.",
    "providerDisabledExplanationShort": "{{Fournisseur.nom}} ne collabore plus avec Patientco pour accepter les paiements en ligne.",
    "paymentPlanTitle": "Détails du programme",
    "paymentPlanStatus": "Plan de paiement {{status}}",
    "verificationCode": "Code de vérification",
    "receivesEbills": "Envoyez-moi de nouvelles factures et rappels",
    "receivesSms": "Envoyez-moi de nouvelles factures et rappels",
    "socialSecurityNumber": "Numéro de sécurité sociale",
    "birthdate": "Date de naissance (JJ / MM / AAAA)",
    "income": "Revenu mensuel du ménage",
    "changePassword": "Avez-vous besoin de changer votre mot de passe?",
    "deactivateAccount": "Avez-vous besoin de désactiver votre compte?",
    "invalidPassword": "Mot de passe invalide",
    "moreInfo": "Plus d'infos"
  },
  "paymentForm": {
    "method": {
      "stepName": "Méthode"
    },
    "billing": {
      "stepName": "Adresse"
    },
    "confirm": {
      "stepName": "Confirmation"
    }
  },
  "payment": {
    "amount": {
      "question": "Combien souhaitez-vous payer?",
      "selectDifferent": "S'il vous plaît sélectionnez une autre option de paiement:",
      "stepName": "Montant du paiement",
      "payBillBalArg": "Paiement de la facture: <b> {{totalTotal}} </ b>",
      "payBillBalArgDiscount": "Paiement du montant de la facture avec {{discountPolicy}} &nbsp;: <br /> <b> {{totalTotal}} </ b>",
      "payAccountBalArg": "Solde du compte de paiement: <b> {{TotalTotal}} </ b>",
      "payAccountBalArgDiscount": "Paiement du solde de compte avec {{discountPolicy}} &nbsp; Remise: <br / > <b> {{totalTotal}} </ b>",
      "payAnother": "Payer un autre montant",
      "otherPaymentOptions": "Examiner les autres options de paiement",
      "payOnce": "Souhaitez-vous effectuer un paiement ponctuel?",
      "payOverTime": "Souhaitez-vous payer plus tard? ?",
      "planInstallmentOption": "{{installmentAmo unt}} / month, {{nombre-acompte}} mois",
      "messageProvider": "Fournisseur de message",
      "reviewFinancingOptions": "Examiner les options de financement",
      "getFinancingOptionsErrorMessage": "Une erreur s'est produite lors de la tentative de chargement de vos options de financement. Veuillez réessayer.",
      "noFinancialOptionsFound": "Désolé, nous n'avons trouvé aucune option de financement à vous proposer.",
      "planDisabledBecauseOfBadDebt": "Les plans de paiement ne peuvent pas être créés sur des comptes qui ont été référés à une agence externe."
    },
    "date": {
      "question": "Quand voulez-vous payer? ",
      "stepName": "Date de paiement",
      "todayArg": "Aujourd'hui: <b> {{todayDate}} </ b>",
      "laterDate": "Payer à Date plus tard"
    },
    "method": {
      "question": "Comment voulez-vous payer?",
      "stepName": "Méthode de paiement",
      "cardMethod": "Carte de crédit / débit",
      "eCheckMethod": "eCheck",
      "userAnotherCard": "Utiliser une autre carte",
      "userAnotherECheck": "Utiliser un autre eCheck",
      "userAnotherCardOrECheck": "Utiliser une autre carte ou un chèque électronique"
    },
    "billing": {
      "question": "Quelle est votre adresse de facturation pour <br /> {{methodSummary}}?",
      "stepName": "Adresse de facturation",
      "useAnotherAddress": "Utiliser une autre adresse de facturation"
    },
    "confirm": {
      "planAmount": "{{acompteAmount}} / mois pour {{acompte}, mois",
      "question": "Fait tout",
      "stepName": "Confirmation",
      "cardBilling": "Adresse de facturation",
      "eCheckBilling": "Adresse de facturation eCheck"
    },
    "friendlyType": {
      "standard": "Paiement",
      "badDebt": "Paiement des créances irrécouvrables",
      "balance": "Paiement du solde",
      "copay": "Copay",
      "insurance": "Paiement de l'assurance",
      "prepayment": "Prépaiement",
      "other": "Autres"
    },
    "financing": {
      "application": {
        "stepName": "Demande de financement",
        "financingApplyHeader": "Pour voir si des offres sont disponibles, remplissez les formulaires:",
        "electronicDisclosureConsentText": "En cochant la case correspondante, vous certifiez que vous avez lu, conservé et accepté les <a href = '{{electronicDisclosureConsentLink} } 'target =' _ blank '> Consentement aux divulgations électroniques </a> et comprenez qu'il s'agit de votre signature électronique nous autorisant à traiter ce formulaire en votre nom.",
        "electronicDisclosureConsentError": "Un consentement à la divulgation électronique est requis.",
        "creditScoreNotice": "Consulter les offres n'a pas d'impact sur votre crédit sc miner.",
        "financingViewOffers": "Voir mes offres",
        "monthlyIncomeHeader": "Revenu mensuel brut total du ménage",
        "monthlyIncomeBody": "Une pension alimentaire, une pension alimentaire pour enfant ou un revenu d'entretien séparé ne doivent pas être inclus si vous ne souhaitez pas qu'il soit pris en compte pour le remboursement de cette obligation. <br > <br> Résidents mariés du Wisconsin: combinez les informations de votre épouse et de votre conjoint sur cette application.",
        "incomeGreaterThanZeroError": "Le revenu doit être supérieur à zéro.",
        "disclaimersHeader": "Lisez les clauses de non-responsabilité ci-dessous et faites défiler l'écran pour afficher vos offres",
        "newAccountProceduresHeader": "Informations importantes sur la procédure à suivre ' Ouverture d'un nouveau compte",
        "newAccountProceduresBody": "Pour aider le gouvernement à lutter contre le financement du terrorisme et des activités de blanchiment de capitaux, la loi fédérale oblige toutes les institutions financières à obtenir, vérifier et enregistrer les informations permettant d'identifier chaque personne qui ouvre un compte. Ce que cela signifie pour vous: lorsque vous sollicitez un prêt, nous vous demanderons votre nom, votre adresse postale, votre adresse électronique, votre date de naissance et d’autres informations nous permettant de vous identifier. Nous pouvons également demander à voir votre permis de conduire ou d’autres documents d’identité.",
        "stateLawNoticesHeader": "Avis de législation",
        "stateLawNoticesBody": "<p> <b> Résidents de la Californie: </ b> Si vous êtes marié, vous pouvez demander un compte séparé. </ P > <p> <b> Résidents du Delaware: </ b> Des frais de service ne dépassant pas ceux autorisés par la loi seront facturés sur les soldes impayés d'un mois à l'autre. </ p> <p> <b> Résidents du Maryland: < / b> Des frais financiers seront imposés pour des montants ou des taux ne dépassant pas ceux autorisés par la loi. </ p> <p> <b> Résidents de New York: </ b> Les résidents de New York peuvent contacter le département d’État de New York. des services financiers par téléphone ou visitez son site Web pour obtenir des informations gratuites sur les taux, frais et délais de grâce des cartes de crédit. Vous pouvez contacter le département des services financiers de l’État de New York au 800-342-3736 ou visiter son site Web: www.dfs.ny.gov. </ P> <p> <b> Résidents de l’Ohio: </ b> Les lois de l’Ohio contre la discrimination, tous les créanciers doivent mettre le crédit à la disposition égale de tous les clients solvables, et les agences d'évaluation du crédit doivent, sur leur demande, établir des historiques de crédit distincts pour chaque individu. La commission des droits civils de l'Ohio veille au respect de cette loi. </ P> <p> <b> Résidents du Vermont: </ b> Le prêteur est engagé dans la production de prêts. </ P> <p> <b> Résidents mariés du Wisconsin: < / b> Aucune disposition d'un contrat matrimonial, d'une déclaration unilatérale en vertu de l'article 766.59 ou d'un jugement d'un tribunal en vertu de l'article 766.70 ne porte atteinte à l'intérêt du créancier à moins que le créancier ne reçoive, avant l'octroi du crédit, une copie de la convention, déclaration ou décret ou a la connaissance effective de la disposition défavorable lorsque l’obligation envers le créancier est contractée. Nous vous demandons de fournir le nom et l'adresse de votre conjoint. </ P>",
        "electronicCommunicationConsentTitle": "Consentement spécial à la communication électronique",
        "electronicCommunicationConsentBody": "En cliquant sur 'Voir mes offres' ci-dessous et en soumettant une demande par voie électronique pour traitement, vous autorisez expressément le prêteur à vous contacter à propos de votre demande et de tout crédit consenti, ou à toute autre fin licite, y compris, sans limitation, le service à la clientèle ou la collecte, à toute adresse ou numéro de téléphone que vous pourrez, de temps à autre, fournir (y compris tout numéro de téléphone portaire), et notamment, sans limitation, les appels passés à l'aide d'appareils de composition et d'annonce automatiques pouvant lire des messages enregistrés.",
        "submitApplicationConsentTitle": "Consentement à soumettre une demande",
        "submitApplicationConsentBody": "En cliquant sur 'Voir mes offres' ci-dessous et en soumettant une demande par voie électronique pour traitement. , vous certifiez par la présente que vous avez au moins 18 ans (19 ans en AL ou NE) et que tout ce que vous avez indiqué ci-dessus est vrai et exact. Vous certifiez que vous n'avez actuellement pas de requête déposée en vertu de la législation fédérale sur les faillites (volontaire ou involontaire) et que vous n'envisagez pas de déposer une requête. Si votre demande de crédit est approuvée, vous acceptez d'être lié par les termes de la convention de crédit qui vous est fournie. Vous autorisez la Bank of Missouri, ses successeurs, ayants droit et mandataires (le 'prêteur') à vérifier votre solvabilité et à obtenir un rapport de solvabilité à des fins licites, y compris toute mise à jour, extension de crédit, révision ou recouvrement de votre compte, et vous autorisez la divulgation de ces informations au prêteur. Si vous le demandez, le prêteur vous indiquera si un rapport de crédit a été demandé et, le cas échéant, le nom et l'adresse de l'agence de renseignements sur la clientèle qui a fourni le rapport."
      },
      "offers": {
        "stepName": "Offres disponibles",
        "financingOptionsHeaderMultiple": "Félicitations, les offres sont disponibles!",
        "financingOptionsHeaderSingle": "Toutes nos félicitations! Voici votre offre!",
        "financingOptionCreditLimit": "Limite de crédit: {{currencyCreditLimit}}",
        "financingOptionAmountAndMonths": "{{currencyMonthlyAmount}} / month pendant {{mois}} mois",
        "financingOptionAPR": "Taux de pourcentage annuel: {{rate}}%",
        "financingOptionDeferredInterest": "Différé Intérêt: {{deferredInterestMonths}} mois",
        "financingOptionInterestBearing": "{{currencyMonthlyAmount}} / mois, {{mois}} mois, {{taux}}% d'intérêts",
        "financingOptionInterestFree": "{{currencyMonthlyAmount}} / mois, {{mois}} mois",
        "chooseFinancingOptionErrorMessage": "Une erreur est survenue lors de la communication avec notre partenaire financier. Veuillez réessayer.",
        "monthlyPaymentDisclaimer": "Paiement mensuel: Les paiements mensuels minimums estimés comprennent les frais financiers à un TAP de la valeur indiquée sur la vignette d'offre correspondante et un paiement total estimé au paiement mensuel minimum multiplié par le terme de remboursement. En supposant que le montant de l'achat soit {{chargeAmount | currency}} et que tous les paiements sont effectués à temps, la transaction d'achat sera payée dans le nombre de mois indiqué pour la période de remboursement indiquée sur la vignette d'offre correspondante.",
        "deferredInterestDisclaimer": "Intérêts différés: Des intérêts seront imputés à votre compte à partir de la date d'achat. si le solde de l’achat éligible n’est pas réglé intégralement au cours de la période promotionnelle ou si vous effectuez un retard de paiement. Vous devez payer plus que votre paiement minimum requis pour éviter les intérêts. Voir Accord du titulaire de carte pour plus de détails."
      },
      "existingAccount": {
        "stepName": "Confirmation",
        "existingAccountHeader": "Sur la base des informations fournies, nous avons trouvé un compte {{existingAccount.vendorName}} existant.",
        "accountNumberLabel": "Numéro de compte:",
        "availableCreditLabel": "Crédit disponible:",
        "authorizeTransactionSubheader": "Autoriser la transaction ' pour {{chargeAmount | currency}}",
        "authorizeTransactionDisclaimer": "En cochant cette case, vous acceptez de permettre à {{existingAccount.vendorName}} d'initier une transaction pour le montant de la facture approprié. Si votre marge de crédit est supérieure au montant de votre facture, vous pourrez l'utiliser pour d'autres factures avec {{providerName}} si vous le souhaitez.",
        "submitExistingAccountConfirmationErrorMessage": "Une erreur est survenue lors de la communication avec notre partenaire financier. Veuillez réessayer.",
        "partialPaymentDisclaimer": "Veuillez noter que vous aurez un solde restant de {{existingAccount.amountDueNotCovered | devise}}."
      },
      "summary": {
        "stepName": "Récapitulatif des conditions de crédit",
        "financingSummaryHeader": "Vérifier et accepter votre offre:",
        "acceptOfferDisclaimer": "Une fois que vous avez cliqué sur Accepter l'offre, votre compte {{vendorName}} sera créé et vous ne pourrez plus sélectionner une offre différente. .",
        "acceptOfferButton": "Accepter offre",
        "reviewTerms": "Avant de choisir votre offre, veuillez consulter le <a href='{{termsUrlBuchde> target='_blank'> Résumé des conditions de crédit </a>, qui résume les conditions essentielles du titulaire de la carte. Accord qui régira votre compte {{vendor}} renouvelable."
      },
      "confirm": {
        "stepName": "Confirmation",
        "financingConfirmHeader": "Presque terminé!",
        "cardholderAgreementSubheader": "Lisez et conservez le contrat de votre titulaire de carte:",
        "cardholderAgreementDisclaimer": "En cochant la case, vous acceptez que vous avez (i) lu et compris votre un href = '{{cardholderAgreementUrl}}' target = '_ blank'> Contrat de titulaire de carte </a>, y compris les conditions de toute offre avec intérêt différé et acceptant d'être lié par ses conditions, et (ii) ayant fourni l'adresse électronique correcte dans conserver une copie du contrat de titulaire de carte pour vos dossiers.",
        "authorizeTransactionSubheader": "Autoriser la transaction pour {{chargeAmount | currency}}",
        "authorizeTransactionDisclaimer": "En cochant cette case, vous acceptez d'autoriser {{vendorChosen}} à lancer une transaction pour {{chargeAmount | devise}}. Si votre marge de crédit est supérieure à ce montant, vous pourrez l'utiliser pour d'autres factures avec {{providerName}} si vous le souhaitez.",
        "partialPaymentDisclaimer": "Veuillez noter que le solde restant de votre compte est {{selectedOption.amountDueNotCovered | currency}}."
      },
      "success": {
        "financingRedirectHeader": "Nous allons bientôt vous rediriger vers notre partenaire financier ...",
        "financeRedirectSubContent": "<br> Si vous n'êtes pas redirigé automatiquement, veuillez <a id = 'redirectUrl' href = '{{redirectUrl}}' target = '_blank'> cliquez ici </a> pour continuer à consulter notre partenaire financier.",
        "financingSuccessHeader": "Merci pour le financement via {{vendor}}!",
        "financeSuccessSubContent": "{{vendor}} vous contacterons directement pour vous fournir les détails de votre compte. Si vous avez des questions, veuillez contacter {{vendor}} directement par téléphone au <b> {{vendorPhone}} </ b> ou par courrier électronique au <b> {{vendorEmail}} </ b>."
      },
      "paymentForm": {
        "infoHeader": "{ {fournisseur}} Carte",
        "infoContent": "<p> En fonction de votre compte existant avec {{fournisseur}}, vous pouvez utiliser votre marge de crédit ouverte pour financer vos factures avec <b> {{fournisseur}} </ b> . </ p> <p> Crédit disponible: {{availableCredit | devise}} </ p> <p> Pour toute question concernant votre compte {{vendor}}, veuillez appeler {{vendorPhone}} ou envoyer un e-mail à {{vendorEmail}}. </ p>"
      }
    }
  },
  "dialogs": {
    "confirmPasswordHeader": "Confirmer le mot de passe",
    "confirmPasswordSubheader": "Pour des raisons de sécurité ' raisons, veuillez entrer votre mot de passe.",
    "deactivateAccountHeader": "Désactiver le compte",
    "deactivateAccountSubheader": "Pour des raisons de sécurité, veuillez entrer votre mot de passe pour continuer.",
    "deactivateAccountWarning": "Attention! Une fois que vous avez désactivé votre compte, vous perdrez l'accès à votre compte PatientWallet<sup>&reg;</sup> et à toutes les données qui lui sont associées.",
    "deactivateAccountSuccessHeader": "Votre compte a été désactivé.",
    "deactivateAccountSuccessSubheader": "Si vous avez désactivé votre compte par erreur, veuillez nous contacter au 1-844-422-4779 ou à support@patientco.com.",
    "thanksForPayment": "Merci pour votre paiement!",
    "emailSentTo": "Nous avons envoyé un accusé de réception à <br /> {{email}}",
    "copySentTo": "Lorsque votre fournisseur répond, nous vous enverrons un e-mail à <br /> {{email}}",
    "successfulLogout": "Vous vous êtes déconnecté avec succès.",
    "thanksAndComeBack": "Merci d'avoir choisi Patientco. <br /> Nous espérons pouvoir vous recevoir. back!",
    "welcome": "Bienvenue à Patientco!",
    "happyYouAreHere": "Créer un compte pour commencer.",
    "thanksForComingBack": "Nous sommes ravis de vous revoir.",
    "leavingPaymentFlow": "Voulez-vous vraiment quitter cette page? ' Votre paiement n'a pas été traité.",
    "yourBalanceWasUpdated": "Votre solde a été mis à jour!",
    "dbuReasonHeaderUp": "Pourquoi mon solde a-t-il augmenté?",
    "dbuReasonHeaderDown": "Pourquoi mon solde a-t-il été réduit?",
    "dbuReason": "Depuis votre dernière facture, une nouvelle activité a eu lieu le Ton compte. Dans la plupart des cas, le solde mis à jour correspond à de nouveaux frais, paiements ou modifications des cotisations d'assurance.",
    "dbuStillPayable": "Ne vous inquiétez pas, vous pouvez toujours payer <br /> le montant imprimé sur votre facture.",
    "newerBillLoaded": "Les détails que vous avez entrés. proviennent d'un ancien projet de loi. Pour votre commodité, nous avons chargé la dernière facture qui reflète vos informations les plus récentes.",
    "demoDialog": "Environnement de démonstration",
    "gatewayDown": "Nous rencontrons actuellement des difficultés pour traiter les paiements. Nous nous excusons pour le désagrément, veuillez réessayer plus tard.",
    "cardsHidden": "Nous rencontrons actuellement des difficultés pour traiter les paiements par carte de crédit. Veuillez effectuer votre paiement par chèque.",
    "eChecksHidden": "Nous rencontrons actuellement des difficultés pour traiter les paiements par chèque. Veuillez effectuer votre paiement en utilisant une carte de crédit.",
    "cardsExpired": "Au moins une de vos cartes de crédit a expiré ou expirera avant la date prévue de votre paiement.",
    "contactTitle": "Merci de nous avoir contacté!",
    "contactSubtitle": "Nous sommes ravis d'entendre de votre part.",
    "phiWarning": "Pour votre sécurité, veuillez exclure les informations personnelles sur la santé.",
    "contactSent": "Merci pour votre message!",
    "contactThanks": "Nous vous répondrons dans les meilleurs délais.",
    "termsTitle": "Conditions d'utilisation",
    "welcomeBack": "Bienvenue retour, {{name}}!",
    "termsUpdated": "Nos conditions d'utilisation ont été mises à jour depuis votre dernière connexion.",
    "logoutWarningHeader": "Votre session est sur le point de expire",
    "logoutWarningSubcontent": "Pour votre sécurité, cette session en ligne se terminera bientôt en raison d'inactivité.",
    "retryProcessPayment": "Nous n'avons pas pu traiter votre paiement dans son intégralité. Veuillez réessayer votre soumission ci-dessous. Ne vous inquiétez pas, nous ne facturerons votre carte qu'une seule fois.",
    "feedbackTitleReceiptLink": "Quelle a été votre expérience de paiement en ligne?",
    "feedbackTitleClosedMessageLink": "Comment était votre service client de {{fournisseurName}}?",
    "feedbackSubtitlePaymentHappy": "Génial! D'autres commentaires?",
    "feedbackSubtitlePaymentSad": "Désolé d'entendre ça! Comment pouvons-nous améliorer?",
    "feedbackSubtitleClosedMessageHappy": "Great! D'autres commentaires?",
    "feedbackSubtitleClosedMessageSad": "Désolé d'entendre ça! Qu'est-ce que votre fournisseur pourrait améliorer?",
    "feedbackCommentPlaceholder": "Commencez à saisir les commentaires ...",
    "thanksForFeedback": "Merci pour vos commentaires!",
    "accountIsFinanced": "Ce compte est actuellement géré par {{financesBy}}.",
    "contactFinancingVendor": "Pour effectuer un paiement ou pour Si vous avez besoin d'aide pour votre compte, veuillez contacter directement {{FinanceBy}}.",
    "updatePaymentFormHeader": "Sélectionner le mode de paiement",
    "updatePaymentFormConfirmHeader": "Modifier le mode de paiement",
    "updatePaymentMethodButtonText": "Confirmer",
    "updatingPaymentMethodButtonText": "Mise à jour ...",
    "updateAndApplyPaymentMethodButtonText": "Confirmer",
    "updateAndApplyingPaymentMethodButtonText": "Traitement en cours ...",
    "confirmingButtonText": "Confirmer ...",
    "addAnotherCard": "Ajouter une autre carte",
    "addAnotherECheck": "Ajouter un autre chèque",
    "addAnotherCardOrECheck": "Ajouter une autre carte ou un chèque électronique"
  },
  "values": {
    "valueArg": "Valeur Patientco # {{numéro}}",
    "descriptions": {
      "1": "Priorité aux patients. Les patients sont au cœur de tout ce que nous faisons chez Patientco.",
      "2": "Travailler pour servir. Nous faisons tout ce qui est nécessaire pour rendre les patients heureux.",
      "3": "Soyez honnête: nous faisons ce que nous disons que nous allons faire.",
      "4": "Créez des gains pour tous. Nous croyons en un monde où patients et prestataires travaillent ensemble.",
      "5": "Viser la simplicité. Nous nous battons pour que les patients puissent gérer facilement leurs dépenses de soins de santé.",
      "6": "Améliorer les soins de santé. Nous existons pour améliorer chaque jour l'expérience des patients en matière de soins de santé."
    }
  },
  "links": {
    "whyPatientco": "À propos de Patientco",
    "news": "Actualités",
    "termsOfService": "Conditions d'utilisation",
    "careers": "Carrières",
    "privacyPolicy": "Politique de confidentialité"
  },
  "misc": {
    "conjunction": "et",
    "on": "sur"
  },
  "errors": {
    "email": "Entrez un adresse e-mail qui correspond au format standard. Voici un exemple: james.smith@example.com.",
    "emailUnavailable": "Vous avez entré une adresse e-mail déjà enregistrée. Essayez de vous connecter pour accéder à vos informations.",
    "emailInUse": "Vous avez entré une adresse e-mail déjà enregistrée.",
    "password": "Votre mot de passe doit contenir au moins 8 caractères, 1 lettre majuscule et 1 chiffre.",
    "confirmPassword": "Il semble que les mots de passe que vous avez entrés ne correspondent pas! Revérifiez pour vous assurer que les deux entrées sont exactement les mêmes.",
    "allNumericSCode": "Essayez de saisir le SecureHealthCode imprimé en vert sur votre facture. Il se trouve juste au-dessus du numéro bleu que vous avez entré.",
    "scodeRequired": "Veuillez saisir le code vert SecureHealthCode situé dans la partie supérieure droite de votre facture, sous le logo Patientco.",
    "amountRequired": "Veuillez saisir le solde total imprimé sur votre facture. Nous vous demanderons le montant du paiement souhaité à l'étape suivante.",
    "belowMinAmount": "Vous avez saisi une facture avec un montant négatif. Veuillez entrer le solde total imprimé sur votre facture.",
    "unableToFindBill": "Nous ne trouvons pas de facture correspondant au code SecureHealthCode et au solde total que vous avez entré. Vérifiez que vos informations sont correctes.",
    "billThrottled": "Vous avez tenté de trouver une facture trop de fois. Pour des raisons de sécurité, votre capacité à rechercher des factures est désactivée depuis quelques minutes.",
    "unableToLogin": "L'adresse e-mail ou le mot de passe que vous avez entré est incorrect.",
    "loginThrottled": "Vous avez tenté de vous connecter trop souvent. Pour des raisons de sécurité, votre capacité à vous connecter est désactivée depuis quelques minutes.",
    "loginEmailRequired": "Entrez une adresse électronique pour nous aider à trouver votre compte.",
    "loginPasswordRequired": "Veuillez saisir le mot de passe de votre compte pour vous connecter à Patientco.",
    "minAmountArg": "Votre paiement Le montant doit être supérieur à {{minAmount}}",
    "maxAmountArg": "Le montant de votre paiement doit être inférieur ou égal au solde de votre compte de {{maxAmount}}",
    "dateRequired": "Veuillez sélectionner une date dans le calendrier.",
    "ccRequired": "Veuillez saisir un crédit. ou numéro de carte de débit.",
    "ccSeemsInvalid": "Veuillez entrer un numéro de carte valide. Il se peut que des numéros manquants ou en panne soient entrés dans votre saisie actuelle.",
    "ccUnknownCardType": "Vous avez entré un type de carte qui n’est pas accepté par Patientco. Essayez d’utiliser une carte Visa, MasterCard, Discover ou American Express.",
    "ccExpired": "Selon la date que vous avez entrée, votre carte est arrivée à expiration et ne peut pas être débitée.",
    "ccPrematureExpiration": "Vous avez entré une carte qui expire avant la date de paiement. . Veuillez utiliser une carte qui expire après {{payMonth}} / {{payYear}}.",
    "genericRequired": "Veuillez renseigner ce champ pour continuer.",
    "genericCheckboxRequired": "Veuillez lire l'avertissement et cochez cette case pour continuer.",
    "invalidRoutingNumber": "Le numéro d'acheminement 'ai saisis n'est pas valide.",
    "minimumAccountNumber": "Les numéros de compte doivent comporter au moins 4 chiffres.",
    "mismatchedAccountNumber": "Les numéros de compte que vous avez entrés ne correspondent pas. Veuillez vérifier que les chiffres sont exactement les mêmes.",
    "unableToReset": "Nous ne pouvons pas réinitialiser votre mot de passe. Assurez-vous que vous utilisez le lien le plus récent de récupération du mot de passe dans votre courrier électronique.",
    "paymentProcessingError": "Nous ne pouvons pas traiter votre paiement. Veuillez contacter Patientco au 1-844-422-4779 pour finaliser votre paiement.",
    "paymentInvalidCardInformation": "Les informations de carte que vous avez entrées sont invalides. Veuillez vérifier le numéro de carte et l’expiration de votre mode de paiement.",
    "paymentCardDeclined": "Nous ne pouvons pas traiter votre paiement en raison d’une carte refusée. Modifiez votre mode de paiement et réessayez.",
    "paymentDuplicatePayment": "Il semblerait que vous essayiez de soumettre un paiement en double. Si cela est intentionnel, veuillez contacter Patientco au 1-844-422-4779.",
    "confirmExpiredCard": "On dirait que vous essayez de payer avec une carte expirée ou expirera avant la date de paiement prévue. Modifiez votre mode de paiement et réessayez.",
    "confirmPrematureExpiration": "Il semblerait que vous essayiez de programmer un paiement après l’expiration de votre carte de crédit. Modifiez votre mode de paiement et réessayez.",
    "phoneFormat": "Il semblerait qu'il manque certains numéros dans votre numéro de téléphone. Veuillez entrer un numéro qui correspond au format de XXX-XXX-XXXX",
    "smsEligible": "Ce numéro ne peut recevoir de messages texte. Veuillez entrer un numéro de téléphone mobile américain valide. Si vous n'avez pas de numéro de téléphone portable, laissez le champ vide.",
    "termsAcceptanceRequired": "Pour des raisons juridiques, nous demandons à tous les utilisateurs d'accepter nos conditions d'utilisation.",
    "invalidPassword": "Le mot de passe actuel que vous avez attribué ne correspond pas à la valeur de notre système . Veuillez le vérifier et réessayer.",
    "unableToChangePassword": "Nous ne pouvons pas mettre à jour votre mot de passe pour le moment. Veuillez vérifier vos coordonnées et réessayer.",
    "nonexistentEmail": "Il n'y a pas de compte Patientco associé à cet email.",
    "minZipCode": "Veuillez entrer un code postal à 5 ​​chiffres pour continuer.",
    "fiveHundred": "Il semble que le serveur ait rencontré une erreur lors du traitement de votre demande. Veuillez réessayer dans quelques minutes.",
    "runningOutOfCharacters": "Vous avez utilisé des {{current}} / {{allowed}} caractères",
    "reasonRequired": "Veuillez sélectionner le motif du message",
    "messageRequired": "Veuillez saisir votre message pour continuer",
    "futureBirthdate": "Vous avez entré une date de naissance qui se déroule dans le futur.",
    "incompleteBirthdate": "Veuillez saisir une date de référence complète (MM / JJ / AAAA).",
    "invalidDate": "La date n'existe pas.",
    "unknown": "Une erreur inconnue s'est produite. Réessayer. Si le problème persiste, veuillez contacter Patientco au 1-844-422-4779.",
    "paymentFormProcessingError": "Nous ne pouvons pas ajouter votre méthode de paiement. Veuillez contacter Patientco au 1-844-422-4779.",
    "paymentFormInvalidCardInformation": "Les informations de votre carte semblent invalides. Veuillez vérifier le numéro de carte et l’expiration de votre mode de paiement.",
    "ssnFormat": "Il semblerait que certains numéros ne figurent pas dans votre numéro de sécurité sociale. Veuillez entrer un nombre qui correspond au format XXX-XX-XXXX"
  },
  "accountEvents": {
    "bill": "Projet de loi envoyé",
    "collection": "collection",
    "collection-stop": "collection-stop",
    "collection-update": "mise à jour de la collection",
    "collection-extraction": "collection-extraction",
    "correspondence": "Correspondance reçue",
    "correspondenceOnly": "correspondance uniquement '.",
    "message": "Message envoyé: {{message}}",
    "changeOfAddress": "Adresse modifiée",
    "payment": "Paiement effectué",
    "paymentPlan": "Paiement planifié",
    "returnMail": "Envoi de courrier",
    "reversePayment": "Remboursement traité",
    "balanceUpdate": "Solde mis à jour",
    "voidedPayment": "Paiement annulé",
    "declinedPayment": "Paiement Refusé",
    "canceledPayment": "Paiement annulé",
    "voidedPaymentPlan": "Plan de paiement annulé",
    "canceledPaymentPlan": "Plan de paiement annulé"
  },
  "transworld": {
    "clientRefNum": "N ° de référence du client",
    "clientRefNumRequired": "Entrez le numéro de référence du client situé en haut à droite de votre facture.",
    "allNumericlientRefNum": "Essayez d'entrer le numéro de référence du client. # imprimé sur votre facture. Il se trouve dans la partie supérieure droite de votre facture."
  },
  "fullPageNotifications": {
    "emailVerified": {
      "header": "Merci!",
      "subheader": "Votre adresse e-mail a été vérifiée.",
      "mainButtonText": "Continuer",
      "message": "Nous sommes heureux de vous accueillir ici! Vous avez rejoint une communauté de 2 millions de consommateurs qui prennent en charge leurs dépenses de santé."
    }
  }
});
}]);
