angular.module('PatientApp').directive('input', function( KeyboardService ){
    var _$body = angular.element(document.body);

    return {
        link: function(scope, el){


            // Since changing the structure of elements to be that
            // the inputs themselves aren't fully in control of the bottom
            // border and color of the ui area in which they are contained,
            // we must indicate to the wrapper that is in control of the ui
            // state that we are focusing

            el.on('focus', function(){
                var parential = el.parent(),
                    matched = false;

                while(!(matched = parential.hasClass('input-area-wrapper'))){
                    parential = parential.parent();
                    
                    // limit scope to the body element
                    if(parential[0] === document.body){
                        break;
                    }
                }

                if(matched){
                    parential.addClass('input-focused');
                }

                // tell the body that we have focus on an element
                // helps identify if a softkeyboard may be visible
                _$body.addClass('js-app-input-focused');
            });


            el.on('blur', function(){
                angular.element(document.querySelector('.input-focused')).removeClass('input-focused');
                _$body.removeClass('js-app-input-focused');
            });


            // inputs with ng-model-options="{updateOn: 'blur'}"
            // are not starting their validations at the correct time
            // so we need to start that process immediately if someone
            // types enter in these fields
            el.on('keydown', function(e){
                if(KeyboardService.parseEvent(e).is('enter')){
                    el.triggerHandler('blur');
                }
            });


            // clean up listeners;
            scope.$on('$destroy', function() {
                el.off('focus');
                el.off('blur');
                _$body.removeClass('js-app-input-focused');
            });
        }
    };
});