angular.module('PatientApp').controller('ConfirmPasswordModalCtrl', function($scope, $rootScope, _, $filter){   

    var _clearInputs = function(){
            $scope.fields = {};
            $scope.fields.password = '';

            if($scope.forms.confirmPasswordForm){
                $scope.forms.confirmPasswordForm.$submitted = false;
            }
        },

        _close = function(){
            _clearInputs();
            $scope.promptVisible = false;
        },
        _callback;

    $scope.promptVisible = false;

    $rootScope.$on('confirmPassword:prompt', function(event, promptProperties) {
        $scope.promptVisible = true;

        $scope.modal = _.assign({
            header: $filter('translate')('dialogs.confirmPasswordHeader'),
            subheader: $filter('translate')('dialogs.confirmPasswordSubheader'),
            additionalInfo: ''
        }, promptProperties);

        _callback = promptProperties.callback;
    });

    $scope.forms = {
        confirmPasswordForm: null
    };

    _clearInputs();

    $scope.cancel = function(){
        _close();
    };

    $scope.confirm = function() {
        $scope.forms.confirmPasswordForm.$submitted = true;

        if ($scope.forms.confirmPasswordForm.$invalid){
            return;
        }

        _callback( $scope.fields.password );
        _close();        
    };
});