angular.module('PatientApp').controller('DashboardCtrl', function($scope, $rootScope, $state, $log, $q, $filter, _, AuthService, PatientUsersService, LinkedAccountsService, ProvidersService, MoneyUtilsService, LoadingIndicatorService, Compass){

    var user = PatientUsersService.getCurrentUser(),
        calculateTotal = function(providers){
            var balances = _.map(providers, function(provider){
                    return (provider.active) ? provider.balance : 0;
                }),
                sum = MoneyUtilsService.add.apply(null, balances);

            $scope.friendlyTotal = {amount: sum};
        },
        getPastDueAccounts = function(provider){

            var today = new Date();
            today.setHours(0,0,0,0);

            // do not reflect past due items for providers that have been disabled in patientco.  also, ignore bill dueDates
            // for providers which don't actually use dueDates (as determined by 'patientDueDateOverrideText'.
            if(!provider.active || provider.patientDueDateOverrideText){
                return [];
            }


            return _.filter(provider.accounts, function(acc){
                // only show accounts that have a due date earlier than today
                // and they have an account balance
                // If we have a balance greater than zero we are not
                // 100% sure if it is the bill or a dbu so we will add the
                // check on dbu to help catch if it is a dbu, don't say it's past
                if (!acc.dueDate) {
                    return false;
                }
                var due = new Date(acc.dueDate);
                due.setHours(0,0,0,0);

                return acc.amountDue > 0 && due < today && acc.lastAction !== 'dbu';
            });
        },

        loadingKeyProviders = LoadingIndicatorService.loading(),

        _refreshLinkableNotification = function() {
            if (LinkedAccountsService.hasUnverified()) {

                Compass.capture.event('account-linking', 'prompted', 'presented-dashboard');

                $scope.showLinkableNotification = true;
                $scope.linkableNotificationText = LinkedAccountsService.getNotificationText();
            } else {
                $scope.showLinkableNotification = false;
            }
        },

        _refreshConfirmPhoneNotification = function() {
            var user = PatientUsersService.getCurrentUser();
            $scope.showConfirmPhone = !user.phoneNumberConfirmed && !user.newUser;
        },

        _refreshConfirmEmailNotification = function() {
            var user = PatientUsersService.getCurrentUser();
            $scope.showConfirmEmail = !user.emailVerified && !user.newUser && !user.newEmail;
            $scope.confirmEmailMessage = $filter('translate')('actions.confirmEmail', {email: user.email});
        },

        _destroyVerificationListener = $rootScope.$on('accountVerification:updated', function() {
            _refreshLinkableNotification();
        }),

        _destroyPhoneConfirmationListener = $rootScope.$on('confirmPhone:confirmed', function() {
            _refreshConfirmPhoneNotification();
        }),

        _destroyUserSettingsUpdatedListener = $rootScope.$on('userSettings:updated', function() {
            _refreshConfirmEmailNotification();
        });

    $rootScope.isFirstBill = false;

    $scope.$on('$destroy', function(){
        _destroyVerificationListener();
        _destroyPhoneConfirmationListener();
        _destroyUserSettingsUpdatedListener();
    });

    $scope.providers = [];


    _refreshConfirmPhoneNotification();
    _refreshConfirmEmailNotification();

    $q.all([
        ProvidersService.sync(),
        LinkedAccountsService.fetchUnverified()
    ]).then(function(data) {

        //results for first promise.  we don't care about the results from the second promise.
        var providers = data[0],
            hasUnverified = LinkedAccountsService.hasUnverified();

        $scope.providers = providers;
        calculateTotal(providers);

        if (providers.length === 1 && !hasUnverified){
            //if we have one provider and there is NO notification for unverified accts, send the user to the
            //single-provider page - otherwise, stay here.
            $state.go('app.dashboard.provider.details', {id: providers[0].id});
        } else if(providers.length === 0){
            // zero state for no providers
            $scope.showZeroState = true;
        }

        // check the deep link trigger parameter to see if we
        // need toggle the visibility of the modal
        if(hasUnverified && $state.params.trigger === 'verify'){
            $rootScope.$emit('accountVerification:prompt');

            // clean up after ourselves:
            // we are going to update our state but don't trigger
            // the ui to be updated. Needed so any reload of this state
            // programatic or not, will not trigger the auto verify again
            $state.go('app.dashboard', {verified: null}, { inherit: false, location: 'replace', notify: false });
        }

        _refreshLinkableNotification();
    }).finally(function(){
        LoadingIndicatorService.complete(loadingKeyProviders);
    });

    if (user.firstName.length <= 12) {
        $scope.usersKey = user.firstName ? user.firstName.slice(-1) !== 's' ? 'dashboard.argsDashboard' : 'dashboard.argsDashboardEndsWithS' : 'dashboard';
    } else {
        $scope.usersKey = 'dashboard';
    }

    $scope.translationData = {
        name: user.firstName
    };

    $scope.completeAcct = function() {
        $rootScope.$emit('accountVerification:prompt');
    };

    $scope.confirmPhone = function() {
        $rootScope.$emit('confirmPhone:prompt');
    };

    $scope.confirmEmail = function() {
        $rootScope.$emit('confirmEmail:prompt');
    };

    $scope.hasPastDueAccounts = function(provider){
        return getPastDueAccounts(provider).length > 0;
    };

    $scope.providerSummary = function(provider){

        var stats = [ ],
            accLen = provider.accounts.length,
            pastDue = getPastDueAccounts(provider),
            pastLen = pastDue.length,
            pastKey = '';

        if (provider.active) {
            stats.push($filter('currency')(provider.balance));
        }

        if(accLen > 0 && pastLen !== accLen){
            stats.push($filter('translate')(accLen > 1 ? 'labels.argAccounts' : 'labels.argAccount', {num : accLen}));
        }

        if(pastLen > 0){

            pastKey = pastLen !== accLen ? 'labels.argNumPastDue' :
                pastLen > 1 ? 'labels.argNumAccountsPastDue' : 'labels.argNumAccountPastDue';

            stats.push(
                '<span class="past-due-stat">' +
                $filter('translate')(pastKey, {num : pastLen}) +
                '</span>'
                );
        }


        // possibly map to a past due and a ok standing array

        return stats.join(' | ');

    };


});
