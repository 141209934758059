angular.module('PatientApp').factory('FinancingService', function($log, $resource, $q, endpoints){



    var Financing = $resource(null, null, {
        getOptions: {
            method: 'POST',
            url: endpoints.financing.getOptions.url
        },
        chooseOption: {
            method: 'POST',
            url: endpoints.financing.chooseOption.url
        },
        chargeFinancingPaymentFormFirstTime: {
            method: 'POST',
            url: endpoints.financing.chargeFinancingPaymentFormFirstTime.url
        },
        chargeExistingFinancingAccount: {
            method: 'POST',
            url: endpoints.financing.chargeExistingFinancingAccount.url
        }
    });

    return {
        getOptions: function(application) {
            // Income must be an int
            if(typeof application.income !== 'undefined'){
                application.income = parseInt(application.income);
            }
            return Financing.getOptions(application).$promise
                .then(function(resp){
                    if(resp && resp.hasData()){
                        return resp.getData();
                    }
                    return $q.reject(resp);
                });
        },

        chooseOption: function(secureCode, vendor, option) {
            return Financing.chooseOption({
                vendor: vendor,
                option: option,
                secureCode: secureCode
            }).$promise
            .then(function(resp){
                if(resp && resp.hasData() && resp.getData().success){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },

        chargeFinancingPaymentFormFirstTime: function(body) {
            return Financing.chargeFinancingPaymentFormFirstTime(body).$promise
            .then(function(resp){
                if(resp && resp.hasData() && resp.getData().success){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },

        chargeExistingFinancingAccount: function(body) {
            return Financing.chargeExistingFinancingAccount(body).$promise
            .then(function(resp){
                if(resp && resp.hasData() && resp.getData().success){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },

        getDownloadUrl: function(vendor, fileId, fileName){
            return endpoints.financing.downloadFinancingDoc.url + '/' + vendor + '/' + fileId + '/' + fileName;
        }
    };

});