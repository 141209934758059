angular.module('PatientApp').controller('FirstBillVerificationCtrl', function($scope, $rootScope, $state, $log, $filter, $timeout, _, ErrorsService, BillsService, Compass){

    var _token,
        _codeSendInProgress = false, //mutex for get-code ajax call
        _verifyInProgress = false, //mutex for get-code ajax call
        _resetNotifications = function() {
            $scope.verifyNotifications = {};
        },
        _reset = function(){
            _token = '';

            $scope.fields = {};
            $scope.fields.birthdate = '';
            $scope.fields.SSN = '';
            $scope.fields.emailVerificationCode = '';
            $scope.fields.smsVerificationCode = '';
            _resetNotifications();

            $scope.submitting = false;
            $scope.submitButtonText = $filter('translate')('firstBill.submitText');
            $scope.sendAgainButtonText = $filter('translate')('firstBill.sendAgainButtonText');

            if ($scope.forms) {
                if ($scope.forms.verifyEmailCodeForm) {
                    $scope.forms.verifyEmailCodeForm.$setPristine();
                }
                if ($scope.forms.verifySmsCodeForm) {
                    $scope.forms.verifySmsCodeForm.$setPristine();
                }
                if ($scope.forms.verifyPdivForm) {
                    $scope.forms.verifyPdivForm.$setPristine();
                }
            } 

            $scope.moveToState('options');
        },
        _hidePrompt = function(){
            $scope.promptVisible = false;
        },
        _destroyShowPrompt = $rootScope.$on('firstBillVerification:prompt', function(event, args) {
            _reset();

            $scope.authOptions = args.options;
            $scope.authPossible = $scope.authOptions.canEmailAuth || $scope.authOptions.canPhoneAuth || $scope.authOptions.canPdivAuth;
            _token = args.token;

            $scope.guarantorFirstName = $filter('translate')('firstBill.missingGuarantorName'); //default to something generic
            if ($scope.authOptions.guarantorName) {
                $scope.guarantorFirstName = $scope.authOptions.guarantorName;
            }

            $scope.promptVisible = true;

            Compass.capture.event('first-bill', 'modal', 'opened');
        }),
        _submitFindNew = function(verificationCode, guarantorLast4SSN, guarantorDOB) {
            if (_verifyInProgress) {
                return;
            }

            $scope.submitting = true;
            $scope.submitButtonText = $filter('translate')('firstBill.submittingText');

            _resetNotifications();

            Compass.capture.event('first-bill', 'find-new', 'started');

            _verifyInProgress = true;
            BillsService.findNew(_token, verificationCode, guarantorLast4SSN, guarantorDOB)
                .then(function() {
                    Compass.capture.success('first-bill', 'find-new', '');

                    _hidePrompt();

                    // Used later in the payment flow for logging
                    $rootScope.isFirstBill = true;
                    
                    //bill has been set as currentBill within billsService.  tell user about the successful link and
                    //them point them toward the verify/payment flow.
                    $scope.$emit('simpleModal:showPrompt', {
                        header: $filter('translate')('firstBill.successHeader'),
                        subcontent: $filter('translate')('firstBill.successSubheader'),
                        intent: 'success',
                        actions: [{
                            label: $filter('translate')('firstBill.successModalButton'),
                            clickHandler: function(){
                                $state.go('app.verify');
                            }
                        }]
                    });
                })
                .catch(function(resp) {
                    Compass.capture.failure('first-bill', 'find-new', '');

                    switch(ErrorsService.resolve(resp)){
                        case ErrorsService.errors.RATE_LIMIT_EXCEEDED:
                            $scope.verifyNotifications.bruteForce = true;
                            break;
                        default:
                            $scope.verifyNotifications.genericError = true;
                            break;
                    }
                })
                .finally(function() {
                    $scope.submitButtonText = $filter('translate')('firstBill.submitText');
                    $scope.submitting = false;
                    _verifyInProgress = false;
                });
        };

    $scope.$on('$destroy', function() {
        _destroyShowPrompt();
    });

    $scope.forms = {};

    $scope.close = function() {
        _hidePrompt();
    };

    $scope.moveToState = function(stepName) {
        _resetNotifications();

        $scope.currentStep = stepName;
    };

    $scope.sendCode = function(isResend) {
        if (_.isUndefined(isResend)) {
            isResend = false;
        }
        if (_codeSendInProgress) {
            return;
        }

        $scope.codeNotSent = false;

        _codeSendInProgress = true;
        BillsService.firstBillGetCode(_token)
            .then(function(){
                if (isResend) {
                    $scope.codeSent = true;
                    $timeout(function(){
                        $scope.codeSent = false;
                    }, 5000);
                }
            })
            .catch(function() {
                $scope.codeNotSent = true;
                $timeout(function(){
                    $scope.codeNotSent = false;
                }, 5000);
            })
            .finally(function() {
                _codeSendInProgress = false;
            });
    };

    $scope.submitEmailCode = function() {
        $scope.forms.verifyEmailCodeForm.$submitted = true;

        if ($scope.forms.verifyEmailCodeForm.$invalid || $scope.submitting) {
            return;
        }

        _submitFindNew($scope.fields.emailVerificationCode, '', '');
    };

    $scope.submitSmsCode = function() {
        $scope.forms.verifySmsCodeForm.$submitted = true;

        if ($scope.forms.verifySmsCodeForm.$invalid || $scope.submitting) {
            return;
        }

        _submitFindNew($scope.fields.smsVerificationCode, '', '');
    };

    $scope.submitPdiv = function() {
        $scope.forms.verifyPdivForm.$submitted = true;

        if ($scope.forms.verifyPdivForm.$invalid || $scope.submitting) {
            return;
        }

        var formattedBirthdate = $filter('dateFormat')($scope.fields.birthdate, 'yyyy-MM-dd');

        _submitFindNew('', $scope.fields.SSN, formattedBirthdate);
    };
});
