angular.module('PatientApp').directive('validName', function(_, KeyboardService){

    // this directive is used to apply a special logic to first- and last-name fields.  
    // only letters and a few special characters are allowed
    return {
        link: function(scope, el){
            el.on('keydown', function(event){
                var key = KeyboardService.parseEvent(event);

                //reject numbers on keydown.  we should be rejecting other non-letter characters too, but we don't have a good way of 
                //detecting these keys on keydown.  instead, we filter them out during keyup
                if (key.isNumber()) {
                    event.preventDefault();
                }
            });

            el.on('keyup', function(){
                var start = this.selectionStart,
                    end = this.selectionEnd,
                    atEnd = this.selectionStart === el.val().length,
                    newVal = el.val().replace(/[^a-zA-Z ,.'-]/g, '');

                el.val(newVal);

                if (atEnd) {
                    this.setSelectionRange(newVal.length, newVal.length);
                } else {
                    this.setSelectionRange(start, end);
                }
            });
        }
    };
});