angular.module('PatientApp').directive('expiry', function($timeout, $filter, _, KeyboardService){

    var _MAX_FORMATTED_LENGTH = 7,
        _getFormattedString = function(inputStr, blur, backspace, position){
            var noFormatting = inputStr.replace(/(\s|\D)/g, ''),
                i = 0,
                curChar = '',
                output = '',
                len = 0;

            while(i < noFormatting.length){

                curChar = _.parseInt(noFormatting[i]);

                i++;

                if(isNaN(curChar)){
                    return;
                }

                // FORMATTING

                // MONTH LOGIC
                if(len === 0){
                    // padded zero or 1 based 
                    if(_.includes([ 0, 1 ], curChar)){
                        output += curChar;
                    }else {
                        output +=  (position === 0 && backspace ? '' : '0') + curChar + ' / ';
                    }
                } else if ( len === 1 ){

                    if(_.includes([ 0, 1, 2 ], curChar)){
                        
                        // account for 00 month entry
                        if(curChar !== 0 || (curChar === 0 && output[0] !== '0')){
                            
                            // account for pressing back when we have only the slash
                            output += curChar + ( position === 4 && backspace ? '' : ' / ');
                        }

                    }else if(output[0] === '0') {
                        output += curChar + (position === 4 && backspace ? '' : ' / ');
                    }else {
                        // for inputting something that should roll over into the year
                        // like 15 should become 01 / 5
                        output = '0' + output + ' / ' + curChar;
                    }
                }

                // YEAR LOGIC
                if(len >= 2){
                    
                    // if the user blurred from the input and 
                    // we do not have a two digit year, pad the
                    // last input
                    if(blur && len === 5 && i === noFormatting.length){
                        output += '0';
                    }

                    output += curChar;
                }
                
                len = output.length;
            }

            return output.slice(0, _MAX_FORMATTED_LENGTH);
        };

    return {
        require: 'ngModel',
        link: function(scope, el, attrs, ngModel){

            var runValidators = function(explicitValue) {

                // TODO: migrate this code to the CardService#isExpired call
                // when we get to unit testing this directive

                var val = (explicitValue || ngModel.$modelValue || '').toString(),
                    expired = false,
                    prematureExpiration = false,
                    expiry,
                    today,
                    year,
                    month;

                if(val.length === 4) {
                    month = _.parseInt(val.slice(0, 2));
                    year = _.parseInt(val.slice(-2));

                    // do some fuzzy logic to pull out the
                    // approximate year they user is referring to
                    // not this is only used for validations
                    if (year < 70) {
                        year = '20' + (year > 9 ? year : '0' + year);
                    } else {
                        year = '19' + (year > 9 ? year : '0' + year);
                    }

                    expiry = new Date(year, month);
                    today = new Date();

                    // account for zero based index
                    // and roll the month up to the next month + 1 day
                    // as cards expire when the month listed elapses
                    expiry.setMonth(expiry.getMonth() - 1);
                    expiry.setMonth(expiry.getMonth() + 1, 1);

                    expired = expiry < today;
                    prematureExpiration = false;

                    if (scope.pay && !expired) {
                        prematureExpiration = scope.pay.isExpiring(val);
                        expired = false;
                    }
                }

                ngModel.$setValidity('required', val.length >= 4);
                ngModel.$setValidity('expired', !expired);
                ngModel.$setValidity('prematureExpiration', !prematureExpiration);
            };



            // don't allow more than the formatted length
            el.attr('maxlength', _MAX_FORMATTED_LENGTH);


            el.on('paste', function(){
                $timeout(function(){
                    el.val(_getFormattedString(el.val()));
                }, 50);
            });
    
            // only accept numbers
            el.on('keydown', function(event){
                var key = KeyboardService.parseEvent(event);
                // we have no reason to use shift currently, so instead of 
                // trying to filter those out on keyup, prevent them now
                if((!key.isNumber() && !key.isEditorKey() && !key.hasModifier()) || key.is('shift')){
                    event.preventDefault();
                }
            });

            el.on('keyup', function(event){
                
                var start = this.selectionStart,
                    end = this.selectionEnd,
                    backspacing = event.keyCode === 8,
                    atEnd = this.selectionStart === el.val().length,
                    newVal = _getFormattedString(el.val(), false, backspacing, start);

                el.val(newVal);

                if(atEnd){
                    this.setSelectionRange(newVal.length, newVal.length);
                
                }else if(backspacing && start === 4) {
                    // if we backspace the slash section, we
                    // need to account for change in position
                    this.setSelectionRange(2, 2);
                }else {
                    this.setSelectionRange(start, end);
                }

                if (scope.pay) {
                    scope.payTranslation = {
                        payMonth: $filter('dateFormat')(scope.pay.getDate(), 'MM'),
                        payYear: $filter('dateFormat')(scope.pay.getDate(), 'yy')
                    };
                }

            });

            el.on('blur', function(){
                var original = el.val(),
                    updated = _getFormattedString(original, true);

                
                // only if we changed the value after
                // user left should we call apply
                if(original !== updated){
                    ngModel.$setViewValue(updated);
                    el.val(updated);
                    runValidators(updated.replace(/(\s|\D)/g, ''));
                }
                
            });
            
            scope.$watch(function () {
                return ngModel.$modelValue;
            }, function () {
                runValidators();
            });

            ngModel.$formatters.push(function(newInput){
                return _getFormattedString(newInput || '');
            });

            ngModel.$parsers.push(function(viewValue){
                return viewValue.replace(/(\s|\D)/g, '').slice(0, 4);
            });
        }
    };
});
