/*
    Factory layer for the different 
*/
angular.module('PatientApp').service('GatewayFactoryService', function(NMIGatewayService){
    return {
        create: function(gatewayKey){
            var gateway = null;
            switch(gatewayKey.toLowerCase()){
                case 'nmi':
                    gateway = NMIGatewayService;
                    break;
            }
            return gateway;
        }
    };
});