angular.module('PatientApp').directive('noPoBox', function(){
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function(scope, el, attrs, ngModel){

            ngModel.$validators.noPoBoxRule = function(modelValue) {
                return !(/(p[\s\.]*o|post)[\s\.]*(office)?[\s\.]*(box\b|bin\b)/i.test(modelValue));
            };
        }
    };
});