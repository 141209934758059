angular.module('PatientApp').factory('PaymentPlansService', function( $q, $resource, endpoints){

    var PaymentPlans = $resource(endpoints.paymentPlans.primary.url, null, {
        fetchPaymentPlanDetails: {
            method: 'GET',
            params: {
                paymentId: '@planId'
            }
        },
        updatePaymentForm: {
            method: 'POST',
            url: endpoints.paymentPlans.updateMethod.url
        }
    });

    return {
        fetchPaymentPlanDetails: function(planId){
            return PaymentPlans.fetchPaymentPlanDetails({planId: planId}).$promise.then(function(resp){
                if(resp && resp.hasData()){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        },

        updatePaymentForm: function(planId, paymentFormId){
            return PaymentPlans.updatePaymentForm({
                planId: planId,
                paymentFormId: paymentFormId
            }).$promise.then(function(resp){
                if (resp && resp.hasData()){
                    return resp.getData();
                }
                return $q.reject(resp);
            });
        }
    };
});
