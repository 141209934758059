angular.module('PatientApp').controller('NotificationsCtrl', function($scope, $rootScope, $state, $filter, _, ErrorsService, NotificationsService, LoadingIndicatorService, ProvidersService){

    var _loadingKey,
        _reset = function() {
            $scope.showAllState = true;
            $scope.showDetailState = false;
        },
        _loadNotifications = function() {
            _loadingKey = LoadingIndicatorService.loading();
            NotificationsService.getNotifications().then(function(notifications){
                $scope.notifications = notifications;
                $scope.isJustOneNotification = notifications.length === 1;
                if ($scope.isJustOneNotification) {
                    $scope.viewDetails(0);
                }
            }).finally(function(){
                // stop the loading
                LoadingIndicatorService.complete(_loadingKey);
            });
        };
    $scope.currentNotification = null;
    $rootScope.$on('notifications:show', function() {
        //defaults
        $scope.showNotifications = true;
        _reset();
        _loadNotifications();
    });

    $scope.viewDetails = function(id) {
        $scope.showAllState = false;
        $scope.showDetailState = true;
        $scope.currentNotification = $scope.notifications[id];
        if (!$scope.currentNotification.isRead) {
            $scope.currentNotification.isRead = true;
            NotificationsService.setSeen();
            NotificationsService.updateNotificationReadLog($scope.currentNotification.id, true);
        }
    };

    $scope.viewAll = function() {
        $scope.showDetailState = false;
        $scope.showAllState = true;
    };

    $scope.messageProvider = function() {
        // To send a message, it must be associated with a bill
        // We are going to arbitrarily use the most recent bill for that provider
        ProvidersService.getProviderById($scope.currentNotification.providerId).then(function(resp) {
            var secureCode = _.head(resp.accounts).secureCode;
            $state.go('app.messages', {
                'autofill': true,
                'data': {
                    'sCode': secureCode,
                    'reason': 6 //the reasons are a static array. this is the "other" reason
                }
            });
            $scope.showNotifications = false;
        });
    };
});
