angular.module('PatientApp').controller('PaymentFlowCtrl', function($scope, $rootScope, $state, $filter, $window, $location, $log, _, BillsService, PaymentsService, PatientUsersService, FlowService, CardService, ErrorsService, ServerStatusService, Compass, Config, ProvidersService, UsageDataService, FinancingService, ExperimentService, endpoints, $timeout){

    // todo: Play debugger to watch trasition between verify and the payment flow to be broken
    //debugger;

    var firstRun = true,
        bill = BillsService.getCurrentBill(),
        user = PatientUsersService.getCurrentUser(),
        savedMethods = PatientUsersService.getSavedMethods(),
        abortingPayment = false,
        pay,
        flow,
        steps;

    $scope.bill = bill;
    $scope.user = user;

    // Controller on load checks
    // assert the minimum required state for the
    // payment flow to begin
    if(firstRun) {
        if(!bill){
            $state.go('app.default');
            return;
        }

        if($state.includes('*.payment.**') && !$state.includes('*.payment.start')){
            $state.go('app.payment.start');
            return;
        }

        firstRun = false;
    }

    // If account has been financed, alert user and go to dashboard
    if (bill.getAccountDetails() && bill.getAccountDetails().financedBy) {
        Compass.capture.misc('payment', 'attempt-to-pay-financed-account', 'secure-code', bill.secureCode);
        $state.go('app.dashboard');
        $scope.$emit('simpleModal:showPrompt',{
            header: $filter('translate')('dialogs.accountIsFinanced', bill.getAccountDetails()),
            subcontent: $filter('translate')('dialogs.contactFinancingVendor', bill.getAccountDetails()),
            intent: 'information',
            actions: [{
                label: $filter('translate')('actions.goToDashboard'),
                clickHandler: function(){}//Already there, just need to close modal
            }]
        });
    }

    Compass.capture.misc('payment', 'flow-selection-metadata', 'secure-code', bill.secureCode);

    // Log got to payment if first bill
    if ($rootScope.isFirstBill) {
        Compass.capture.event('first-bill', 'payment-flow', 'started');
    }

    // EXPERIMENT
    ExperimentService.getVariation('hsaMethod').then(function(resp) {
        if (resp.data && !$state.is('app.payment.method')) {
            $scope.method.showHsa = resp.data.showHsa;
            Compass.capture.event('payment', 'method', resp.data.showHsa ? 'gotHsaVariation' : 'gotNoHsaVariation');
        }
    });

    // This check is so that this change could be deployed before the backend without breaking
    if (!user.recentDeniedFinancingVendors) {
        user.recentDeniedFinancingVendors = [];
    }
    if (!bill.getProviderDetails().financingPartnersRequiringApplication) {
        bill.getProviderDetails().financingPartnersRequiringApplication = [];
    }
    if (!bill.getProviderDetails().financingPartnersAllowingPaymentPlans) {
        bill.getProviderDetails().financingPartnersAllowingPaymentPlans = [];
    }
    if (!bill.getProviderDetails().allFinancingPartners) {
        bill.getProviderDetails().allFinancingPartners = [];
    }

    // Remove financing payment forms that belong to a different provider
    savedMethods = _.reject(savedMethods, function(m){ return m.formType == 'financing' && m.providerId != $scope.bill.providerDetails.id; });

    // Create savedFinanceMethodVendors, which is a list of vendors (className) for any saved financing payment forms
    var savedFinanceMethodVendors = _.map(_.filter(savedMethods, function(m){ return m.formType == 'financing'; }), function(m){ return m.vendor; });
    $scope.financingPartnersRequiringApplication = _.difference(bill.getProviderDetails().financingPartnersRequiringApplication, user.recentDeniedFinancingVendors, savedFinanceMethodVendors);
    $scope.availableFinancingPartners = _.difference(bill.getProviderDetails().allFinancingPartners, user.recentDeniedFinancingVendors, savedFinanceMethodVendors);
    $scope.hasFinancingOption = bill.hasFinancingOption() && $scope.availableFinancingPartners.length > 0;

    // deniedFromAllFinancingVendors - if the user has no availible financing vendors but has been declined from some, we assume they've been declined from all of them.
    $scope.deniedFromAllFinancingVendors = $scope.availableFinancingPartners.length == 0 && user.recentDeniedFinancingVendors.length > 0;
    // financingHidesPaymentPlans - financing settings hide the payment plan option if there are no vendors that allow payment plans, the bill is greater than the minimum amount to finance.
    $scope.financingHidesPaymentPlans = bill.getProviderDetails().financingPartnersAllowingPaymentPlans.length == 0 && $scope.hasFinancingOption && !$scope.deniedFromAllFinancingVendors;

    if ($scope.hasFinancingOption) {
        UsageDataService.log('finance', 'HAS_FINANCE_OPTION', bill.secureCode);
        if (bill.getProviderDetails().isFinanceBtnOnFirstPage) {
            UsageDataService.log('finance', 'SAW_FINANCE_OPTION_FIRST', bill.secureCode);
            var loggableData = {patientUserId:PatientUsersService.getCurrentUser().patientUserId, vendor:bill.getProviderDetails().allFinancingPartners[0]};
            Compass.capture.misc('payment', 'financing', 'financingOptionsButtonShown', loggableData);

            // EXPERIMENT
            // Only get experiment if we're not on a phone/tablet
            if (!/(phone|mobile)/i.test(navigator.userAgent) && $scope.availableFinancingPartners[0] === 'Curae') {
                ExperimentService.getVariation('scrollableDisclaimers').then(function(resp) {
                    if (resp.data && !$state.is('app.payment.financing.application')) {
                        $scope.financing.scrollDisclaimersExperiment = true;
                        $scope.financing.scrollDisclaimers = resp.data.scrollDisclaimers;
                        Compass.capture.event('payment', 'financing', resp.data.scrollableDisclaimers ? 'gotScrollVariation': 'gotNoScrollVariation');
                    }
                });
            }
        }
    }

    pay = $scope.pay = PaymentsService.createPayment().applyBillVariant(bill);

    // checking the gateway status.  If gateway is down, don't allow a payment to be taken.
    if (!pay.isFormAccepted('card') && !pay.isFormAccepted('eCheck')) {
        $log.log('abandoning payment flow because of gateway status...');
        abortingPayment = true;
        $state.go('app.verify');
    }

    // for mobile. We will match what the browser back button would do
    $scope.goBack = function(){
        if ((flow.isCurrent('financingOffers') && $scope.financing.financingApplicationSubmitted) || $scope.financing.acceptedOption) {
            flow.goTo('amount');
        } else {
            $window.history.back();
        }
    };

    // holds the form references for the payment flow templates
    $scope.forms = {};
    $scope.processingNotifications = {};

    // dynamic data needed for translation areas
    $scope.translationData = {
        calculatedTotal: $filter('currency')(bill.billAmount),
        todayDate: $filter('dateFormat')(new Date(), 'longDate'),
        minAmount: $filter('currency')(0),
        maxAmount: $filter('currency')(bill.accountBalance.amount)
    };

    // the following is quasi-hack to get around the fact that our forms are submitting even
    // while async validators are still running.  we care about this because if this form is submitted
    // prior to the validator finishing, the routing number doesn't get bound to the model and we end-up
    // submitting an eCheck without a routing number.  the work-around here is to short-circuit the
    // controller logic which binds + moves to the next step, record the user's intention to move to the next
    // step and replay their intention once we hear back from the validator.
    $scope.resubmitPostRoutingNumberCheck = false;
    $scope.routingNumberValidated = function() {
        if ($scope.resubmitPostRoutingNumberCheck) {
            $scope.resubmitPostRoutingNumberCheck = false;
            $scope.method.submitNewMethod();
        }
    };

    $scope.amount = {

        // using server side calculated payment option
        // variations. We just take these server generated
        // options and display them correctly. We do not worry
        // about generating a pyment option other thant the
        // "Pay Another Amount" option
        paymentOptions: bill.getPaymentOptions(),

        customAmount: 0,

        installmentOptions: [],
        installmentOptionsKey: null, //this is a key used to identify the set of options that were given to the user.  used for data-collection
        substep: 'initial',
        installmentCount: null,

        question: $filter('translate')('payment.amount.question'),

        // select a payment option as opposed to selecting to
        // "Pay Another Amount"
        selectOption: function( index ){
            $scope.amount.installmentCount = null;
            $scope.amount.optionIndex = index;
            $scope.amount.customAmount = 0;
            $scope.amount.custom = false;

            pay.setAmount.billOptionAmount(index, /*installmentCount*/ null);

            // If user had chosen payment plan earlier, make sure they can now change the date
            $scope.date.forcePayToday = bill.getAccountDetails() && bill.getAccountDetails().isBadDebt;

            flow.continue();
        },

        // pay a custom amount as opposed to a predefined
        // amount based on the bill and provider variables
        payAnother: function(){
            $scope.amount.substep = 'diff';

            if(!$scope.amount.custom){
                // going to pay another requires the user
                // to explicitly enter an amount or
                // select a payment option
                pay.setAmount.clear();
            }
        },

        submitAnother: function(){

            if($scope.forms.customAmount.$invalid){
                return;
            }

            if($scope.amount.customAmount){

                $scope.amount.installmentCount = null;
                $scope.amount.custom = true;
                $scope.amount.optionIndex = null;
                pay.setAmount.custom( $scope.amount.customAmount );
                // If user had chosen payment plan earlier, make sure they can now change the date
                $scope.date.forcePayToday = bill.getAccountDetails() && bill.getAccountDetails().isBadDebt;
                flow.continue();
            }
        },

        reset: function(){
            $scope.amount.substep = 'initial';
            $scope.financeErrorMessage = null;
            $scope.financeOptionErrorMessage = null;
        },

        payViaPlan: function() {
            if ($scope.hasFinancingOption) {
                UsageDataService.log('finance', 'SAW_FINANCE_OPTION', bill.secureCode);
            }
            $scope.amount.substep = 'plans';
        },

        selectPlanOption: function( installmentCount ){
            $scope.amount.installmentCount = installmentCount;
            $scope.amount.optionIndex = null;
            $scope.amount.customAmount = 0;
            $scope.amount.custom = false;

            pay.setAmount.custom( bill.accountBalance.amount, installmentCount );

            if (bill.getProviderDetails().disableSchedulePaymentPlans) {
                $scope.date.forcePayToday = bill.getProviderDetails().disableSchedulePaymentPlans;
                $scope.date.payToday();
            }
            flow.continue();
        },

        messageProviderAboutPlans: function() {
            abortingPayment = true;

            if ($scope.amount.installmentOptionsKey) {
                UsageDataService.record(bill.getProviderDetails().id, 'payment-plan-usage-data', $scope.amount.installmentOptionsKey, 'abandon');
            }

            $state.go('app.messages', {
                'autofill': true,
                'data': {
                    'reason': 7, //the reasons are a static array. this is the "create a payment plan" reason
                    'message': 'I would like assistance in setting up a payment plan.'
                }
            });
        },

        goToPaymentPlan: function() {
            if (bill.getAccountDetails().currentPlan) {
                abortingPayment = true;
                $state.go('app.dashboard.provider.details.plan-receipt', {id: bill.getProviderDetails().id, planId: _.parseInt(bill.getAccountDetails().currentPlan.paymentPlanId)});
                Compass.capture.event('payment', 'paymentPlan', 'paymentFormViewPlanDetailsClicked');
            }
        },

        showPaymentPlans: function() {
            return $scope.amount.hasPlans && !$scope.amount.hideFinancing && !$scope.financingHidesPaymentPlans;
        }
    };

    $scope.amount.reviewFinancingButtonText = $filter('translate')('payment.amount.reviewFinancingOptions');
    if ($scope.availableFinancingPartners[0] === 'Curae' && ExperimentService.isExperimentLoaded('financeButtonText')) {
        var financeButtonTextExp = ExperimentService.getExperimentData('financeButtonText');
        if (financeButtonTextExp.data && financeButtonTextExp.data.financeButtonText) {
            $scope.amount.reviewFinancingButtonText = $filter('translate')('payment.amount.' + financeButtonTextExp.data.financeButtonText, bill.getProviderDetails().curaePreviewOffer);
            $scope.amount.showPaymentsAsLowAsDisclaimer = financeButtonTextExp.data.financeButtonText === 'paymentsAsLowAs';
            ExperimentService.sendStart('financeButtonText');
        }
    }

    var paymentPlanInstallmentOptions = bill.getProviderDetails().paymentPlanInstallmentOptions;

    if (paymentPlanInstallmentOptions) {
        var totalAmount = bill.accountBalance.amount,
            installmentOptionsForAmt = null,
            matchedBucketLimit = null;

        for (var bucketLimit in paymentPlanInstallmentOptions) {
            if (bucketLimit !== 'inf' && parseFloat(bucketLimit) >= totalAmount) {
                installmentOptionsForAmt = paymentPlanInstallmentOptions[bucketLimit];
                matchedBucketLimit = bucketLimit;
                break;
            }
        }

        if (installmentOptionsForAmt == null) {
            installmentOptionsForAmt = paymentPlanInstallmentOptions['inf'];  //nothing found.  use the last one.
            matchedBucketLimit = 'inf';
        }

        $scope.amount.installmentOptionsKey = bill.getProviderDetails().id + '_' + matchedBucketLimit + '_' + installmentOptionsForAmt.join('-');
        $scope.amount.installmentOptions = _.map(installmentOptionsForAmt, function(installmentCountOption) {
            var installmentAmt = PaymentsService.calculateInstallmentAmt(totalAmount, installmentCountOption),
                installmentCount = installmentCountOption,
                translationVars = {
                    installmentAmount: $filter('currency')(installmentAmt),
                    installmentCount: installmentCount
                };

            return {
                installmentCount: installmentCount,
                text: $filter('translate')('payment.amount.planInstallmentOption', translationVars)
            };
        });
        $scope.amount.hasPlans = $scope.amount.installmentOptions.length > 0;
    }

    $scope.date = {

        stateErrors : {},

        minDate: (new Date()).toDateString(),

        todayDate: $filter('translate')('payment.date.todayArg', $scope.translationData),

        getMaxDate: function(){
            return pay.getCurrentPaymentOptionMaxDate($scope.amount.optionIndex).toDateString();
        },

        // used to pull the date from the date picker directive
        customDate: null,

        customeDateSubmitted: false,

        forcePayToday: bill.getAccountDetails() && bill.getAccountDetails().isBadDebt,

        payToday: function(){

            pay.setDate.today();

            $scope.date.today = true;
            $scope.date.customDate = null;
            $scope.date.customeDateSubmitted = false;

            flow.continue();
        },

        payLater: function(){
            $scope.date.showLater = true;
            $scope.date.today = false;

            if(!$scope.date.customeDateSubmitted){
                // going into the pay later if they
                // have not selected one previously
                // will require the user to explicitly
                // select the today option or an item
                // from the picker
                pay.setDate.clear();
            }
        },

        submitLater: function(){
            if($scope.date.customDate){
                $scope.date.customeDateSubmitted = true;
                pay.setDate.later($scope.date.customDate);
                flow.continue();
            }else {

                // tell the user about the requirement
                $scope.date.stateErrors.required = true;
            }
        },

        dateSelected: function(){
            // remove the error on the page if we are showing it
            if($scope.date.stateErrors.required !== undefined){
                $scope.date.stateErrors.required = false;
            }
        },

        reset: function(){
            $scope.date.showLater = false;
        },

        clear: function(){
            $scope.date.showLater = false;
            $scope.date.customDate = null;
            $scope.date.customeDateSubmitted = false;
            $scope.date.today = false;
        },

        editDate: function(){
            if (!$scope.date.forcePayToday) {
                flow.goTo('date');
            }
        }
    };



    $scope.method = {
        notifications: {
            cardsHidden: false,
            eChecksHidden: false,
            cardsExpired: false
        },

        // EXPERIMENT
        showHsa: false,

        // Apple Card Scan variables and prcessors
        scannedCardYear: '',
        scannedCardMonth:'',
        scannedCardName: '',
        processScannedCard : function(){
            if($scope.method.scannedCardMonth && $scope.method.scannedCardYear){
                // format the scanned expiration which comes in as MM and YYYY
                $scope.method.card.expiration = [$scope.method.scannedCardMonth, $scope.method.scannedCardYear.slice(-2)].join('');
                // trigger the custom format event so angular will reformat the injected card number
                angular.element(document.getElementById('cc_number')).triggerHandler('format');
            }
            if($scope.method.scannedCardName){
                //apply scan sends us a single name field.  we have to do our best to split it into first and last name parts.  to handle situations where
                //we're sent three names, we're going to assume that the first and second words are considered the first name.
                var nameParts = $scope.method.scannedCardName.split(' ');
                $scope.method.card.firstName = _.dropRight(nameParts, /*elements to drop*/ 1).join(' ');
                $scope.method.card.lastName = _.last(nameParts);
            }
        },

        applyBank: function(bank){
            $scope.method.eCheck.bankName = bank;
        },

        currentForm: pay.isFormAccepted('eCheck') ? 'eCheck' : 'card',

        savedMethods: savedMethods,

        // determine based on if user has saved cards
        newMethod: savedMethods.length === 0,

        refreshMethods: function() {
            //re-filter methods so that we have the most recent list of allowed methods
            var acceptsCards = pay.isFormAccepted('card'),
                acceptsEChecks = pay.isFormAccepted('eCheck'),
                eChecksEnabled = pay.providerAcceptsForm('eCheck');

            $scope.method.notifications.cardsHidden = !acceptsCards && _.some(savedMethods, function(method){
                return _.trim(method.formType).toLowerCase() === 'card';
            });
            // Only show if eChecks are enabled and Getti is down
            $scope.method.notifications.eChecksHidden = eChecksEnabled && !ServerStatusService.getPaymentStatus('echeck') && _.some(savedMethods, function(method) {
                return _.trim(method.formType).toLowerCase() === 'echeck';
            });
            $scope.method.notifications.cardsExpired = _.some(savedMethods, function(method) {
                return method.formType.toLowerCase() === 'card' && pay.isExpiring(method.expDate);
            });
            $scope.method.savedMethods = _.filter(savedMethods, function(method){
                if (_.trim(method.formType).toLowerCase() === 'card') {
                    return acceptsCards;
                } else if(_.trim(method.formType).toLowerCase() === 'financing') {
                    // Do not show financing payment types on payment plans && the payment amount must be greater than the providers minimum amount to finance && payment amount must be less than or equal to the availible credit
                    return !pay.getInstallmentCount() && pay.getAmount() >= bill.getProviderDetails().minAmountForTransaction && pay.getAmount() <= method.availableCredit;
                } else {
                    return acceptsEChecks;
                }
            });
        },
        hideNotifications: function() {
            $scope.method.notifications.cardsHidden = false;
            $scope.method.notifications.eChecksHidden = false;
        },


        newMethodSubmitted: false,

        savedMethodIndex: null,

        // seeded initial values
        card: {
            firstName: user.firstName || '',
            lastName: user.lastName || ''
        },

        // seeded initial values
        eCheck: {
            firstName: user.firstName || '',
            lastName: user.lastName || ''
        },

        cardIcon: function(){
            var issuer = pay.getMethod.issuer($scope.method.card.number);
            return CardService.getIcon(issuer);
        },

        selectMethod: function(index){

            $scope.method.savedMethodIndex = index;

            pay.setMethod.savedMethod($scope.method.savedMethods[index]);

            $scope.method.newMethod = false;
            $scope.method.newMethodSubmitted = false;

            // we do not want a billing address when we
            // are using a saved method
            $scope.billing.dontRequire();

            flow.continue();
        },

        toggleNewForm: function(){
            $scope.method.newMethod = true;
            $scope.method.savedMethodIndex = null;

            // only if we haven't previously submited a new form
            if(!$scope.method.newMethodSubmitted){

                // the user has deselected any possible selected
                // saved methods and has gone into creating a new
                // form, clear any saved data about previous method
                // selections so the user has to explicitly complete
                // the new form or select a saved form
                pay.setMethod.clear();
            }
        },

        toggleCard: function(){
            $scope.method.currentForm = 'card';
        },
        toggleECheck: function(){
            $scope.method.currentForm = 'eCheck';
        },
        toggleHsa: function() {
            $scope.method.currentForm = 'hsa';
        },
        submitNewMethod : function(){

            $scope.method.savedMethodIndex = null;

            // remove any data that might be hanging around from
            // swapping the method types
            if($scope.method.currentForm === 'card' || $scope.method.currentForm === 'hsa') {

                if($scope.forms.ccForm.$invalid){
                    return;
                }

                pay.setMethod.card($scope.method.card);

            } else {

                if($scope.forms.echeckForm.$invalid){
                    return;
                }

                if ($scope.forms.echeckForm.routing.$pending) {
                    $scope.resubmitPostRoutingNumberCheck = true;
                    return;
                }

                pay.setMethod.eCheck($scope.method.eCheck);
            }

            $scope.method.newMethodSubmitted = true;

            // make sure we are getting a billing address
            $scope.billing.require();

            flow.continue();
        },

        reset : function(){
            $scope.method.hideNotifications();
            $scope.method.refreshMethods();

            if(($scope.method.savedMethods || []).length > 0){
                $scope.method.newMethod = false;
            }
        },

        clear: function(){
            $scope.method.currentForm = pay.isFormAccepted('eCheck') ? 'eCheck' : 'card';
            $scope.method.savedMethodIndex = null;
            $scope.method.newMethodSubmitted = false;
        }
    };

    $scope.billing = {

        dynamicQuestion: function(){
            return $filter('translate')('payment.billing.question', { methodSummary : pay.getMethod.shortDesc()});
        },

        guarantorBilling: bill.getGuarantorAddress(),

        showForm: !bill.getGuarantorAddress(),

        submittedGuarantor: false,

        submittedAnother: false,

        fields : {},

        // this state is needed because saved payment methods
        // do not require billing input. So don't show or ask for it
        // don't directly set this use the helper functions below
        required: true,

        dontRequire: function(){
            $scope.billing.required = false;
        },

        require: function(){
            if(!$scope.billing.required){
                $scope.billing.required = true;
            }
        },

        submitGuarantor: function(){
            $scope.billing.submittedGuarantor = true;
            $scope.billing.submittedAnother = false;

            pay.setBilling.useForm($scope.billing.guarantorBilling);

            flow.continue();
        },

        newAddress : function(){

            $scope.billing.showForm = true;

            if($scope.billing.submittedGuarantor){
                $scope.billing.submittedGuarantor = false;
                pay.setBilling.clear();
            }
        },

        submitAnother : function(){

            if($scope.forms.billing.$invalid){
                return;
            }

            $scope.billing.submittedAnother = true;

            pay.setBilling.useForm($scope.billing.fields);
            flow.continue();
        },

        reset : function(){
            if($scope.billing.guarantorBilling){
                $scope.billing.showForm = false;
            }
        }
    };

    $scope.confirm = {
        confirmDialog: $filter('translate')('actions.processPayment'),
        submitting: false,
        gatewayRetryRequested: false,
        reset: function() {
            if ($scope.pay.scheduledFuture()) {
                $scope.confirm.confirmDialog = $filter('translate')('actions.processPaymentFuture');
            } else if ($scope.confirm.gatewayRetryRequested) {
                $scope.confirm.confirmDialog = $filter('translate')('actions.retryProcessPayment');
            } else {
                $scope.confirm.confirmDialog = $filter('translate')('actions.processPayment');
            }
            $scope.confirm.submitting = false;
            flow.allowNavigation(true);
        },
        makePayment: function(){

            // don't allow people to double submit payment button
            if($scope.confirm.submitting){
                return;
            }

            $scope.processingNotifications = {};

            if (pay.isExpiring()) {
                $scope.processingNotifications.prematureExpiration = true;
                return;
            }

            if ($scope.pay.scheduledFuture()) {
                $scope.confirm.confirmDialog = $filter('translate')('labels.submittingPaymentFuture');
            } else {
                $scope.confirm.confirmDialog = $filter('translate')('labels.submittingPayment');
            }

            $scope.confirm.submitting = true;

            flow.allowNavigation(false);

            ExperimentService.sendSuccess('hsaMethod');
            Compass.capture.misc('payment', 'completed-metadata', 'securecode', bill.secureCode);
            Compass.capture.metricValue('payment', 'completed-metadata', 'amount', pay.getAmount());
            Compass.capture.misc('payment', 'completed-metadata', 'amount-option-type', pay.getAmountOptionType());
            Compass.capture.misc('payment', 'completed-metadata', 'installment-count', pay.getInstallmentCount());

            if ($rootScope.isFirstBill) {
                Compass.capture.event('first-bill', 'payment-flow', 'completed');
                $rootScope.isFirstBill = false;
            }

            pay.processPayment(/*resuming*/ $scope.confirm.gatewayRetryRequested).then(function(result){

                result.providerId = bill.getProviderDetails().id;

                ProvidersService.flushAcctActivity(bill.accountNumber);

                // reset the core requirement for payment flow
                $scope.bill = null;
                bill = null;
                BillsService.flushCurrentBill();
                $scope.confirm.gatewayRetryRequested = false;

                if (pay.getMethod.isForm('financing')) {
                    $scope.financing.financingSuccess(result);
                } else {
                    // go to dashboard
                    $state.go('app.dashboard');

                    // overlay the thank you modal
                    $scope.$emit('simpleModal:showPrompt',{
                        header: $filter('translate')('dialogs.thanksForPayment'),
                        subcontent: $filter('translate')('dialogs.emailSentTo', { email: user.email }),
                        intent: 'success',
                        actions: [{
                            label: $filter('translate')('actions.payAnotherBill'),
                            clickHandler: function(){
                                $rootScope.$emit('newBill:showPrompt',{
                                    showAcctsWithBalances: true
                                });
                            }
                        }, {
                            label: $filter('translate')('actions.openReceipt'),
                            clickHandler: function(){
                                if (pay.getInstallmentCount() && pay.getInstallmentCount() > 1) {
                                    $state.go('app.dashboard.provider.details.plan-receipt', {id: result.providerId, planId: result.planId});
                                }
                                else {
                                    $state.go('app.dashboard.provider.details.receipt', {id: result.providerId, receiptId: result.paymentId});
                                }
                            }
                        }]
                    });
                }

                Compass.capture.success('payment', 'processed');
            })
            .catch(function(resp){

                Compass.capture.failure('payment', 'processed');
                Compass.capture.event('payment', 'failureReason', ErrorsService.resolve(resp) || 'NO_REASON_AVAILABLE');

                switch(ErrorsService.resolve(resp)){
                    case ErrorsService.errors.GATEWAY_RETRY_REQUESTED:
                        $scope.confirm.gatewayRetryRequested = true;
                        $scope.processingNotifications.retryProcess = true;
                        break;

                    case ErrorsService.errors.METHOD_UNAVAILABLE:
                        if (pay.isFormAccepted('card') || pay.isFormAccepted('eCheck')) {
                            //other methods available.  filter the methods that are shown and encourage them to go back to methods screen
                            $scope.processingNotifications.cardsUnavailable =  !pay.isFormAccepted('card');
                            $scope.processingNotifications.eChecksUnavailable = !pay.isFormAccepted('eCheck');
                            $scope.method.clear();
                        } else {
                            //no payment form is available.  user is allowed to go back and change their method, but it will most likely not work and they
                            //will keep getting error message until they give up.
                            $scope.processingNotifications.processingError = true;
                            $scope.method.clear();
                        }
                        break;

                    case ErrorsService.errors.PAYMENT_DECLINED:
                        $scope.processingNotifications.declinedCard = true;
                        break;

                    case ErrorsService.errors.PREMATURE_EXPIRATION:
                        $scope.processingNotifications.prematureExpiration = true;
                        break;

                    case ErrorsService.errors.PAYMENT_INVALID_CARD_NUMBER:
                    case ErrorsService.errors.PAYMENT_CARD_EXPIRED:
                        $scope.processingNotifications.invalidCardInformation = true;
                        break;

                    case ErrorsService.errors.PAYMENT_DUPLICATE:
                        $scope.processingNotifications.duplicatePayment = true;
                        break;
                    case ErrorsService.errors.UNKNOWN_ERROR:
                    case ErrorsService.errors.INVALID_PARAMETERS:
                    case ErrorsService.errors.PAYMENT_PROCESSING_ERROR:
                    case ErrorsService.errors.ACCOUNT_PAID_IN_FULL:
                    case ErrorsService.errors.NETWORK_ERROR:
                        $scope.processingNotifications.processingError = true;
                        $scope.confirm.gatewayRetryRequested = false;
                        break;
                    default:
                        $scope.processingNotifications.processingError = true;
                        break;
                }


                    // reset the dialog
                $scope.confirm.reset();

            });
        }
    };

    var guarantorAddress = bill.getGuarantorAddress();

    if(guarantorAddress) {
        var address = guarantorAddress.address || '';
        var address2 = guarantorAddress.address2 || '';
        var city = guarantorAddress.city || '';
        var state = guarantorAddress.state || '';
        var zip = guarantorAddress.zip || '';
    }
    $scope.financing = {
        application: {
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            email: user.email || '',
            phone: user.phoneNumber || '',
            ssn: '',
            birthdate: '',
            secureCode: bill.secureCode,
            vendors: $scope.availableFinancingPartners,
            electronicDisclosureConsent: false,
            income: '',
            address: address,
            address2: address2,
            city: city,
            state: state,
            zip: zip
        },

        providerName: bill.getProviderDetails().name,
        financingApplicationSubmitted: false,
        acceptedOption: false,

        // EXPERIMENT
        scrollDisclaimers: false,
        scrollDisclaimersExperiment: false,

        billRequiresFinancingApplication: function() {
            return $scope.financingPartnersRequiringApplication && $scope.financingPartnersRequiringApplication.length > 0;
        },

        isFinancingApplicationComplete: function() {
            return $scope.forms.financingApplication && $scope.forms.financingApplication.$valid;
        },

        getFinancingOptions: function() {
            var loggableData = {patientUserId:PatientUsersService.getCurrentUser().patientUserId, vendor:bill.getProviderDetails().allFinancingPartners[0]};
            Compass.capture.misc('payment', 'financing', 'runningGetOptions', loggableData);
            if ($scope.financing.billRequiresFinancingApplication()) {
                // Financing application is required
                if ($scope.financing.isFinancingApplicationComplete()) {
                    // Financing application is complete
                    var loggableApplication = angular.copy($scope.financing.application);
                    if (loggableApplication.ssn.length > 0) {
                        loggableApplication.ssn = '***-**-****';
                    }
                    if (loggableApplication.income != null) {
                        loggableApplication.income = '$******.**';
                    }
                    Compass.capture.misc('payment', 'financing', 'getOptionsApplication', loggableApplication);

                    // EXPERIMENT
                    // The button was clicked, report successful experiment
                    if ($scope.financing.scrollDisclaimersExperiment) {
                        ExperimentService.sendSuccess('scrollableDisclaimers');
                    }

                    $scope.financing.sendGetOptions(function(){
                        // Is there an existing account?
                        if ($scope.financing.existingAccount) {
                            // If so, go to existing account page
                            Compass.capture.event('payment', 'financing', 'foundExistingAccount');
                            Compass.capture.misc('payment', 'financing', 'numOptionsReturned', $scope.financing.optionsCount);
                            flow.goToSubFlow();
                        } else {
                            // There was not an existing account found, so let's see if we got new options back...
                            // Are there offers to present?
                            if ($scope.financing.optionsCount > 0) {
                                // If so, continue to the offers page
                                Compass.capture.success('payment', 'financingFoundOptions');
                                Compass.capture.misc('payment', 'financing', 'numOptionsReturned', $scope.financing.optionsCount);
                                flow.continue();
                            } else {
                                // If not, go back to the amount page and show a failure message. User must choose a different payment option.
                                // We didn't find any options after applying, so start payment flow over with no option found messaging.
                                Compass.capture.failure('payment', 'financingFoundOptions');
                                $scope.amount.reset();
                                $scope.amount.question = $filter('translate')('payment.amount.noFinancialOptionsFound');
                                $scope.amount.subquestion = $filter('translate')('payment.amount.selectDifferent');
                                $scope.amount.hideFinancing = true;
                                // Update the user with the denial so that he doesn't try to apply again
                                user.recentDeniedFinancingVendors = user.recentDeniedFinancingVendors.concat($scope.financing.application.vendors);
                                $scope.availableFinancingPartners = _.without($scope.availableFinancingPartners, $scope.financing.application.vendors);
                                $scope.deniedFromAllFinancingVendors = $scope.availableFinancingPartners.length == 0 && user.recentDeniedFinancingVendors.length > 0;
                                flow.goTo('amount');
                            }
                        }
                    }, function(){
                        Compass.capture.failure('payment', 'financingGetOptions');
                    });
                } else {
                    // Financing application is not complete, let's make sure we show it
                    if (flow.isCurrent('amount')) {
                        flow.goToSubFlow();
                        ExperimentService.sendSuccess('financeButtonText');
                    }
                }
            } else {
                // Financing application is not required, user is coming from amount page
                $scope.financing.sendGetOptions(function(){
                    // Are there offers to present?
                    if ($scope.financing.optionsCount == 0) {
                        // If no offers are present, show an error
                        // We didn't find any options and are already in amount step, so just show inline no options found message.
                        Compass.capture.failure('payment', 'financingFoundOptions');
                        $scope.financeErrorMessage = $filter('translate')('payment.amount.noFinancialOptionsFound');
                    } else {
                        // Otherwise, go to the offers page
                        Compass.capture.success('payment', 'financingFoundOptions');
                        Compass.capture.misc('payment', 'financing', 'numOptionsReturned', $scope.financing.optionsCount);
                        flow.goToSubFlow();
                    }
                }, function(){
                    Compass.capture.failure('payment', 'financingGetOptions');
                });
            }
        },

        sendGetOptions: function(successCallback, failureCallback) {
            // Set default vaules for callbacks
            if (_.isUndefined(failureCallback)) {
                failureCallback = function(){};
            }
            if (_.isUndefined(successCallback)) {
                successCallback = function(){};
            }

            $scope.isLoading = true;
            $scope.financeErrorMessage = null;
            $scope.financeOptionErrorMessage = null;
            $scope.financingOptionIndexChosen = null;
            $scope.financing.existingAccount = null;
            FinancingService.getOptions($scope.financing.application).then(function(financingOptions) {
                $scope.financing.financingApplicationSubmitted = true;
                $scope.financing.options = {};
                $scope.financing.optionsCount = 0;

                _.forEach(financingOptions, function(vendorDetails, vendor) {
                    $scope.financing.options[vendor] = {};
                    $scope.financing.options[vendor]['options'] = _.map(vendorDetails.options, function(option) {
                        if(option.isExistingAccount){
                            // User has an existing account with this vendor, exit and go to existing account association page
                            $scope.financing.existingAccount = option;
                            $scope.financing.chargeAmount = option.amountDueNotCovered ? option.availableCreditFloat : bill.accountBalance.amount;
                            if (!_.isUndefined(option.paymentForms) && _.isArray(option.paymentForms) && !_.isEmpty(option.paymentForms)) {
                                savedMethods.push(option.paymentForms[0]);
                                pay.setMethod.savedMethod(option.paymentForms[0]);
                            }
                        }else{
                            option.vendorName = vendorDetails.name;
                            option.currencyMonthlyAmount = $filter('currency')(option.amount);
                            if (option.creditLimit) {
                                $scope.financing.options[vendor]['hasCreditLimit'] = true;
                                option.currencyCreditLimit = $filter('currency')(option.creditLimit);
                                $scope.financing.chargeAmount = option.amountDueNotCovered ? option.creditLimit : bill.accountBalance.amount;
                            } else {
                                option.text = $filter('translate')(option.hasInterest ? 'payment.financing.offers.financingOptionInterestBearing' : 'payment.financing.offers.financingOptionInterestFree', option);
                            }
                            option.index = ++$scope.financing.optionsCount;
                            if (option.hasInterest) {
                                $scope.financing.options[vendor]['hasInterestBearing'] = true;
                            } else {
                                $scope.financing.options[vendor]['hasInterestFree'] = true;
                            }
                            if (option.deferredInterestMonths) {
                                $scope.financing.options[vendor]['hasDeferredInterest'] = true;
                                option.text += $filter('translate')('payment.financing.offers.financingOptionDeferredInterest', option);
                            }
                        }

                        return option;
                    });

                    if (vendorDetails.options.length) {
                        $scope.creditLimit = vendorDetails.options[0].creditLimit;
                        $scope.currencyCreditLimit = vendorDetails.options[0].currencyCreditLimit;
                        if (vendorDetails.options[0].summaryOfTerms) {
                            var termsUrlInfo = {
                                termsUrl: FinancingService.getDownloadUrl(vendor, vendorDetails.options[0].summaryOfTerms, 'Summary of Terms.pdf'),
                                vendor: vendorDetails.name
                            };
                            $scope.financing.options[vendor]['termsUrl'] = $filter('translate')('payment.financing.summary.reviewTerms', termsUrlInfo);
                        }
                    }

                    $scope.financing.options[vendor]['disclaimerFinancingOptions'] = bill.getProviderDetails().disclaimers.financingOptions.replace(/\|vendor\|/g, vendorDetails.name);
                    $scope.financing.options[vendor]['disclaimerInterestFree'] = bill.getProviderDetails().disclaimers.interestFree.replace(/\|vendor\|/g, vendorDetails.name);
                    $scope.financing.options[vendor]['disclaimerInterestBearing'] = bill.getProviderDetails().disclaimers.interestBearing.replace(/\|vendor\|/g, vendorDetails.name);
                });

                successCallback();
                $scope.isLoading = false;
            })
            .catch(function() {
                failureCallback();
                $scope.isLoading = false;
                $scope.financeErrorMessage = $filter('translate')('payment.amount.getFinancingOptionsErrorMessage');
            });
        },

        getFinancingVendorLogoEndpoint: function(vendorName) {
            return endpoints.financing.getVendorLogo.url + '/' + vendorName;
        },

        getFinancingVendorDocEndpoint: function(vendorName, documentName) {
            return endpoints.financing.getFinancingVendorDoc.url + '/' + vendorName + '/' + documentName;
        },

        chooseFinancingOption: function(vendor, option) {
            Compass.capture.event('payment', 'financing', 'chooseOptionClicked');
            UsageDataService.log('finance', 'FINANCING_ACCEPT_OPTION', bill.secureCode);
            $scope.financing.selectedOption = option;
            $scope.financingOptionIndexChosen = option.index;
            $scope.financing.vendorChosen = vendor;
            $scope.financeOptionErrorMessage = null;
            if ($scope.financing.billRequiresFinancingApplication() && flow.isCurrent('financingOffers')) {
                Compass.capture.event('payment', 'financing', 'goingToConfirmationPage');
                flow.continue();
            } else {
                $scope.isLoading = true;
                FinancingService.chooseOption(bill.secureCode, vendor, option).then(function(resp) {
                    $scope.isLoading = false;
                    $scope.financing.acceptedOption = true;
                    if (resp.redirectUrl) {
                        Compass.capture.event('payment', 'financing', 'chooseOptionHasRedirectUrl');
                        Compass.capture.misc('payment', 'financing', 'chooseOptionRedirectUrl', resp.redirectUrl);
                        $scope.$emit('simpleModal:showPrompt', {
                            header: $filter('translate')('payment.financing.success.financingRedirectHeader'),
                            intent: 'success',
                            subcontent: $filter('translate')('payment.financing.success.financeRedirectSubContent', resp)
                        });
                        $timeout(function() {
                            // Try to open a new tab, but if that don't work then redirect in current tab
                            var newWin = $window.open(resp.redirectUrl, '_blank');
                            if(!newWin || newWin.closed || typeof newWin.closed=='undefined') {
                                Compass.capture.event('payment', 'financing', 'redirectingToFinancingVendor');
                                $window.location.href = resp.redirectUrl;
                            }
                        }, $window.backendless ? 500 : 4000);
                    } else {
                        $scope.financing.paymentFormId = resp.paymentFormId;
                        $scope.financing.cardholderAgreementUrl = FinancingService.getDownloadUrl(vendor, resp.cardholderAgreement, 'Cardholder Agreement.pdf');
                        $scope.financing.cardholderAgreementDisclaimer = $filter('translate')('payment.financing.confirm.cardholderAgreementDisclaimer', $scope.financing);
                        Compass.capture.event('payment', 'financing', 'goingToSummaryStep');
                        flow.continue();
                    }
                })
                .catch(function() {
                    Compass.capture.failure('payment', 'financingChooseOption');
                    $scope.isLoading = false;
                    $scope.financeOptionErrorMessage = $filter('translate')('payment.financing.offers.chooseFinancingOptionErrorMessage');
                });
            }
        },

        triggerIncomeModal: function() {
            $rootScope.$emit('simpleModal:showPrompt', {
                header: $filter('translate')('payment.financing.application.monthlyIncomeHeader'),
                subcontent: $filter('translate')('payment.financing.application.monthlyIncomeBody'),
                actions: [{
                    label: $filter('translate')('actions.close'),
                    includeClasses: 'button-primary'
                }]
            });
        },

        getElectronicDisclosureConsentText: function(vendor, doc) {
            return $filter('translate')('payment.financing.application.electronicDisclosureConsentText', {electronicDisclosureConsentLink: $scope.financing.getFinancingVendorDocEndpoint(vendor, doc)});
        },

        submitConfirmation: function() {
            if ($scope.forms.financingConfirm && $scope.forms.financingConfirm.$valid) {
                $scope.isLoading = true;
                $scope.financeOptionErrorMessage = null;
                Compass.capture.event('payment', 'financing', 'clickedConfirmation');

                var chargeFinancingPaymentFormBody = {
                    paymentFormId: $scope.financing.paymentFormId,
                    secureCode: bill.secureCode,
                    cardholderAgreementCheck: $scope.financing.cardholderAgreementCheck,
                    authorizeTransactionCheck: $scope.financing.authorizeTransactionCheck,
                    cardholderAgreementUrl: $scope.financing.cardholderAgreementUrl
                };

                FinancingService.chargeFinancingPaymentFormFirstTime(chargeFinancingPaymentFormBody).then(function(resp) {
                    Compass.capture.success('payment', 'financingConfirmation');
                    $scope.isLoading = false;
                    $scope.financing.financingSuccess(resp);
                })
                .catch(function() {
                    Compass.capture.failure('payment', 'financingConfirmation');
                    $scope.isLoading = false;
                    $scope.financeOptionErrorMessage = $filter('translate')('payment.financing.offers.chooseFinancingOptionErrorMessage');
                });
            }
        },

        submitExistingAccountConfirmation: function() {
            if($scope.forms.financingExistingAccountConfirm && $scope.forms.financingExistingAccountConfirm.$valid) {
                $scope.isLoading = true;
                Compass.capture.event('payment', 'financing', 'clickedConfirmExistingAccount');

                var chargeExistingFinancingAccountBody = {
                    authorizeTransactionCheck: $scope.financing.authorizeTransactionCheck,
                    secureCode: bill.secureCode,
                    paymentFormId: pay.getMethod.id()
                };

                FinancingService.chargeExistingFinancingAccount(chargeExistingFinancingAccountBody).then(function(resp) {
                    Compass.capture.success('payment', 'financingConfirmExistingAccount');
                    $scope.isLoading = false;
                    abortingPayment = true;
                    $scope.financing.financingSuccess(resp);
                })
                .catch(function() {
                    Compass.capture.failure('payment', 'financingConfirmExistingAccount');
                    $scope.isLoading = false;
                    $scope.financeExistingAccountErrorMessage = $filter('translate')('payment.financing.existingAccount.submitExistingAccountConfirmationErrorMessage');
                });
            }
        },

        financingSuccess: function(resp) {
            Compass.capture.event('payment', 'financing', 'financingSuccess');
            pay.setCompleted(true);
            $state.go('app.dashboard');
            $scope.$emit('simpleModal:showPrompt', {
                header: $filter('translate')('payment.financing.success.financingSuccessHeader', resp),
                subcontent: $filter('translate')('payment.financing.success.financeSuccessSubContent', resp),
                intent: 'success',
                actions: [{
                    label: $filter('translate')('actions.payAnotherBill'),
                    clickHandler: function(){
                        $rootScope.$emit('newBill:showPrompt',{
                            showAcctsWithBalances: true
                        });
                    }
                }, {
                    label: $filter('translate')('actions.openReceipt'),
                    clickHandler: function(){
                        $state.go('app.dashboard.provider.details.receipt', {id: resp.providerId || bill.getProviderDetails().id, receiptId: resp.paymentId});
                    }
                }]
            });
        }
    };

    /***************
    STEP DEFINITIONS
    ***************/

    steps = {
        amountStep : {
            name: 'amount',
            routeState: 'app.payment.start',
            stepNum: 1,
            numSteps: 5,
            subFlow: $scope.financing.billRequiresFinancingApplication() ? 'financingApplication' : 'financingOffers',
            canExit : function(){
                return pay.validity.amount();
            },

            onEnter: function(){
                $scope.amount.reset();
                if ($scope.hasFinancingOption && bill.getProviderDetails().isFinanceBtnOnFirstPage) {
                    UsageDataService.log('finance', 'SAW_FINANCE_OPTION_FIRST', bill.secureCode);
                }
            }
        },
        dateStep : {
            name: 'date',
            routeState: 'app.payment.date',
            stepNum: 2,
            numSteps: 5,
            canExit : function(){
                return pay.validity.date();
            },
            onEnter: function(){
                // if we are entering this page
                // and the validity has gone bad
                // we need to clear selections and
                // make user pick again
                if(pay.validity.date()){
                    $scope.date.reset();
                }else {
                    $scope.date.clear();
                }
            }
        },

        methodStep : {
            name: 'method',
            routeState: 'app.payment.method',
            stepNum: 3,
            numSteps: 5,
            canExit: function(){
                return pay.validity.method();
            },
            onEnter: function(){
                // EXPERIMENT
                ExperimentService.sendStart('hsaMethod');
                $scope.method.reset();
            }
        },

        billingStep : {
            name: 'billing',
            routeState: 'app.payment.billing',
            stepNum: 4,
            numSteps: 5,
            canExit: function(){
                return pay.validity.billing();
            },
            onEnter: function(){
                if(!$scope.billing.required && pay.validity.billing()){
                    flow.continue();
                }else {
                    $scope.billing.reset();
                }
            }
        },

        confirmStep : {
            name:'confirm',
            routeState: 'app.payment.confirm',
            stepNum: 5,
            numSteps: 5,
            onEnter: function(){

                if(($scope.processingNotifications.invalidCardInformation || $scope.processingNotifications.declinedCard || $scope.processingNotifications.cardsUnavailable || $scope.processingNotifications.eChecksUnavailable) && !pay.preProcessDirty('method')) {
                    // if we have an invalid card error, we will not remove the
                    // errors until the card is updated
                    return;
                } else if(pay.preProcessDirty()) {
                    // reset any errors that may be visible
                    $scope.processingNotifications = {};
                }

                $scope.confirm.reset();

            },
            canExit: function(){
                // add confirmation step checks
                return false;
            }
        },

        financingApplicationStep : {
            name: 'financingApplication',
            routeState: 'app.payment.financing.application',
            isFinancingStep: true,
            suppressApplication: false,
            suppressOffers: false,
            suppressSummary: false,
            isSubFlow: true,
            stepNum: 2,
            numSteps: 5,
            subFlow: 'financingExistingAccount',
            canExit: function(){
                return true;
            },
            onEnter: function(){
                if (!$scope.hasFinancingOption || $scope.amount.hideFinancing) {
                    flow.goTo('amount');
                } else if ($scope.financing.acceptedOption) {
                    $scope.isLoading = false;
                    flow.goTo('financingConfirm');
                } else if ($scope.financing.financingApplicationSubmitted) {
                    if (flow.isCurrent('financingOffers')) {
                        // going back in browser
                        flow.goTo('amount');
                    } else {
                        // going forward in browser
                        flow.goTo('financingOffers');
                    }
                } else {
                    Compass.capture.event('payment', 'financing', 'loadedFinancingApplication');

                    // EXPERIMENT
                    if ($scope.financing.scrollDisclaimersExperiment) {
                        ExperimentService.sendStart('scrollableDisclaimers');
                    }
                }
            }
        },

        financingOffersStep : {
            name: 'financingOffers',
            routeState: 'app.payment.financing.offers',
            isFinancingStep: true,
            suppressApplication: true,
            suppressOffers: false,
            suppressSummary: false,
            isSubFlow: true,
            stepNum: 3,
            numSteps: $scope.financing.billRequiresFinancingApplication() ? 5 : 3,
            canExit: function(){
                return true;
            },
            onEnter: function(){
                // going back in flow & safety in case someone enters URL
                if ($scope.financing.acceptedOption) {
                    flow.goTo('amount');
                } else {
                    Compass.capture.event('payment', 'financing', 'loadedFinancingOffers');
                }
            },
            onBack: function(){
                if ($scope.financing.financingApplicationSubmitted) {
                    flow.goTo('amount');
                }
            }
        },

        financingSummaryStep : {
            name: 'financingSummary',
            routeState: 'app.payment.financing.summary',
            isFinancingStep: true,
            suppressApplication: true,
            suppressOffers: false,
            suppressSummary: false,
            isSubFlow: true,
            stepNum: 4,
            numSteps: 5,
            canExit: function(){
                return true;
            },
            onEnter: function(){
                if ($scope.financing.acceptedOption) {
                    flow.goTo('financingConfirm');
                } else {
                    Compass.capture.event('payment', 'financing', 'loadedFinancingSummary');
                }
            }
        },

        financingConfirmStep : {
            name: 'financingConfirm',
            routeState: 'app.payment.financing.confirm',
            isFinancingStep: true,
            suppressApplication: true,
            suppressOffers: true,
            suppressSummary: true,
            isSubFlow: true,
            stepNum: 5,
            numSteps: 5,
            canExit: function(){
                return true;
            },
            onEnter: function(){
                Compass.capture.event('payment', 'financing', 'loadedFinancingConfirm');
            },
            onBack: function(){
                flow.goTo('amount');
            }
        },

        financingExistingAccountStep : {
            name: 'financingExistingAccount',
            routeState: 'app.payment.financing.existingAccount',
            isFinancingStep: true,
            isSubFlow: true,
            stepNum: 3,
            numSteps: 3,
            canExit: function(){
                return true;
            },
            onEnter: function(){
                Compass.capture.event('payment', 'financing', 'loadedFinancingExistingAccount');
            }
        }
    };

    flow =  FlowService.createFlow()
                        .bindToRoute('*.payment.**', function leavingPaymentBoundRoute(){

                            var inFirstStepSubBranch = false;

                            // we need to allow a user to come out of $state.is('app.payment.start')
                            // but if we are on the sub branch we are going to ask to pull the user
                            // out of that sub branch first, then subsequent backs will take them out of the
                            // payment flow as expected
                            if($state.is('app.payment.start') && !abortingPayment){
                                inFirstStepSubBranch = $scope.amount.substep !== 'initial';
                                $scope.amount.reset();
                            }

                            return inFirstStepSubBranch || (!abortingPayment && !pay.completed() && !confirm($filter('translate')('dialogs.leavingPaymentFlow')));

                        })
                        .step(steps.amountStep)
                        .step(steps.dateStep)
                        .step(steps.methodStep)
                        .step(steps.billingStep)
                        .step(steps.confirmStep);

    if ($scope.financing.billRequiresFinancingApplication()) {
        flow.step(steps.financingApplicationStep)
            .step(steps.financingOffersStep)
            .step(steps.financingSummaryStep)
            .step(steps.financingConfirmStep)
            .step(steps.financingExistingAccountStep);
    } else {
        flow.step(steps.financingOffersStep);
    }

    $scope.flow = flow.init();

    $scope.reverseTransition = flow.reverseTransition;


    /***********
    DEEP LINKING
    ************/
    if ($state.params.goto === 'plans' && $scope.amount.hasPlans) {
        Compass.capture.event('payment', 'goto', 'plans');
        $scope.amount.payViaPlan();
    } else if ($state.params.goto === 'financing') {
        if ($scope.financing.billRequiresFinancingApplication()) {
            Compass.capture.event('payment', 'goto', 'financing');
            $scope.financing.getFinancingOptions();
        }
    }
});
